import React, { useContext, useEffect } from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { ModalProvider } from '@console/common/contexts/ModalContext'

import Creation from './Creation'
import Edit from './Edit'
import Home from './Home'

const Customers: React.FC = () => {
  const { path } = useRouteMatch()

  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS_CUSTOMERS)
  }, [analytics])

  return (
    <Switch>
      <Route exact path={path}>
        <ModalProvider>
          <Home />
        </ModalProvider>
      </Route>
      <Route path={`${path}/create`}>
        <ModalProvider>
          <Creation />
        </ModalProvider>
      </Route>
      <Route path={`${path}/edit/:customerId`}>
        <ModalProvider>
          <Edit />
        </ModalProvider>
      </Route>
    </Switch>
  )
}

export default Customers
