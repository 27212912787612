import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'

import { nanoid } from 'nanoid/non-secure'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import CameraIcon from '@console/common/assets/simpleCamera.svg'
import Button from '@console/common/components/Button'
import CarouselCard from '@console/common/components/CarouselCard'
import MultiCarousel from '@console/common/components/MultiCarousel'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { upload } from '@console/common/services/upload'
import theme from '@console/common/theme.json'

import { Images, MultipleContext } from '../../../contexts/MultipleContext'
import ModelModal from './ModelModal'
import {
  UploadModal,
  UploadContainer,
  UploadModalHeader,
  UploadModalSubtitle,
  ModalUploadArea,
  ModalUploadIcon,
  ModalUploadTextHelper,
  ModalUploadButton,
  ModalUploadContent,
  BottomButtonsContainer,
  CarouselContainer
} from './styles'

const UploadPopup: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const { product, modal, handleModal, setImageToProduct } = useContext(MultipleContext)
  const [uploadingImage, setUploadingImage] = useState(false)
  const [imageSlides, setImageSlides] = useState<React.ReactNode[]>([])
  const [normalSize] = useState(useMediaQuery({ query: '(min-width: 1441px)' }))
  const { analytics } = useContext(AnalyticsContext)

  const [uploadContent, setUploadContent] = useState(
    <ModalUploadContent>
      <ModalUploadIcon src={CameraIcon} alt='' />
      <ModalUploadTextHelper>
        {t('multipleRegistration.uploadPopup.textHelper')}
      </ModalUploadTextHelper>
      <ModalUploadButton>
        <span>{t('multipleRegistration.uploadPopup.textButton')}</span>
      </ModalUploadButton>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <ModalUploadTextHelper>.PNG e .JPG</ModalUploadTextHelper>
    </ModalUploadContent>
  )

  const carouselResponsive = {
    three: {
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
      slidesToSlide: 1
    },
    two: {
      breakpoint: { max: 1700, min: 500 },
      items: 3,
      slidesToSlide: 1
    }
  }

  const deleteImage = useCallback(
    async ({ id }: Images) => {
      analytics?.track(AnalyticsEvent.REGISTRATION_DELETE_IMAGE)
      const { images } = product
      setImageToProduct(
        product.productId,
        images.filter((image) => image.id !== id)
      )
    },
    [product.images]
  )

  useEffect(() => {
    const slides = product?.images.map((image) => (
      <React.Fragment key={image.id}>
        <CarouselCard
          type='IMAGE'
          imageUrl={image.url}
          deleteCallback={() => deleteImage(image)}
          width={normalSize ? '360px' : '200px'}
          height={normalSize ? '283px' : '200px'}
        />
      </React.Fragment>
    ))
    if (uploadingImage) {
      slides.push(
        <CarouselCard
          key={nanoid()}
          type='UPLOADING'
          height={normalSize ? '283px' : '200px'}
          width={normalSize ? '360px' : '200px'}
        />
      )
    }
    setImageSlides(slides)
  }, [product, uploadingImage])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!product) return
      setUploadingImage(true)
      const newImages = [...product.images]

      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          await upload(file, 'registration', async (uploadUrl: string) => {
            const id = nanoid()
            setImageToProduct(
              product.productId,
              newImages.concat([{ productId: product.productId, id, url: uploadUrl }])
            )
            newImages.push({ productId: product.productId, id, url: uploadUrl })
          })
          return 'OK'
        })
      )
      analytics?.track(AnalyticsEvent.REGISTRATION_UPLOAD_IMAGE)
      setUploadContent(<div />)
      setUploadingImage(false)
    },
    [product?.images]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/*',
    noClick: !!product.images.length,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <>
      {modal === 'MODEL' ? (
        <ModelModal />
      ) : (
        <UploadModal>
          <UploadContainer>
            <UploadModalHeader>{product?.name}</UploadModalHeader>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <UploadModalSubtitle>SKU: {product?.sku}</UploadModalSubtitle>
            <ModalUploadArea {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {imageSlides.length ? (
                <CarouselContainer>
                  <MultiCarousel slides={imageSlides} responsive={carouselResponsive} />
                </CarouselContainer>
              ) : (
                <>{uploadContent}</>
              )}
            </ModalUploadArea>
            <BottomButtonsContainer>
              <Button
                style={{ width: '191px', height: '41px' }}
                onClick={() => handleModal('CLOSE')}
                alternative
              >
                {t('cancel')}
              </Button>
              <Button
                style={{ width: '191px', height: '41px' }}
                onClick={() => handleModal('CLOSE')}
              >
                {t('conclude')}
              </Button>
            </BottomButtonsContainer>
          </UploadContainer>
        </UploadModal>
      )}
    </>
  )
}

export default UploadPopup
