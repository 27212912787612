import React, { createContext, useState, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface ExpandBarContextData {
  barExpanded: boolean
  setBarExpanded: (visibility: boolean) => void
  toggleSideBar: () => void
}

export const ExpandBarContext = createContext<ExpandBarContextData>({} as ExpandBarContextData)

export const ExpandBarProvider: React.FC<Props> = ({ children }: Props) => {
  const [barExpanded, setBarExpanded] = useState(true)
  const toggleSideBar = () => setBarExpanded(!barExpanded)

  return (
    <ExpandBarContext.Provider
      value={{
        barExpanded,
        setBarExpanded,
        toggleSideBar
      }}
    >
      {children}
    </ExpandBarContext.Provider>
  )
}
