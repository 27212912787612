import styled from 'styled-components'

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.grey5};
  border: 1px solid ${(props) => props.theme.lightBlue2};
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  padding: 90px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FieldNames = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-top: 17px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.dark};
  width: 200px;
  border-radius: 5px;
`
