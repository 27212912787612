import React, { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '@console/common/components/Button'
import PopupBase, { PopupMode } from '@console/common/components/Popup'
import { ModalContext } from '@console/common/contexts/ModalContext'

import { deleteProduct, changeActiveStatus } from '../../services/api'
import { Container, MaintText, SubText, ButtonsContainer } from './styles'

export enum PopupOperation {
  DEACTIVATE = 'deactivate',
  DELETE = 'delete'
}

interface Props {
  operation: PopupOperation | null
  productId: string
  setActive: (active: boolean) => void
  customerId?: string
}

const Popup: React.FC<Props> = ({ operation, customerId, productId, setActive }: Props) => {
  const { t } = useTranslation(['portfolio'])
  const history = useHistory()
  const { isOpen, closeModal } = useContext(ModalContext)
  const [confirmed, setConfirmed] = useState(false)

  const confirmDelete = () => {
    if (customerId) deleteProduct(customerId, productId)
    setConfirmed(true)
  }

  const confirmDeactivate = () => {
    if (customerId) changeActiveStatus(customerId, productId, false)
    setConfirmed(true)
    setActive(false)
  }

  const confirmCallback = () => {
    if (operation === PopupOperation.DEACTIVATE) {
      confirmDeactivate()
    } else {
      confirmDelete()
    }
  }

  const closeCallback = () => {
    setConfirmed(false)
    closeModal()
    if (operation === PopupOperation.DELETE && confirmed) history.push('/portfolio')
  }

  return operation ? (
    confirmed ? (
      <PopupBase mode={PopupMode.CONFIRM} trigger={isOpen}>
        <Container>
          <MaintText>{t(`detailed.popup.${operation}.resultText`)}</MaintText>
          <div />
          <ButtonsContainer>
            <Button onClick={closeCallback}>{t('detailed.popup.ok')}</Button>
          </ButtonsContainer>
        </Container>
      </PopupBase>
    ) : (
      <PopupBase mode={PopupMode.ALERT} trigger={isOpen}>
        <Container>
          <MaintText>{t(`detailed.popup.${operation}.warnText`)}</MaintText>
          <SubText>{t(`detailed.popup.${operation}.warnSubText`)}</SubText>
          <ButtonsContainer>
            <Button alternative onClick={closeCallback}>
              {t('detailed.popup.cancel')}
            </Button>
            <Button onClick={confirmCallback}>
              {t(`detailed.popup.${operation}.confirmButton`)}
            </Button>
          </ButtonsContainer>
        </Container>
      </PopupBase>
    )
  ) : null
}

export default Popup
