import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  top: 0px;
  left: 0px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  background: ${(props) => props.theme.dark};
`

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 150px);
  min-height: 370px;

  .text {
    height: 100px;
    text-align: center;

    a {
      color: inherit;
    }
  }
`
