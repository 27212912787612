import React, { useState } from 'react'

import { Menu, MenuItem, Button, Tooltip } from '@material-ui/core'

interface Props {
  icon?: string
  items: { label: string; value: string }[]
  handleItem: (item: { label: string; value: string }) => void
  tooltip: string
}

const IconMenu: React.FC<Props> = ({ icon, items, handleItem, tooltip }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Tooltip title={tooltip}>
      <div>
        <Button
          id='basic-button'
          aria-controls='basic-menu'
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img src={icon} alt='' />
        </Button>
        <Menu
          getContentAnchorEl={null}
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {items.map((i) => (
            <MenuItem
              key={i.label}
              onClick={() => {
                handleItem(i)
                handleClose()
              }}
            >
              {i.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Tooltip>
  )
}

export default IconMenu
