import React, { useContext } from 'react'

import { ModalProvider } from '@console/common/contexts/ModalContext'

import { CommentsProvider } from '../../contexts/CommentsContext'
import { ProductContext } from '../../contexts/ProductContext'
import { ReviewProvider } from '../../contexts/ReviewContext'
import { RightBarOptionsContext, BarOptions } from '../../contexts/RightBarOptionsContext'
import ARBar from '../ARBar'
import CommentsBar from '../CommentsBar'
import ConfigurationBar from '../ConfigurationBar'
import CustomizationBar from '../CustomizationBar'
import InfoBar from '../InfoBar'
import ReferenceImagesBar from '../ReferenceImagesBar'
import { Container, LoadingContainer } from './styles'

const RightBar: React.FC = () => {
  const { loading } = useContext(ProductContext)
  const { rightBarOptions } = useContext(RightBarOptionsContext)

  if (loading) return <LoadingContainer />

  switch (rightBarOptions) {
    case BarOptions.INFO:
      return (
        <Container>
          <InfoBar />
        </Container>
      )
    case BarOptions.AR:
      return (
        <Container>
          <ARBar />
        </Container>
      )
    case BarOptions.REFERENCE:
      return (
        <ModalProvider>
          <Container>
            <ReferenceImagesBar />
          </Container>
        </ModalProvider>
      )
    case BarOptions.COMMENTS:
      return (
        <ReviewProvider>
          <CommentsProvider>
            <Container>
              <CommentsBar />
            </Container>
          </CommentsProvider>
        </ReviewProvider>
      )
    case BarOptions.CONFIGURATION:
      return (
        <Container>
          <ConfigurationBar />
        </Container>
      )
    case BarOptions.CUSTOMIZATION:
      return (
        <Container>
          <CustomizationBar />
        </Container>
      )
    default:
      return <Container />
  }
}

export default RightBar
