import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

interface Props {
  isLoading: boolean
}

export const GalleryContainer = styled.div<Props>`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  .fullscreen .image-gallery-slide img {
    height: 100vh;
  }
  .image-gallery-bullets .image-gallery-bullet {
    background: ${(props) => props.theme.grey2};
    border: 0;
    width: 12px;
    height: 12px;
    &.active {
      background: ${(props) => props.theme.dark};
      border: 0;
      width: 12px;
      height: 12px;
    }
  }
  .image-gallery-fullscreen-button {
    color: ${(props) => props.theme.dark};
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    color: ${(props) => props.theme.dark};
  }
  .fullscreen .image-gallery-bullets .image-gallery-bullet {
    background: ${(props) => props.theme.grey2};
    &.active {
      background: ${(props) => 'white'};
    }
  }
  .fullscreen .image-gallery-fullscreen-button {
    color: ${(props) => 'white'};
  }
  .fullscreen .image-gallery-left-nav {
    color: ${(props) => 'white'};
  }
  .fullscreen .image-gallery-right-nav {
    color: ${(props) => 'white'};
  }

  .image-gallery-svg {
    height: 40px;
  }

  .image-gallery-bullet {
    box-shadow: unset;
  }

  ${(props) => props.isLoading && skeletonAnimation}
`
