import {
  api,
  UserRole,
  getAssetSize,
  Product,
  Version,
  Asset,
  AssetStatus,
  Comment,
  Reference
} from '@console/common/services/api'

export interface ProductNeighbours {
  prev?: Neighbours
  next?: Neighbours
}

type Neighbours = Pick<Product, 'id' | 'sku'>

const setUSDZAssetSize = async (version: Version) => {
  const asset = version.Assets.find((e: Asset) => e.type === 'USDZ')
  if (asset) {
    try {
      asset.size = await getAssetSize(asset)
    } catch (e) {
      asset.size = undefined
    }
  }
}

export const fetchProduct = async (id: string, role?: UserRole): Promise<Product | null> => {
  try {
    const { data: product } = await api.get<Product>(`/v1/products/${id}`)
    product.Versions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    if (role === UserRole.ARTIST || role === UserRole.FREELANCER) {
      product.Versions = product.Versions.filter((version, index) => {
        const underReview = version.Assets.some(
          (asset) =>
            asset.status === AssetStatus.UNDER_REVIEW ||
            asset.status === AssetStatus.WORK_IN_PROGRESS
        )
        const hasComments = version.Assets.some((asset) => asset.Comments.length)
        return (underReview && index === 0) || !underReview || hasComments
      })
    } else {
      product.Versions = product.Versions.filter((version) =>
        version.Assets.some(
          (asset) =>
            asset.status === AssetStatus.TEAM_APPROVED ||
            asset.status === AssetStatus.CUSTOMER_APPROVED ||
            asset.status === AssetStatus.CUSTOMER_REJECTED
        )
      )
    }
    await Promise.all(product.Versions.map(setUSDZAssetSize))
    return product
  } catch (e) {
    return null
  }
}

export const fetchProductNeighbours = async (
  id: string,
  customerId?: string,
  status?: string
): Promise<ProductNeighbours | null> => {
  try {
    const { data: productNeighbours } = await api.get<ProductNeighbours>(
      `/v1/products/${id}/neighbours`,
      { params: { customerId, status } }
    )
    return productNeighbours
  } catch (e) {
    return null
  }
}

export const createComment = async (
  assetId: string,
  data: Partial<Comment>[]
): Promise<Comment[] | null> => {
  try {
    const { data: comments } = await api.post<Comment[]>(`/v1/assets/${assetId}/comments`, {
      comments: data
    })
    return comments
  } catch (e) {
    return null
  }
}

export const getComments = async (assetId: string): Promise<Comment[] | null> => {
  try {
    const { data: comments } = await api.get<Comment[]>(`/v1/assets/${assetId}/comments`)
    return comments.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
  } catch (e) {
    return null
  }
}

export const deleteComment = async (commentId: string): Promise<Comment | null> => {
  try {
    const { data: comment } = await api.delete<Comment>(`/v1/comments/${commentId}`)
    return comment
  } catch (e) {
    return null
  }
}

export const updateComment = async (
  commentId: string,
  data: Partial<Comment>
): Promise<Comment | null> => {
  try {
    const { data: comment } = await api.patch<Comment>(`/v1/comments/${commentId}`, data)
    return comment
  } catch (e) {
    return null
  }
}

export const createReference = async (
  productId: string,
  data: Partial<Reference>
): Promise<Reference | null> => {
  try {
    const { data: reference } = await api.post<Reference>(
      `/v1/products/${productId}/references`,
      data
    )
    return reference
  } catch (e) {
    return null
  }
}
