/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext, useEffect, useState, useLayoutEffect } from 'react'

import FileSaver from 'file-saver'
import JsZip from 'jszip'
import { useTranslation } from 'react-i18next'
import { useParams, Link, useLocation } from 'react-router-dom'

import AddIcon from '@console/common/assets/add.svg'
import DownloadIcon from '@console/common/assets/downloadArrow.svg'
import NewTabLinkIcon from '@console/common/assets/newTabLink.svg'
import PictureIcon from '@console/common/assets/picture.svg'
import RevisionIcon from '@console/common/assets/revision.svg'
import ViewerIcon from '@console/common/assets/viewer.svg'
import AssetViewer from '@console/common/components/AssetViewer'
import Carousel from '@console/common/components/Carousel'
import Header from '@console/common/components/Header'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { ConfigurationProvider } from '@console/common/contexts/ConfigurationContext'
import { ModalContext } from '@console/common/contexts/ModalContext'
import { Asset, Product, Customer, UserRole } from '@console/common/services/api'
import Status from '@console/common/types/Status'

import Popup, { PopupOperation } from '../../components/Popup'
import { getProductInfo, changeActiveStatus } from '../../services/api'
import { getStatus } from '../../services/status'
import Download from './Download'
import {
  MainContainer,
  ContentContainer,
  LeftGrid,
  RightGrid,
  GalleryContainer,
  Name,
  Sku,
  ButtonsContainer,
  NoLinkMessageBlock,
  UnstylledButton,
  Table,
  PdpRow,
  PdpUrl,
  TableValue,
  PdpLink,
  Button,
  LeftInfoContainer,
  ViewerContainer,
  OpenUploadButton,
  ButtonLink,
  Placeholder,
  TableDiv
} from './styles'
import UpdateAndStatus from './UpdateAndStatus'
import UploadArea from './UploadArea'

interface ParamTypes {
  productId: string
}

const Detailed: React.FC = () => {
  const { t } = useTranslation(['portfolio'])
  const { productId } = useParams<ParamTypes>()
  const { isOpen, openModal } = useContext(ModalContext)
  const { analytics } = useContext(AnalyticsContext)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<Product | null>(null)
  const [popupOperation, setPopupOperation] = useState<PopupOperation | null>(null)
  const [displayMessageBlock, setDisplayMessageBlock] = useState(false)
  const [active, setActive] = useState(false)
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [sku, setSku] = useState<string | null>(null)
  const [pdpUrl, setPdpUrl] = useState<string | null>(null)
  const [viewerUrl, setViewerUrl] = useState<string | null>(null)
  const [glb, setGlb] = useState<Asset | undefined>()
  const [usdz, setUsdz] = useState<Asset | undefined>()
  const [glbUrl, setGlbUrl] = useState<string | undefined>()
  const [usdzUrl, setUsdzUrl] = useState<string | undefined>()
  const [bloom, setBloom] = useState<boolean | undefined>(false)
  const [showDownload, setShowDownload] = useState(false)
  const [addPhoto, setAddPhoto] = useState(false)
  const [images, setImages] = useState<{ id: string; original: string }[]>([])
  const [status, setStatus] = useState<Status | undefined>()

  const { session, assumedCustomer } = useContext(AuthContext)
  const location = useLocation()

  useLayoutEffect(() => {
    if (session && product) {
      setCustomer(
        session.role === UserRole.CUSTOMER
          ? product.Customers?.find((fCustomer) => fCustomer.id === session.customer.id) || null
          : (session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) &&
            assumedCustomer
          ? product.Customers?.find((fCustomer) => fCustomer.id === assumedCustomer.id) || null
          : product.Customers?.find((fCustomer) => fCustomer.id === product.owner) || null
      )
    }
  }, [product, session])

  useLayoutEffect(() => {
    if (product && customer) {
      setName(customer.CustomerProduct?.name || product.name)
      setSku(customer.CustomerProduct?.sku || null)
      setPdpUrl(customer.CustomerProduct?.pdpUrl || null)
      setViewerUrl(`https://viewer.r2u.io/?customerId=${product.owner}&sku=${product.sku}`)

      analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL, {
        sku: customer.CustomerProduct?.sku || null
      })
    }
  }, [analytics, product, customer])

  useEffect(() => {
    if (product) {
      setGlb(product.Versions[0]?.Assets?.find((e: Asset) => e.type === 'GLB'))
      setUsdz(product.Versions[0]?.Assets?.find((e: Asset) => e.type === 'USDZ'))

      setGlbUrl(product.Versions[0]?.Assets?.find((e: Asset) => e.type === 'GLB')?.url)
      setUsdzUrl(product.Versions[0]?.Assets?.find((e: Asset) => e.type === 'USDZ')?.url)
    }
  }, [product])

  useEffect(() => {
    if (product) {
      setImages(
        product?.References.map((r) => ({
          id: r.id,
          original: r.url
        }))
      )
    }
  }, [product])

  useEffect(() => {
    if (product) {
      const activeProduct = customer?.CustomerProduct
        ? customer?.CustomerProduct?.active
        : product.active
      setStatus(product ? getStatus(product.status, activeProduct) : undefined)
    }
  }, [product, customer])

  const toggleDisplayMessageBlock = () => setDisplayMessageBlock(!displayMessageBlock)

  const date = product ? new Date(product?.Versions[0]?.createdAt) : null

  const handleDownloadProduct = async (types: string[]) => {
    if (types.length === 0) return
    analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_DOWNLOAD_ASSETS, {
      sku
    })

    const getBlobs = async (urls: string[]): Promise<{ name: string; blob: Blob }[]> =>
      Promise.all(
        urls.map(async (url) => {
          const response = await fetch(url)
          const blob = await response.blob()
          return { name: url.split('/').pop() as string, blob }
        })
      )

    const exportZip = async (blobs: { name: string; blob: Blob }[]) => {
      const zip = JsZip()
      blobs.forEach((blob) => {
        zip.file(blob.name, blob.blob)
      })
      const zipFile = await zip.generateAsync({ type: 'blob' })
      const fileName = `${product?.name}.zip`
      return FileSaver.saveAs(zipFile, fileName)
    }

    const urls: string[] = []
    // eslint-disable-next-line array-callback-return
    types.map((type) => {
      if (type === 'USDZ' && usdzUrl) urls.push(usdzUrl)
      if (type === 'GLB' && glbUrl) urls.push(glbUrl)
    })

    const blobs = await getBlobs(urls)
    exportZip(blobs)
  }

  const handlePdpUrl = () => {
    analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_CLICK_PDP_URL, { sku })
  }

  const openViewer = () => {
    if (viewerUrl) {
      window.open(viewerUrl)

      analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_CLICK_VIEWER_URL, { sku })
    }
  }

  const activateCallback = () => {
    if (!customer) return
    if (!active) {
      changeActiveStatus(customer.id, productId, true)
      setActive(true)
    } else {
      if (isOpen) return
      setPopupOperation(PopupOperation.DEACTIVATE)
      openModal()
    }
  }

  const openDeletePopup = () => {
    if (isOpen) return

    analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_CLICK_DELETE_PRODUCT, { sku })
    setPopupOperation(PopupOperation.DELETE)
    openModal()
  }

  const openDownloadPopup = () => {
    setShowDownload(!showDownload)
  }

  useEffect(() => {
    setLoading(true)
    getProductInfo(productId).then((p: Product | null) => {
      setProduct(p)
      setBloom(p?.ViewerOption?.bloom)
      setLoading(false)
    })
  }, [productId])

  useEffect(() => {
    if (product && customer) {
      const customerProductActive = product.Customers?.find(
        (fCustomer) => fCustomer.id === customer.id
      )?.CustomerProduct?.active
      setActive(customerProductActive === undefined ? product.active : customerProductActive)
    }
  }, [product, customer])

  return (
    <>
      <Popup
        operation={popupOperation}
        productId={productId}
        customerId={customer?.id}
        setActive={setActive}
      />
      {showDownload && (
        <Download
          glb={glb}
          usdz={usdz}
          handleDownload={handleDownloadProduct}
          close={openDownloadPopup}
        />
      )}
      <MainContainer>
        <Header
          title={t('title')}
          returnPage={`/portfolio${location.search?.toString()}`}
          status={
            <UpdateAndStatus
              loading={loading}
              status={status}
              date={date}
              activateCallback={activateCallback}
              active={active}
              openDeletePopup={openDeletePopup}
              editPath={`/portfolio/${productId}/edit`}
            />
          }
        />
        <ContentContainer>
          <LeftGrid>
            {loading ? null : images?.length ? (
              <OpenUploadButton onClick={() => setAddPhoto(!addPhoto)}>
                <img src={addPhoto ? PictureIcon : AddIcon} alt='' />
              </OpenUploadButton>
            ) : null}
            {images?.length && !addPhoto ? (
              <GalleryContainer isLoading={loading}>
                {loading ? null : <Carousel images={images} isLoading={loading} />}
              </GalleryContainer>
            ) : (
              <UploadArea
                loading={loading}
                type='IMAGE'
                product={product}
                setAddPhoto={setAddPhoto}
                setImages={setImages}
              />
            )}
            <LeftInfoContainer>
              <Name isLoading={loading}>{loading ? null : name}</Name>
              <Sku isLoading={loading}>
                {loading ? null : (
                  <span>
                    {t('detailed.sku')} {sku}
                  </span>
                )}
              </Sku>
              {loading ? null : (
                <TableDiv>
                  <Table isLoading={loading}>
                    <tbody>
                      <tr>
                        <th className='title'>
                          <span>{t('detailed.customer')}</span>
                        </th>
                        <th>
                          {product?.metadata ? (
                            <TableValue>
                              {
                                product.Customers?.find(
                                  (fCustomer) => fCustomer.id === product.owner
                                )?.name
                              }
                            </TableValue>
                          ) : (
                            <Placeholder> {t('detailed.pdpPlaceholder')} </Placeholder>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th className='title'>
                          <span>{t('detailed.pdp')}</span>
                        </th>
                        <th>
                          <PdpRow>
                            <PdpUrl hasPdp={pdpUrl}>
                              {pdpUrl ? (
                                <Link
                                  onClick={handlePdpUrl}
                                  to={{ pathname: pdpUrl }}
                                  target='_blank'
                                >
                                  {pdpUrl}
                                </Link>
                              ) : (
                                t('detailed.pdpPlaceholder')
                              )}
                            </PdpUrl>
                            <PdpLink>
                              {pdpUrl ? (
                                <Link
                                  onClick={handlePdpUrl}
                                  to={{ pathname: pdpUrl }}
                                  target='_blank'
                                >
                                  <img src={NewTabLinkIcon} alt='' />
                                </Link>
                              ) : (
                                <UnstylledButton
                                  onKeyPress={toggleDisplayMessageBlock}
                                  onMouseEnter={toggleDisplayMessageBlock}
                                  onMouseLeave={toggleDisplayMessageBlock}
                                >
                                  <img src={NewTabLinkIcon} alt={t('noLink')} />
                                </UnstylledButton>
                              )}
                              <NoLinkMessageBlock displayMessageBlock={displayMessageBlock}>
                                <p>{t('noLink')}</p>
                              </NoLinkMessageBlock>
                            </PdpLink>
                          </PdpRow>
                        </th>
                      </tr>
                      <tr>
                        <th className='title'>
                          <span>{t('detailed.ean')}</span>
                        </th>
                        <th>
                          {product?.ean?.length ? (
                            product?.ean.map((e) => <TableValue key={e}>{e}</TableValue>)
                          ) : (
                            <Placeholder>{t('detailed.pdpPlaceholder')}</Placeholder>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th className='title'>
                          <span>{t('detailed.productDimension.dimension')}</span>
                        </th>
                        <th>
                          {product?.metadata ? (
                            <TableValue>
                              {product.metadata.height} {t('detailed.productDimension.x')}{' '}
                              {product.metadata.width} {t('detailed.productDimension.x')}{' '}
                              {product.metadata.depth} {t('detailed.productDimension.cm')}
                            </TableValue>
                          ) : (
                            <Placeholder> {t('detailed.pdpPlaceholder')} </Placeholder>
                          )}
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </TableDiv>
              )}
            </LeftInfoContainer>
          </LeftGrid>
          <RightGrid>
            {glbUrl ? (
              <ViewerContainer isLoading={loading}>
                {loading
                  ? null
                  : product && (
                      <>
                        <ConfigurationProvider product={product} customer={customer}>
                          <AssetViewer
                            src={glbUrl}
                            customerId={customer?.id}
                            sku={sku}
                            viewerUrl={viewerUrl}
                            viewerBloom={bloom}
                          />
                        </ConfigurationProvider>
                      </>
                    )}
              </ViewerContainer>
            ) : (
              <UploadArea loading={loading} type='MODEL' product={product} setGlbUrl={setGlbUrl} />
            )}
            <div>
              <ButtonsContainer isLoading={loading}>
                {loading ? null : (
                  <>
                    <ButtonLink
                      onClick={() => setShowDownload(!showDownload)}
                      disabled={!glbUrl || !usdzUrl}
                    >
                      <img src={DownloadIcon} alt='Download link' />
                      <h3>{t('detailed.download')}</h3>
                    </ButtonLink>
                    {session?.role === UserRole.CUSTOMER &&
                    session.customer.id !== product?.owner ? null : (
                      <Link to={`/review/${productId}`} target='_blank'>
                        <Button>
                          <img src={RevisionIcon} alt='Reviewer link' />
                          <h3>{t('detailed.reviewer')}</h3>
                        </Button>
                      </Link>
                    )}
                    <ButtonLink onClick={openViewer} disabled={!viewerUrl}>
                      <img src={ViewerIcon} alt='Viewer R2U' />
                      <h3>{t('detailed.viewer')}</h3>
                    </ButtonLink>
                  </>
                )}
              </ButtonsContainer>
            </div>
          </RightGrid>
        </ContentContainer>
      </MainContainer>
    </>
  )
}

export default Detailed
