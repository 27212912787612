import React, { useState, useContext } from 'react'

import Viewer from '@r2u/viewer'
import { useTranslation } from 'react-i18next'

import { ConfigurationContext } from '../../contexts/ConfigurationContext'
import {
  ComparisonContainer,
  ComparisonTitle,
  ComparisonImage,
  ModelViewerContainer,
  ThumbnailsContainer,
  Thumbnail
} from './styles'

interface Props {
  defaultImage: { id: string; url: string } | null
  images: { id: string; url: string }[]
  glbSrc: string
}

const ComparisonPanel: React.FC<Props> = ({ defaultImage, images, glbSrc }: Props) => {
  const { t } = useTranslation(['components/reference-bar'])
  const [selectedImage, setSelectedImage] = useState(defaultImage || images[0])
  const { viewerOptions } = useContext(ConfigurationContext)

  return (
    <>
      <ComparisonContainer>
        <div>
          <ComparisonTitle>{t('popup.references')}</ComparisonTitle>
          <ComparisonImage>
            <img src={selectedImage.url} alt={selectedImage.id} />
          </ComparisonImage>
        </div>
        <div>
          <ComparisonTitle>{t('popup.model')}</ComparisonTitle>
          <ModelViewerContainer>
            <Viewer glbSrc={glbSrc} {...viewerOptions} />
          </ModelViewerContainer>
        </div>
      </ComparisonContainer>
      <ThumbnailsContainer>
        {images.map((img) => (
          <Thumbnail
            src={img.url}
            key={img.id}
            alt={img.id}
            onClick={() => setSelectedImage(img)}
            onKeyPress={() => setSelectedImage(img)}
          />
        ))}
      </ThumbnailsContainer>
    </>
  )
}

export default ComparisonPanel
