import styled, { keyframes } from 'styled-components'

interface Props {
  width: string
  height: string
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Animation = styled.div<Props>`
  border: 10px solid ${(props) => props.theme.blue};
  border-top: 10px solid ${(props) => props.theme.white};
  border-radius: 50%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  animation: ${spin} 1s linear infinite;
`

export const SpinnerComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  p {
    position: absolute;
    color: ${(props) => props.theme.blue};
  }
`
