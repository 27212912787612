import styled from 'styled-components'

import background from '../../assets/loginBackground.png'

export const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.dark} !important;
  background: url(${background}) no-repeat center;
  background-size: cover;

  @media (max-width: 800px) {
    img {
      margin-bottom: 30px;
    }
  }

  .modal {
    position: relative;
    background-color: white;
  }

  #terms-and-conditions {
    font-weight: bold;
    color: ${(props) => props.theme.dark};
    text-decoration: underline;
    cursor: pointer;
  }
`
