import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'
import { Input } from '@console/common/components/TextInput/styles'

interface Props {
  isLoading?: boolean
}

export const MainContainer = styled.div`
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 2%;
`

export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.dark};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomerFromTo = styled.div<Props>`
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 50%;
  height: 56px;
  background: ${(props) => props.theme.grey11};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.grey9};

  span:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${(props) => (props.isLoading ? 'transparent' : props.theme.blue3)};
  }
  span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    color: ${(props) => (props.isLoading ? 'transparent' : props.theme.blue3)};
  }

  ${(props) => props.isLoading && skeletonAnimation}
`

export const TableContainer = styled.table<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5%;
  border-collapse: collapse;

  tr td,
  tr th {
    border-left: 1px solid ${(props) => props.theme.grey8};
    border-right: 1px solid ${(props) => props.theme.grey8};
    ${(props) => (props.isLoading ? 'border: 0' : null)}
  }

  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }
  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }
`

export const HeaderContainer = styled.tr<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.grey9};
  border-top: 2px solid ${(props) => props.theme.grey8};
  border-bottom: 2px solid ${(props) => props.theme.grey8};

  ${(props) => props.isLoading && skeletonAnimation}
  ${(props) => (props.isLoading ? 'border: 0' : null)}
`

export const TableHeader = styled.th<Props>`
  padding: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 25%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => (props.isLoading ? 'transparent' : props.theme.dark)};
`

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RowContainer = styled.tr`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  :hover {
    background-color: ${(props) => props.theme.grey9};
  }

  border-bottom: 2px solid ${(props) => props.theme.grey8};
`

export const TableRow = styled.td`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`

interface InputProps {
  selected?: boolean
  error?: boolean
}

export const FullInput = styled(Input)<InputProps>`
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.error ? props.theme.red5 : props.theme.red4} !important`
      : '0 !important'};

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`

export const StaticContent = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 64px;
  margin-top: 50px;
`
