import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { ModalContext } from '@console/common/contexts/ModalContext'
import { UserRole } from '@console/common/services/api'

import closeIconWhite from '../../assets/closeIconWhite.svg'
import selectCustomerIcon from '../../assets/selectCustomer.svg'
import { AdminContainer, CustomerLogo, CustomerLogoPlaceholder } from './styles'

interface Props {
  expanded: boolean
}

const Admin: React.FC<Props> = ({ expanded }: Props) => {
  const { t } = useTranslation(['components/sidebar'])

  const { openModal } = useContext(ModalContext)
  const { session, assumeCustomer, assumedCustomer } = useContext(AuthContext)

  if (
    session?.role !== UserRole.ADMIN &&
    session?.role !== UserRole.R2USER &&
    session?.role !== UserRole.ARTIST
  ) {
    return null
  }

  return (
    <AdminContainer expanded={expanded}>
      <CustomerLogo className='left' expanded={expanded} src={session.customer.logoUrl} />
      {assumedCustomer ? (
        <>
          {assumedCustomer.logoUrl ? (
            <CustomerLogo className='right' expanded={expanded} src={assumedCustomer.logoUrl} />
          ) : (
            <CustomerLogoPlaceholder className='right' expanded={expanded}>
              {assumedCustomer.name.charAt(0)}
            </CustomerLogoPlaceholder>
          )}
          <button
            type='button'
            className='close'
            tabIndex={0}
            onClick={() => assumeCustomer(null)}
            onKeyPress={() => assumeCustomer(null)}
          >
            <img src={closeIconWhite} alt='remove customer' />
          </button>
          {expanded ? (
            <small>
              {t('admin.actions')}
              {assumedCustomer.name}
            </small>
          ) : null}
        </>
      ) : (
        <CustomerLogo
          className='right clickable'
          expanded={expanded}
          src={selectCustomerIcon}
          onClick={openModal}
        />
      )}
    </AdminContainer>
  )
}

export default Admin
