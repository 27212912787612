import React, { useContext, useState } from 'react'

import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { CheckboxContext } from '../../contexts/CheckboxContext'
import { SelectAllContainer, StyledCheckbox } from './styles'

const SelectAllCheckbox: React.FC = () => {
  const { t } = useTranslation(['review'])

  const [isChecked, setIsChecked] = useState(false)

  const { toggleSelectAll } = useContext(CheckboxContext)

  const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    toggleSelectAll()
  }

  return (
    <SelectAllContainer>
      <StyledCheckbox>
        <FormControlLabel
          control={
            <Checkbox size='small' color='default' checked={isChecked} onChange={toggleCheckbox} />
          }
          label={t('selectAll')}
        />
      </StyledCheckbox>
    </SelectAllContainer>
  )
}

export default SelectAllCheckbox
