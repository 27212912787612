import React from 'react'

import { useTranslation } from 'react-i18next'

import InviteStatus from '../../types/InviteStatus'
import { Container, StyledStatusBadge } from './styles'

interface Props {
  status: InviteStatus
}

const InviteStatusBadge: React.FC<Props> = ({ status }: Props) => {
  const { t } = useTranslation(['settings'])

  return (
    <Container>
      <StyledStatusBadge status={status}>{t(`users.${status}`)}</StyledStatusBadge>
    </Container>
  )
}

export default InviteStatusBadge
