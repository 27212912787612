import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

export interface RightBarProps {
  expanded: boolean
}

export const Container = styled.div<RightBarProps>`
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.expanded ? 'auto 372px' : '100% 0%')};
`

export const ViewerContainer = styled.div`
  height: 100vh;
  background-color: white;
  margin-left: 44px;
  margin-right: 44px;
  overflow: hidden;
`

export const ProductInfoBar = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 33px;
`

export interface Props {
  isLoading?: boolean
}

export const ModelViewerContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  margin: 20px;
  width: 100%;
  height: 60vh;

  ${(props) => props.isLoading && skeletonAnimation}

  @media (max-height: 700px) {
    height: 55vh;
  }
`

export const UploadImage = styled.img`
  height: 247px;
  width: auto;
`

export const UploadContainer = styled.div`
  text-align: center;
  height: 190px;
  width: 75%;
  border: 2px dashed ${(props) => props.theme.darkBlue4};
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey5};
  padding: 50px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
`

export const UploadProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: ${(props) => props.theme.darkBlue4};
  }
`

export const UploadContainerEmpty = styled.div`
  text-align: justify;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: ${(props) => props.theme.darkBlue4};
  }
`

export const UploadButtonContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.darkBlue4};
  }
`

export const UploadButton = styled.div`
  display: flex;
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
  height: 51px;
  width: 75%;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.darkBlue4};

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.white};
  }
`
