import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Arrow from '@console/reviewer/assets/icons/dropdown.svg'
import Reset from '@console/reviewer/assets/icons/reset.svg'

interface IProps {
  open?: boolean
  header: string | React.ReactNode
  headerClassName?: string
  titleClassName?: string
  iconButtonClassName?: string
  contentClassName?: string
  contentContainerClassName?: string
  collapsibleClassName?: string
  children: ReactNode
  nested?: boolean
  resetAction?(): void
}

const Collapsible: React.FC<IProps> = ({
  open,
  collapsibleClassName = 'collapsible-card-edonec',
  headerClassName = 'collapsible-header-edonec',
  titleClassName = 'title-text-edonec',
  iconButtonClassName = 'collapsible-icon-button-edonec',
  contentClassName = 'collapsible-content-edonec',
  contentContainerClassName = 'collapsible-content-padding-edonec',
  children,
  header,
  nested,
  resetAction
}: IProps) => {
  const { t } = useTranslation('components/configuration-bar')
  const [isOpen, setIsOpen] = useState(open)
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0)
  const ref = useRef<HTMLDivElement>(null)
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev)
  }
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height)
    })
    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, isOpen])
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height)
    else setHeight(0)
  }, [isOpen])
  return (
    <>
      <div className={collapsibleClassName}>
        <div className={nested ? 'nested' : undefined}>
          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
          <div className={headerClassName} onClick={handleFilterOpening} role='button' tabIndex={0}>
            <div className={titleClassName}>{header}</div>
            <button
              title={t('resetTitle')}
              type='button'
              className='reset-button'
              onClick={(e) => {
                e.stopPropagation()
                if (resetAction !== undefined) resetAction()
              }}
            >
              <img src={Reset} className='reset-icon' alt='reset' />
            </button>
            <div className={iconButtonClassName}>
              <img
                alt='arrow'
                src={Arrow}
                className={`fas-edonec fa-chevron-down-edonec ${
                  isOpen ? 'rotate-center-edonec down' : 'rotate-center-edonec up'
                }`}
              />
            </div>
          </div>
        </div>
        <div className={contentClassName} style={{ height }}>
          <div ref={ref}>
            <div className={contentContainerClassName}>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Collapsible
