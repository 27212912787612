import React, { useState, useCallback, useContext, useEffect } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import galleryEmptyState from '@console/common/assets/galleryEmptyState.svg'
import modelEmptyState from '@console/common/assets/modelEmptyState.svg'
import uploadImgArrow from '@console/common/assets/uploadImgArrow.svg'
import uploadModelArrow from '@console/common/assets/uploadModelArrow.svg'
import Spinner from '@console/common/components/Spinner'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import {
  ReferenceType,
  Product,
  getModelPresignedUrl,
  confirmModelUpload
} from '@console/common/services/api'
import { upload, uploadToUrl } from '@console/common/services/upload'

import { createReference } from '../../../reviewer/services/api'
import { UploadAreaContainer, UploadText, UploadImgArrow } from './styles'

interface Props {
  acceptedFile?: string
  loading: boolean
  type: 'IMAGE' | 'MODEL'
  product: Product | null
  setAddPhoto?: React.Dispatch<React.SetStateAction<boolean>>
  setGlbUrl?: React.Dispatch<React.SetStateAction<string | undefined>>
  setImages?: React.Dispatch<
    React.SetStateAction<
      {
        id: string
        original: string
      }[]
    >
  >
}

const UploadArea: React.FC<Props> = ({
  loading,
  type,
  product,
  setAddPhoto,
  setGlbUrl,
  setImages
}: Props) => {
  const { t } = useTranslation(['portfolio'])
  const { analytics } = useContext(AnalyticsContext)
  const [upLoading, setUpLoading] = useState(false)
  const [percentage, setPercentage] = useState('0%')

  const updateImages = () => {
    if (product && setImages) {
      setImages(
        product?.References?.filter((reference) => reference.type === ReferenceType.IMAGE)?.map(
          (reference) => ({ id: reference.id, original: reference.url })
        )
      )
    }
  }

  useEffect(() => {
    updateImages()
  }, [product])

  const handleAfterUpload = () => {
    setUpLoading(false)
    if (setAddPhoto) setAddPhoto(false)
  }

  const handlePercentage = (value: number) => setPercentage(`${Math.round(value)}%`)

  const onDropImage = useCallback(
    (acceptedFiles) => {
      if (!product) return
      setUpLoading(true)
      analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_UPLOAD_IMAGE, { productId: product.id })

      acceptedFiles.forEach((file: File) => {
        upload(
          file,
          `references/${product.id}`,
          async (e: string) => {
            createReference(product.id, {
              name: file.name,
              type: ReferenceType.IMAGE,
              url: e,
              description: 'Uploaded using the platform'
            }).then((reference) => {
              if (reference) {
                product.References.push(reference)
                updateImages()
              }
              handleAfterUpload()
            })
          },
          handlePercentage,
          true
        )
      })
    },
    [product]
  )

  const onDropModel = useCallback(
    (acceptedFiles) => {
      if (!product) return
      setUpLoading(true)
      analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_UPLOAD_MODEL, { productId: product.id })

      acceptedFiles.forEach(async (file: File) => {
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        const uploadRequest = await getModelPresignedUrl(product.id, extension)
        if (!uploadRequest) return
        await uploadToUrl(
          file,
          uploadRequest.uploadUrl,
          async () => {
            await confirmModelUpload(uploadRequest.assetId, uploadRequest.viewId)
            if (setGlbUrl && extension === 'glb') setGlbUrl(uploadRequest.outputUrl)
            setUpLoading(false)
          },
          handlePercentage
        )
      })
    },
    [product]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: type === 'IMAGE' ? onDropImage : onDropModel,
    accept: type === 'IMAGE' ? 'image/*' : undefined,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  return (
    <UploadAreaContainer {...getRootProps()} isLoading={loading} onClick={open} onKeyPress={open}>
      {loading ? null : (
        <>
          {upLoading ? (
            <Spinner width='100px' height='100px' text={percentage} />
          ) : (
            <>
              <input {...getInputProps()} />
              {type === 'IMAGE' ? (
                <UploadText className='firstLine'>{t('uploadPlaceholder.image')}</UploadText>
              ) : (
                <UploadText className='firstLine'>{t('uploadPlaceholder.model')}</UploadText>
              )}
              <img
                className='back-image'
                src={type === 'IMAGE' ? galleryEmptyState : modelEmptyState}
                alt=''
              />
              <UploadImgArrow>
                <img src={type === 'IMAGE' ? uploadImgArrow : uploadModelArrow} alt='' />
              </UploadImgArrow>
            </>
          )}
        </>
      )}
    </UploadAreaContainer>
  )
}

export default UploadArea
