import React from 'react'

import { useTranslation } from 'react-i18next'

import leftClick from '@console/common/assets/mouse left click.svg'
import rightClick from '@console/common/assets/mouse right click.svg'
import scroll from '@console/common/assets/mouse scroll.svg'

import { HelpContainer } from './styles'

const Help: React.FC = () => {
  const { t } = useTranslation(['portfolio'])

  return (
    <HelpContainer>
      <span className='title'>{t('detailed.help.title')}</span>
      <div className='instruction'>
        <img src={scroll} alt='scroll' />
        <span>{t('detailed.help.zoom')}</span>
      </div>
      <div className='instruction'>
        <img src={leftClick} alt='leftClick' />
        <span>{t('detailed.help.rotate')}</span>
      </div>
      <div className='instruction'>
        <img src={rightClick} alt='rightClick' />
        <span>{t('detailed.help.move')}</span>
      </div>
    </HelpContainer>
  )
}

export default Help
