import styled from 'styled-components'

interface ContainerProps {
  loading: boolean
}

interface ButtonProps {
  action: 'reject' | 'cancel'
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-height: 25%;
  overflow-x: hidden;
  ${(props) => (props.loading ? 'pointer-events: none; opacity: 0.5;' : '')};
`

export const ShowCommentsContainer = styled.div`
  width: 100%;
`

export const CommentUser = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  span {
    color: ${(props) => props.theme.dark2};
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
`

export const CommentContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 16px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .attachments {
    img {
      width: 95%;
      margin-left: 2.5%;
    }
  }
`

export const CommentText = styled.span`
  text-align: left;
  color: ${(props) => props.theme.dark2};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  outline: none;
  resize: none;
  margin: 0;
`

export const CommentDate = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: ${(props) => props.theme.grey8};
`

export const InputContainer = styled.div`
  width: 100%;
  min-height: 148px;
`

export const TextareaContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px 8px 0px 0px;
  border: ${(props) => `solid 0.5px ${props.theme.white}`};

  textarea {
    color: ${(props) => props.theme.dark};
    border: 0;
    margin: 8px 8px 0 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    outline: none;
    resize: none;
    min-height: 70px;
    max-height: 70px;
    width: 93%;
    padding: 1%;
    border: none;
  }

  img {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 5px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const ImageWrapper = styled.div`
  width: 78px;
  height: 74px;
  position: relative;
  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 2px;
    width: auto;
  }
  img {
    width: 78px;
    height: 74px;
  }
`

export const DragAndDropContainer = styled.span`
  width: 100%;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #797979;
  border-radius: 5px;
  border: solid 0.5px #ffffff;
  min-height: 100px;
  background-color: #ffffff;
`

export const ImageButton = styled.img`
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
`

export const Attatchment = styled.span`
  margin: 16px;
  display: grid;
  column-gap: 16px;
  row-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(48px, 1fr));
  justify-items: center;

  img {
    height: 48px;
  }
`

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
  bottom: 0px;
  width: 100%;
`

export const ActionButton = styled.button<ButtonProps>`
  border-radius: 8px;
  min-width: 40%;
  height: 44px;
  border: 0px;
  cursor: pointer;
  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  background-color: ${(props) =>
    props.action === 'reject' ? props.theme.darkBlue4 : props.theme.white};
  :disabled {
    background-color: ${(props) => props.theme.grey8};
    cursor: initial;
  }
  :hover:enabled {
    background-color: ${(props) =>
      props.action === 'cancel' ? props.theme.grey5 : props.theme.lightBlue2};
  }
`
export const ActionsButtonText = styled.span<ButtonProps>`
  color: ${(props) => (props.action === 'reject' ? props.theme.white : props.theme.darkBlue4)};
  line-height: 1.29;
  text-align: center;
`

export const CommentsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }
`

export const AlterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 8px;
`

export const AlterCommentButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 22px;
  height: 23px;
  img {
    width: 22px;
    height: 23px;
    // theme.grey8
    filter: invert(74%) sepia(0%) saturate(0%) hue-rotate(319deg) brightness(96%) contrast(90%);
  }
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`
export const AttachButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 90%;
  margin: 8px;
  height: 28px;
  cursor: pointer;
  img {
    width: 22px;
    height: 23px;
    margin-left: 0;
    margin-bottom: 0;
    // theme.blue2
    filter: invert(46%) sepia(74%) saturate(803%) hue-rotate(174deg) brightness(83%) contrast(84%);
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.blue2};
    :hover {
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.blue2};
    }
  }
`

export const CommentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  gap: 16px;
  border-radius: 0px 0px 8px 8px;
  background-color: ${(props) => props.theme.lightBlue2};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
  span {
    color: ${(props) => props.theme.darkBlue4};
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
  }
  img {
    width: 21px;
    height: 18px;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 5px;
  }
`
