import React, { useState } from 'react'

import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  file: string
  loadingMessage?: string
}

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true
}

const PDFViewer: React.FC<Props> = ({ file, loadingMessage = '' }: Props) => {
  const [numPages, setNumPages] = useState(0)

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }) => {
    setNumPages(nextNumPages)
  }

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      options={options}
      loading={loadingMessage}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={1000} />
      ))}
    </Document>
  )
}

export default PDFViewer
