/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useContext } from 'react'

import { ConfigurationContext } from '../../contexts/ConfigurationContext'
import flatSlotsMaterials from '../../utils/flatSlotsMaterials'
import SlotsModelsMenu from './SlotsModelsMenu'
import { Container } from './styles'
import type { SlotsModels, SlotsMaterials } from './types'

const CustomizationBar: React.FC = () => {
  const { slotsModels, setSlotsModels, slotsMaterials, setSlotsMaterials, viewerRef } =
    useContext(ConfigurationContext)

  return (
    <Container>
      <div className='title'>Customization</div>
      <SlotsModelsMenu
        viewerRef={viewerRef}
        slotsModels={slotsModels}
        slotsMaterials={flatSlotsMaterials(slotsMaterials)}
        onChange={useCallback(
          (sMod: SlotsModels, sMat: SlotsMaterials) => {
            setSlotsModels(sMod)
            setSlotsMaterials(sMat)
          },
          [slotsModels]
        )}
      />
    </Container>
  )
}

export default CustomizationBar
