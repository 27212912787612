import React, { createContext, ReactNode, useState, useEffect, useContext } from 'react'

import { nanoid } from 'nanoid/non-secure'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { ReferenceType, createReference, updateHanaAsset } from '@console/common/services/api'

import { createProducts } from '../services/api'

interface Props {
  children: ReactNode
}

export interface Images {
  productId?: string
  id?: string
  url: string
}

export interface Model {
  productId?: string
  id: string
  hanaId: string
  type: string
  url: string
}

export interface TempProduct {
  id: string
  name?: string
  sku?: string
  ean?: string
  pdp?: string
  dimensions?: string
  vendor?: string
  urlModel3d?: string
  imagesUrls?: string[]
  width?: string
  height?: string
  depth?: string
  models?: Model[]
  hanaId?: string
}

export interface ProductInfo {
  images: Images[]
  models: Model[]
  productId: string
  sku?: string
  name?: string
  hanaId?: string
}

type ModalType = 'IMAGE' | 'MODEL' | 'CLOSE'

interface MultipleContextData {
  modal: ModalType
  handleModal: (open: ModalType, productId?: string) => void
  setImageToProduct: (productId: string, newImages: Images[]) => void
  setModelToProduct: (productId: string, newModels: Model[], hanaId: string) => void
  setProducts: React.Dispatch<React.SetStateAction<TempProduct[]>>
  products: TempProduct[]
  product: ProductInfo
  handleSetProducts: (tempProducts: TempProduct[]) => void
  confirmRegistration: () => Promise<void>
  registering: boolean
  creatingProgress: number | null
  percentage: number
  clearRegistration: () => void
}

export const MultipleContext = createContext<MultipleContextData>({} as MultipleContextData)

const emptyProduct: ProductInfo = {
  productId: '',
  name: '',
  sku: '',
  images: [],
  models: []
}

export const MultipleProvider: React.FC<Props> = ({ children }: Props) => {
  const [modal, setModal] = useState<ModalType>('CLOSE')
  const [products, setProducts] = useState<TempProduct[]>([])
  const [product, setProduct] = useState<ProductInfo>(emptyProduct)
  const [registering, setRegistering] = useState(false)
  const [creatingProgress, setCreatingProgress] = useState<number | null>(null)
  const [percentage, setPercentage] = useState(0)

  const { session, assumedCustomer } = useContext(AuthContext)

  useEffect(() => {
    const multipleProductsData = localStorage.getItem('multipleProducts')
    if (multipleProductsData) setProducts(JSON.parse(multipleProductsData))
  }, [])

  useEffect(() => {
    if (creatingProgress !== null) {
      setPercentage((products.length - creatingProgress) * (100 / products.length))
    }
  }, [creatingProgress])

  const handleSetProducts = (tempProducts: TempProduct[]) => {
    setProducts(tempProducts)
    localStorage.setItem('multipleProducts', JSON.stringify(tempProducts))
  }

  const handleModal = (open: ModalType, productId?: string) => {
    if (open !== 'CLOSE') {
      const index = products.findIndex((p) => p.id === productId)
      if (index < 0) return setProduct(emptyProduct)
      setProduct({
        productId: products[index].id,
        images: (products[index].imagesUrls || []).map((u) => ({ url: u })),
        name: products[index].name,
        sku: products[index].sku,
        models: products[index].models || []
      })
    }
    return setModal(open)
  }

  const setImageToProduct = (productId: string, newImages: Images[]) => {
    const index = products.findIndex((p) => p.id === productId)
    if (index < 0) return
    const newProducts = products
    newProducts[index].imagesUrls = newImages.map((i) => i.url)
    handleSetProducts(newProducts)
    if (product.productId) {
      setProduct({
        productId,
        images: newImages,
        name: newProducts[index].name,
        sku: newProducts[index].sku,
        models: newProducts[index].models || []
      })
    }
  }

  const setModelToProduct = (productId: string, newModels: Model[], hanaId: string) => {
    const index = products.findIndex((p) => p.id === productId)
    if (index < 0) return
    const newProducts = products
    newProducts[index].models = newModels
    newProducts[index].hanaId = hanaId
    handleSetProducts(newProducts)
    if (product.productId) {
      setProduct({
        productId,
        images: (newProducts[index].imagesUrls || []).map((u) => ({ url: u })),
        models: newModels,
        name: newProducts[index].name,
        sku: newProducts[index].sku,
        hanaId
      })
    }
  }

  const confirmRegistration = async () => {
    setRegistering(true)
    setCreatingProgress(products.length)
    const newCustomerProducts = await createProducts(
      products.map((p) => ({
        name: p.name,
        sku: p.sku,
        ean: p.ean ? [p.ean] : undefined,
        pdpUrl: p.pdp,
        metadata: {
          height: p.height,
          width: p.width,
          depth: p.depth
        },
        customerId: assumedCustomer?.id ?? session?.customer.id
      }))
    )
    if (!newCustomerProducts) return
    await Promise.all(
      newCustomerProducts.map(async (cProd) => {
        const index = products.findIndex((p) => p.sku === cProd.sku && p.imagesUrls?.length)
        await Promise.all(
          (products[index]?.imagesUrls || []).map((image) => {
            if (cProd.productId) {
              return createReference(cProd.productId, {
                url: image,
                type: ReferenceType.IMAGE,
                description: 'Uploaded using the platform',
                name: nanoid()
              })
            }
            return null
          })
        )
        const { hanaId } = products[index] ? products[index] : { hanaId: undefined }
        if (hanaId && cProd.productId) {
          updateHanaAsset(hanaId, cProd.productId)
        }
        setCreatingProgress((progress) => progress && progress - 1)
      })
    )
    localStorage.removeItem('multipleProducts')
    setRegistering(false)
  }

  const clearRegistration = async () => {
    localStorage.removeItem('multipleProducts')
  }

  return (
    <MultipleContext.Provider
      value={{
        modal,
        handleModal,
        setImageToProduct,
        setModelToProduct,
        setProducts,
        products,
        product,
        handleSetProducts,
        confirmRegistration,
        registering,
        creatingProgress,
        percentage,
        clearRegistration
      }}
    >
      {children}
    </MultipleContext.Provider>
  )
}
