import styled from 'styled-components'

interface Props {
  error?: boolean
}

export const MainContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: ${(props) => props.theme.white};
  border: 4px solid ${(props) => (props.error ? props.theme.red5 : props.theme.red4)};
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 2%;
  gap: 19px;
  position: relative;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`

export const ErrorTypeText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.dark2};
`

export const ErrorDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 34px;
`

export const ArrowButton = styled.button<Props>`
  width: 20px;
  height: 41px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  img {
    width: 5px;
    height: 12px;
    filter: ${(props) =>
      props.error
        ? // theme.red5
          'invert(13%) sepia(89%) saturate(3206%) hue-rotate(356deg) brightness(95%) contrast(88%)'
        : // theme.red4
          'invert(71%) sepia(54%) saturate(3154%) hue-rotate(323deg) brightness(99%) contrast(96%)'};
  }
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const TextHelper = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.grey10};
`

export const SkipButton = styled.button`
  width: 352px;
  height: 36px;
  cursor: pointer;
  border: 0;
  background-color: ${(props) => props.theme.grey9};
  border-radius: 8px;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.dark2};
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`
