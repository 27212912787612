import styled from 'styled-components'

export const CardsContainer = styled.div`
  display: grid;
  column-gap: 35px;
  row-gap: 28px;
  grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));
  margin-top: 25px;
  justify-items: start;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }
`
