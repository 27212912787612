import styled from 'styled-components'

export const Switch = styled.label`
  position: relative;
  display: flex;
  width: 43px;
  height: 21px;
`

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.grey2};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &::after {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 5px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

export const Input = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 43px;
  height: 21px;
  &:checked + ${Slider} {
    background: ${(props) => props.theme.blue};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 20px;
      transition: 0.2s;
    }
  }
`
