import styled from 'styled-components'

export const InviteField = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-left: 10px;
    background-color: white;
    border: 0;
    cursor: pointer;
  }
  img {
    height: 20px;
  }
`

export const ActionsButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  button {
    background-color: white;
    border: 0;
    cursor: pointer;
  }
  img {
    height: 20px;
  }
`
