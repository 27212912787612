import React from 'react'

import { useTranslation } from 'react-i18next'

import { UserRole } from '@console/common/services/api'

import { Container, StyledStatusBadge } from './styles'

interface Props {
  role: UserRole
}

const RoleBadge: React.FC<Props> = ({ role }: Props) => {
  const { t } = useTranslation(['settings'])

  return (
    <Container>
      <StyledStatusBadge>{t(`users.${role.toLowerCase()}`)}</StyledStatusBadge>
    </Container>
  )
}

export default RoleBadge
