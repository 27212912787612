import React, { ChangeEventHandler } from 'react'

import { Input } from './styles'

export enum InputTypes {
  TEXT = 'text',
  EMAIL = 'email',
  URL = 'url',
  NUMBER = 'number'
}

interface Props {
  value?: string
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  width?: string
  type?: InputTypes
  required?: boolean
  disabled?: boolean
  isLoading?: boolean
  id?: string
}

const TextInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  width,
  type,
  required,
  disabled,
  isLoading,
  id
}: Props) => (
  <Input
    type={type || 'text'}
    value={value}
    onChange={onChange}
    placeholder={isLoading ? '' : placeholder}
    width={width}
    required={required}
    disabled={isLoading || disabled}
    isLoading={isLoading || false}
    id={id}
  />
)

export default TextInput
