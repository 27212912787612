import React, { useContext, useRef, useCallback } from 'react'

import { Link } from 'react-router-dom'

import Card from '@console/common/components/Card'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import { ProductsContext } from '../../contexts/ProductsContext'
import ReviewCard from '../ReviewCard'
import { CardsContainer } from './styles'

const CardsList: React.FC = () => {
  const { loading, products, nextPage, fetchAndSetProducts } = useContext(ProductsContext)
  const { analytics } = useContext(AnalyticsContext)

  const observer = useRef<IntersectionObserver>()
  const lastCardElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          fetchAndSetProducts()
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, nextPage]
  )

  const cards = products?.map((product, index) => (
    <Link
      ref={products.length === index + 1 ? lastCardElementRef : null}
      key={product.id}
      onClick={() => analytics?.track(AnalyticsEvent.REVIEW_CARD_CLICK, { productId: product.id })}
      to={`/review/${product.id}`}
      style={{ textDecoration: 'none' }}
    >
      <ReviewCard
        imgSrc={product.References ? product.References[0]?.url : ''}
        name={product.name}
        sku={product.sku}
        status={product.publicStatus}
        customer={product.Customers?.find((fCustomer) => fCustomer.id === product.owner)?.name}
        id={product.id}
      />
    </Link>
  ))

  return (
    <CardsContainer>
      {cards}
      {loading
        ? Array(5)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, i) => <Card isLoading key={i} />)
        : null}
    </CardsContainer>
  )
}

export default CardsList
