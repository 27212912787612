import React, { useContext } from 'react'

import PDFViewer from '@console/common/components/PDFViewer'
import { ModalContext } from '@console/common/contexts/ModalContext'

import closeIcon from '../../assets/closeIcon.svg'
import terms from '../../assets/terms.pdf'
import { Container, PDFContainer } from './style'

const TermsAndConditions: React.FC = () => {
  const { isOpen, closeModal } = useContext(ModalContext)

  if (!isOpen) return null

  return (
    <Container>
      <button type='button' onClick={closeModal} onKeyDown={closeModal}>
        <img src={closeIcon} alt='close terms' />
      </button>
      <PDFContainer>
        <PDFViewer file={terms} />
      </PDFContainer>
    </Container>
  )
}

export default TermsAndConditions
