import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import backArrow from '@console/common/assets/arrowLeft.svg'

import { ProductContext } from '../../contexts/ProductContext'
import { Arrow, ArrowSku, TooltipText, Icon } from './styles'

interface Props {
  type: 'prev' | 'next'
}

const NeighboursArrow: React.FC<Props> = ({ type }: Props) => {
  const { neighbours, loading } = useContext(ProductContext)

  const SKULiteral = 'SKU: '

  if (loading) return <div />

  return (
    <>
      {neighbours?.[type] ? (
        <Link to={`${neighbours[type]?.id}`}>
          <Arrow type={type}>
            <TooltipText> {neighbours[type]?.sku} </TooltipText>

            <ArrowSku>
              {type === 'prev' ? <Icon type={type} src={backArrow} /> : null}
              {SKULiteral} {neighbours[type]?.sku}
              {type === 'next' ? <Icon type={type} src={backArrow} /> : null}
            </ArrowSku>
          </Arrow>
        </Link>
      ) : (
        <Arrow type='none' />
      )}
    </>
  )
}

export default NeighboursArrow
