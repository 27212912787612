import React, { useContext, useEffect } from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { ModalProvider } from '@console/common/contexts/ModalContext'

import Edit from './Edit'
import Home from './Home'
import Invite from './Invite'

const Users: React.FC = () => {
  const { path } = useRouteMatch()

  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS_USERS)
  }, [analytics])

  return (
    <Switch>
      <Route exact path={path}>
        <ModalProvider>
          <Home />
        </ModalProvider>
      </Route>
      <Route path={`${path}/invite`}>
        <ModalProvider>
          <Invite />
        </ModalProvider>
      </Route>
      <Route path={`${path}/edit/:userId`}>
        <Edit />
      </Route>
    </Switch>
  )
}

export default Users
