import { api } from '@console/common/services/api'

export interface Notification {
  id: string
  type: 'COMMENT' | 'ANNOUNCEMENT' | 'PENDING_REVIEW'
  data: unknown
  read: boolean
  createdAt: string
}

export interface CommentData {
  productId: string
}

export interface ReviewData {
  products: string[]
}

export interface AnnouncementData {
  [key: string]: { title: string; content: string; redirectUrl?: string }
}

export const getNotifications = async (): Promise<Notification[]> => {
  try {
    const from = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    const { data: notifications } = await api.get('/v1/notifications', { params: { from } })
    return notifications
  } catch (e) {
    return []
  }
}

export const updateNotification = async (
  id: string,
  data: unknown
): Promise<Notification | null> => {
  try {
    const { data: notification } = await api.patch(`/v1/notifications/${id}`, data)
    return notification
  } catch (e) {
    return null
  }
}
