import { AssetStatus } from '@console/common/services/api'
import Status from '@console/common/types/Status'

export const getStatusFilter = (
  status: Status | null
): { status: AssetStatus[] | null; active: boolean | null } => {
  switch (status) {
    case Status.RUNNING: {
      return { status: [AssetStatus.CUSTOMER_APPROVED], active: true }
    }
    case Status.REJECTED: {
      return { status: [AssetStatus.CUSTOMER_REJECTED], active: null }
    }
    case Status.PENDING_REVIEW: {
      return { status: [AssetStatus.TEAM_APPROVED], active: null }
    }
    case Status.PAUSED: {
      return { status: [AssetStatus.CUSTOMER_APPROVED], active: false }
    }
    case Status.IN_PROGRESS: {
      return {
        status: [AssetStatus.WORK_IN_PROGRESS, AssetStatus.UNDER_REVIEW, AssetStatus.TEAM_REJECTED],
        active: null
      }
    }
    default: {
      return { status: null, active: null }
    }
  }
}

export const getStatus = (status: AssetStatus, active: boolean): Status | undefined => {
  switch (status) {
    case AssetStatus.WORK_IN_PROGRESS: {
      return Status.IN_PROGRESS
    }
    case AssetStatus.UNDER_REVIEW: {
      return Status.IN_PROGRESS
    }
    case AssetStatus.TEAM_APPROVED: {
      return Status.PENDING_REVIEW
    }
    case AssetStatus.CUSTOMER_APPROVED: {
      if (active) {
        return Status.RUNNING
      }
      return Status.PAUSED
    }
    case AssetStatus.TEAM_REJECTED: {
      return Status.IN_PROGRESS
    }
    case AssetStatus.CUSTOMER_REJECTED: {
      return Status.REJECTED
    }
    default: {
      return undefined
    }
  }
}
