import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '@console/common/components/Button'
import PopupBase, { PopupMode } from '@console/common/components/Popup'
import { ModalContext } from '@console/common/contexts/ModalContext'

import { Container, MaintText, ButtonsContainer } from './styles'

const InvitePopup: React.FC = () => {
  const { t } = useTranslation(['settings'])
  const history = useHistory()
  const { isOpen, closeModal } = useContext(ModalContext)

  const closeCallback = () => {
    closeModal()
    history.push('/settings/users')
  }

  return (
    <PopupBase mode={PopupMode.CONFIRM} trigger={isOpen}>
      <Container>
        <MaintText>{t('users.invite.popup.mainText')}</MaintText>
        <div />
        <ButtonsContainer>
          <Button onClick={closeCallback}>{t('users.invite.popup.ok')}</Button>
        </ButtonsContainer>
      </Container>
    </PopupBase>
  )
}

export default InvitePopup
