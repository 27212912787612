import React, { ReactElement } from 'react'

import popupAlert from '../../assets/popupAlert.svg'
import popupConfirm from '../../assets/popupConfirm.svg'
import { Background, LargeContainer, Container, Icon, Content } from './styles'

export enum PopupMode {
  LARGE = 'LARGE',
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM'
}

interface Props {
  mode: PopupMode
  trigger: boolean
  children: ReactElement
}

const Popup: React.FC<Props> = ({ mode, trigger, children }: Props) =>
  trigger ? (
    <Background>
      {mode === PopupMode.LARGE ? (
        <LargeContainer>{children}</LargeContainer>
      ) : (
        <Container>
          <Icon src={mode === PopupMode.ALERT ? popupAlert : popupConfirm} alt='' />
          <Content>{children}</Content>
        </Container>
      )}
    </Background>
  ) : null

export default Popup
