import React, { useContext } from 'react'

import { AuthContext } from '@console/common/contexts/AuthContext'

import PerformanceHeader from '../../../components/Header'
import { Container } from './styles'

const BigQueryAnalytics: React.FC = () => {
  const { sessionCustomer, assumedCustomer } = useContext(AuthContext)

  const json = JSON.stringify({
    'ds0.customer_id': assumedCustomer?.id ?? sessionCustomer?.id
  })

  const encodeParams = encodeURIComponent(json)
  const srcAnalytics = `https://lookerstudio.google.com/embed/reporting/0eed07e5-6df7-4820-8303-0ba463fc31a4/page/p_aeytrif79c?params=${encodeParams}`

  return (
    <Container>
      <PerformanceHeader />
      <div>
        <iframe
          title='looker analytics'
          width='100%'
          height='3000px'
          src={srcAnalytics}
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
    </Container>
  )
}

export default BigQueryAnalytics
