import React, { useContext, useEffect } from 'react'

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import General from './pages/General'

const Performance: React.FC = () => {
  const { path } = useRouteMatch()
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.PERFORMANCE)
  }, [analytics])

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/general`} />
      </Route>
      <Route path={`${path}/general`}>
        <General />
      </Route>
    </Switch>
  )
}

export default Performance
