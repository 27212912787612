import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from '@console/common/components/Button'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { Customer, getCustomers } from '@console/common/services/api'

import CustomersTable from '../../../components/CustomersTable'
import Header from '../../../components/Header'
import { Container } from './styles'

const Home: React.FC = () => {
  const { t } = useTranslation(['settings'])
  const [customers, setCustomers] = useState<Customer[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { analytics } = useContext(AnalyticsContext)

  const loadCustomers = () => {
    setLoading(true)
    getCustomers().then((c) => {
      if (c) setCustomers(c)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadCustomers()
  }, [])

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS_CUSTOMERS_HOME)
  }, [analytics])

  return (
    <Container>
      <Header />
      <Link to='/settings/customers/create'>
        <Button>{t('customers.createCustomer')}</Button>
      </Link>
      <CustomersTable customers={customers} isLoading={loading} />
    </Container>
  )
}

export default Home
