import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

export const TableSkeleton = styled.div`
  display: block;
  max-width: 100%;
  margin-top: 17px;
  height: 500px;
  ${() => skeletonAnimation}
`

export const TableContainer = styled.div`
  display: block;
  max-width: 100%;
  padding-top: 17px;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow: scroll;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: ${(props) => `1px solid ${props.theme.grey1}`};

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      height: 40px;
      text-align: center;
      margin: 0;
      padding: 0.5rem;
      min-width: 120px;
      width: 1%;
      border: ${(props) => `1px solid ${props.theme.grey2}`};
      :last-child {
        border-right: 0;
      }
    }

    th {
      background-color: ${(props) => props.theme.grey5};
      color: ${(props) => props.theme.dark};
      border: 0;
      span {
        cursor: pointer;
      }
    }

    td {
      color: ${(props) => props.theme.grey1};
      input {
        border: 0;
        background: transparent;
        outline: none;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`
