import { api } from '@console/common/services/api'

export type Service = 'viewer' | 'ar' | 'customizer'

export interface Filters {
  from?: Date
  to?: Date
  customerId?: string
  service?: Service
  page?: number
  limit?: number
  orderBy?: SKUOrderOptions
}

export interface FunnelData {
  sessions: number
  r2uSessions: number
  interactions: number
  addToCart: number
}

export type InteractionsData = Record<Service, number>

export interface SKUAnalytics {
  sku: string
  id?: string
  name?: string
  pageViews: number
  interactions: number
  addToCart: number
}

export enum SKUOrderOptions {
  SKU = 'sku ASC',
  MostPageViews = 'pageViews DESC',
  LeastPageViews = 'pageViews ASC',
  MostInteractions = 'interactions DESC',
  LeastInteractions = 'interactions ASC',
  MostAddedToCart = 'addToCart DESC',
  LeastAddedToCart = 'addToCart ASC'
}

export interface ServiceData {
  products: number
  impressions: number
  sessions: number
  ctr: number
  time: number
  fallbackRate?: number
  combinations?: number
}

export type ServiceHistory = Array<{ count: number; date: Date }>

type ServiceHistoryData = {
  ctr: ServiceHistory
  impressions: ServiceHistory
}

export interface ProductSplitData {
  total: number
  r2uProducts: number
  otherProducts: number
}

export interface WebXRData {
  sessionId: string
  compatible: boolean | null
  planeMapped: string | null
  objectPlaced: string | null
  translateInstructions: string | null
  rotateInstructions: string | null
  firstTranslation: string | null
  firstRotation: string | null
  reset: string | null
  sceneViewerFallback: number | null
  fallback: number | null
  share: number | null
  exit: number | null
  likes: number | null
  dislikes: number | null
  callToAction: number | null
  translation: number | null
  rotation: number | null
}

const get = async <T>(route: string, filters: Filters): Promise<T | null> => {
  try {
    const { data } = await api.get<T>(route, {
      params: filters
    })
    return data
  } catch (e) {
    return null
  }
}

export const getFunnel = async (filters: Filters): Promise<FunnelData | null> =>
  get<FunnelData>('/v1/performance/funnel', filters)

export const getInteractions = async (filters: Filters): Promise<InteractionsData | null> =>
  get<InteractionsData>('/v1/performance/interactions', filters)

export const getSKUs = async (filters: Filters): Promise<SKUAnalytics[] | null> =>
  get<SKUAnalytics[]>('/v1/performance/skus', filters)

export const getService = async (filters: Filters): Promise<ServiceData | null> =>
  get<ServiceData>('/v1/performance/service', filters)

export const getHistory = async (filters: Filters): Promise<ServiceHistoryData | null> =>
  get<ServiceHistoryData>('/v1/performance/history', filters)

export const getProductSplit = async (filters: Filters): Promise<ProductSplitData | null> =>
  get<ProductSplitData>('/v1/performance/products', filters)

export const getWebXR = async (filters: Filters): Promise<WebXRData[] | null> =>
  get<WebXRData[]>('/v1/performance/webxr', filters)
