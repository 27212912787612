import React, { useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import Header from '@console/common/components/Header'

import emptyState from '../../assets/illustrations/emptyState.svg'
import ApproveReviewButton from '../../components/ApproveReviewButton'
import CardsList from '../../components/CardsList'
import ProductCount from '../../components/ProductCount'
import SelectAllCheckbox from '../../components/SelectAllCheckbox'
import { ProductsContext } from '../../contexts/ProductsContext'
import { MainContainer, FiltersContainer, EmptyStateContainer, SubheadingContainer } from './styles'

const List: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { loading, products, fetchAndSetProducts } = useContext(ProductsContext)

  useEffect(() => {
    fetchAndSetProducts(true)
  }, [])

  return (
    <MainContainer>
      <Header title={t('list.reviews')} />
      {!loading && !products.length ? (
        <EmptyStateContainer>
          <img src={emptyState} alt='' />
          <h3>{t('list.emptyState')}</h3>
        </EmptyStateContainer>
      ) : (
        <>
          <SubheadingContainer>
            <h3>{t('list.select')}</h3>
          </SubheadingContainer>
          <FiltersContainer>
            <SelectAllCheckbox />
            <ApproveReviewButton />
            <ProductCount />
          </FiltersContainer>
          <CardsList />
        </>
      )}
    </MainContainer>
  )
}

export default List
