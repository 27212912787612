import React, { useCallback, useState, useEffect, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import AssetViewer from '@console/common/components/AssetViewer'
import CarouselCard from '@console/common/components/CarouselCard'
import MultiCarousel from '@console/common/components/MultiCarousel'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import NextButton from '../../../components/NextButton'
import {
  MainContainer,
  CarouselContainer,
  ContentContainer,
  ContentField,
  Separator,
  ViewerContainer
} from './styles'

const Confirmation: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [glbUrl] = useState<string | undefined>()
  const [images, setImages] = useState<{ id: string; url: string }[]>([])
  const [imageSlides, setImageSlides] = useState<React.ReactNode[]>([])
  const [name, setName] = useState<string | null>(null)
  const [sku, setSku] = useState<string | null>(null)
  const [ean, setEan] = useState<string | null>(null)
  const [pdpUrl, setPdpUrl] = useState<string>()
  const [placement, setPlacement] = useState<string | null>(null)
  const [height, setHeight] = useState<string>()
  const [width, setWidth] = useState<string>()
  const [depth, setDepth] = useState<string>()
  const { analytics } = useContext(AnalyticsContext)

  const carouselResponsive = {
    three: {
      breakpoint: { max: 3000, min: 500 },
      items: 3,
      slidesToSlide: 1
    }
  }

  useEffect(() => {
    const productDataItem = localStorage.getItem('productData')
    if (productDataItem) {
      const productData = JSON.parse(productDataItem)
      setName(productData.name)
      setSku(productData.sku)
      setEan(productData.ean)
      setPdpUrl(productData.pdpUrl)
      setHeight(productData.height)
      setWidth(productData.width)
      setDepth(productData.depth)
      setPlacement(t(`confirmation.${productData.placement.toLowerCase()}`))
    }

    const imageUploadItem = localStorage.getItem('imageUpload')
    if (imageUploadItem) {
      setImages(JSON.parse(imageUploadItem))
    }
  }, [])

  const deleteImage = useCallback(
    async (id: string) => {
      analytics?.track(AnalyticsEvent.REGISTRATION_DELETE_IMAGE)
      const newImages = images.filter((image) => image.id !== id)
      setImages(newImages)
      localStorage.setItem('imageUpload', JSON.stringify(newImages))
    },
    [images]
  )

  useEffect(() => {
    const slides = images.map((image) => (
      <React.Fragment key={image.id}>
        <CarouselCard
          type='IMAGE'
          imageUrl={image.url}
          deleteCallback={() => deleteImage(image.id)}
          height='227px'
          width='330px'
        />
      </React.Fragment>
    ))
    setImageSlides(slides)
  }, [images])

  return (
    <MainContainer>
      {imageSlides.length ? (
        <CarouselContainer>
          <MultiCarousel slides={imageSlides} responsive={carouselResponsive} />
        </CarouselContainer>
      ) : null}
      <ContentContainer>
        <ContentField>
          <h2>{t('confirmation.name')}</h2>
          <span>{name}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.sku')}</h2>
          <span>{sku}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.ean')}</h2>
          <span>{ean}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.pdp')}</h2>
          <span>{pdpUrl}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.height')}</h2>
          <span>{height}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.width')}</h2>
          <span>{width}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.depth')}</h2>
          <span>{depth}</span>
        </ContentField>
        <Separator />
        <ContentField>
          <h2>{t('confirmation.placement')}</h2>
          <span>{placement}</span>
        </ContentField>
      </ContentContainer>
      {glbUrl ? (
        <ViewerContainer>
          <AssetViewer src={glbUrl} />
        </ViewerContainer>
      ) : null}

      <NextButton confirm />
    </MainContainer>
  )
}

export default Confirmation
