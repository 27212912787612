import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

interface ButtonProps {
  active: boolean
  isLoading?: boolean
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  background-color: ${(props) => props.theme.lightBlue2};
  border-radius: 50%;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all 200ms;
  ${(props) => (props.active ? 'transform: scale(1.35);' : null)};

  ${(props) => props.isLoading && skeletonAnimation}
`

export const Icon = styled.img<ButtonProps>`
  padding: 16px;
  margin: 0;
  border: none;
  height: ${(props) => (props.active ? '32px' : '24px')};
  width: ${(props) => (props.active ? '32px' : '24px')};

  // theme.darkBlue4
  filter: invert(11%) sepia(99%) saturate(3082%) hue-rotate(182deg) brightness(92%) contrast(103%);
`
