import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 275px;
  .text-input {
    width: 277px;
    & .MuiInputBase-root {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${(props) => props.theme.black};
    }

    & .MuiInputBase-input {
      padding: 0;
      ::-webkit-input-placeholder {
        color: ${(props) => props.theme.black};
        opacity: 1;
        font-weight: 500;
        font-size: 16px;
        font-style: normal;
      }
    }
  }
  .adornment {
    background-color: ${(props) => props.theme.white};
    padding-right: 10px;
    button {
      border: 0;
      background-color: white;
    }
  }
`

export const Icon = styled.img`
  width: 22px;
  height: 22px;
  color: #003e58;
`

export const CustomPaper = styled(Paper)`
  border: ${(props) => `1px solid ${props.theme.grey2}`};
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
`
