import React, { useState, useEffect, useRef, useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import Button from '@console/common/components/Button'
import Cascade, { SelectOptions } from '@console/common/components/Cascade'
import TextInput, { InputTypes } from '@console/common/components/TextInput'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole, getCustomers } from '@console/common/services/api'

import Header from '../../../components/Header'
import { getUser, patchUser } from '../../../services/api'
import { Container, FieldNames, ButtonContainer } from './styles'

interface ParamTypes {
  userId: string
}

const Invite: React.FC = () => {
  const { t } = useTranslation(['settings'])
  const { userId } = useParams<ParamTypes>()
  const history = useHistory()
  const { session } = useContext(AuthContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [customerOptions, setCustomerOptions] = useState<SelectOptions[]>([])
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [customer, setCustomer] = useState<SelectOptions | null>(null)
  const [access, setAccess] = useState<SelectOptions | null>(null)
  const [validity, setValidity] = useState<boolean>(false)
  const nameRef = useRef<HTMLDivElement>(null)
  const emailRef = useRef<HTMLDivElement>(null)

  const AccessOptions = [
    {
      value: UserRole.ADMIN,
      label: t('users.admin')
    },
    {
      value: UserRole.ARTIST,
      label: t('users.artist')
    },
    {
      value: UserRole.CUSTOMER,
      label: t('users.customer')
    },
    {
      value: UserRole.R2USER,
      label: t('users.r2user')
    },
    {
      value: UserRole.FREELANCER,
      label: t('users.freelancer')
    }
  ]

  const setInitialValues = async (customers: SelectOptions[]) => {
    await getUser(userId).then((u) => {
      if (u) {
        setName(u.name ? u.name : '')
        setEmail(u.id ? u.id : '')
        setAccess(AccessOptions.filter((c) => c.value === u.role)[0])
        setCustomer(customers.filter((c) => c.value.id === u.customerId)[0])
      }
    })
  }

  useEffect(() => {
    const nameValidity = (nameRef.current?.children[0] as HTMLInputElement)?.checkValidity()
    const emailValidity = (emailRef.current?.children[0] as HTMLInputElement)?.checkValidity()
    setValidity(nameValidity && emailValidity && !!customer && !!access)
  }, [name, email, access])

  useEffect(() => {
    setLoading(true)
    getCustomers().then(async (c) => {
      if (c) {
        const customerOption = c.map((value) => ({
          value,
          label: value.name
        }))
        setCustomerOptions(customerOption)
        await setInitialValues(customerOption)
        setLoading(false)
      }
    })
  }, [])

  const updateUser = async () => {
    if (!validity) return

    await patchUser(userId, name, access?.value, customer?.value.id)
    history.push('/settings/users')
  }

  return (
    <Container>
      <Header returnPage='/settings/users' />
      <FieldNames isLoading={loading}>{t('users.invite.name')}</FieldNames>
      <div ref={nameRef}>
        <TextInput
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder={t('users.invite.nameExample')}
          width='487px'
          type={InputTypes.TEXT}
          required
          isLoading={loading}
        />
      </div>
      <FieldNames isLoading={loading}>{t('users.invite.email')}</FieldNames>
      <div ref={emailRef}>
        <TextInput
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder={t('users.invite.emailExample')}
          width='337px'
          type={InputTypes.EMAIL}
          required
          isLoading={loading}
          disabled
        />
      </div>
      <FieldNames isLoading={loading}>{t('users.customer')}</FieldNames>
      <Cascade
        options={customerOptions}
        onChange={(option) => setCustomer(option)}
        value={customer}
        isSearchable
        isLoading={loading}
        isDisabled={
          !session || (session.role !== UserRole.ADMIN && session.role !== UserRole.R2USER)
        }
      />
      <FieldNames isLoading={loading}>{t('users.invite.access')}</FieldNames>
      <Cascade
        options={AccessOptions}
        onChange={(option) => setAccess(option)}
        value={access}
        isSearchable={false}
        isLoading={loading}
        isDisabled={
          !session || (session.role !== UserRole.ADMIN && session.role !== UserRole.R2USER)
        }
      />
      <ButtonContainer>
        <Button onClick={updateUser} disabled={!validity}>
          {t('users.saveEdit')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default Invite
