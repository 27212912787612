import React from 'react'

import arrowLeft from '@console/common/assets/arrowLeft.svg'

import { Container, Title, TitleHover, TitleSeparator, StyledLink, Icon } from './styles'
import SubPageMenu, { SubPage } from './SubPageMenu'

interface Props {
  title: string
  subPages?: SubPage[]
  returnPage?: string
  children?: React.ReactNode
  status?: React.ReactNode
}

const Header: React.FC<Props> = ({ title, subPages, returnPage, children, status }: Props) => (
  <>
    <Container>
      {returnPage ? (
        <StyledLink to={returnPage}>
          <Title>
            <Icon src={arrowLeft} />
            <TitleHover>{title}</TitleHover>
          </Title>
        </StyledLink>
      ) : (
        <Title>{title}</Title>
      )}
      {children}
    </Container>
    {subPages ? (
      <>
        <SubPageMenu subPages={subPages} />
        <TitleSeparator hasSubPageMenu />
      </>
    ) : (
      <>
        {status || <div />}
        <TitleSeparator hasStatusBar={!!status} />
      </>
    )}
  </>
)

export default Header
