import React, { useEffect, useState } from 'react'

import { ContainerInputRange, InputRangeStyle, OutputRange, OutputRangeContainer } from './styles'

interface Props {
  min: number
  max: number
  step: number
  value: number
  onChange(newValue: number): void
  maxValue?: number
  minValue?: number
  afterChange?(newValue: number): void
}

const InputRange: React.FC<Props> = ({
  min,
  max,
  step,
  value,
  onChange,
  maxValue,
  minValue,
  afterChange
}: Props) => {
  const percent = ((value - min) / (max - min)) * 100
  const [outputValue, setOutputValue] = useState<string>()

  const checkBoxValue = (newValue: string) => {
    let boxValue: number

    if (Number.isNaN(Number(newValue))) boxValue = min
    else if (Number(newValue) < min) boxValue = min
    else if (Number(newValue) > max) boxValue = max
    else boxValue = Number(newValue)

    setOutputValue(boxValue.toString())
  }

  const checkValue = (newValue: number): number => {
    if (
      maxValue !== undefined &&
      minValue !== undefined &&
      (newValue >= maxValue || newValue <= minValue)
    ) {
      if (newValue > maxValue) return maxValue
      return minValue
    }
    if (maxValue !== undefined && newValue >= maxValue) {
      return maxValue
    }
    if (minValue !== undefined && newValue <= minValue) {
      return minValue
    }
    return newValue
  }

  const saveValue = () => {
    onChange(checkValue(Number(outputValue)))
  }

  useEffect(() => {
    checkBoxValue(value.toFixed(2))
  }, [value])

  return (
    <ContainerInputRange>
      <InputRangeStyle
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        percent={percent}
        onChange={(e) => {
          onChange(checkValue(Number(e.target.value)))
        }}
        onMouseUp={() => {
          if (afterChange !== undefined) afterChange(Number(value))
        }}
      />
      <OutputRangeContainer>
        <OutputRange percent={percent + (1.5 - percent * 0.065)}>
          <input
            className='value-input'
            value={outputValue}
            type='number'
            pattern='[0-9]+([\.,][0-9]+)?'
            step='0.01'
            onChange={(e) => checkBoxValue(e.target.value)}
            onBlur={saveValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                saveValue()
              }
            }}
          />
        </OutputRange>
      </OutputRangeContainer>
    </ContainerInputRange>
  )
}

export default InputRange
