import styled from 'styled-components'

import StyledSkeleton from '@console/common/components/Skeleton'

const Skeleton = styled(StyledSkeleton)`
  width: 100%;
  height: 42px;
  border-radius: 8px;
  margin-bottom: 8px;
`

export default Skeleton
