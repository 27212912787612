import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react'

import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  Product,
  fetchAllProducts,
  fetchProducts,
  OrderOptions,
  UserRole
} from '@console/common/services/api'
import Status from '@console/common/types/Status'

import { getStatusFilter, getStatus } from '../services/status'

export interface ProductWithStatus extends Product {
  publicStatus?: Status
}

interface Props {
  children: ReactNode
  order: OrderOptions
  status: Status | null
  search: string | null
  lastMonthActive: string | null
}

interface ProductsContextData {
  products: ProductWithStatus[]
  loading: boolean
  total: number | null
  filteredTotal: number | null
  nextPage: number | null
  fetchAllProductsInfo: () => Promise<Blob | null>
  fetchAndSetProducts: () => void
  setProducts: React.Dispatch<ProductWithStatus[]>
}

export const ProductsContext = createContext<ProductsContextData>({} as ProductsContextData)

export const ProductsProvider: React.FC<Props> = ({
  children,
  order,
  status,
  search,
  lastMonthActive
}: Props) => {
  const [products, setProducts] = useState<ProductWithStatus[]>([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<number | null>(null)
  const [filteredTotal, setFilteredTotal] = useState<number | null>(null)
  const [nextPage, setNextPage] = useState<number | null>(0)
  const [pageSize] = useState<number>(30)
  const { session, assumedCustomer, sessionCustomer } = useContext(AuthContext)

  const addStatusToProducts = (productList: Product[]): ProductWithStatus[] =>
    // eslint-disable-next-line implicit-arrow-linebreak
    productList.map((p) => {
      const customer = sessionCustomer
        ? p.Customers?.find((findCustomer) => findCustomer.id === sessionCustomer.id)
        : p.Customers?.find((findCustomer) => findCustomer.id === p.owner)
      return {
        ...p,
        publicStatus: getStatus(
          p.status,
          customer?.CustomerProduct ? customer.CustomerProduct.active : p.active
        )
      }
    })

  const fetchAndSetProducts = useCallback(
    (reset = false) => {
      if (loading) return
      setLoading(true)
      const { status: productStatus, active } = getStatusFilter(status)
      const page = reset ? 0 : nextPage ?? 0
      fetchProducts({
        order,
        page,
        pageSize,
        status: productStatus,
        active,
        search,
        lastMonthActive,
        customerId:
          session?.role === UserRole.ADMIN ||
          session?.role === UserRole.R2USER ||
          session?.role === UserRole.ARTIST
            ? assumedCustomer?.id
            : null
      }).then((p) => {
        if (p) {
          const newList = reset
            ? addStatusToProducts(p.results)
            : [...products, ...addStatusToProducts(p.results)]
          setProducts(newList)
          if (reset) setTotal(p.total)
          setFilteredTotal(p.total)
          setNextPage(
            p.total > p.start + p.results.length
              ? Math.round((p.start + p.results.length) / pageSize)
              : null
          )
        }
        setLoading(false)
      })
    },
    [order, status, search, loading, products, nextPage, pageSize, assumedCustomer, lastMonthActive]
  )

  useEffect(() => {
    setProducts([])
    fetchAndSetProducts(true)
  }, [order, status, search, assumedCustomer, lastMonthActive])

  const fetchAllProductsInfo = async () => {
    const { status: productStatus, active } = getStatusFilter(status)
    const result = await fetchAllProducts({
      order,
      status: productStatus,
      active,
      search,
      lastMonthActive,
      customerId:
        session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER
          ? assumedCustomer?.id
          : null
    })
    return result
  }

  return (
    <ProductsContext.Provider
      value={{
        products,
        loading,
        total,
        filteredTotal,
        nextPage,
        fetchAllProductsInfo,
        fetchAndSetProducts,
        setProducts
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
