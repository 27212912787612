import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import UploadGif from '@console/common/assets/cubeLine.gif'
import ModelIcon from '@console/common/assets/cubeOutline.svg'
import DownloadIcon from '@console/common/assets/downloadArrow.svg'
import FullscreenIcon from '@console/common/assets/fullscreen.svg'
import DeleteIcon from '@console/common/assets/thrash.svg'

import { getThumbIfExists } from '../../services/api'
import {
  CardDiv,
  Skeleton,
  Image,
  ContentContainer,
  IconElement,
  ExtensionText,
  HoverContainer,
  HoverButton
} from './styles'

interface Props {
  type: 'IMAGE' | 'MODEL' | 'UPLOADING'
  deleteCallback?: () => void
  imageUrl?: string
  modelUrl?: string
  extension?: string
  height?: string
  width?: string
  isLoading?: boolean
}

const CarouselCard: React.FC<Props> = ({
  type,
  deleteCallback,
  imageUrl,
  modelUrl,
  extension,
  height,
  width,
  isLoading
}: Props) => {
  const [hover, setHover] = useState(false)

  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined)

  useEffect(() => {
    getThumbIfExists(imageUrl as string).then((result) => {
      setThumbnail(result)
    })
  }, [])

  return (
    <>
      {isLoading ? (
        <Skeleton height={height} width={width} />
      ) : type === 'UPLOADING' ? (
        <CardDiv height={height} width={width}>
          <ContentContainer>
            <IconElement src={UploadGif} alt={type} />
          </ContentContainer>
        </CardDiv>
      ) : (
        <CardDiv
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          height={height}
          width={width}
        >
          {type === 'IMAGE' ? (
            <ContentContainer>
              {thumbnail && <Image src={thumbnail} alt={imageUrl} height={height} width={width} />}
            </ContentContainer>
          ) : (
            <ContentContainer>
              <IconElement src={ModelIcon} />
              <ExtensionText>{extension}</ExtensionText>
            </ContentContainer>
          )}
          {hover ? (
            <HoverContainer>
              <HoverButton deleteButton direction='left' onClick={deleteCallback}>
                <img src={DeleteIcon} alt='delete' />
              </HoverButton>
              {type === 'IMAGE' ? (
                <Link to={{ pathname: imageUrl }} target='_blank'>
                  <HoverButton direction='right'>
                    <img src={FullscreenIcon} alt='fullscreen' />
                  </HoverButton>
                </Link>
              ) : (
                <Link to={{ pathname: modelUrl }} target='_blank'>
                  <HoverButton direction='right'>
                    <img src={DownloadIcon} alt='download' />
                  </HoverButton>
                </Link>
              )}
            </HoverContainer>
          ) : null}
        </CardDiv>
      )}
    </>
  )
}

export default CarouselCard
