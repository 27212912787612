import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  height: 420px;
  width: 70%;
  margin-top: 45px;
  position: relative;

  img {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }
`
export const PDFContainer = styled.div`
  overflow: auto;
  margin-top: 60px;
  height: 80%;
  width: 90%;
  margin-left: 4%;
  margin-right: 0;
  padding-right: 6%;
`
