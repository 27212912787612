import styled from 'styled-components'

interface Props {
  alternative?: boolean
  isLoading?: boolean
}

const Button = styled.button<Props>`
  width: 185px;
  height: 39px;
  border-radius: 8px;
  font-size: 14px;
  cursor: ${(props) => (props.isLoading ? 'default' : 'pointer')};

  border: ${(props) =>
    props.alternative && !props.isLoading ? `solid 1px ${props.theme.dark}` : '0px'};

  background-color: ${(props) => {
    if (props.isLoading) return props.theme.skeleton
    if (props.alternative) return 'white'
    return props.theme.darkBlue3
  }};
  color: ${(props) => {
    if (props.isLoading) return 'transparent'
    if (props.alternative) return props.theme.dark
    return 'white'
  }};

  :hover {
    ${(props) => {
      if (props.isLoading) return ''
      if (props.alternative) {
        return `
          color: ${props.theme.blue};
          border-color: ${props.theme.blue};
        `
      }
      return `
        background-color: ${props.theme.lightBlue2};
        color: ${props.theme.darkBlue4};
      `
    }}
  }

  :disabled {
    pointer-events: none;
    ${(props) => {
      if (props.isLoading) return ''
      if (props.alternative) {
        return `
          color: ${props.theme.grey2};
          border-color: ${props.theme.grey2};
      `
      }
      return `
        background-color: ${props.theme.grey2};
      `
    }}
  }
`

export default Button
