import styled from 'styled-components'

interface Props {
  isLoading?: boolean
}

export const ApproveReviewButtonStyle = styled.button<Props>`
  margin-top: 35px;
  display: flex;
  width: 181px;
  height: 39px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.grey7};
  border-radius: 8px;
  border: 0;
  cursor: pointer;

  /* text style */
  color: ${(props) => props.theme.dark2};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 5px 8px 5px 0px;

  img {
    height: 18px;
    margin: 8px;
    background-color: ${(props) => props.theme.grey7};

    animation: ${(props) => (props.isLoading ? 'rotate 1.4s linear infinite' : '0')};
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`
