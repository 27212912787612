import React, { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import readIcon from '../../assets/readIcon.svg'
import unreadIcon from '../../assets/unreadIcon.svg'
import {
  Notification,
  CommentData,
  ReviewData,
  AnnouncementData,
  updateNotification
} from '../../services/api'
import { Container, VerticalRule, TitleContainer } from './styles'

interface Props {
  notification: Notification
}

const formatDate = (d: Date): string => d.toLocaleString([], { dateStyle: 'short' })

const formatTime = (d: Date): string => d.toLocaleString([], { timeStyle: 'short' })

const NotificationContainer: React.FC<Props> = ({ notification }: Props) => {
  const { t } = useTranslation(['notification'])
  const [read, setRead] = useState(notification.read)
  const { analytics } = useContext(AnalyticsContext)

  const history = useHistory()

  const d = new Date(notification.createdAt)

  let title
  let content
  let clickCallback: () => void

  if (notification.type === 'COMMENT') {
    const data = notification.data as CommentData
    title = t('comment.title')
    content = t('comment.content')
    clickCallback = () => history.push(`/review/${data.productId}`)
  } else if (notification.type === 'PENDING_REVIEW') {
    const data = notification.data as ReviewData
    title = t('review.title')
    const count = data.products.length
    content = t('review.content', { count })
    clickCallback = () => history.push(`/review/${data.products[0]}`)
  } else {
    const data = notification.data as AnnouncementData
    title = data[t('announcement.language')].title
    content = data[t('announcement.language')].content
    clickCallback = () => {
      const { redirectUrl } = data[t('announcement.language')]
      if (redirectUrl) history.push(redirectUrl)
    }
  }

  const onClick = () => {
    if (read) return

    analytics?.track(AnalyticsEvent.NOTIFICATIONS_CLICK, { notificationId: notification.id })

    updateNotification(notification.id, { read: true }).then(() => {
      setRead(true)
      clickCallback()
    })
  }

  return (
    <Container read={read} onClick={onClick}>
      <TitleContainer>
        <img src={read ? readIcon : unreadIcon} alt='' />
        <span>{title}</span>
      </TitleContainer>
      <VerticalRule />
      <span className='content'>{content}</span>
      <VerticalRule />
      <span className='date'>
        {formatDate(d)} {t('timePreposition')} {formatTime(d)}
      </span>
    </Container>
  )
}

export default NotificationContainer
