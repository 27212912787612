import styled from 'styled-components'

import Button from '@console/common/components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 62px;
  padding-left: 26px;
  padding-right: 26px;

  p {
    margin: 0;
  }
`

export const Icon = styled.img`
  height: 22px;
  width: 20px;
  margin-right: 10px;
`

export const QRCodeContainer = styled.div`
  svg {
    display: block;
    height: auto;
    width: 60%;
  }
`

export const LargeButton = styled(Button)`
  width: 320px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
`
