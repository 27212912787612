import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import LaunchIcon from '@console/common/assets/launch.svg'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'

import { ProductContext } from '../../contexts/ProductContext'
import {
  Container,
  Title,
  FieldsContainer,
  FieldName,
  FieldValue,
  FieldRow,
  Divider,
  LinkContainer,
  LinkIcon,
  LinkText
} from './styles'

const InfoBar: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { product } = useContext(ProductContext)
  const { session } = useContext(AuthContext)

  const formatBigString = (word?: string) => {
    if (word && word.length < 17) return <span>{word}</span>
    const firstLine = word?.slice(0, 17)
    let secondLine = word?.slice(17)
    if (secondLine && secondLine?.length > 17) {
      secondLine = `${secondLine?.slice(0, 14)}...`
    }
    return (
      <>
        <span>{firstLine}</span>
        <br />
        <span>{secondLine}</span>
      </>
    )
  }

  return (
    <>
      <Container>
        {product && (
          <>
            <Title>{t('info.title')}</Title>
            <FieldsContainer>
              <FieldRow>
                <FieldName>{t('info.client')}</FieldName>
                <FieldValue>
                  {formatBigString(product.Customers ? product.Customers[0].name : '')}
                </FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.product')}</FieldName>
                <FieldValue>{formatBigString(product.name)}</FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.sku')}</FieldName>
                <FieldValue>{formatBigString(product.sku)}</FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.pdp')}</FieldName>
                <FieldValue>
                  <Link to={{ pathname: product.pdpUrl }} target='_blank'>
                    {formatBigString(product.pdpUrl)}
                  </Link>
                </FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.ean')}</FieldName>
                <FieldValue>
                  <span>{product.ean}</span>
                </FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.height')}</FieldName>
                <FieldValue>
                  <span>{product.metadata?.height}</span>
                </FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.width')}</FieldName>
                <FieldValue>
                  <span>{product.metadata?.width}</span>
                </FieldValue>
              </FieldRow>
              <FieldRow>
                <FieldName>{t('info.depth')}</FieldName>
                <FieldValue>
                  <span>{product.metadata?.depth}</span>
                </FieldValue>
              </FieldRow>
            </FieldsContainer>
          </>
        )}
        {session && session?.role !== UserRole.FREELANCER ? (
          <>
            <Divider />
            <LinkContainer>
              <LinkIcon src={LaunchIcon} alt='link icon' />
              <Link to={`/portfolio/${product?.id}`} target='_blank'>
                <LinkText>{t('info.link')}</LinkText>
              </Link>
            </LinkContainer>
          </>
        ) : null}
      </Container>
    </>
  )
}

export default InfoBar
