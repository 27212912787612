import React, { useContext, useState, useCallback, useEffect } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import closeIcon from '@console/common/assets/close.svg'
import Popup, { PopupMode } from '@console/common/components/Popup'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { ModalContext } from '@console/common/contexts/ModalContext'
import { ReferenceType } from '@console/common/services/api'
import { upload } from '@console/common/services/upload'

import AddIcon from '../../assets/icons/addIcon.svg'
import { ProductContext } from '../../contexts/ProductContext'
import { VersionContext } from '../../contexts/VersionContext'
import { createReference } from '../../services/api'
import ComparisonPanel from './ComparisonPanel'
import {
  AddImage,
  AddImageContainer,
  Container,
  Grid,
  Image,
  LargeButton,
  PopupContainer,
  CloseButton
} from './styles'

const ReferenceImagesBar: React.FC = () => {
  const { t } = useTranslation(['components/reference-bar'])
  const { analytics } = useContext(AnalyticsContext)
  const { product } = useContext(ProductContext)
  const { isOpen, toggleModal, closeModal } = useContext(ModalContext)
  const { glb } = useContext(VersionContext)
  const [images, setImages] = useState<{ id: string; url: string }[]>([])
  const [selectedImage, setSelectedImage] = useState<{ id: string; url: string } | null>(null)

  const updateImages = () => {
    if (product) {
      setImages(
        product?.References?.filter((reference) => reference.type === ReferenceType.IMAGE)?.map(
          (reference) => ({ id: reference.id, url: reference.url })
        )
      )
    }
  }

  useEffect(() => {
    updateImages()
  }, [product])

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!product) return

      analytics?.track(AnalyticsEvent.REVIEWER_UPLOAD_REFERENCE, { productId: product.id })

      acceptedFiles.forEach((file: File) => {
        upload(
          file,
          `references/${product.id}`,
          async (e: string) => {
            createReference(product.id, {
              name: file.name,
              type: ReferenceType.IMAGE,
              url: e,
              description: 'Uploaded using the platform'
            }).then((reference) => {
              if (reference) {
                product.References.push(reference)
                updateImages()
              }
            })
          },
          undefined,
          true
        )
      })
    },
    [images]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    noDrag: true,
    noDragEventsBubbling: true
  })

  const openComparisonPopup = (image: { id: string; url: string }) => {
    analytics?.track(AnalyticsEvent.REVIEWER_COMPARE_REFERENCE, { referenceId: image.id })
    setSelectedImage(image)
    toggleModal()
  }

  return (
    <Container>
      <Popup mode={PopupMode.LARGE} trigger={isOpen}>
        <PopupContainer>
          <CloseButton role='button' onClick={closeModal} onKeyPress={closeModal} tabIndex={0}>
            <img src={closeIcon} alt='close' />
          </CloseButton>
          <ComparisonPanel defaultImage={selectedImage} images={images} glbSrc={glb || ''} />
        </PopupContainer>
      </Popup>
      <LargeButton onClick={() => openComparisonPopup(images[0])}> {t('compare')} </LargeButton>
      <Grid>
        {images.map((image) => (
          <Image src={image.url} alt={image.url} onClick={() => openComparisonPopup(image)} />
        ))}
        <AddImageContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <AddImage src={AddIcon} />
        </AddImageContainer>
      </Grid>
    </Container>
  )
}

export default ReferenceImagesBar
