import styled, { css } from 'styled-components'

interface CollapsibleProps {
  resetVisible?: boolean
  resetVisibleNested?: boolean
}

export const Container = styled.div`
  display: grid;
  grid-template-rows: 8fr 1fr;
  height: 100vh;
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 16px 0 16px;
  overflow-y: auto;

  .title {
    font-weight: 700;
    font-size: 18px;
    color: ${(props) => props.theme.dark2};
    text-align: center;
  }

  .subTitle {
    font-weight: 500;
    font-size: 14px;
    color: black;
  }

  .disclaimer {
    display: flex;
    font-size: 9px;
    text-align: justify;
    padding: 5px;
    margin-top: 7px;
    background: #cfcfcf;
    color: #333333;
    border-radius: 8px;

    .attention-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  .hdris {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 100px 100px;
    position: relative;
    max-width: 100%;
    overflow: hidden;

    .hdri {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: scale(0.7);

      button {
        background: transparent;
        padding: 0;
        border: none;
        transition: 0.2s;

        &:hover {
          transform: scale(1.2);
          cursor: pointer;
        }

        &.selected {
          transform: scale(1.2);
        }
      }

      span {
        font-weight: 500;
        font-size: 16px;
        color: black;
      }
    }
  }

  .advertising {
    margin-top: 40px;
    margin-bottom: 60px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    color: ${(props) => props.theme.darkBlue4};
    text-align: center;
    font-weight: 500;
    font-size: 18px;
  }
`
export const ContainerInputRange = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  margin-top: 2px;
`

export const InputRangeStyle = styled.input.attrs((props: { percent: number }) => props)`
  -webkit-appearance: none !important;
  width: 100%;
  background: transparent;
  margin-down: 0;

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
        to right,
        ${(props) => props.theme.darkBlue4},
        ${(props) => props.theme.darkBlue4}
      ),
      ${(props) => props.theme.lightBlue2};
    background-repeat: no-repeat;
    background-size: ${(props) => `${props.percent !== 0 ? props.percent : 0.01}%`};
    border-radius: 8px;
    height: 3px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    width: 15px;
    height: 15px;
    cursor: ew-resize;
    background: ${(props) => props.theme.darkBlue2};
    transform: rotate(-45deg);
    margin-top: -6px;
    border-radius: 15px;
  }

  &&::-moz-range-track {
    background: linear-gradient(
        to right,
        ${(props) => props.theme.darkBlue4},
        ${(props) => props.theme.darkBlue4}
      ),
      ${(props) => props.theme.lightBlue2};
    background-repeat: no-repeat;
    background-size: ${(props) => `${props.percent}%`};
    border-radius: 8px;
    height: 3px;
  }

  &&::-moz-range-thumb {
    width: 18px;
    height: 18px;
    cursor: ew-resize;
    transform: rotate(-45deg);
    margin-top: -5px;
    border: none;
    border-radius: 0px;
    background: ${(props) => props.theme.darkBlue2};
  }
`

export const OutputRangeContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 5px;
`

export const OutputRange = styled.output.attrs((props: { percent: number }) => props)`
  font-size: 12px;
  font-weight: bold;
  padding-left: 8px;
  text-align: center;

  .value-input {
    background-color: #cfcfcf;
    border: 0px;
    border-radius: 5px;
    width: 45px;
    text-align: right;
    margin-bottom: 8px;
  }

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

export const CollapsibleContainer = styled.div<CollapsibleProps>`
  .fa-chevron-down-edonec {
    width: 35px;
    height: 35px;
  }
  .collapsible-content-edonec {
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    border-bottom: 1px solid #dee2e6 !important;
  }
  .title-text-edonec {
    display: block;
    font-size: 1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .collapsible-header-edonec {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0px 0px 20px;
    border-bottom: 1px solid #dee2e6 !important;
    cursor: pointer;
    background-color: #ebf1f3;
  }
  .collapsible-content-padding-edonec {
    padding: 20px 20px 20px 20px;
  }
  .rotate-center-edonec {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .rotate-center-edonec.down {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .rotate-center-edonec.up {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  .collapsible-icon-button-edonec {
    cursor: pointer;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
  }

  .collapsible-card-edonec {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .collapsible-card-edonec:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .reset-button {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #003e58;
    border: 0;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 0;

    ${({ resetVisible }) =>
      !resetVisible
        ? css`
            visibility: hidden;
          `
        : css`
            visibility: visible;
          `};
  }

  .nested {
    .reset-button {
      ${({ resetVisibleNested }) =>
        !resetVisibleNested
          ? css`
              visibility: hidden !important;
            `
          : css`
              visibility: visible !important;
            `};
    }
  }

  .reset-button:hover {
    background: #dee2e6;
  }

  .reset-icon {
    width: 20px;
  }
`

export const ConfigurationBox = styled.div`
  border-radius: 8px;
  box-shadow: -2px -2px 4px #ffffff, 2px 2px 4px rgba(86, 86, 86, 0.25);
  padding: 10px;
  margin-bottom: 5px;

  .react-colorful {
    height: 100px;
    margin-top: 13px;
    margin-left: 8px;
  }

  .default-checkbox-span {
    font-size: 13px;
    color: #414141;
  }
`

export const InputContainer = styled.div`
  .property-input {
    background-color: #cfcfcf;
    border-radius: 5px;
    border: 0px;
  }

  .color-input {
    background-color: #cfcfcf;
    border-radius: 5px;
    border: 0px;
    margin-left: 8px;
    width: 60px;
  }
`

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  height: 20px;

  .switch input {
    display: none;
  }

  .switch {
    // display: inline-block;
    width: 40px;
    height: 20px;
    position: relative;
  }

  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    box-shadow: 0 0 0 2px #777, 0 0 2px #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: 0.2s;
  }

  .slider:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #777;
    border-radius: 20px;
    transform: translateX(-20px); /*translateX(-(w-h))*/
    transition: 0.2s;
  }

  input:checked + .slider:before {
    transform: translateX(20px); /*translateX(w-h)*/
    background-color: #003e58;
  }

  input:checked + .slider {
    box-shadow: 0 0 0 2px #003e58, 0 0 2px #003e58;
  }
`

interface SaveButtonContainerProps {
  isViewerOptionsChanged: boolean
}

export const SaveButtonContainer = styled.div<SaveButtonContainerProps>`
  overflow: hidden;
  bottom: 0;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  margin: 8px;
  opacity: ${({ isViewerOptionsChanged }) => (isViewerOptionsChanged ? '1' : '0.5')};

  .save-button {
    width: 178px;
    height: 40px;
    color: #ffffff;
    background: #13344b;
    border-radius: 8px;
    border: 0;
    cursor: ${({ isViewerOptionsChanged }) => (isViewerOptionsChanged ? 'pointer' : 'not-allowed')};
    font-weight: 500;
    font-size: 16px;
  }
  .save-button:hover {
    background: #4b6577;
  }
`
