import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

export interface Props {
  isLoading?: boolean
}

export const InfoBar = styled.div<Props>`
  height: 32px;
  background-color: ${(props) => props.theme.dark};
  border-radius: 19px;
  min-width: 250px;
  max-width: 500px;
  font-size: 18px;
  color: white;
  margin: auto;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.isLoading && skeletonAnimation}

  @media (max-width: 1440px) {
    max-width: 300px;
  }
`

export const TextStyleBold = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  font-weight: 400;
  margin-right: 5px;
  margin-left: 20px;
`

export const TextStyleLight = styled.p`
  margin: 5px;
  margin-right: 20px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-flex;
  font-weight: lighter;
`
