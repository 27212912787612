import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

// Use this site to convert hex color to filter
// https://codepen.io/sosuke/pen/Pjoqqp

interface Props {
  isLoading: boolean
}

export const MainContainer = styled.div`
  margin-left: 2%;
  margin-right: 2%;
`

export const UpdateAndStatusContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b9b9b9;
    padding-left: 20px;
  }
  border-radius: 8px;
  width: ${(props) => (props.isLoading ? '235px' : null)};
  height: ${(props) => (props.isLoading ? '13px' : null)};
  ${(props) => props.isLoading && skeletonAnimation}
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`

export const LeftGrid = styled.div`
  display: grid;
  grid-template-rows: 40vh 40vh;
`

export const RightGrid = styled.div`
  display: grid;
  grid-template-rows: 40vh 40vh;
`

export const GalleryContainer = styled.div<Props>`
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-color: ${(props) => props.theme.white};

  .image-gallery-slide img {
    width: 100%;
    height: 40vh;
  }
  ${(props) => (props.isLoading ? '' : `border: 1px solid ${props.theme.grey2}`)};
  ${(props) => props.isLoading && skeletonAnimation}
`

export const LeftInfoContainer = styled.div`
  width: 100%;
  height: 40vh;
`

export const ViewerContainer = styled.div<Props>`
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-color: ${(props) => props.theme.white};

  model-viewer {
    width: 100%;
    height: 40vh;
  }

  ${(props) => (props.isLoading ? '' : `border: 1px solid ${props.theme.grey2};`)};
  ${(props) => props.isLoading && skeletonAnimation}
`

interface ViewerButtonProps {
  active: boolean
}

export const ARViewerButton = styled.span<ViewerButtonProps>`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: absolute;
  cursor: pointer;
  margin-bottom: 2%;
  margin-left: 85%;
  width: 12%;
  height: 12%;

  img {
    filter: ${(props) =>
      props.active
        ? 'invert(40%) sepia(61%) saturate(2088%) hue-rotate(170deg) brightness(95%) contrast(101%)'
        : null};
  }

  @media screen and (max-width: 1400px) {
    margin-bottom: 4%;
    margin-left: 80%;
    width: 15%;
    height: 15%;
  }

  @media screen and (min-width: 2000px) {
    margin-bottom: 1%;
  }

  :hover {
    // props.theme.blue
    filter: ${(props) =>
      props.active
        ? 'brightness(0%)'
        : 'invert(40%) sepia(61%) saturate(2088%) hue-rotate(170deg) brightness(95%) contrast(101%)'};
  }
`

export const Name = styled.div<Props>`
  font-style: normal;
  font-weight: 500;
  margin-top: 2%;
  @media screen and (min-width: 1024px) {
    font-size: 22px;
    line-height: 24px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 24px;
    line-height: 28px;
  }

  border-radius: 8px;
  width: ${(props) => (props.isLoading ? '274px' : null)};
  height: ${(props) => (props.isLoading ? '26px' : null)};

  ${(props) => props.isLoading && skeletonAnimation}
`

interface MessageBlockProps {
  displayMessageBlock: boolean
}

export const NoLinkMessageBlock = styled.div<MessageBlockProps>`
  z-index: 2;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.dark};
  width: max-content;
  max-width: 23vw;
  margin-left: 8px;
  margin-top: 23px;
  display: ${(props) => (props.displayMessageBlock ? 'inline' : 'none')};
  background-color: white;
  position: absolute;
  text-align: center;

  p {
    margin-left: 5%;
    margin-right: 5%;
  }
`

export const Sku = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.blue};
  margin-top: 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  padding: 4px 12px;
  color: ${(props) => props.theme.white};
  width: ${(props) => (props.isLoading ? '103px' : null)};
  height: ${(props) => (props.isLoading ? '13px' : null)};
  ${(props) => props.isLoading && skeletonAnimation}
  @media screen and (min-width: 1024px) {
    width: max-content;
    min-width: 30%;
    max-width: 50%;
  }
  @media screen and (min-width: 1441px) {
    width: max-content;
    min-width: 20%;
    max-width: 50%;
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const UnstylledButton = styled.button`
  background-color: unset;
  border: unset;

  img {
    // props.theme.grey8
    filter: invert(78%) sepia(5%) saturate(0%) hue-rotate(189deg) brightness(91%) contrast(83%);
  }
`

export const DimensionsContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 50px;
  margin-top: 8%;

  width: ${(props) => (props.isLoading ? '100%' : null)};
  height: ${(props) => (props.isLoading ? '34px' : null)};
  ${(props) => props.isLoading && skeletonAnimation}
`

export const Dimension = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  background-color: ${(props) => props.theme.white};
  width: 92px;
  height: 79px;

  img {
    height: 40%;
    width: 100px;
  }

  .tooltip {
    margin-top: 10%;
    padding: 10px;
    color: ${(props) => props.theme.blue};
  }
`

export const DetailText = styled.div`
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  height: 40%;
`

export const Availability = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
`

export const ButtonsContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: ${(props) => (props.isLoading ? '100%' : null)};
  height: ${(props) => (props.isLoading ? '24px' : null)};
  margin-top: ${(props) => (props.isLoading ? '2%' : null)};
  ${(props) => props.isLoading && skeletonAnimation}
`

export const Activate = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: ${(props) => props.theme.dark};
    padding-left: 8px;
  }
  border-radius: 8px;
  width: ${(props) => (props.isLoading ? '134px' : null)};
  height: ${(props) => (props.isLoading ? '24px' : null)};

  ${(props) => props.isLoading && skeletonAnimation}
`

export const ActionButton = styled.a<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: ${(props) => props.theme.dark};
  }
  img {
    height: 25px;
    padding-right: 8px;
  }
  :hover {
    // reset the color of h3 for the filter to work
    span {
      color: #000;
    }

    // #0098D7
    filter: invert(48%) sepia(42%) saturate(4190%) hue-rotate(168deg) brightness(92%) contrast(102%);
  }
  border-radius: 8px;
  width: ${(props) => (props.isLoading ? '94px' : null)};
  height: ${(props) => (props.isLoading ? '24px' : null)};

  ${(props) => props.isLoading && skeletonAnimation}
`

export const DeleteButton = styled.a`
  display: inline-block;
  align-items: baseline;
  justify-content: center;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: ${(props) => props.theme.dark};
  }
  img {
    align-self: center;
    height: 25px;
    padding-right: 8px;
    vertical-align: middle;
  }
  border-radius: 8px;
  :hover {
    // reset the color of h3 for the filter to work
    span {
      color: #000;
    }

    // #AD2F1F
    filter: invert(19%) sepia(82%) saturate(2110%) hue-rotate(348deg) brightness(96%) contrast(91%);
  }
`

export const QRCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: ${(props) => props.theme.lightBlue2};
  width: 50%;
  height: 50%;
  border-color: ${(props) => props.theme.grey2};
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;

  @media screen and (min-width: 1024px) {
    & > span {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    & > svg {
      height: 60%;
    }
  }

  @media screen and (min-width: 1441px) {
    & > span {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
    & > svg {
      height: 70%;
    }
  }
`

export const HeaderBar = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 1%;
  width: ${(props) => (props.isLoading ? '235px' : '100%')};
  height: ${(props) => (props.isLoading ? '13px' : null)};
  ${(props) => props.isLoading && skeletonAnimation}
`

export const HeaderStatusAndUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b9b9b9;
    padding-left: 20px;
  }
  width: 50%;
`

export const HeaderActionButtons = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
  gap: 20px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: ${(props) => props.theme.dark};
  }
`

export const UploadAreaContainer = styled.div<Props>`
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23061621FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='29' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: ${(props) => props.theme.grey5};
  ${(props) => props.isLoading && skeletonAnimation}

  & .back-image {
    flex: 1 1 0;
    max-height: 80%;
    max-width: 80%;
    object-fit: scale-down;
    object-position: center;
  }
`

export const UploadText = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-style: normal;
  line-height: 26px;
  color: ${(props) => props.theme.blue};
  position: relative;

  &.firstLine {
    font-weight: 500;
    font-size: 18px;
  }
  &.secondLine {
    font-weight: 400;
    font-size: 16px;
  }

  @media screen and (min-width: 1024px) {
    &.firstLine {
      font-weight: 500;
      font-size: 14px;
    }

    &.secondLine {
      font-weight: 400;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1441px) {
    &.firstLine {
      font-weight: 500;
      font-size: 18px;
    }

    &.secondLine {
      font-weight: 400;
      font-size: 16px;
    }
  }
`

export const UploadImgArrow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  position: absolute;

  @media screen and (min-width: 1024px) {
    margin-top: 30%;
    img {
      width: 30%;
      height: 30%;
    }
  }

  @media screen and (max-width: 1400px) {
    margin-top: 22%;
    img {
      width: 30%;
      height: 30%;
    }
  }

  @media screen and (min-width: 1441px) {
    margin-top: 18%;
    img {
      width: 30%;
      height: 30%;
    }
  }
`

export const TableDiv = styled.div`
  margin-top: 28px;
  border: 1px solid ${(props) => props.theme.grey9};
  border-radius: 8px;
  background-color: ${(props) => props.theme.white};
`
export const Table = styled.table<Props>`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th {
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.grey9};
    padding: 10px 16px;
    border-radius: 8px;

    &.title {
      width: 60px;
      text-align: right;
    }

    & > span {
      color: ${(props) => props.theme.grey10};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }

  tr:last-child th {
    border-bottom: 0;
  }

  td {
    text-align: left;
  }

  th,
  td {
    background-color: ${(props) => props.theme.white};
  }
  ${(props) => props.isLoading && skeletonAnimation}
`

export const PdpRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

interface PdpUrlProps {
  hasPdp: string | null
}

export const PdpUrl = styled.span<PdpUrlProps>`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.hasPdp ? props.theme.blue : props.theme.grey8)};
  a:visited {
    color: ${(props) => props.theme.blue};
  }
  @media screen and (min-width: 1024px) {
    max-width: 370px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 300px;
  }
  @media screen and (min-width: 1441px) {
    max-width: 500px;
  }
`

export const TableValue = styled.p`
  text-align: start;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: ${(props) => props.theme.dark2};
  margin: 0;
`

export const Placeholder = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  text-align: start;
  color: ${(props) => props.theme.grey8};
  margin: 0;
`

export const PdpLink = styled.span`
  align-self: flex-end;

  img {
    height: 12px;
  }
`

interface ButtonProps {
  disabled?: boolean
}

export const ButtonLink = styled.a<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};
  border-radius: 8px;
  height: 46px;
  margin-top: 10px;
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    padding: 5px;
    color: ${(props) => (props.disabled ? props.theme.grey2 : props.theme.dark)};
  }
  img {
    height: 24px;
    width: 24px;
    padding-right: 7px;
    filter: ${(props) =>
      props.disabled
        ? // props.theme.grey2
          'invert(86%) sepia(6%) saturate(30%) hue-rotate(316deg) brightness(82%) contrast(87%);'
        : null};
  }
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};
  border: 0;
  background-color: transparent;
  border-radius: 8px;
  height: 46px;
  margin-top: 10px;
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    padding: 5px;
    color: ${(props) => (props.disabled ? props.theme.grey2 : props.theme.dark)};
  }
  img {
    height: 25px;
    padding-right: 7px;
    filter: ${(props) =>
      props.disabled
        ? // props.theme.grey2
          'invert(94%) sepia(0%) saturate(2612%) hue-rotate(227deg) brightness(80%) contrast(106%)'
        : null};
  }
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const DownloadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.35);

  .inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 30%;
    height: 60%;
    background-color: ${(props) => props.theme.grey7};
    position: absolute;
    top: 50%;
    left: 50%;
    border-color: ${(props) => props.theme.grey4};
    border-style: solid;
    border-width: 2px;
    border-radius: 8px;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 1400px) {
    .inside {
      height: 70%;
    }
  }
`

export const DownloadHeader = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  align-self: flex-start;
  margin: 3%;
`

export const CheckAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  align-self: flex-end;
  margin-top: 2%;
  margin-right: 8%;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    align-self: flex-end;
  }
`

export const Checkbox = styled.span`
  width: 24px;
  height: 24px;
  align-self: flex-end;

  img {
    width: 24px;
    height: 24px;
  }
`

export const FileTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 25%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey3};
  justify-content: space-start;
  gap: 10px;

  .icon {
    filter: invert(15%) sepia(9%) saturate(5661%) hue-rotate(167deg) brightness(97%) contrast(90%);
    align-self: center;
    width: 60px;
    height: 60px;
  }

  .checkbox {
    align-self: flex-end;
    margin-bottom: 2%;
    margin-right: 3%;
  }
`

export const FileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 5px;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    white-space: pre-wrap;
  }
`

export const DownloadButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 100%;

  button {
    margin: auto 10px 5%;
  }
`

export const OpenUploadButton = styled.span`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  position: absolute;
  z-index: 1;
  margin-top: 0.5%;
  margin-left: 0.5%;
  width: 47px;
  height: 47px;
  padding: 2px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey7};

  @media screen and (max-width: 1400px) {
    margin-top: 0.5%;
    margin-left: 0.5%;
    width: 39px;
    height: 39px;
  }

  @media screen and (min-width: 2000px) {
    margin-bottom: 1%;
  }

  img {
    width: 24px;
    height: 24px;
    align-self: center;
  }

  :hover {
    background-color: ${(props) => props.theme.lightBlue2};
    border-radius: 8px;
  }
`
