import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import AddIcon from '@console/common/assets/icons/add.svg'
import Button from '@console/common/components/Button'
import LoadingIcon from '@console/common/components/LoadingIcon'
import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  AssetStatus,
  Version,
  UserRole,
  AssetType,
  getAssetSize
} from '@console/common/services/api'

import { ProductContext } from '../../contexts/ProductContext'
import { ReviewContext, ReviewStatus } from '../../contexts/ReviewContext'
import { VersionContext } from '../../contexts/VersionContext'
import CommentSection from '../CommentSection'
import CountdownButton from '../CountdownButton'
import ReviewButton from '../ReviewButton'
import {
  Container,
  Separator,
  AddButton,
  VersionButton,
  VersionContainer,
  ApproveButtonsContainer
} from './styles'

const CommentsBar: React.FC = () => {
  const { t } = useTranslation(['components/comments-bar'])
  const { reviewStatus, confirmReviewUpdate, loading } = useContext(ReviewContext)
  const { product, pendingReview } = useContext(ProductContext)
  const { versionIndex, glb, setVersionIndex, setShowUpload } = useContext(VersionContext)
  const [versions, setVersions] = useState<Version[]>([])
  const [usdzSize, setUsdzSize] = useState<string | null>(null)

  const { session, assumedCustomer } = useContext(AuthContext)

  const role =
    session?.role === UserRole.ARTIST ||
    session?.role === UserRole.FREELANCER ||
    ((session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) && !assumedCustomer)
      ? UserRole.ARTIST
      : UserRole.CUSTOMER

  useEffect(() => {
    setVersions(product?.Versions || [])
  }, [product])

  useEffect(() => {
    const usdz = versions[versionIndex]?.Assets?.find((asset) => asset.type === AssetType.USDZ)
    if (usdz) {
      getAssetSize(usdz).then((size) => {
        setUsdzSize(size)
      })
    } else {
      setUsdzSize(null)
    }
  }, [versions, versionIndex])

  const getOrdinal = (n: number) => {
    const lastDigit = n % 10
    if (lastDigit === 1 && n !== 11) return 'first'
    if (lastDigit === 2 && n !== 12) return 'second'
    if (lastDigit === 3 && n !== 13) return 'third'
    return 'other'
  }

  return (
    <Container>
      <p>{t('VERSION_CONTROL')} </p>
      {versions.length ? (
        <VersionContainer>
          {[UserRole.ADMIN, UserRole.ARTIST, UserRole.FREELANCER].includes(role) ? (
            <AddButton alternative onClick={() => setShowUpload(true)}>
              <img src={AddIcon} alt='ADD' />
            </AddButton>
          ) : null}
          {versions.map((version, index) => {
            const versionNumber = versions.length - index
            const ordinal = getOrdinal(versionNumber)
            return (
              <VersionButton
                alternative={index !== versionIndex}
                onClick={() => {
                  setVersionIndex(index)
                  setShowUpload(false)
                }}
                key={version.id}
              >
                {versionNumber}
                <sup>{t(`VERSIONS.${ordinal}`)}</sup>
              </VersionButton>
            )
          })}
        </VersionContainer>
      ) : null}
      {usdzSize}
      <Separator />
      {loading ? (
        <LoadingIcon />
      ) : (
        <>
          {session && session?.role !== UserRole.FREELANCER && pendingReview && !reviewStatus ? (
            <ApproveButtonsContainer>
              <ReviewButton mode='approve' />
              <ReviewButton mode='reject' />
            </ApproveButtonsContainer>
          ) : product?.status === AssetStatus.WORK_IN_PROGRESS && glb ? (
            <Button onClick={confirmReviewUpdate}>{t('moveUnderReview')}</Button>
          ) : null}
          {reviewStatus === ReviewStatus.APPROVED ? <CountdownButton /> : null}
        </>
      )}
      <CommentSection />
    </Container>
  )
}

export default CommentsBar
