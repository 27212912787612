import React, { useEffect, useContext } from 'react'

import { Line } from 'rc-progress'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import DoneIcon from '@console/common/assets/done.svg'
import Loading from '@console/common/assets/loadingMultipleProducts.gif'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { ToastContext } from '@console/common/contexts/ToastContext'
import theme from '@console/common/theme.json'

import { MultipleContext } from '../../../contexts/MultipleContext'
import {
  MainContainer,
  GifContainer,
  InfoContainer,
  InfoTitle,
  PercentageContainer,
  Percentage,
  PercentageText,
  TextContainer,
  LoadingContainer,
  LineContainer
} from './styles'

const Confirmation: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const history = useHistory()

  const { registering, confirmRegistration, products, percentage } = useContext(MultipleContext)
  const { openToast } = useContext(ToastContext)
  const { session, assumedCustomer } = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    if (products) {
      confirmRegistration()
      analytics?.track(AnalyticsEvent.REGISTRATION_MULTI_COMPLETE)
    }
  }, [])

  useEffect(() => {
    if (!registering && percentage >= 100) {
      openToast({
        color: 'red4',
        text: `${t('multipleRegistration.toastText', { length: products.length })}`,
        filter:
          'invert(52%) sepia(95%) saturate(373%) hue-rotate(327deg) brightness(99%) contrast(96%)',
        icon: DoneIcon
      })
      history.push('/portfolio')
    }
  }, [registering])

  return (
    <MainContainer>
      <GifContainer>
        <img src={Loading} alt='loading' />
      </GifContainer>
      <InfoContainer>
        <TextContainer>
          <InfoTitle>{assumedCustomer?.name ?? session?.customer.name}</InfoTitle>
          <InfoTitle>
            {products.length}
            {t('multipleRegistration.products')}
          </InfoTitle>
        </TextContainer>
        <LoadingContainer>
          <PercentageContainer>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <Percentage>{`${percentage.toFixed(0)}%`}</Percentage>
            <PercentageText>{t('multipleRegistration.registerProgress')}</PercentageText>
          </PercentageContainer>
          <LineContainer>
            <Line
              percent={percentage}
              strokeWidth={4}
              strokeColor={theme.blue}
              trailColor={theme.grey9}
              trailWidth={4}
              strokeLinecap='square'
            />
          </LineContainer>
        </LoadingContainer>
      </InfoContainer>
    </MainContainer>
  )
}

export default Confirmation
