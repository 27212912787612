import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { AuthContext } from '@console/common/contexts/AuthContext'

import { isBetaUser } from '../../routes/isBeta'
import { TextBubble, Icon, NotificationBubble, PlainButton, StyledNavLink } from './styles'

interface Props {
  to: string
  src: string
  expanded: boolean
  text: string
  notifications?: number | null
  isBeta?: boolean
  isNew?: boolean
}

const SideBarLink: React.FC<Props> = ({
  expanded,
  src,
  to,
  text,
  notifications,
  isBeta = false,
  isNew = false
}: Props) => {
  const { t } = useTranslation(['components/sidebar'])
  const { session } = useContext(AuthContext)

  if (isBeta && !isBetaUser(session)) return null

  return (
    <StyledNavLink to={to} activeClassName='active'>
      <PlainButton expanded={expanded}>
        <Icon expanded={expanded} src={src} /> <span> {text} </span>
        {notifications ? (
          <NotificationBubble expanded={expanded}> {notifications} </NotificationBubble>
        ) : null}
        {isBeta || isNew ? (
          <TextBubble expanded={expanded}>{t(isBeta ? 'beta' : 'new')}</TextBubble>
        ) : null}
      </PlainButton>
    </StyledNavLink>
  )
}

export default SideBarLink
