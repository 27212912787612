import React from 'react'

import Collapsible from './Collapsible'
import { Menu } from './styles'
import { FlatSlotsMaterials, SlotsModels, ViewerRef } from './types'

interface Props {
  slotsMaterials: FlatSlotsMaterials
  slotsModels: SlotsModels
  viewerRef: ViewerRef
}

const SlotMaterialMenu: React.FC<Props> = ({ slotsMaterials, slotsModels, viewerRef }: Props) => (
  <>
    {slotsMaterials
      .filter((sMat) => sMat.id === slotsModels?.id)
      .map((sMat) => (
        <Collapsible key={sMat.id.concat(sMat.name)} title={sMat.name}>
          <Menu>
            {sMat.materials.map((mat) => (
              <React.Fragment key={sMat.id.concat(sMat.name.concat(mat))}>
                <input
                  id={sMat.id.concat(sMat.name.concat(mat))}
                  type='radio'
                  name={sMat.name}
                  value={mat}
                  onChange={(event) => {
                    viewerRef.current?.setMaterial({
                      modelId: sMat.id,
                      slot: sMat.name,
                      materialName: event.target.value
                    })
                  }}
                />
                <label htmlFor={sMat.id.concat(sMat.name.concat(mat))} title={mat}>
                  {mat.length > 8 ? mat.slice(0, 7).concat('...') : mat}
                </label>
              </React.Fragment>
            ))}
          </Menu>
        </Collapsible>
      ))}
  </>
)

export default SlotMaterialMenu
