import { getResizedImage, getUploadUrl } from './api'

export const uploadToUrl = async (
  file: File,
  uploadUrl: string,
  onEnd?: (uploadUrl: string) => void,
  handlePercentage?: (value: number) => void
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    let extension = file.name.split('.').pop()
    if (extension) extension = `.${extension}`

    const xhr = new XMLHttpRequest()

    if (handlePercentage) {
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          handlePercentage((e.loaded / e.total) * 100)
        }
      }
    }

    xhr.onload = () => {
      if (onEnd) {
        onEnd(uploadUrl.split('?')[0])
      }
      resolve()
    }

    xhr.open('PUT', uploadUrl, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.send(file)
  })

export const upload = async (
  file: File,
  path: string,
  onEnd: (uploadUrl: string) => void,
  handlePercentage?: (value: number) => void,
  generateThumb = false
): Promise<void> => {
  let extension = file.name.split('.').pop()
  if (extension) extension = `.${extension}`

  const uploadUrl = await getUploadUrl(extension, path)
  const fileUUID = uploadUrl?.split('?')[0].split('/').pop()
  if (!uploadUrl) return

  let resultUrl = ''

  const uploadPromise = uploadToUrl(
    file,
    uploadUrl,
    (url) => {
      resultUrl = url
    },
    handlePercentage
  )

  if (generateThumb && isImage(file)) {
    const thumbnail = await getResizedImage(file, 150)
    const thumbnailUploadUrl = await getUploadUrl(
      extension,
      `${path}/thumbs`,
      fileUUID?.split('.')[0]
    )
    if (thumbnailUploadUrl) {
      await uploadToUrl(thumbnail, thumbnailUploadUrl)
    }
  }
  await uploadPromise
  onEnd(resultUrl)
}

const isImage = (file: File): boolean => {
  const imageExtensions: { [key: string]: boolean } = {
    jpg: true,
    png: true,
    jpeg: true
  }

  const extension = file.name.split('.').pop()

  if (extension) return !!imageExtensions[extension]
  return false
}
