import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@console/common/components/Button'

import { RegistrationSteps, ProcessContext } from '../../contexts/ProcessContext'
import { ButtonContainer } from './styles'

interface Props {
  nextPage?: RegistrationSteps
  confirm?: boolean
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}

const NextButton: React.FC<Props> = ({ nextPage, confirm, onClick, disabled, loading }: Props) => {
  const { t } = useTranslation(['registration'])
  const { setCurrentStep, confirmRegistration } = useContext(ProcessContext)

  return (
    <ButtonContainer>
      {nextPage ? (
        <Button
          disabled={disabled}
          onClick={() => {
            if (onClick) onClick()
            localStorage.setItem('registrationStep', nextPage)
            setCurrentStep(nextPage)
          }}
        >
          {t('next')}
        </Button>
      ) : confirm ? (
        <Button
          disabled={disabled}
          onClick={() => {
            if (onClick) onClick()
            confirmRegistration()
          }}
        >
          {t('confirm')}
        </Button>
      ) : null}
    </ButtonContainer>
  )
}

export default NextButton
