import styled from 'styled-components'

import Status from '@console/common/types/Status'

interface Props {
  status: Status
}

export const Container = styled.div`
  display: flex;
  height: 25px;
`

/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
export const StyledStatusBadge = styled.span<Props>`
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 16px;

  color: ${(props) =>
    props.status === Status.PENDING_REVIEW ? props.theme.red4 : props.theme.white};
  border: ${(props) =>
    props.status === Status.PENDING_REVIEW ? `solid 2px ${props.theme.red4}` : '2px'};
  background-color: ${(props) => {
    switch (props.status) {
      case Status.RUNNING:
        return props.theme.green
      case Status.PAUSED:
        return props.theme.grey1
      case Status.REJECTED:
        return props.theme.red
      case Status.PENDING_REVIEW:
        return props.theme.white
      case Status.MISSING_INFORMATION:
        return props.theme.orange
      case Status.READY_TO_START:
        return props.theme.purple
      case Status.IN_PROGRESS:
        return props.theme.aqua
      default:
        return props.theme.white
    }
  }};
`
/* eslint-enable implicit-arrow-linebreak */
/* eslint-enable no-confusing-arrow */
