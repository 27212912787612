import React from 'react'

import Color from '@console/common/types/Color'

import Loading from '../Loading'
import { LoadingContainer } from './style'

interface Props {
  saving: boolean
  text: string
  loadingColor?: Color
}

const Saving: React.FC<Props> = ({ saving, text, loadingColor }: Props) => (
  <LoadingContainer saving={saving}>
    <div className='text'>{text}</div>
    <div className='loading'>
      <Loading color={loadingColor} />
    </div>
  </LoadingContainer>
)

export default Saving
