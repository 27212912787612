import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
`

export const Image = styled.img`
  width: auto;
  height: auto;
`

export const CardText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.grey6};
  &.firstLine {
    margin-top: -96px;
  }
  &.secondLine {
    margin-top: -80px;
    margin-left: 8px;
  }
`

export const ImageText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.grey6};
  &.firstLine {
    margin-top: -243px;
  }
  &.secondLine {
    margin-top: -215px;
    margin-left: 18px;
  }
`

export const ModelText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.grey6};
  &.firstLine {
    margin-top: -210px;
    margin-left: 62px;
  }
  &.secondLine {
    margin-top: -186px;
    margin-left: 80px;
  }
`
