import React from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { FiltersProvider } from '../../contexts/FiltersContext'
import BigQueryAnalytics from './BigQueryAnalytics'
import GA4Analytics from './GA4Analytics'

const General: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <FiltersProvider>
      <Switch>
        <Route exact path={path}>
          <BigQueryAnalytics />
        </Route>
        <Route path={`${path}/general`}>
          <BigQueryAnalytics />
        </Route>
        <Route path={`${path}/purchase`}>
          <GA4Analytics />
        </Route>
      </Switch>
    </FiltersProvider>
  )
}

export default General
