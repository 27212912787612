import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

interface Props {
  width: string | undefined
  isLoading: boolean
}

export const Input = styled.input<Props>`
  width: ${(props) => (props.width ? props.width : '261px')};
  height: 39px;
  border: ${(props) => (props.isLoading ? 0 : `1px solid ${props.theme.grey2}`)};
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.isLoading ? skeletonAnimation : props.theme.grey2)};
  padding-left: 14px;
  background-color: white;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) => props.theme.dark};
    transition: background-color 5000s ease-in-out 0s;
  }
  :focus {
    outline: none;
    background-color: white;
  }
  :valid {
    border: ${(props) => (props.isLoading ? 0 : `1px solid ${props.theme.grey2}`)};
    color: ${(props) => props.theme.dark};
  }
  :invalid {
    border: ${(props) => (props.isLoading ? 0 : `1px solid ${props.theme.red}`)};
    color: ${(props) => props.theme.red};
  }
  ${(props) => props.isLoading && skeletonAnimation}
`
