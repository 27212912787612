import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  TableContainer,
  TableHeader,
  HeaderContainer,
  TableRow,
  RowContainer,
  FullInput,
  StaticContent,
  TableBody
} from './styles'

interface ProductData {
  id: string
  name: string
  sku: string
  pdpUrl: string
  ean: string
}

interface Props {
  products: ProductData[]
  setProducts: (products: ProductData[]) => void
  loading: boolean
}

const Table: React.FC<Props> = ({ products, setProducts, loading }: Props) => {
  const { t } = useTranslation(['portfolio'])

  const headers = [
    {
      title: t('forwardProduct.name')
    },
    {
      title: t('forwardProduct.sku')
    },
    {
      title: t('forwardProduct.pdp')
    },
    {
      title: t('forwardProduct.ean')
    }
  ]

  const handleOnChange = (value: string, column: keyof ProductData, id: string) => {
    const index = products.findIndex((l) => l.id === id)
    const newRow = [...products]
    newRow[index][column] = value
    setProducts(newRow)
  }

  return (
    <>
      <TableContainer isLoading={loading}>
        <HeaderContainer isLoading={loading}>
          {headers.map((h) => (
            <TableHeader key={h.title} isLoading={loading}>
              {h.title}
            </TableHeader>
          ))}
        </HeaderContainer>
        <TableBody>
          {products.map((lp) => (
            <React.Fragment key={lp.id}>
              <RowContainer>
                <TableRow>
                  <FullInput
                    name='name'
                    value={lp?.name}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'name', lp.id)}
                  />
                </TableRow>
                <TableRow>
                  <FullInput
                    name='sku'
                    value={lp?.sku}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'sku', lp.id)}
                  />
                </TableRow>
                <TableRow>
                  <FullInput
                    name='pdpUrl'
                    value={lp?.pdpUrl}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'pdpUrl', lp.id)}
                  />
                </TableRow>
                <TableRow>
                  <StaticContent>{lp?.ean}</StaticContent>
                </TableRow>
              </RowContainer>
            </React.Fragment>
          ))}
        </TableBody>
      </TableContainer>
    </>
  )
}

export default Table
