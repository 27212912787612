import React from 'react'

import ArrowLeftIcon from '@console/common/assets/arrowLeftFill.svg'
import ArrowRightIcon from '@console/common/assets/arrowRightFill.svg'
import CloseIcon from '@console/common/assets/roundClose.svg'
import WarningIcon from '@console/common/assets/warning.svg'

import {
  MainContainer,
  Row,
  ErrorTypeText,
  ErrorDetailContainer,
  ArrowButton,
  TextHelper,
  SkipButton
} from './styles'

type ErrorType = 'error' | 'warning'

interface Error {
  column?: string
  id?: string
  description: string
}

interface Props {
  isError: boolean
  errors: Error[]
  warning: Error[]
  type?: string
  previousError: (type: ErrorType) => void
  nextError: (type: ErrorType) => void
  errorIndex: number
  warningIndex: number
  helperText?: string
  skipText?: string
  handleSkipWarning?: () => void
}

const ValidationBanner: React.FC<Props> = ({
  isError,
  errors,
  warning,
  type,
  previousError,
  nextError,
  errorIndex,
  warningIndex,
  helperText,
  skipText,
  handleSkipWarning
}: Props) => (
  <MainContainer error={isError}>
    <Row>
      <img src={errors.length ? CloseIcon : WarningIcon} alt='' />
      <ErrorTypeText>
        {errors.length ? (
          <>
            {errors.length}
            {type}
          </>
        ) : (
          <>
            {warning.length}
            {type}
          </>
        )}
      </ErrorTypeText>
      <ErrorDetailContainer>
        <ArrowButton
          onClick={() => previousError(errors.length ? 'error' : 'warning')}
          error={!!errors.length ?? !!warning.length}
        >
          <img src={ArrowLeftIcon} alt='' />
        </ArrowButton>
        <span>
          {errors.length ? errors[errorIndex].description : warning[warningIndex].description}
        </span>
        <ArrowButton
          onClick={() => nextError(errors.length ? 'error' : 'warning')}
          error={!!errors.length ?? !!warning.length}
        >
          <img src={ArrowRightIcon} alt='' />
        </ArrowButton>
      </ErrorDetailContainer>
    </Row>
    <Row>
      <TextHelper>{helperText}</TextHelper>
    </Row>
    {warning.length > 0 && !errors.length && handleSkipWarning ? (
      <Row>
        <SkipButton onClick={() => handleSkipWarning()}>
          <span>{skipText}</span>
        </SkipButton>
      </Row>
    ) : null}
  </MainContainer>
)

export default ValidationBanner
