import styled, { keyframes } from 'styled-components'

const RotateAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`

export const LoadingAnimation = styled.div`
  width: 20px;
  height: 20px;
  animation: ${RotateAnimation} infinite 1s linear;
`
