import React from 'react'

import { Switch, Slider, Input } from './styles'

interface Props {
  value: boolean
  onChange: () => void
}

const Toggle: React.FC<Props> = ({ value, onChange }: Props) => (
  <form>
    <Switch className='switch' htmlFor='temp-id'>
      <Input type='checkbox' id='temp-id' checked={value || false} onChange={onChange} />
      <Slider className='slider round' />
    </Switch>
  </form>
)

export default Toggle
