import React, { useContext, useEffect } from 'react'

import { Redirect } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'

const Logout: React.FC = () => {
  const { analytics } = useContext(AnalyticsContext)
  const { destroySession } = useContext(AuthContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.LOGOUT)
  }, [analytics])

  destroySession()
  return <Redirect to='/portfolio' />
}
export default Logout
