import styled from 'styled-components'

export const Total = styled.span`
  justify-self: end;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.5px;
  text-align: right;
  color: ${(props) => props.theme.dark};
  margin-top: 35px;
  margin-left: auto;
`
