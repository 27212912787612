import React from 'react'

import { Icon } from './styles'

const LoadingIcon: React.FC = () => (
  <Icon>
    <div className='lds-spinner'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </Icon>
)

export default LoadingIcon
