/* eslint-disable react/jsx-no-literals */
import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react'

import { nanoid } from 'nanoid/non-secure'
import { Line } from 'rc-progress'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  createHanaAsset,
  getModelPresignedWithoutId,
  confirmModelUpload
} from '@console/common/services/api'
import { upload, uploadToUrl } from '@console/common/services/upload'
import theme from '@console/common/theme.json'

import { MultipleContext, Images } from '../../../contexts/MultipleContext'
import {
  UploadAreaContainer,
  UploadText,
  LoadingContainer,
  PercentageSecondsContainer
} from './styles'

interface Props {
  productId: string
  forceUpdate: () => void
}

const UploadArea: React.FC<Props> = ({ productId, forceUpdate }: Props) => {
  const [uploadingImage, setUploadingImage] = useState(false)
  const { products, setImageToProduct, setModelToProduct } = useContext(MultipleContext)
  const [percentage, setPercentage] = useState(0)
  const [counter, setCounter] = useState<NodeJS.Timer>()
  const [seconds, setSeconds] = useState(1000)
  const { analytics } = useContext(AnalyticsContext)
  const { session, assumedCustomer } = useContext(AuthContext)

  const { t } = useTranslation(['registration'])

  const handlePercentage = () => {
    const interval = setInterval(() => {
      setPercentage((p) => p + 1)
      setSeconds((sec) => sec - 10)
    }, 10)
    setCounter(interval)
  }

  useEffect(() => {
    if (percentage === 100 && counter) {
      clearInterval(counter)
      setCounter(undefined)
      setUploadingImage(false)
      setPercentage(0)
      setSeconds(1000)
      forceUpdate()
    }
  }, [percentage, counter])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploadingImage(true)

      const product = products.find((filterProduct) => filterProduct.id === productId)
      if (!product) return

      const customerId = assumedCustomer?.id ?? session?.customer.id
      let hanaAssetId: string | null | undefined
      if (acceptedFiles.filter((file: File) => !file.type.match('image.*'))) {
        hanaAssetId = product.hanaId
          ? product.hanaId
          : await createHanaAsset(product.name || product.sku || productId)
      }

      const newImages: Images[] = (product.imagesUrls || []).map((u) => ({ productId, url: u }))
      const newModels = [...(product?.models || [])]

      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          if (file.type.match('image.*')) {
            await upload(
              file,
              'registration',
              async (uploadUrl: string) => {
                const id = nanoid()
                setImageToProduct(productId, newImages.concat([{ productId, id, url: uploadUrl }]))
                newImages.push({ productId, id, url: uploadUrl })
              },
              undefined,
              true
            )
          } else {
            if (!customerId || !hanaAssetId) return
            const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
            const uploadRequest = await getModelPresignedWithoutId(
              hanaAssetId,
              customerId,
              product.sku || '',
              extension
            )
            if (!uploadRequest) return
            await uploadToUrl(file, uploadRequest.uploadUrl, async () => {
              if (!hanaAssetId) return
              await confirmModelUpload(hanaAssetId, uploadRequest.viewId)
              setModelToProduct(
                productId,
                newModels.concat([
                  {
                    productId,
                    id: uploadRequest.viewId,
                    url: uploadRequest.outputUrl,
                    type: extension,
                    hanaId: hanaAssetId
                  }
                ]),
                hanaAssetId
              )
              newModels.push({
                id: uploadRequest.viewId,
                url: uploadRequest.outputUrl,
                type: extension,
                hanaId: hanaAssetId
              })
            })
          }
        })
      )
      analytics?.track(AnalyticsEvent.REGISTRATION_UPLOAD_IMAGE)
      handlePercentage()
    },
    [products]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: undefined,
    noClick: uploadingImage,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <UploadAreaContainer {...getRootProps({ style })}>
      {percentage < 100 && uploadingImage ? (
        <LoadingContainer>
          <PercentageSecondsContainer>
            <span>{`${percentage}%`}</span>
            <span>{`${seconds / 1000} ${t('multipleRegistration.imageUpload.seconds')}`}</span>
          </PercentageSecondsContainer>
          <Line
            percent={percentage}
            strokeWidth={4}
            strokeColor={theme.blue}
            trailColor={theme.grey9}
            trailWidth={4}
          />
        </LoadingContainer>
      ) : (
        <>
          <input {...getInputProps()} />
          <UploadText>{t('multipleRegistration.imageUpload.uploadText')}</UploadText>
        </>
      )}
    </UploadAreaContainer>
  )
}

export default UploadArea
