import styled from 'styled-components'

interface LoadingProps {
  saving: boolean
}

export const LoadingContainer = styled.div<LoadingProps>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transform: translateY(${({ saving }) => (saving ? '-100%' : '0%')});
    transition: 0.2s;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translateY(${({ saving }) => (saving ? '0%' : '100%')});
    transition: 0.2s;
  }
`
