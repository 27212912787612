import React, { useState, useContext, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AssetStatus, Comment } from '@console/common/services/api'

import { CommentsContext } from '../../contexts/CommentsContext'
import { ProductContext } from '../../contexts/ProductContext'
import { ReviewContext, ReviewStatus } from '../../contexts/ReviewContext'
import { VersionContext } from '../../contexts/VersionContext'
import { createComment } from '../../services/api'
import InputComponent from './InputComponent'
import ShowComments from './ShowComments'
import {
  Container,
  ActionButtonContainer,
  CommentsHeader,
  ActionButton,
  ActionsButtonText
} from './styles'

const Comments: React.FC = () => {
  const { t } = useTranslation(['components/comments-bar'])
  const { analytics } = useContext(AnalyticsContext)
  const { reviewStatus, setReviewStatus, confirmReviewUpdate } = useContext(ReviewContext)
  const { setHasCommented } = useContext(CommentsContext)
  const { assets, assetIndex } = useContext(VersionContext)
  const { pendingReview } = useContext(ProductContext)
  const [comments, setComments] = useState<Comment[]>([])
  const [tempComments, setTempComments] = useState<Comment[]>([])
  const [requestReview, setRequestReview] = useState(false)
  const [status, setStauts] = useState<AssetStatus>()

  const [loading, setLoading] = useState(false)

  const mounted = useRef(true)

  window.onbeforeunload = () => {
    if (tempComments.length) return 'Are you sure'
    return undefined
  }

  useEffect(() => {
    if (assets[assetIndex]) setStauts(assets[assetIndex].status)
  }, [])

  useEffect(() => {
    const cleanup = () => {
      mounted.current = false
    }
    mounted.current = true
    if (!assets[assetIndex]) return cleanup

    assets[assetIndex].Comments.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
    if (mounted.current) setComments(assets[assetIndex].Comments)

    return cleanup
  }, [assets, assetIndex])

  useEffect(() => {
    setRequestReview(pendingReview && reviewStatus === ReviewStatus.REJECTED)
  }, [pendingReview, reviewStatus])

  const createCommentCallback = (content?: string, attachments?: string[]) => {
    if (!assets[assetIndex]) return
    analytics?.track(AnalyticsEvent.REVIEWER_COMMENT_CREATE, { assetId: assets[assetIndex].id })
    let data = []

    if (content || attachments) {
      data = [{ content, attachments }]
    } else {
      data = tempComments.map(({ createdAt, id, ...rest }) => rest)
    }

    setLoading(true)
    createComment(assets[assetIndex].id, data).then((c) => {
      if (mounted.current) {
        if (c) setComments([...comments, ...c])
        setHasCommented(true)
        setLoading(false)
        if (tempComments.length > 0) confirmReviewUpdate()
      }
    })
  }

  const cancelCommentCallback = () => {
    analytics?.track(AnalyticsEvent.REVIEWER_COMMENT_CANCEL, { assetId: assets[assetIndex].id })

    setReviewStatus(null)
  }

  const addTempComment = (content: string, attachments: string[]) => {
    const commentTest: Comment = {
      id: `${new Date()}${tempComments.length}`,
      content,
      attachments,
      createdAt: ''
    }
    setTempComments([...tempComments, commentTest])
  }

  const deleteTempComment = (id: string) => {
    analytics?.track(AnalyticsEvent.REVIEWER_COMMENT_DELETE, { assetId: assets[assetIndex].id })

    setTempComments(tempComments.filter((c) => c.id !== id))
  }

  const editTempComment = (id: string) => {
    const index = tempComments.findIndex((c) => c.id === id)

    return (content?: string, attachments?: string[]) => {
      analytics?.track(AnalyticsEvent.REVIEWER_COMMENT_UPDATE, { assetId: assets[assetIndex].id })

      tempComments[index].content = content || tempComments[index].content
      tempComments[index].attachments = attachments || tempComments[index].attachments
    }
  }

  if (!assets[assetIndex]) return null

  return (
    <>
      <Container loading={loading}>
        <CommentsHeader>
          <p> {t('COMMENTS')} </p>
        </CommentsHeader>
      </Container>
      {!requestReview && status === AssetStatus.CUSTOMER_REJECTED && !pendingReview ? (
        <>
          <InputComponent saveCallback={createCommentCallback} />
          <ShowComments
            comments={tempComments}
            deleteComment={deleteTempComment}
            editComment={editTempComment}
          />
        </>
      ) : null}
      {!requestReview ? <ShowComments comments={comments} /> : <div />}
      {requestReview ? (
        <>
          <ActionButtonContainer>
            <ActionButton action='cancel' type='button' onClick={() => cancelCommentCallback()}>
              <ActionsButtonText action='cancel'>{t('ACTION_BUTTONS.CANCEL')}</ActionsButtonText>
            </ActionButton>
            <ActionButton
              action='reject'
              disabled={!tempComments.length}
              onClick={() => createCommentCallback()}
            >
              <ActionsButtonText action='reject'>
                {t('ACTION_BUTTONS.CONFIRM_REJECT')}
              </ActionsButtonText>
            </ActionButton>
          </ActionButtonContainer>
          <InputComponent saveCallback={addTempComment} />
          <ShowComments
            comments={tempComments}
            deleteComment={deleteTempComment}
            editComment={editTempComment}
          />
        </>
      ) : (
        <div />
      )}
    </>
  )
}

export default Comments
