import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react'

import { nanoid } from 'nanoid/non-secure'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import CubeIcon from '@console/common/assets/cubeOutline.svg'
import CarouselCard from '@console/common/components/CarouselCard'
import MultiCarousel from '@console/common/components/MultiCarousel'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { getModelPresignedWithoutId, confirmModelUpload } from '@console/common/services/api'
import { uploadToUrl } from '@console/common/services/upload'
import theme from '@console/common/theme.json'

import NextButton from '../../../components/NextButton'
import { RegistrationSteps, Model, ProductData } from '../../../contexts/ProcessContext'
import { UploadContainer, CarouselContainer, UploadContainerEmpty, UploadButton } from './styles'

const ModelUpload: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [uploading, setUploading] = useState(false)
  const [models, setModels] = useState<Model[]>([])
  const [modelSlides, setModelSlides] = useState<React.ReactNode[]>([])

  const { session, assumedCustomer } = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  const carouselResponsive = {
    three: {
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
      slidesToSlide: 1
    },
    two: {
      breakpoint: { max: 1700, min: 500 },
      items: 2,
      slidesToSlide: 1
    }
  }

  useEffect(() => {
    const modelUploadItem = localStorage.getItem('modelUpload')
    if (modelUploadItem) {
      setModels(JSON.parse(modelUploadItem))
    }
  }, [])

  const deleteModel = useCallback(
    async (id: string) => {
      analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_DELETE_IMAGE)
      setModels(models.filter((image) => image.id !== id))
    },
    [models]
  )

  useEffect(() => {
    const slides = models.map((model) => (
      <React.Fragment key={model.id}>
        <CarouselCard
          type='MODEL'
          extension={model.type}
          modelUrl={model.url}
          deleteCallback={() => deleteModel(model.id)}
          height='334px'
          width='400px'
        />
      </React.Fragment>
    ))
    if (uploading) {
      slides.push(<CarouselCard key={nanoid()} type='UPLOADING' height='334px' width='400px' />)
    }
    setModelSlides(slides)
  }, [models, uploading])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true)
      analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_UPLOAD_IMAGE)

      const productDataItem = localStorage.getItem('productData')
      if (!productDataItem) return
      const productData = JSON.parse(productDataItem) as ProductData

      const hanaAssetId = localStorage.getItem('hanaAssetId')
      if (!hanaAssetId) return

      const customerId = assumedCustomer?.id ?? session?.customer.id
      if (!customerId) return

      const newModels = [...models]

      // Upload models to Hana3D DB
      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
          const uploadRequest = await getModelPresignedWithoutId(
            hanaAssetId,
            customerId,
            productData.sku,
            extension
          )
          if (!uploadRequest) return
          await uploadToUrl(file, uploadRequest.uploadUrl, async () => {
            await confirmModelUpload(hanaAssetId, uploadRequest.viewId)
            setModels(
              newModels.concat([
                { id: uploadRequest.viewId, url: uploadRequest.outputUrl, type: extension }
              ])
            )
            newModels.push({
              id: uploadRequest.viewId,
              url: uploadRequest.outputUrl,
              type: extension
            })
          })
        })
      )
      setUploading(false)
    },
    [models]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: undefined,
    noClick: !!models.length,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const baseStyle = {
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.dark,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .15s ease-in-out'
  }

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const saveInfo = () => {
    localStorage.setItem('modelUpload', JSON.stringify(models))
  }

  return (
    <div>
      <UploadContainer {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {modelSlides.length ? (
          <CarouselContainer>
            <MultiCarousel slides={modelSlides} responsive={carouselResponsive} />
          </CarouselContainer>
        ) : (
          <UploadContainerEmpty>
            <img src={CubeIcon} alt='camera' />
            <span>{t('modelSpecifications.dragNDrop')}</span>
            <UploadButton>
              <span>{t('modelSpecifications.select')}</span>
            </UploadButton>
          </UploadContainerEmpty>
        )}
      </UploadContainer>
      <NextButton nextPage={RegistrationSteps.CONFIRMATION} onClick={() => saveInfo()} />
    </div>
  )
}

export default ModelUpload
