/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext } from 'react'

import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'

import CubeIcon from '@console/common/assets/cubeOutline.svg'
import LinkIcon from '@console/common/assets/link.svg'
import AddIcon from '@console/common/assets/roundAdd.svg'
import CameraIcon from '@console/common/assets/simpleCamera.svg'
import WarningIcon from '@console/common/assets/warning.svg'
import Button from '@console/common/components/Button'

import ValidationBanner from '../../../components/ValidationBanner'
import { MultipleContext } from '../../../contexts/MultipleContext'
import { ProcessContext, RegistrationSteps } from '../../../contexts/ProcessContext'
import {
  MainContainer,
  TableContainer,
  HeaderContainer,
  TableHeader,
  RowContainer,
  TableRow,
  TableBody,
  HeaderIcon,
  RowIcon,
  ImageThumbnail,
  ModelThumbnail,
  BottomButtonsContainer
} from './styles'
import UploadArea from './UploadArea'

interface Warning {
  id: string
  description: string
}

const ModelImageUpload: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const history = useHistory()

  const [warning, setWarning] = useState<Warning[]>([])
  const [skipWarning, setSkipWarning] = useState(false)

  const { setCurrentStep } = useContext(ProcessContext)
  const { handleModal, products, clearRegistration } = useContext(MultipleContext)

  const forceUpdate: () => void = useState({})[1].bind(null, {})

  const handleCancel = () => {
    clearRegistration()
    history.push('/portfolio')
  }

  const validate = () => {
    const newWarning = [...warning]
    // eslint-disable-next-line array-callback-return
    products.map((product) => {
      if (!product.imagesUrls?.length && !findWarning(product.id)) {
        newWarning.push({ id: product.id, description: 'upload de imagem' })
      } else if (product.imagesUrls?.length && findWarning(product.id)) {
        const index = newWarning.findIndex((w) => w.id === product.id)
        setWarning(newWarning.splice(index, 1))
      }
    })
    if (!newWarning.length || skipWarning) nextStep()
    setWarning(newWarning)
  }

  const findWarning = (productId: string) => warning.findIndex((w) => w.id === productId) >= 0

  const handleSkipWarning = () => {
    setWarning([])
    setSkipWarning(true)
  }

  const nextStep = () => {
    setCurrentStep(RegistrationSteps.CONFIRMATION)
  }

  return (
    <>
      <MainContainer>
        <TableContainer>
          <HeaderContainer>
            <TableHeader>{t('multipleRegistration.imageUpload.productTitle')}</TableHeader>
            <TableHeader>{t('multipleRegistration.imageUpload.sku')}</TableHeader>
            <TableHeader>{t('multipleRegistration.imageUpload.pdp')}</TableHeader>
            <TableHeader>
              <HeaderIcon src={CameraIcon} alt='' />
            </TableHeader>
            <TableHeader>
              <HeaderIcon src={CubeIcon} alt='' />
            </TableHeader>
            <TableHeader />
          </HeaderContainer>
          <TableBody>
            {products.map((p) => (
              <RowContainer key={p.id}>
                <TableRow wrapText>
                  <span>{p.name}</span>
                </TableRow>
                <TableRow wrapText>
                  <span>{p.sku}</span>
                </TableRow>
                <TableRow>
                  <Tooltip title={p?.pdp as string}>
                    <Link to={{ pathname: p.pdp }} target='_blank'>
                      <RowIcon src={LinkIcon} alt='' />
                    </Link>
                  </Tooltip>
                </TableRow>
                {findWarning(p.id) && !p.imagesUrls?.length ? (
                  <TableRow>
                    <img src={WarningIcon} alt='' />
                  </TableRow>
                ) : (
                  <TableRow>
                    {p.imagesUrls?.length ? (
                      <ImageThumbnail
                        src={p.imagesUrls[0]}
                        alt=''
                        onClick={() => handleModal('IMAGE', p.id)}
                      />
                    ) : (
                      <RowIcon
                        src={AddIcon}
                        alt='image'
                        onClick={() => handleModal('IMAGE', p.id)}
                      />
                    )}
                  </TableRow>
                )}
                <TableRow>
                  {p.models?.length ? (
                    <ModelThumbnail onClick={() => handleModal('MODEL', p.id)}>
                      <img src={CubeIcon} alt='MODEL' />
                      <span>{p.models[0].type}</span>
                    </ModelThumbnail>
                  ) : (
                    <RowIcon src={AddIcon} alt='model' onClick={() => handleModal('MODEL', p.id)} />
                  )}
                </TableRow>
                <TableRow>
                  <UploadArea productId={p.id} forceUpdate={forceUpdate} />
                </TableRow>
              </RowContainer>
            ))}
          </TableBody>
        </TableContainer>
      </MainContainer>
      <BottomButtonsContainer>
        <Button
          style={{ width: '191px', height: '41px' }}
          onClick={() => handleCancel()}
          alternative
        >
          {t('cancel')}
        </Button>
        <Button style={{ width: '191px', height: '41px' }} onClick={() => validate()}>
          {t('next')}
        </Button>
      </BottomButtonsContainer>
      {warning.length > 0 && (
        <ValidationBanner
          isError={false}
          errors={[]}
          warning={warning}
          type={t('multipleRegistration.imageUpload.warningText')}
          previousError={() => {}}
          nextError={() => {}}
          errorIndex={-1}
          warningIndex={0}
          helperText={t('multipleRegistration.productData.warningTextHelper')}
          skipText={t('multipleRegistration.productData.skipText')}
          handleSkipWarning={handleSkipWarning}
        />
      )}
    </>
  )
}

export default ModelImageUpload
