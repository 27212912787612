import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react'

import { nanoid } from 'nanoid/non-secure'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import CubeIcon from '@console/common/assets/cubeOutline.svg'
import Button from '@console/common/components/Button'
import CarouselCard from '@console/common/components/CarouselCard'
import MultiCarousel from '@console/common/components/MultiCarousel'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  createHanaAsset,
  getModelPresignedWithoutId,
  confirmModelUpload
} from '@console/common/services/api'
import { uploadToUrl } from '@console/common/services/upload'
import theme from '@console/common/theme.json'

import { MultipleContext, Model } from '../../../../contexts/MultipleContext'
import {
  UploadModal,
  UploadContainer,
  UploadModalHeader,
  UploadModalSubtitle,
  ModalUploadArea,
  ModalUploadIcon,
  ModalUploadTextHelper,
  ModalUploadButton,
  ModalUploadContent,
  BottomButtonsContainer,
  CarouselContainer
} from '../styles'

const ACCEPTED_FILE_FORMATS = 'GLB, USDZ, BLEND'

const ModelModal: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [uploading, setUploading] = useState(false)
  const [modelSlides, setModelSlides] = useState<React.ReactNode[]>([])

  const { product, handleModal, setModelToProduct } = useContext(MultipleContext)
  const { analytics } = useContext(AnalyticsContext)
  const { session, assumedCustomer } = useContext(AuthContext)

  const carouselResponsive = {
    three: {
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
      slidesToSlide: 1
    },
    two: {
      breakpoint: { max: 1700, min: 500 },
      items: 3,
      slidesToSlide: 1
    }
  }

  const deleteModel = useCallback(
    async ({ id }: Model) => {
      analytics?.track(AnalyticsEvent.REGISTRATION_DELETE_IMAGE)
      const { models } = product
      if (!models) return
      setModelToProduct(
        product.productId,
        models.filter((fModel) => fModel.id !== id),
        product.hanaId || ''
      )
    },
    [product.models]
  )

  useEffect(() => {
    const slides = product.models.map((mapModel) => (
      <React.Fragment key={mapModel.id}>
        <CarouselCard
          type='MODEL'
          extension={mapModel.type}
          modelUrl={mapModel.url}
          deleteCallback={() => deleteModel(mapModel)}
          height='334px'
          width='400px'
        />
      </React.Fragment>
    ))
    if (uploading) {
      slides.push(<CarouselCard key={nanoid()} type='UPLOADING' height='334px' width='400px' />)
    }
    setModelSlides(slides)
  }, [product, uploading])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!product || !acceptedFiles.length) return
      analytics?.track(AnalyticsEvent.REGISTRATION_UPLOAD_IMAGE)
      setUploading(true)

      const customerId = assumedCustomer?.id ?? session?.customer.id
      if (!customerId) return

      const hanaAssetId = product.hanaId
        ? product.hanaId
        : await createHanaAsset(product.name || product.sku || product.productId)
      if (!hanaAssetId) return

      const newModels = [...product.models]

      // Upload models to Hana3D DB
      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
          const uploadRequest = await getModelPresignedWithoutId(
            hanaAssetId,
            customerId,
            product.sku || '',
            extension
          )
          if (!uploadRequest) return
          await uploadToUrl(file, uploadRequest.uploadUrl, async () => {
            await confirmModelUpload(hanaAssetId, uploadRequest.viewId)
            setModelToProduct(
              product.productId,
              newModels.concat([
                {
                  productId: product.productId,
                  id: uploadRequest.viewId,
                  url: uploadRequest.outputUrl,
                  type: extension,
                  hanaId: hanaAssetId
                }
              ]),
              hanaAssetId
            )
            newModels.push({
              id: uploadRequest.viewId,
              url: uploadRequest.outputUrl,
              type: extension,
              hanaId: hanaAssetId
            })
          })
        })
      )
      setUploading(false)
    },
    [product]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: undefined,
    noClick: !!product.images.length,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <UploadModal>
      <UploadContainer>
        <UploadModalHeader>{product?.name}</UploadModalHeader>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <UploadModalSubtitle>SKU: {product?.sku}</UploadModalSubtitle>
        {modelSlides.length ? (
          <CarouselContainer>
            <MultiCarousel slides={modelSlides} responsive={carouselResponsive} />
          </CarouselContainer>
        ) : (
          <ModalUploadArea {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <ModalUploadContent>
              <ModalUploadIcon src={CubeIcon} alt='model' />
              <ModalUploadTextHelper>
                {t('multipleRegistration.uploadPopup.textHelper')}
              </ModalUploadTextHelper>
              <ModalUploadButton>
                <span>{t('multipleRegistration.uploadPopup.textButton')}</span>
              </ModalUploadButton>
              <ModalUploadTextHelper>{ACCEPTED_FILE_FORMATS}</ModalUploadTextHelper>
            </ModalUploadContent>
          </ModalUploadArea>
        )}
        <BottomButtonsContainer>
          <Button
            style={{ width: '191px', height: '41px' }}
            onClick={() => handleModal('CLOSE')}
            isLoading={false}
            alternative
          >
            {t('cancel')}
          </Button>
          <Button
            style={{ width: '191px', height: '41px' }}
            isLoading={false}
            onClick={() => handleModal('CLOSE')}
          >
            {t('conclude')}
          </Button>
        </BottomButtonsContainer>
      </UploadContainer>
    </UploadModal>
  )
}

export default ModelModal
