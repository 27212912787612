import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'

import copyIcon from '@console/common/assets/copy.svg'
import embedIcon from '@console/common/assets/embed.svg'

import {
  DividerItemHr,
  EmbedDocumentationContainer,
  EmbedDocumentationLink,
  EmbedPopupCode,
  EmbedPopupContainer,
  EmbedPopupCopyButton,
  EmbedPopupCopyContainer,
  EmbedPopupCopyImage
} from './styles'

interface Props {
  customerId?: string | null
  sku?: string | null
}

const EmbedPopup: React.FC<Props> = ({ customerId, sku }: Props) => {
  const { t } = useTranslation(['components/asset-viewer'])
  const refInput = useRef<HTMLInputElement>(null)

  const iframe = `<iframe width=100% height=100% src="https://viewer.r2u.io/embed/?customerId=${customerId}&sku=${sku}" frameborder=0 scrolling="no" allowxr="yes" allow="xr-spatial-tracking;fullscreen;camera"></iframe>`

  const handlerClickIcon = () => {
    refInput?.current?.focus()
    refInput?.current?.select()
    navigator.clipboard.writeText(iframe)
  }

  return (
    <EmbedPopupContainer>
      <EmbedPopupCopyContainer>
        <EmbedPopupCode ref={refInput} type='text' value={iframe} readOnly />
        <EmbedPopupCopyButton
          type='button'
          onClick={handlerClickIcon}
          onKeyPress={handlerClickIcon}
        >
          <EmbedPopupCopyImage alt='copy icon' src={copyIcon} />
        </EmbedPopupCopyButton>
      </EmbedPopupCopyContainer>
      <DividerItemHr />
      <EmbedDocumentationContainer>
        <EmbedDocumentationLink
          href='https://docs.r2u.io/guides/embed/getting-started'
          target='_blank'
          rel='noeferrer'
        >
          <img src={embedIcon} alt='embed icon' />
          {t('embed.documentation')}
        </EmbedDocumentationLink>
      </EmbedDocumentationContainer>
    </EmbedPopupContainer>
  )
}

export default EmbedPopup
