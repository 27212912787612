import React, { createContext, ReactNode, useState, useCallback, useLayoutEffect } from 'react'

import Auth0Lock from 'auth0-lock'
import { useTranslation } from 'react-i18next'

import config from '../config'
import { api, UserRole, getSession, Customer, getCustomer } from '../services/api'
import translations from '../translations.json'

interface Props {
  children: ReactNode
}

export interface Session {
  email: string
  role: UserRole
  name: string
  customer: Customer
}

interface AuthContextData {
  isSigned: boolean
  session: Session | null
  assumedCustomer: Customer | null
  assumeCustomer: (customer: Customer | null) => void
  createSession: (accessToken: string, redirectCallback?: (session: Session | null) => void) => void
  destroySession: () => void
  sessionCustomer: Customer | null
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const storedToken = localStorage.getItem('access_token') || ''
  const expiresAt = localStorage.getItem('expires_at') || ''
  const assumedCustomerIdLocalStorage = localStorage.getItem('assumed_customer_id')
  const assumedCustomerIdSessionStorage = sessionStorage.getItem('assumed_customer_id')

  const { ready } = useTranslation(translations)

  const [isSigned, setSigned] = useState(true)
  const [session, setSession] = useState<Session | null>(null)

  const [assumedCustomer, assumeCustomer] = useState<Customer | null>(null)
  const [sessionCustomer, setSessionCustomer] = useState<Customer | null>(null)

  const destroySession = () => {
    localStorage.removeItem('auth0_id')
    localStorage.removeItem('access_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('assumed_customer_id')
    sessionStorage.removeItem('assumed_customer_id')
    setSession(null)
    setSigned(false)
  }

  const handleAssumeCustomer = (customer: Customer | null) => {
    if (customer) {
      sessionStorage.setItem('assumed_customer_id', customer.id)
      localStorage.setItem('assumed_customer_id', customer.id)
    } else {
      sessionStorage.removeItem('assumed_customer_id')
      localStorage.removeItem('assumed_customer_id')
    }

    assumeCustomer(customer)
  }

  const createSession = useCallback(
    (accessToken: string, redirectCallback?: (session: Session | null) => void) => {
      const clientID = config.auth.AUTH0_CLIENT_ID
      const domain = config.auth.AUTH0_DOMAIN
      const lock = new Auth0Lock(clientID, domain)

      api.interceptors.request.use(
        (request) => {
          // eslint-disable-next-line no-param-reassign
          request.headers.Authorization = `Bearer ${accessToken}`
          return request
        },
        (error) => Promise.reject(error)
      )

      api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.status === 401) destroySession()
          return Promise.reject(error)
        }
      )

      lock.getProfile(accessToken, (err, profile) => {
        if (profile && ready) {
          setSigned(true)
          getSession().then((r) => {
            if (r) {
              const { id, role, name, customer } = r
              const s = { email: id, role, name, customer }
              getCustomer(customer.id)
                .then((data) => {
                  if (data) s.customer = data
                })
                .finally(() => {
                  setSession(s)
                  if (redirectCallback) redirectCallback(s)
                })
            }
          })
        } else destroySession()
      })
    },
    [ready]
  )

  useLayoutEffect(() => {
    const date = Date.now()
    if (Number(expiresAt) > date) createSession(storedToken)
    else destroySession()
  }, [storedToken, createSession, expiresAt])

  useLayoutEffect(() => {
    if (!session) return
    if (!assumedCustomerIdLocalStorage && !assumedCustomerIdSessionStorage) return
    if (session.role !== UserRole.ADMIN && session.role !== UserRole.R2USER) return

    const assumedCustomerId = assumedCustomerIdSessionStorage ?? assumedCustomerIdLocalStorage

    if (assumedCustomerId) {
      getCustomer(assumedCustomerId).then((customer) => {
        if (customer) {
          sessionStorage.setItem('assumed_customer_id', customer.id)
          assumeCustomer(customer)
        }
      })
    }
  }, [session, assumedCustomerIdLocalStorage, assumedCustomerIdSessionStorage])

  useLayoutEffect(() => {
    if (!session) return
    if (session.role === UserRole.CUSTOMER) {
      setSessionCustomer(session.customer)
    } else if (session.role === UserRole.ADMIN || session.role === UserRole.R2USER) {
      setSessionCustomer(assumedCustomer)
    }
  }, [session, assumedCustomer])

  return (
    <AuthContext.Provider
      value={{
        isSigned,
        session,
        assumedCustomer,
        assumeCustomer: handleAssumeCustomer,
        createSession,
        destroySession,
        sessionCustomer
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
