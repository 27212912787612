import React, { useContext, useState } from 'react'

import fileDownload from 'js-file-download'
import { parseAsync } from 'json2csv'
import { useTranslation } from 'react-i18next'

import FileIcon from '@console/common/assets/file.svg'
import ErrorIcon from '@console/common/assets/icons/error.svg'
import LoadingIcon from '@console/common/assets/icons/loading.svg'
import Toast from '@console/common/components/Toast'
import { AuthContext } from '@console/common/contexts/AuthContext'

import { CheckboxContext } from '../../contexts/CheckboxContext'
import { ProductsContext } from '../../contexts/ProductsContext'
import { ExportCsvButtonStyle } from './styles'

const FIELDS = [
  'id',
  'name',
  'customer',
  'ean',
  'sku',
  'active',
  'pdpUrl',
  'status',
  'referenceImage',
  'createdAt',
  'updatedAt',
  'lastVersion'
]

const ExportCsvButton: React.FC = () => {
  const { t } = useTranslation(['portfolio'])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const { isAnyChecked, selectAllStatus, checkedStatus } = useContext(CheckboxContext)
  const { fetchAllProductsInfo, products } = useContext(ProductsContext)
  const { sessionCustomer } = useContext(AuthContext)

  const dataToCsv = async (): Promise<string> => {
    const opts = { fields: FIELDS }

    const checkedProductIds = checkedStatus
      .filter((checkedProduct) => checkedProduct.checked)
      .map((checkedProduct) => checkedProduct.id)

    const checkedProducts = products.filter((product) => checkedProductIds.includes(product.id))

    const data = checkedProducts.map((product) => ({
      id: product.id,
      name:
        product.Customers && sessionCustomer
          ? product.Customers.find((customer) => customer.id === sessionCustomer.id)
              ?.CustomerProduct?.name
          : product.name,
      customer: sessionCustomer?.name || product.owner,
      ean: product.ean,
      sku:
        product.Customers && sessionCustomer
          ? product.Customers.find((customer) => customer.id === sessionCustomer.id)
              ?.CustomerProduct?.sku
          : product.sku,
      active:
        product.Customers && sessionCustomer
          ? product.Customers.find((customer) => customer.id === sessionCustomer.id)
              ?.CustomerProduct?.active
          : product.active,
      pdpUrl:
        product.Customers && sessionCustomer
          ? product.Customers.find((customer) => customer.id === sessionCustomer.id)
              ?.CustomerProduct?.pdpUrl
          : product.pdpUrl,
      status: product.status,
      referenceImage: product.References && product.References[0] ? product.References[0].url : '',
      createdAt: product.createdAt,
      updatedAt: product.updatedAt,
      lastVersion: product.Versions && product.Versions[0] ? product.Versions[0].createdAt : ''
    }))

    return new Promise((resolve) => {
      parseAsync(data, opts).then((csv) => resolve(csv))
    })
  }

  const exportCsv = async () => {
    if (selectAllStatus) {
      setLoading(true)
      const result = await fetchAllProductsInfo()
      if (result) {
        fileDownload(result, 'Products.zip')
      } else {
        setError(true)
      }
      setLoading(false)
    } else {
      const result = await dataToCsv()
      if (result) fileDownload(result, 'Products.csv')
    }
  }

  return (
    <>
      {isAnyChecked ? (
        <ExportCsvButtonStyle onClick={() => exportCsv()}>
          <img src={FileIcon} alt={t('exportCsv.button')} />
          {t('exportCsv.button')}
        </ExportCsvButtonStyle>
      ) : null}
      {loading ? (
        <Toast
          icon={LoadingIcon}
          handleClose={() => setLoading(false)}
          text={t('exportCsv.loading')}
          color='lightBlue3'
        />
      ) : null}
      {error ? (
        <Toast
          icon={ErrorIcon}
          handleClose={() => setError(false)}
          text={t('exportCsv.error')}
          color='red5'
        />
      ) : null}
    </>
  )
}

export default ExportCsvButton
