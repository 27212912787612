import styled from 'styled-components'

interface Props {
  color?: string
  filter?: string
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.grey9};
  max-width: 40%;
  height: 10%;
  position: fixed;
  bottom: 0;
  margin-left: 27px;
  margin-bottom: 17px;
  z-index: 99999999;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-left: 10px;
  width: 100% - 10px;
  height: 85%;
`

export const CloseButton = styled.button`
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: -2px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  img {
    width: 14px;
    height: 14px;
    // theme.grey10
    filter: invert(49%) sepia(0%) saturate(0%) hue-rotate(39deg) brightness(98%) contrast(92%);
  }
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const Icon = styled.img<Props>`
  width: 44px;
  height: 30px;
  filter: ${(props) => (props.filter ? props.filter : null)};
`

export const InfoText = styled.span`
  font-size: 18px;
  color: ${(props) => props.theme.black};
`

export const VerticalBar = styled.div<Props>`
  background-color: ${(props) => props.theme[`${props.color ?? 'black'}`]};
  border-radius: 2px;
  min-width: 12px;
  height: 100%;
`
