import styled from 'styled-components'

export const NextButton = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.dark};
`

export const TimerText = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.dark};
  width: 80%;
  height: 100%;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TimeCounter = styled.div`
  color: ${(props) => props.theme.dark};
  width: 20%;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
