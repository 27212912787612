import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.dark};
`

export const Title = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  margin: 0;
  display: flex;
`

export const TitleHover = styled.h4`
  margin: 0;
  margin-left: 10px;
  :hover {
    text-decoration: underline 8%;
  }
`

interface TitleSeparatorProps {
  hasSubPageMenu?: boolean
  hasStatusBar?: boolean
}

export const TitleSeparator = styled.hr<TitleSeparatorProps>`
  border: 1px solid ${(props) => props.theme.grey2};
  background-color: ${(props) => props.theme.grey2};
  margin-top: ${(props) => (props.hasSubPageMenu || props.hasStatusBar ? '-1px' : '24px')};
  margin-bottom: 30px;

  @media screen and (min-width: 1024px) {
    margin-top: ${(props) =>
      props.hasSubPageMenu ? '-1px' : props.hasStatusBar ? '-15px' : '24px'};
    margin-bottom: 16px;
  }
  @media screen and (min-width: 1441px) {
    margin-top: ${(props) =>
      props.hasSubPageMenu ? '-1px' : props.hasStatusBar ? '-15px' : '24px'};
    margin-bottom: 60px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 17px;
  display: flex;
  justify-content: flex-start;
  gap: 28px;
`

interface ButtonProps {
  selected: boolean
}

export const Button = styled.button<ButtonProps>`
  border: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: ${(props) => (props.selected ? props.theme.blue : props.theme.grey2)};
  background-color: transparent;
  height: 32px;
  border-bottom: ${(props) => (props.selected ? `4px solid ${props.theme.blue}` : 0)};
  padding-left: 0;
  padding-right: 0;
`

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

export const Icon = styled.img`
  height: 22px;
  align-self: center;
`

export const StatusBar = styled.div`
  margin-bottom: 0;
  margin-top: 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
