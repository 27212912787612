import React, { useState, useRef } from 'react'

import Select, {
  ControlProps,
  MenuListComponentProps,
  OptionProps,
  StylesConfig,
  GroupTypeBase,
  InputActionMeta,
  components
} from 'react-select'

import EllipsisIcon from '../../assets/ellipsis.svg'
import theme from '../../theme.json'
import { Ellipsis, HeaderText } from './styles'

export interface CardOptions {
  label: string
  action: () => void
}

interface Props {
  options: CardOptions[]
  headerText: string
}

type IsMulti = false

const customStyles: StylesConfig<CardOptions, IsMulti> = {
  control: (provided, state) => ({
    ...provided,
    width: '0',
    height: '0',
    border: 'transparent',
    boxShadow: 'transparent'
  }),

  input: (provided, state) => ({
    ...provided,
    width: '0',
    height: '0',
    color: 'transparent'
  }),

  placeholder: (provided, state) => ({
    ...provided,
    width: '0',
    height: '0',
    color: 'transparent'
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    width: '0',
    height: '0'
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: '0',
    height: '0'
  }),

  menu: (provided, state) => ({
    ...provided,
    width: '177px',
    border: `1px solid ${theme.dark}`,
    borderRadius: '10px',
    boxShadow: 'none',
    paddingBottom: '7px',
    paddingTop: '7px',
    top: '20px',
    left: '-150px'
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? theme.dark : theme.grey2,
    backgroundColor: state.isFocused ? theme.grey5 : 'transparent',
    padding: 10,
    fontStyle: 'normal',
    fonteWeight: 'normal',
    fontSize: '14px',
    cursor: 'pointer'
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: theme.dark,
    fontStyle: 'normal',
    fonteWeight: 'normal',
    fontSize: '14px'
  })
}

const Options: React.FC<Props> = ({ options, headerText }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const ref = useRef<Select<CardOptions, false, GroupTypeBase<CardOptions>>>(null)

  const onChange = (option: CardOptions | null) => {
    if (option) option.action()
  }

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'menu-close') {
      setMenuIsOpen(false)
    }
  }

  const openMenu = () => {
    setMenuIsOpen(true)
    ref.current?.focus()
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  const ControlComponent = (
    props: ControlProps<CardOptions, false, GroupTypeBase<CardOptions>>
  ) => (
    <Ellipsis
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!menuIsOpen) openMenu()
        else closeMenu()
      }}
    >
      <img src={EllipsisIcon} alt='' />
      <components.Control {...props} />
    </Ellipsis>
  )

  const MenuListComponent = (
    props: MenuListComponentProps<CardOptions, false, GroupTypeBase<CardOptions>>
  ) => {
    const { children } = props
    return (
      <components.MenuList {...props}>
        <HeaderText>{headerText}</HeaderText>
        {children}
      </components.MenuList>
    )
  }

  const OptionComponent = (props: OptionProps<CardOptions, false, GroupTypeBase<CardOptions>>) => {
    const { children, innerProps } = props
    return (
      <components.Option
        {...props}
        innerProps={{
          ...innerProps,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            innerProps.onClick(e)
          }
        }}
      >
        {children}
      </components.Option>
    )
  }

  return (
    <Select
      ref={ref}
      menuIsOpen={menuIsOpen}
      components={{
        Control: ControlComponent,
        MenuList: MenuListComponent,
        Option: OptionComponent
      }}
      styles={customStyles}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={closeMenu}
      isClearable={false}
      controlShouldRenderValue={false}
      isSearchable={false}
    />
  )
}

export default Options
