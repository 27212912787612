// eslint-disable-next-line no-shadow
enum Status {
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  REJECTED = 'REJECTED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  MISSING_INFORMATION = 'MISSING_INFORMATION',
  READY_TO_START = 'READY_TO_START',
  IN_PROGRESS = 'IN_PROGRESS'
}

export default Status
