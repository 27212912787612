import React, { useState, useEffect, useContext } from 'react'

import { nanoid } from 'nanoid/non-secure'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'

import AddIcon from '@console/common/assets/roundAdd.svg'
import ThrashIcon from '@console/common/assets/thrash.svg'
import Button from '@console/common/components/Button'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import ValidationBanner from '../../../components/ValidationBanner'
import { TempProduct, MultipleContext } from '../../../contexts/MultipleContext'
import { ProcessContext, RegistrationSteps } from '../../../contexts/ProcessContext'
import {
  TableContainer,
  TableHeader,
  TableHeaderSubtitle,
  TableDivider,
  HeaderContainer,
  TableRow,
  RowContainer,
  FullInput,
  TableForm,
  RemoveIcon,
  SmallInput,
  NewLineBtn,
  NewLineContainer,
  BottomButtonsContainer
} from './styles'
import { validate } from './validator'

interface Error {
  column?: string
  id?: string
  description: string
}

type ErrorType = 'error' | 'warning'

const Table: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const history = useHistory()
  const [rows, setRows] = useState<TempProduct[]>([
    { id: nanoid(), name: '', sku: '', ean: '', pdp: '', dimensions: '' }
  ])
  const [errors, setErrors] = useState<Error[]>([])
  const [warning, setWarning] = useState<Error[]>([])
  const [errorIndex, setErrorIndex] = useState<number>(0)
  const [warningIndex, setWarningIndex] = useState<number>(0)
  const [skipWarning, setSkipWarning] = useState(false)

  const { setCurrentStep } = useContext(ProcessContext)
  const { products, handleSetProducts, clearRegistration } = useContext(MultipleContext)
  const { analytics } = useContext(AnalyticsContext)

  const headers = [
    {
      title: t('multipleRegistration.productData.tableHeaders.productTitle.title'),
      subtitle: t('multipleRegistration.productData.tableHeaders.productTitle.subtitle')
    },
    {
      title: t('multipleRegistration.productData.tableHeaders.sku.title'),
      subtitle: t('multipleRegistration.productData.tableHeaders.sku.subtitle')
    },
    {
      title: t('multipleRegistration.productData.tableHeaders.ean.title'),
      subtitle: t('multipleRegistration.productData.tableHeaders.ean.subtitle')
    },
    {
      title: t('multipleRegistration.productData.tableHeaders.pdp.title'),
      subtitle: t('multipleRegistration.productData.tableHeaders.pdp.subtitle')
    },
    {
      title: t('multipleRegistration.productData.tableHeaders.dimensions.title'),
      subtitle: t('multipleRegistration.productData.tableHeaders.dimensions.subtitle')
    }
  ]

  useEffect(() => {
    if (products?.length) {
      setRows(products)
    }
  }, [])

  useEffect(() => {
    if (products?.length) {
      setRows(products)
    }
  }, [products])

  const addNewLine = () =>
    setRows([...rows, { id: nanoid(), name: '', sku: '', ean: '', pdp: '', dimensions: '' }])

  const removeLine = (id: string) => setRows(rows.filter((r) => r.id !== id))

  const findError = (column: string, id: string) =>
    errors.findIndex((e) => e.id === id && column === e.column) >= 0

  const findWarning = (column: string, id: string) =>
    warning.findIndex((e) => e.id === id && column === e.column) >= 0

  const nextStep = () => {
    setCurrentStep(RegistrationSteps.MODEL_IMAGE_UPLOAD)
    handleSetProducts(rows)
    analytics?.track(AnalyticsEvent.REGISTRATION_MULTI_SUBMIT_DATA)
  }

  const handleSkipWarning = () => {
    setWarning([])
    setSkipWarning(true)
  }

  const Schema = {
    name: (id: string, pErrors: Error[], value?: string) =>
      validate({
        findError,
        product: {
          id,
          value,
          column: 'name',
          description: t('multipleRegistration.productData.titleError')
        },
        array: pErrors
      }),
    sku: (id: string, pErrors: Error[], value?: string) =>
      validate({
        findError,
        product: {
          id,
          value,
          column: 'sku',
          description: t('multipleRegistration.productData.skuError')
        },
        array: pErrors
      }),
    dimensions: (id: string, pErrors: Error[], value?: string) =>
      validate({
        findError,
        product: {
          id,
          value,
          column: 'dimensions',
          description: t('multipleRegistration.productData.dimensionsError')
        },
        array: pErrors
      }),
    ean: (id: string, pWarning: Error[], value?: string) =>
      validate({
        findError: findWarning,
        product: {
          id,
          value,
          column: 'ean',
          description: t('multipleRegistration.productData.fillEAN')
        },
        array: pWarning
      }),
    pdp: (id: string, pWarning: Error[], value?: string) =>
      validate({
        findError: findWarning,
        product: {
          id,
          value,
          column: 'pdp',
          description: t('multipleRegistration.productData.fillPDP')
        },
        array: pWarning
      })
  }

  const handleValidate = () => {
    let newErrors = [...errors]
    let newWarning = [...warning]
    // eslint-disable-next-line array-callback-return
    rows.map((product) => {
      newErrors = Schema.name(product.id, newErrors, product?.name)
      newErrors = Schema.sku(product.id, newErrors, product?.sku)
      newErrors = Schema.dimensions(product.id, newErrors, product?.dimensions)
      newWarning = Schema.ean(product.id, newWarning, product?.ean)
      newWarning = Schema.pdp(product.id, newWarning, product?.pdp)
    })
    if (!newErrors.length && (!newWarning.length || skipWarning)) nextStep()
    setErrors(newErrors)
    setWarning(newWarning)
  }

  const handleOnChange = (e: string, column: keyof TempProduct, id: string) => {
    const index = rows.findIndex((l) => l.id === id)
    const newRow = [...rows]
    if (column !== 'imagesUrls' && column !== 'models') newRow[index][column] = e
    if (column === 'dimensions') {
      const [height, width, depth] = e.split(' x ')
      newRow[index].width = width
      newRow[index].height = height
      newRow[index].depth = depth
    }
    setRows(newRow)
  }

  const nextError = (type: ErrorType) => {
    const [index, setIndex, array] =
      type === 'error'
        ? [errorIndex, setErrorIndex, errors]
        : [warningIndex, setWarningIndex, warning]
    const increment = index + 1
    if (increment >= array.length) return setIndex(0)
    return setIndex(increment)
  }

  const previousError = (type: ErrorType) => {
    const [index, setIndex, array] =
      type === 'error'
        ? [errorIndex, setErrorIndex, errors]
        : [warningIndex, setWarningIndex, warning]
    const decrement = index - 1
    if (decrement < 0) return setIndex(array.length - 1)
    return setIndex(decrement)
  }

  const handleCancel = () => {
    clearRegistration()
    history.push('/portfolio')
  }

  return (
    <>
      <TableContainer>
        <HeaderContainer>
          {headers.map((h) => (
            <TableHeader key={h.title}>
              {h.title}
              <TableHeaderSubtitle>{h.subtitle}</TableHeaderSubtitle>
            </TableHeader>
          ))}
        </HeaderContainer>
        <TableDivider />
        <TableForm>
          {rows.map((lp) => (
            <React.Fragment key={lp.id}>
              <RowContainer>
                <TableRow>
                  <FullInput
                    name='name'
                    value={lp?.name}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'name', lp.id)}
                    style={
                      findError('name', lp.id) ? { backgroundColor: 'rgba(173, 47, 31, 0.25)' } : {}
                    }
                    selected={
                      errors[errorIndex]?.id === lp.id && errors[errorIndex]?.column === 'name'
                    }
                    error
                  />
                </TableRow>
                <TableRow>
                  <FullInput
                    name='sku'
                    value={lp?.sku}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'sku', lp.id)}
                    style={
                      findError('sku', lp.id) ? { backgroundColor: 'rgba(173, 47, 31, 0.25)' } : {}
                    }
                    selected={
                      errors[errorIndex]?.id === lp.id && errors[errorIndex]?.column === 'sku'
                    }
                    error
                  />
                </TableRow>
                <TableRow>
                  <FullInput
                    name='ean'
                    value={lp?.ean}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'ean', lp.id)}
                    style={
                      findWarning('ean', lp.id)
                        ? { backgroundColor: 'rgba(248, 129, 86, 0.25)' }
                        : {}
                    }
                    selected={
                      warning[warningIndex]?.id === lp.id && warning[warningIndex]?.column === 'ean'
                    }
                  />
                </TableRow>
                <TableRow>
                  <FullInput
                    name='pdp'
                    value={lp?.pdp}
                    width=''
                    isLoading={false}
                    onChange={(e) => handleOnChange(e.target.value, 'pdp', lp.id)}
                    style={
                      findWarning('pdp', lp.id)
                        ? { backgroundColor: 'rgba(248, 129, 86, 0.25)' }
                        : {}
                    }
                    selected={
                      warning[warningIndex]?.id === lp.id && warning[warningIndex]?.column === 'pdp'
                    }
                  />
                </TableRow>
                <TableRow>
                  <InputMask
                    mask='999 x 999 x 999'
                    onChange={(e) => handleOnChange(e.target.value, 'dimensions', lp.id)}
                    value={lp?.dimensions}
                  >
                    <SmallInput
                      name='dimensions'
                      width=''
                      isLoading={false}
                      style={
                        findError('dimensions', lp.id)
                          ? { backgroundColor: 'rgba(173, 47, 31, 0.25)' }
                          : {}
                      }
                      selected={
                        errors[errorIndex]?.id === lp.id &&
                        errors[errorIndex]?.column === 'dimensions'
                      }
                      error
                    />
                  </InputMask>
                  <RemoveIcon src={ThrashIcon} alt='' onClick={() => removeLine(lp.id)} />
                </TableRow>
              </RowContainer>
              <TableDivider />
            </React.Fragment>
          ))}
        </TableForm>
      </TableContainer>
      <NewLineContainer>
        <NewLineBtn onClick={() => addNewLine()}>
          <img src={AddIcon} alt='' />
          <span>{t('multipleRegistration.productData.newLine')}</span>
        </NewLineBtn>
      </NewLineContainer>
      <BottomButtonsContainer>
        <Button
          style={{ width: '191px', height: '41px' }}
          onClick={() => handleCancel()}
          isLoading={false}
          alternative
        >
          {t('cancel')}
        </Button>
        <Button
          style={{ width: '191px', height: '41px' }}
          onClick={() => handleValidate()}
          isLoading={false}
        >
          {t('next')}
        </Button>
      </BottomButtonsContainer>
      {errors.length > 0 || warning.length > 0 ? (
        <ValidationBanner
          isError={!!errors.length ?? !!warning.length}
          errors={errors}
          warning={warning}
          type={
            errors.length
              ? t('multipleRegistration.productData.errorType')
              : t('multipleRegistration.productData.warningType')
          }
          previousError={previousError}
          nextError={nextError}
          errorIndex={errorIndex}
          warningIndex={warningIndex}
          helperText={
            errors.length
              ? t('multipleRegistration.productData.errorTextHelper')
              : t('multipleRegistration.productData.warningTextHelper')
          }
          skipText={t('multipleRegistration.productData.skipText')}
          handleSkipWarning={handleSkipWarning}
        />
      ) : null}
    </>
  )
}

export default Table
