import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

interface Props {
  isLoading?: boolean
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

export const CarouselContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 46px;
`

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const ContentField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 150px;
    margin: 17px;
    text-align: end;
    color: ${(props) => props.theme.grey10};
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }
`

export const Separator = styled.hr`
  border: 1px solid ${(props) => props.theme.grey7};
  background-color: ${(props) => props.theme.grey7};
  width: 100%;
`

export const ViewerContainer = styled.div<Props>`
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-color: ${(props) => props.theme.white};
  margin-bottom: 40px;

  ${(props) => (props.isLoading ? '' : `border: 1px solid ${props.theme.grey2};`)};
  ${(props) => props.isLoading && skeletonAnimation}
`
