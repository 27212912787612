/**
 * ! Disclaimer
 * This code presented is meant for temporary use while the set default customization is not yet
 * implemented on the viewer. When it is, please delete this file along with the usages marked with
 * * "TODO delete code segment".
 */

/* eslint-disable no-console */
import React from 'react'

import { ViewerRef } from '@r2u/viewer/dist/types'

const viewerLoaded = (viewerRef: React.RefObject<ViewerRef>): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    let resolveInterval: NodeJS.Timer

    const rejectTimeout = setTimeout(() => {
      if (resolveInterval) clearInterval(resolveInterval)

      console.error('Viewer timeout exceeded!')
      reject()
    }, 10000)

    resolveInterval = setInterval(() => {
      if (!viewerRef.current || !viewerRef.current?.getAllSlotsModels()) return

      clearTimeout(rejectTimeout)
      clearInterval(resolveInterval)

      resolve()
    }, 250)
  })

export default viewerLoaded
