import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { ProductsContext } from '../../contexts/ProductsContext'
import { Total } from './styles'

const ProductCount: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { loading, total } = useContext(ProductsContext)

  return (
    <>
      {loading ? null : (
        <Total>
          {t('number', { number: total })} {t('list.products')}
        </Total>
      )}
    </>
  )
}

export default ProductCount
