import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Header from '@console/common/components/Header'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'

interface Props {
  returnPage?: string
}

const SettingsHeader: React.FC<Props> = ({ returnPage }: Props) => {
  const { t } = useTranslation(['settings'])
  const { session } = useContext(AuthContext)

  const subPages = [{ to: '/settings/users', title: t('userManagement') }]
  if (session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) {
    subPages.unshift({ to: '/settings/customers', title: t('customerManagement') })
  }

  return <Header title={t('title')} subPages={subPages} returnPage={returnPage} />
}

export default SettingsHeader
