import styled from 'styled-components'

export interface Props {
  expanded: boolean
}

export const MainContainer = styled.div`
  margin-left: 44px;
  margin-right: 44px;
  margin-bottom: 62px;
`

export const HeaderButtonsContainer = styled.div`
  display: flex;
  column-gap: 25px;
`

export const AddProductButton = styled.div`
  display: flex;
  column-gap: 10px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;

  img {
    height: 24px;
  }
  span {
    color: ${(props) => props.theme.dark};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const FiltersContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${(props) => props.theme.white};
  border-radius: 50px;
  border: 2px solid #ecf1f3;
  height: ${(props) => (props.expanded ? '148px' : '68px')};
  @media screen and (min-width: 1024px) {
    width: 80%;
  }
  @media screen and (min-width: 1441px) {
    width: 65%;
  }
`

export const FilterTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  & .MuiAutocomplete-root div {
    margin-top: 2px;
  }
`

export const FilterMidContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  span {
    margin-bottom: 2px;
  }
`

export const FilterDivider = styled.div`
  border-left: 2px solid ${(props) => props.theme.grey9};
  height: 40px;
`

export const Divider = styled.hr`
  border: 1px solid ${(props) => props.theme.grey9};
  height: 0px;
  width: 95%;
`

export const FilterBottomContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media screen and (min-width: 1441px) {
    margin-right: 35px;
  }
`

export const FilterBottomContainerLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`

export const FilterBottomContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`
