import React, { useContext, useEffect } from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import { MultipleProvider } from './contexts/MultipleContext'
import { ProcessProvider } from './contexts/ProcessContext'
import MultipleProduct from './pages/MultipleProduct'
import SelectRegistration from './pages/SelectRegistration'
import SingleProduct from './pages/SingleProduct'

const Portfolio: React.FC = () => {
  const { path } = useRouteMatch()
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.PORTFOLIO)
  }, [analytics])

  return (
    <Switch>
      <Route exact path={path}>
        <SelectRegistration />
      </Route>
      <ProcessProvider>
        <Route path={`${path}/single`}>
          <SingleProduct />
        </Route>
        <MultipleProvider>
          <Route path={`${path}/multiple`}>
            <MultipleProduct />
          </Route>
        </MultipleProvider>
      </ProcessProvider>
    </Switch>
  )
}

export default Portfolio
