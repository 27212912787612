import React, { useState, useEffect } from 'react'

import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import CheckMark from '@console/common/assets/checkMark.svg'
import CheckMarkFilled from '@console/common/assets/checkMarkFilled.svg'
import MouseLeftClick from '@console/common/assets/mouseLeftClick.svg'
import MouseRightClick from '@console/common/assets/mouseRightClick.svg'
import MouseScroll from '@console/common/assets/mouseScroll.svg'
import AssetViewer from '@console/common/components/AssetViewer'

import NextButton from '../../../components/NextButton'
import { RegistrationSteps, Model } from '../../../contexts/ProcessContext'
import {
  ViewerContainer,
  MainContainer,
  ContentContainer,
  Approval,
  RequirementsContainer,
  Requirement,
  RequirementTitle,
  RequirementValues,
  InstructionsContainer,
  Instruction,
  QRContainer,
  Separator
} from './styles'

const ProductView: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [glbUrl, setGlbUrl] = useState<string | undefined>(
    'https://storage.googleapis.com/invoker2u-public/models_3d/be963317-5881-4a89-a137-f49b6886265e.glb'
  )
  const [viewerUrl, setViewerUrl] = useState<string | null>(null)
  const [sizeLimit] = useState<string | null>('10 Mb')
  const [polygonLimit] = useState<string | null>('100K')
  const [performanceMin] = useState<string | null>('30K')
  const [performanceMax] = useState<string | null>('50K')
  const [maxResolution] = useState<string | null>('2048x2048')
  const [size] = useState<string | null>('2.84 Mb')
  const [polygons] = useState<string | null>('2.84 Mb')
  const [resolution] = useState<string | null>('2.84 Mb')

  useEffect(() => {
    const modelUploadItem = localStorage.getItem('modelUpload')
    if (modelUploadItem) {
      const data = JSON.parse(modelUploadItem) as Model[]
      setGlbUrl(data.find((model) => model.type === 'glb')?.url)
    }
  }, [])

  useEffect(() => {
    setViewerUrl(`https://viewer.r2u.io/?glb=${glbUrl}`)
  }, [glbUrl])

  return (
    <div>
      {glbUrl ? (
        <ViewerContainer>
          <AssetViewer src={glbUrl} />
        </ViewerContainer>
      ) : null}
      <MainContainer>
        <ContentContainer>
          <Approval>
            <img src={CheckMark} alt='CheckMark' />
            <span>{t('productView.approved')}</span>
          </Approval>
          <RequirementsContainer>
            <Requirement>
              <RequirementTitle>
                <img src={CheckMarkFilled} alt='CheckMark' />
                <h2>{t('productView.size')}</h2>
              </RequirementTitle>
              <RequirementValues>
                <h3>{size}</h3>
                <span>{t('productView.sizeLimit', { sizeLimit })}</span>
              </RequirementValues>
            </Requirement>
            <Separator />
            <Requirement>
              <RequirementTitle>
                <img src={CheckMarkFilled} alt='CheckMark' />
                <h2>{t('productView.modelResolution')}</h2>
              </RequirementTitle>
              <RequirementValues>
                <h3>{polygons}</h3>
                <span>{t('productView.polygonLimit', { polygonLimit })}</span>
                <span>{t('productView.modelPerformance', { performanceMin, performanceMax })}</span>
              </RequirementValues>
            </Requirement>
            <Separator />
            <Requirement>
              <RequirementTitle>
                <img src={CheckMarkFilled} alt='CheckMark' />
                <h2>{t('productView.imageResolution')}</h2>
              </RequirementTitle>
              <RequirementValues>
                <h3>{resolution}</h3>
                <span>{t('productView.maxResolution', { maxResolution })}</span>
              </RequirementValues>
            </Requirement>
          </RequirementsContainer>
        </ContentContainer>
        <ContentContainer>
          <InstructionsContainer>
            <Instruction>
              <img src={MouseScroll} alt='MouseScroll' />
              <span>{t('productView.zoom')}</span>
            </Instruction>
            <Separator />
            <Instruction>
              <img src={MouseLeftClick} alt='MouseLeftClick' />
              <span>{t('productView.rotate')}</span>
            </Instruction>
            <Separator />
            <Instruction>
              <img src={MouseRightClick} alt='MouseRightClick' />
              <span>{t('productView.move')}</span>
            </Instruction>
          </InstructionsContainer>
          <QRContainer>
            <span>{t('productView.seeInAR')}</span>
            <QRCode value={viewerUrl ?? ''} renderAs='svg' />
          </QRContainer>
        </ContentContainer>
      </MainContainer>
      <NextButton nextPage={RegistrationSteps.CONFIRMATION} />
    </div>
  )
}

export default ProductView
