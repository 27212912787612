import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 26px;
  padding-right: 26px;
`

export const Title = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.dark2};
  margin-top: 110px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding-right: 8px;
`

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  gap: 10%;
`

export const FieldName = styled.span`
  font-size: 16px;
  text-align: right;
  color: ${(props) => props.theme.grey10};
  width: 40%;
  max-width: 40%;
  min-width: 40%;
  align-self: flex-start;
`

export const FieldValue = styled.div`
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  height: 62px;
  span {
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => props.theme.dark2};
  }
`

export const Divider = styled.hr`
  border: 1px solid ${(props) => props.theme.grey2};
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow-x: hidden;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  :hover {
    text-decoration-line: underline;
  }
`

export const LinkIcon = styled.img`
  width: 18px;
  height: 18px;
`

export const LinkText = styled.span`
  color: ${(props) => props.theme.dark2};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`
