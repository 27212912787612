import React, { useContext, useEffect } from 'react'

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import AdminOnly from '@console/common/components/AdminOnly'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { ModalProvider } from '@console/common/contexts/ModalContext'
import { UserRole } from '@console/common/services/api'

import Customers from './pages/Customers'
import Users from './pages/Users'

const Settings: React.FC = () => {
  const { path } = useRouteMatch()
  const { analytics } = useContext(AnalyticsContext)

  const { session } = useContext(AuthContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS)
  }, [analytics])

  if (session?.role === UserRole.FREELANCER) return <Redirect to='/forbidden' />

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/users`} />
      </Route>
      <Route path={`${path}/users`}>
        <ModalProvider>
          <Users />
        </ModalProvider>
      </Route>
      <AdminOnly path={`${path}/customers`}>
        <ModalProvider>
          <Customers />
        </ModalProvider>
      </AdminOnly>
    </Switch>
  )
}

export default Settings
