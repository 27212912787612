import React from 'react'

import { useTranslation } from 'react-i18next'

import RequestImage from '@console/common/assets/illustrations/personIdea.svg'
import UploadImage from '@console/common/assets/illustrations/personUsingPC.svg'

import NextButton from '../../../components/NextButton'
import { RegistrationSteps } from '../../../contexts/ProcessContext'
import { MainContainer, ContentContainer } from './styles'

const ModelConversions: React.FC = () => {
  const { t } = useTranslation(['registration'])

  return (
    <div>
      <MainContainer>
        <ContentContainer>
          <span>{t('modelSpecifications.have3dModels')}</span>
          <img src={UploadImage} alt={t('modelSpecifications.upload')} />
        </ContentContainer>
        <ContentContainer>
          <span>{t('modelSpecifications.request3d')}</span>
          <img src={RequestImage} alt={t('modelSpecifications.finish')} />
        </ContentContainer>
      </MainContainer>
      <NextButton nextPage={RegistrationSteps.PRODUCT_VIEW} />
    </div>
  )
}

export default ModelConversions
