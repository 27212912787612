import React, { useContext } from 'react'

import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

// import ShareIcon from '../../assets/icons/ShareIcon.svg'
import { VersionContext } from '../../contexts/VersionContext'
import { Container, LargeButton, QRCodeContainer } from './styles'
// import { Icon } from './styles'

const ARBar: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { glb, usdz, viewerUrl } = useContext(VersionContext)

  return (
    <Container>
      <p>{t('ar.open')} </p>
      {viewerUrl ? (
        <>
          {!glb ? <span className='alert'>{t('ar.androidUnavailable')}</span> : null}
          {!usdz ? <span className='alert'>{t('ar.iOSUnavailable')}</span> : null}
          <QRCodeContainer>
            <QRCode value={viewerUrl} renderAs='svg' />
          </QRCodeContainer>
          {/* <LargeButton>
            <Icon src={ShareIcon} /> {t('share')}
          </LargeButton> */}
        </>
      ) : (
        <LargeButton> {t('ar.unavailable')} </LargeButton>
      )}
    </Container>
  )
}

export default ARBar
