import styled from 'styled-components'

interface Props {
  read: boolean
}

export const Container = styled.div<Props>`
  width: 100%;
  height: 42px;
  border: 2px solid ${(props) => props.theme.grey2};
  border-radius: 8px;
  color: ${(props) => (props.read ? props.theme.grey2 : props.theme.dark)};
  background-color: ${(props) => (props.read ? props.theme.grey4 : 'white')};
  margin-bottom: 8px;
  ${(props) => (props.read ? '' : 'cursor: pointer;')}

  display: grid;
  grid-template-columns: 25% 1px auto 1px 15%;
  align-items: center;

  .content {
    margin-left: 15px;
  }

  .date {
    margin: auto;
  }

  :hover {
    ${(props) => (props.read ? '' : `border-color: ${props.theme.blue};`)}
  }
`

export const VerticalRule = styled.hr`
  background-color: ${(props) => props.theme.grey4};
  height: 34px;
  margin-top: auto;
  margin-bottom: auto;
  width: 1px;
  border: 0px;
`

export const TitleContainer = styled.div`
  display: flex;

  img {
    margin-left: 15px;
    margin-right: 15px;
  }
`
