import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect
} from 'react'

import { ModelViewerElement } from '@r2u/react-ar-components'
import Viewer from '@r2u/viewer'
import { Line } from 'rc-progress'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import EmptyIllustration from '@console/common/assets/illustrations/personUsingPC.svg'
import Header from '@console/common/components/Header'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole, getModelPresignedUrl, confirmModelUpload } from '@console/common/services/api'
import { uploadToUrl } from '@console/common/services/upload'
import theme from '@console/common/theme.json'

import { ConfigurationContext } from '../../contexts/ConfigurationContext'
import { ProductContext } from '../../contexts/ProductContext'
import { RightBarOptionsContext, BarOptions } from '../../contexts/RightBarOptionsContext'
import { VersionContext } from '../../contexts/VersionContext'
import flatSlotsMaterials from '../../utils/flatSlotsMaterials'
import NeighboursArrow from '../NeighboursArrow'
import ProductName from '../ProductName'
import RightBar from '../RightBar'
import ViewerButton from '../ViewerButton'
import {
  Container,
  ViewerContainer,
  ProductInfoBar,
  ButtonsContainer,
  ModelViewerContainer,
  UploadImage,
  UploadContainer,
  UploadProgressContainer,
  UploadContainerEmpty,
  UploadButtonContainer,
  UploadButton
} from './styles'

const isIOS = (): boolean => /iPhone|iPad|iPod/i.test(navigator.userAgent)
const isAndroid = (): boolean =>
  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const CenterArea: React.FC = () => {
  const { t } = useTranslation(['review'])
  const history = useHistory()

  const { rightBarOptions } = useContext(RightBarOptionsContext)
  const { loading, product } = useContext(ProductContext)
  const { assets, glb, viewerUrl, setGlb, showUpload } = useContext(VersionContext)
  const { session } = useContext(AuthContext)
  const [rightBarExpanded, setRightBarExpanded] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [showViewer, setShowViewer] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const ref = useRef<ModelViewerElement>(null)

  const { viewerOptions, viewerRef, slotsModels, slotsMaterials } = useContext(ConfigurationContext)
  const { analytics } = useContext(AnalyticsContext)

  useLayoutEffect(() => {
    if (
      session &&
      product &&
      session.role === UserRole.CUSTOMER &&
      product?.owner !== session.customer.id
    ) {
      history.push('/review')
    }
  }, [session, product])

  useEffect(() => {
    if (ref.current) ref.current.src = glb || ''
  }, [assets])

  useEffect(() => {
    if (viewerUrl && (isIOS() || isAndroid())) window.location.href = viewerUrl
  }, [viewerUrl])

  useEffect(() => {
    setRightBarExpanded(!!rightBarOptions)
  }, [rightBarOptions])

  useEffect(() => {
    setShowViewer(!!(!showUpload && glb))
  }, [glb, showUpload])

  const handlePercentage = (value: number) => setPercentage(Math.round(value))

  const onDropModel = useCallback(
    (acceptedFiles) => {
      if (!product) return
      setUploading(true)
      analytics?.track(AnalyticsEvent.PORTFOLIO_DETAIL_UPLOAD_MODEL, { productId: product.id })

      acceptedFiles.forEach(async (file: File) => {
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        const uploadRequest = await getModelPresignedUrl(product.id, extension)
        if (!uploadRequest) return
        await uploadToUrl(
          file,
          uploadRequest.uploadUrl,
          async () => {
            await confirmModelUpload(uploadRequest.assetId, uploadRequest.viewId)
            if (setGlb && extension === 'glb') setGlb(uploadRequest.outputUrl)
            setUploading(false)
            setShowViewer(true)

            // Force reload to get new version from API
            setTimeout(() => {
              // eslint-disable-next-line no-restricted-globals
              location.reload()
            }, 2000)
          },
          handlePercentage
        )
      })
    },
    [product]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: onDropModel,
    accept: undefined,
    noClick: false,
    noKeyboard: true,
    noDragEventsBubbling: true
  })
  const baseStyle = {
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.lightBlue2,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .15s ease-in-out'
  }

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <Container expanded={rightBarExpanded}>
      <ViewerContainer>
        <Header
          title={t('title')}
          returnPage={session && session?.role !== UserRole.FREELANCER ? '/review' : undefined}
        />
        <ProductInfoBar>
          {session && session?.role !== UserRole.FREELANCER ? (
            <NeighboursArrow type='prev' />
          ) : (
            <div />
          )}
          <ProductName />
          {session && session?.role !== UserRole.FREELANCER ? (
            <NeighboursArrow type='next' />
          ) : (
            <div />
          )}
        </ProductInfoBar>
        <ModelViewerContainer isLoading={loading}>
          {loading ? null : showViewer && glb ? (
            <Viewer ref={viewerRef} glbSrc={glb} {...viewerOptions} />
          ) : (
            <>
              {/* <p> {t('notFound.assetUnavailable')} </p> */}
              <UploadImage src={EmptyIllustration} />
              <UploadContainer {...getRootProps({ style })}>
                {uploading ? (
                  <>
                    <UploadProgressContainer>
                      {/* eslint-disable-next-line react/jsx-no-literals */}
                      <span>{`${percentage}%`}</span>
                      <Line
                        percent={percentage}
                        strokeWidth={2}
                        trailWidth={2}
                        strokeColor={theme.blue}
                        trailColor={theme.grey9}
                      />
                    </UploadProgressContainer>
                  </>
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <UploadContainerEmpty>
                      <UploadButtonContainer>
                        <span>{t('upload.dragNDrop')}</span>
                        <UploadButton>
                          <span>{t('upload.select')}</span>
                        </UploadButton>
                      </UploadButtonContainer>
                      <span>{t('upload.waitForRevision')}</span>
                    </UploadContainerEmpty>
                  </>
                )}
              </UploadContainer>
            </>
          )}
        </ModelViewerContainer>
        <ButtonsContainer>
          <ViewerButton type={BarOptions.COMMENTS} />
          <ViewerButton type={BarOptions.CONFIGURATION} />
          {slotsModels?.slots.length || flatSlotsMaterials(slotsMaterials).length ? (
            <ViewerButton type={BarOptions.CUSTOMIZATION} />
          ) : null}
          <ViewerButton type={BarOptions.AR} />
          <ViewerButton type={BarOptions.REFERENCE} />
          <ViewerButton type={BarOptions.INFO} />
        </ButtonsContainer>
      </ViewerContainer>
      {rightBarExpanded ? <RightBar /> : null}
    </Container>
  )
}

export default CenterArea
