import React, { useContext } from 'react'

import { Redirect } from 'react-router-dom'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { ModalProvider } from '@console/common/contexts/ModalContext'

import R2UConsole from '../../assets/logo.png'
import Lock from '../../auth/Lock'
import TermsAndConditions from '../../auth/TermsAndConditions'
import { Container } from './styles'

interface Props {
  signUp?: boolean
}

const Login: React.FC<Props> = ({ signUp = false }: Props) => {
  const { isSigned, session } = useContext(AuthContext)

  if (isSigned && session) return <Redirect to='/' />

  return (
    <Container>
      <ModalProvider>
        <img src={R2UConsole} alt='logo' />
        <TermsAndConditions />
        <Lock signUp={signUp} />
      </ModalProvider>
    </Container>
  )
}
export default Login
