import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import DoneIcon from '@console/common/assets/done.svg'
import ErrorIcon from '@console/common/assets/warningBlack.svg'
import { ToastContext } from '@console/common/contexts/ToastContext'
import { patchProductStatus } from '@console/common/services/api'

import { ProductsContext } from './ProductsContext'

interface Props {
  children: ReactNode
}

interface CheckboxContextData {
  approveReviews: () => Promise<void>
  checkedStatus: { id: string; checked: boolean }[]
  handleChange: (id: string) => void
  isAnyChecked: boolean
  loading: boolean
  selectAllStatus: boolean
  toggleSelectAll: () => void
}

export const CheckboxContext = createContext<CheckboxContextData>({} as CheckboxContextData)

export const CheckboxProvider: React.FC<Props> = ({ children }: Props) => {
  const { products, fetchAndSetProducts } = useContext(ProductsContext)
  const { openToast } = useContext(ToastContext)

  const { t } = useTranslation(['review'])

  const [checkedStatus, setCheckedStatus] = useState<{ id: string; checked: boolean }[]>([])
  const [loading, setLoading] = useState(false)
  const [selectAllStatus, setSelectAllStatus] = useState(false)

  useEffect(() => {
    setCheckedStatus(products.map((product) => ({ id: product.id, checked: false })))
  }, [products])

  // update checkbox state (checked or not)
  const handleChange = (productId: string) => {
    const updatedCheckedStatus = checkedStatus.map((cardCheckedStatus) =>
      cardCheckedStatus.id === productId
        ? { id: cardCheckedStatus.id, checked: !cardCheckedStatus.checked }
        : { id: cardCheckedStatus.id, checked: cardCheckedStatus.checked }
    )
    setCheckedStatus(updatedCheckedStatus)
  }

  // find if there is at least one checkbox checked (to display other checkboxes)
  const isAnyChecked = !!checkedStatus.find((cardCheckedStatus) => cardCheckedStatus.checked)

  // select all checkbox
  const toggleSelectAll = () => {
    setSelectAllStatus(!selectAllStatus)
  }

  useEffect(() => {
    const updatedAllCheckedStatus = checkedStatus.map((cardCheckedStatus) => ({
      id: cardCheckedStatus.id,
      checked: selectAllStatus
    }))
    setCheckedStatus(updatedAllCheckedStatus)
  }, [selectAllStatus])

  // button approve reviews
  const approveReviews = async () => {
    setLoading(true)

    const checkedProducts = checkedStatus.filter((checkedProduct) => checkedProduct.checked)

    const checkedProductIds = checkedProducts.map((checkedProduct) => checkedProduct.id)

    const response = await patchProductStatus(checkedProductIds, 'CUSTOMER_APPROVED')

    setLoading(false)
    fetchAndSetProducts(true)

    // show snackbar according to response
    if (response) {
      openToast({
        color: 'green2',
        text: `${checkedProductIds.length} ${t('success')}`,
        filter:
          'invert(43%) sepia(37%) saturate(5736%) hue-rotate(132deg) brightness(93%) contrast(101%)',
        icon: DoneIcon
      })
    } else {
      openToast({
        color: 'red5',
        text: `${t('error')}`,
        filter:
          'invert(13%) sepia(71%) saturate(4069%) hue-rotate(357deg) brightness(94%) contrast(87%)',
        icon: ErrorIcon
      })
    }
  }

  return (
    <CheckboxContext.Provider
      value={{
        approveReviews,
        checkedStatus,
        handleChange,
        isAnyChecked,
        loading,
        selectAllStatus,
        toggleSelectAll
      }}
    >
      {children}
    </CheckboxContext.Provider>
  )
}
