export type Rule = 'REQUIRED' | 'DUPLICATE'

interface Product {
  id: string
  column: string
  value?: string
  description: string
}

interface Error {
  column?: string
  id?: string
  description: string
}

export interface Props {
  product: Product
  findError: (column: string, id: string) => boolean
  array: Error[]
}

export const validate = ({
  findError,
  product: { id, value, column, description },
  array
}: Props): Error[] => {
  const result = handleLength(value)
  const found = findError(column, id)
  if (!result && !found) {
    array.push({ id, column, description })
  } else if (result && found) {
    const index = array.findIndex((e) => e.id === id && e.column === column)
    array.splice(index, 1)
  }
  return array
}

const handleLength = (word?: string) => word?.length !== 0
