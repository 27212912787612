import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.grey5};
  border: 1px solid ${(props) => props.theme.lightBlue2};
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;

  padding: 40px;
  width: 35%;
  height: 559px;

  img {
    height: 300px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }
`
