import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.grey5};
  border: 1px solid ${(props) => props.theme.lightBlue2};
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;

  padding: 40px;
  width: 35%;
  height: 559px;

  img {
    height: 300px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }
`
export const UploadContainer = styled.div`
  text-align: center;
  height: 474px;
  width: 100%;
  border: 2px dashed ${(props) => props.theme.darkBlue4};
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey5};
`

export const CarouselContainer = styled.div`
  margin-top: 70px;
`

export const UploadContainerEmpty = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    height: 75px;
    // props.theme.blue
    filter: invert(42%) sepia(95%) saturate(1461%) hue-rotate(167deg) brightness(91%) contrast(102%);
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: ${(props) => props.theme.blue};
  }
`

export const UploadButton = styled.div`
  display: flex;
  text-align: center;
  border: 2px solid ${(props) => props.theme.blue};
  box-sizing: border-box;
  border-radius: 8px;
  height: 58px;
  width: 30%;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.blue};
  }
`
