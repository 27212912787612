import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import CenterArea from '../../components/CenterArea'
import { ConfigurationProvider } from '../../contexts/ConfigurationContext'
import { ProductProvider } from '../../contexts/ProductContext'
import { RightBarOptionsProvider } from '../../contexts/RightBarOptionsContext'
import { VersionProvider } from '../../contexts/VersionContext'
import { MainContainer } from './styles'

interface ParamTypes {
  id: string
  productId: string
}

const Reviewer: React.FC = () => {
  const params = useParams<ParamTypes>()
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.REVIEW_DETAIL, { productId: params.productId })
  }, [analytics])

  return (
    <MainContainer>
      <ProductProvider id={params.productId}>
        <ConfigurationProvider>
          <RightBarOptionsProvider>
            <VersionProvider>
              <CenterArea />
            </VersionProvider>
          </RightBarOptionsProvider>
        </ConfigurationProvider>
      </ProductProvider>
    </MainContainer>
  )
}

export default Reviewer
