import React, { Suspense } from 'react'

import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { AnalyticsProvider } from '@console/common/contexts/AnalyticsContext'
import { AuthProvider } from '@console/common/contexts/AuthContext'
import { ExpandBarProvider } from '@console/common/contexts/ExpandBarContext'
import { ToastProvider } from '@console/common/contexts/ToastContext'
import theme from '@console/common/theme.json'

import i18n from './config/i18n'
import GlobalStyle from './globalStyles'
import Routes from './routes'

const App: React.FC = () => (
  <AnalyticsProvider>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<span />}>
        <AuthProvider>
          <BrowserRouter>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
              <ExpandBarProvider>
                <ToastProvider>
                  <Routes />
                </ToastProvider>
              </ExpandBarProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </Suspense>
    </I18nextProvider>
  </AnalyticsProvider>
)

export default App
