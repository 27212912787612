import styled, { keyframes, css } from 'styled-components'

export const VerticalMenu = styled.div`
  z-index: 10;
  position: absolute;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.grey7};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 12px 10px;
  padding: 0 8px;

  div {
    &:not(:first-of-type) {
      border-top: 1px solid ${(props) => props.theme.grey9};
    }

    img {
      padding: 12px 4px;
      // props.theme.grey9
      filter: invert(94%) sepia(0%) saturate(2612%) hue-rotate(227deg) brightness(80%)
        contrast(106%);
      width: 24px;
      height: 24px;

      &:hover {
        // props.theme.lightBlue2
        filter: invert(40%) sepia(61%) saturate(2088%) hue-rotate(170deg) brightness(95%)
          contrast(101%);
        cursor: pointer;
        transition: 0.2s;
        cursor: pointer;
      }

      &.activePopupMenu {
        // props.theme.darkBlue4
        filter: invert(18%) sepia(13%) saturate(6313%) hue-rotate(166deg) brightness(97%)
          contrast(105%) !important;
      }
    }
  }
`

export const grow = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

interface PopupContainerProps {
  popupAnimation: 'open' | 'close' | 'none'
}

export const PopupContainer = styled.div<PopupContainerProps>`
  position: absolute;
  z-index: 10;
  margin: 12px 70px;
  padding: 0px 40px 25px 40px;
  background: ${(props) => props.theme.grey7};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  transform-origin: top left;

  ${({ popupAnimation }) =>
    popupAnimation === 'none'
      ? null
      : css`
          animation: ${grow} 0.2s linear forwards ${popupAnimation === 'open' ? '' : 'reverse'};
        `}

  .close {
    width: 13px;
    height: auto;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
      // props.theme.lightBlue2
      filter: invert(40%) sepia(61%) saturate(2088%) hue-rotate(170deg) brightness(95%)
        contrast(101%);
      cursor: pointer;
      transition: 0.2s;
      cursor: pointer;
    }
  }
`

export const QRCodeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: 500;

  span {
    max-width: 250px;
    margin-bottom: 24px;
  }
`

export const SceneConfigurationContainer = styled.div`
  .carouselOuter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .carousel {
    width: ${(3 * 520) / 5}px;
    overflow: hidden;
  }

  .carouselInner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: 0.2s;
  }

  .carouselItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 17px 0 17px;

    button {
      background: transparent;
      padding: 0;
      border: none;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.3);
      }

      &.selected {
        transform: scale(1.3);
      }

      img {
        height: 70px;
        width: 70px;
      }
    }

    span {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }

  .dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .dot {
      margin: 14px 8px 0 8px;
      width: 8px;
      height: 8px;
      padding: 0;
      border: none;
      border-radius: 50%;
      transition: 0.2s;
      background-color: ${(props) => props.theme.grey9};

      &.active {
        background-color: ${(props) => props.theme.darkBlue4};
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
`

interface ArrowProps {
  active?: boolean
  right?: boolean
}

export const Arrow = styled.img<ArrowProps>`
  height: 15px;
  width: auto;
  transition: 0.2s;

  ${({ right }) =>
    right
      ? css`
          right: 0;
          transform: rotate(180deg);
        `
      : null}

  ${({ active, right }) =>
    active
      ? css`
          // props.theme.darkBlue4
          filter: invert(18%) sepia(29%) saturate(2794%) hue-rotate(166deg) brightness(96%)
            contrast(102%);

          &:hover {
            cursor: pointer;
            ${right
              ? css`
                  transform: translateX(4px) rotate(180deg);
                `
              : css`
                  transform: translateX(-4px);
                `}
          }
        `
      : css`
          // props.theme.grey9
          filter: invert(88%) sepia(0%) saturate(17%) hue-rotate(176deg) brightness(96%)
            contrast(107%);
        `}
`

export const EmbedPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
`

export const EmbedPopupCopyContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  width: 20em;
  background: ${(props) => props.theme.grey5};
  border: 2px solid ${(props) => props.theme.grey9};
  box-sizing: border-box;
  border-radius: 8px;
`

export const EmbedPopupCode = styled.input`
  margin: 0;
  padding: 11px 8px 11px 8px;
  width: 100%;
  align-self: flex-start;
  font-family: 'Roboto Mono', monospace;
  font-weight: 525;
  font-size: 14px;
  text-align: justify;
  overflow: auto;
  color: ${(props) => props.theme.blue};
  border: none;
  background: none;

  &::-webkit-scrollbar {
    display: none;
  }

  :focus {
    outline: none;
  }

  ::selection {
    color: ${(props) => props.theme.darkBlue4};
    background-color: ${(props) => props.theme.lightBlue2};
  }
`

export const EmbedPopupCopyButton = styled.button`
  align-self: flex-start;
  padding: 8px 18px 8px 14px;
  margin: -1px;
  border: none;
  background: none;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  :hover {
    background: ${(props) => props.theme.lightBlue2};
    cursor: pointer;

    img {
      filter: invert(18%) sepia(13%) saturate(6313%) hue-rotate(166deg) brightness(97%)
        contrast(105%) !important;
    }
  }
`

export const EmbedPopupCopyImage = styled.img`
  margin: 0;
  width: 20px;

  :not(:hover) {
    filter: invert(40%) sepia(61%) saturate(2088%) hue-rotate(170deg) brightness(95%) contrast(101%);
  }
`

export const DividerItemHr = styled.hr`
  margin: 24px 0 0 0;
  padding: 0;
  border: none;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.grey9};
`

export const EmbedDocumentationContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 5px 12px 5px 5px;
  border-radius: 8px;
  cursor: pointer;

  img {
    margin: 0;
    padding: 5px;
    width: 24px;
  }

  :hover {
    background: ${(props) => props.theme.grey5};
  }
`

export const EmbedDocumentationLink = styled.a`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: initial;
  font-size: 14px;
`

export const HelpContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => props.theme.dark2};
  }

  .instruction {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-column-gap: 10px;
    align-items: center;
    padding: 15px 0;

    &:first-of-type {
      padding-top: 25px;
    }

    &:not(:first-of-type) {
      border-top: 2px solid ${(props) => props.theme.grey9};
    }

    img {
      justify-self: end;
      height: 42px;
      width: auto;
    }

    span {
      font-weight: 700;
      font-size: 18px;
      color: ${(props) => props.theme.dark2};
    }
  }
`
