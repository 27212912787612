import React from 'react'

import Carousel, { ArrowProps, ResponsiveType } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import ArrowIcon from '@console/common/assets/arrowLeft.svg'

import { GalleryContainer, ArrowLeft, ArrowRight } from './styles'

interface Props {
  slides: React.ReactNode[]
  responsive?: ResponsiveType
  isLoading?: boolean
}

const MultiCarousel: React.FC<Props> = ({ slides, responsive, isLoading }: Props) => {
  const responsiveDefault = {
    six: {
      breakpoint: { max: 3000, min: 1900 },
      items: 6,
      slidesToSlide: 1
    },
    five: {
      breakpoint: { max: 1900, min: 1600 },
      items: 5,
      slidesToSlide: 1
    },
    four: {
      breakpoint: { max: 1600, min: 1350 },
      items: 4,
      slidesToSlide: 1
    },
    three: {
      breakpoint: { max: 1350, min: 1100 },
      items: 3,
      slidesToSlide: 1
    },
    two: {
      breakpoint: { max: 1100, min: 500 },
      items: 2,
      slidesToSlide: 1
    }
  }

  const CustomLeftArrow = ({ onClick, carouselState }: ArrowProps) => (
    <>
      {isLoading ? null : (
        <ArrowLeft
          src={ArrowIcon}
          onClick={() => {
            if (onClick) onClick()
          }}
        />
      )}
    </>
  )

  const CustomRightArrow = ({ onClick, carouselState }: ArrowProps) => (
    <>
      {isLoading ? null : (
        <ArrowRight
          src={ArrowIcon}
          onClick={() => {
            if (onClick) onClick()
          }}
        />
      )}
    </>
  )

  return (
    <GalleryContainer>
      {slides ? (
        <Carousel
          responsive={responsive || responsiveDefault}
          swipeable={false}
          draggable={false}
          showDots={false}
          infinite={false}
          autoPlay={false}
          keyBoardControl={false}
          itemClass='carousel-item'
          containerClass='carousel-container'
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {slides}
        </Carousel>
      ) : null}
    </GalleryContainer>
  )
}

export default MultiCarousel
