import styled from 'styled-components'

export const GalleryContainer = styled.div`
  text-align: center;
  margin: 27px;

  .carousel-item {
    justify-self: center;
    align-self: center;
    text-align: center;
  }
`

export const ArrowLeft = styled.img`
  height: 30px;
  cursor: pointer;
  position: absolute;
`

export const ArrowRight = styled.img`
  height: 30px;
  transform: rotate(180deg);
  cursor: pointer;
  position: absolute;
  right: 0;
`
