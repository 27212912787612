import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 18px;
    color: ${(props) => props.theme.dark2};
    text-align: center;
    margin-top: 55px;
    margin-bottom: 10px;
  }
`

export const CollapsibleContainer = styled.div`
  width: 100%;

  box-shadow: 0px 0px 4px 1px rgba(86, 86, 86, 0.25);

  button.toggle {
    height: 48px;
    width: 100%;

    outline: none;
    border: none;
    border-bottom: 1px solid #dee2e6;
    background-color: #ebf1f3;

    display: grid;
    grid-template-columns: 6fr 1fr;
    align-items: center;
    justify-items: start;

    font-size: 1em;
    font-weight: bold;

    cursor: pointer;

    & > *:first-child {
      margin-left: 20px;
    }

    .collapsible-arrow {
      width: 35px;
      height: 35px;

      transition: 0.2s linear;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .content {
    padding: 0 10px;

    overflow: hidden;
    transition: 0.2s linear;
  }
`

export const Menu = styled.div`
  padding: 20px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  align-items: center;
  justify-items: center;

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;
    width: 120px;
    border-radius: 8px;

    box-shadow: -2px -2px 4px #ffffff, 2px 2px 4px rgba(86, 86, 86, 0.25);

    cursor: pointer;
  }

  input {
    display: none;

    &:checked + label {
      box-shadow: inset -2px -2px 4px #ffffff, inset 2px 2px 4px rgba(86, 86, 86, 0.25);

      background-color: rgba(86, 86, 86, 0.1);
    }
  }
`
