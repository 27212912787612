import React, { createContext, ReactNode, useState } from 'react'

interface Props {
  children: ReactNode
}

export interface ToastProps {
  color?: string
  text: string
  filter?: string
  icon: string
}

interface ToastContextData {
  isOpen: boolean
  openToast: (props: ToastProps) => void
  closeToast: () => void
  toastProps?: ToastProps
}

export const ToastContext = createContext<ToastContextData>({} as ToastContextData)

export const ToastProvider: React.FC<Props> = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [toastProps, setToastProps] = useState<ToastProps>()

  const openToast = (props: ToastProps) => {
    setIsOpen(true)
    setToastProps(props)
  }

  const closeToast = () => {
    setIsOpen(false)
    setToastProps(undefined)
  }

  return (
    <ToastContext.Provider
      value={{
        isOpen,
        openToast,
        closeToast,
        toastProps
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}
