import React, { useContext, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import DoneIcon from '@console/common/assets/done.svg'
import hdris from '@console/common/components/Hdris'
import Saving from '@console/common/components/Saving'
import { ToastContext } from '@console/common/contexts/ToastContext'
import { ViewerOptions } from '@console/common/services/api'
import Attention from '@console/reviewer/assets/icons/attention.svg'

import { ConfigurationContext } from '../../contexts/ConfigurationContext'
import { ProductContext } from '../../contexts/ProductContext'
import Collapsible from './Collapsible'
import ColorContainer from './ColorContainer'
import InputRange from './InputRange'
import {
  MenuContainer,
  CheckboxContainer,
  CollapsibleContainer,
  ConfigurationBox,
  Container,
  InputContainer,
  SaveButtonContainer
} from './styles'

const isValid = (value: unknown): boolean => value !== undefined && value !== null

function getValidValue<T>(option1: T | undefined, option2: T): T {
  if (option1 !== undefined && option1 !== null) return option1
  return option2
}

const ConfigurationBar: React.FC = () => {
  const { t } = useTranslation('components/configuration-bar')
  const [saving, setSaving] = useState(false)
  const [oldViewerOptions, setOldViewerOptions] = useState<ViewerOptions>({} as ViewerOptions)

  const { product } = useContext(ProductContext)
  const { openToast, closeToast } = useContext(ToastContext)

  const {
    viewerOptions,
    loading,
    setViewerOptionsPartial,
    updateViewer,
    saveViewerOptions,
    propsViewerOptions
  } = useContext(ConfigurationContext)

  // Checkbox options for use default
  const [disableOptions, setDisableOptions] = useState({
    backgroundColor: true,
    maxZoom: true,
    minZoom: true,
    initialZoom: true,
    maxAzimuth: false,
    minAzimuth: false,
    maxPolar: true,
    minPolar: true,
    maxDragX: true,
    minDragX: true,
    maxDragY: true,
    minDragY: true,
    maxDragZ: true,
    minDragZ: true
  })

  const [resetOptions, setResetOptions] = useState({
    environment: false,
    background: false,
    fogEffect: false,
    shadow: false,
    camera: false,
    zoom: false,
    orbit: false,
    displacement: false,
    animation: false,
    bloom: false
  })

  const defaultViewerOptions = {
    exposure: 1,
    hdri: 'neutral',
    sceneBackground: false,
    backgroundColor: '',
    backgroundImage: '',
    activeFog: false,
    fogColor: '#ffffff',
    fogNear: 1,
    fogFar: 15,
    activeShadow: true,
    shadowOpacity: 0.1,
    shadowOffsetX: 0,
    shadowOffsetY: 10,
    shadowOffsetZ: 0,
    shadowRadius: 1,
    cameraOrbitSensitivity: 2,
    cameraNear: 0.1,
    cameraFar: 100,
    maxZoom: 2,
    minZoom: 10,
    initialZoom: 8,
    minAzimuthalAngleRotation: -60,
    maxAzimuthalAngleRotation: 60,
    minPolarAngleRotation: -360,
    maxPolarAngleRotation: 360,
    maxDragX: 10,
    minDragX: -10,
    maxDragY: 10,
    minDragY: -10,
    maxDragZ: 10,
    minDragZ: -10,
    initialAnimation: true,
    bloom: false,
    initialBloom: true,
    bloomIntensity: 3,
    bloomRadius: 0,
    bloomThreshold: 0.21,
    enableBloomButton: true
  }

  // Output values for the sliders, needs to be outside for the reset buttons
  const [outputBackgroundColor, setOutputBackgroundColor] = useState(
    getValidValue(viewerOptions.backgroundColor, defaultViewerOptions.backgroundColor)
  )
  const [outputFogColor, setOutputFogColor] = useState(
    getValidValue(viewerOptions.fogColor, defaultViewerOptions.fogColor)
  )

  const getZoomRange = (value: number): number => -4 * value + 100

  const getZoomValue = (range: number): number => 25 - (range / 100) * 25 || 0.1

  const isViewerOptionsEquals = (a: ViewerOptions, b: ViewerOptions): boolean => {
    if (!isValid(a) || !isValid(b)) return false
    if (Object.keys(a).length !== Object.keys(b).length) return false

    let isEqual = true

    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    keysA.forEach((keyA) => {
      if (!keysB.includes(keyA)) isEqual = false
    })

    const objectA = Object.entries(a)
    const objectB = Object.entries(b)

    objectA.forEach(([keyA, valueA]) => {
      objectB.forEach(([keyB, valueB]) => {
        if (keyA === keyB && valueA !== valueB) isEqual = false
      })
    })

    return isEqual
  }

  useEffect(() => {
    setOldViewerOptions(viewerOptions)

    const initialDisableOptions = {
      backgroundColor: true,
      maxZoom: true,
      minZoom: true,
      initialZoom: true,
      maxAzimuth: true,
      minAzimuth: true,
      maxPolar: true,
      minPolar: true,
      maxDragX: true,
      minDragX: true,
      maxDragY: true,
      minDragY: true,
      maxDragZ: true,
      minDragZ: true
    }

    const initialResetOptions = {
      environment: false,
      background: false,
      fogEffect: false,
      shadow: false,
      camera: false,
      zoom: false,
      orbit: false,
      displacement: false,
      animation: false,
      bloom: false
    }

    if (viewerOptions.backgroundColor !== '') {
      initialDisableOptions.backgroundColor = false
    }
    if (isValid(viewerOptions.maxZoom)) {
      initialDisableOptions.maxZoom = false
    }
    if (isValid(viewerOptions.minZoom)) {
      initialDisableOptions.minZoom = false
    }
    if (isValid(viewerOptions.initialZoom)) {
      initialDisableOptions.initialZoom = false
    }
    if (isValid(viewerOptions.maxAzimuthalAngleRotation)) {
      initialDisableOptions.maxAzimuth = false
    }
    if (isValid(viewerOptions.minAzimuthalAngleRotation)) {
      initialDisableOptions.minAzimuth = false
    }
    if (isValid(viewerOptions.maxPolarAngleRotation)) {
      initialDisableOptions.maxPolar = false
    }
    if (isValid(viewerOptions.minPolarAngleRotation)) {
      initialDisableOptions.minPolar = false
    }
    if (isValid(viewerOptions.maxDragX)) {
      initialDisableOptions.maxDragX = false
    }
    if (isValid(viewerOptions.minDragX)) {
      initialDisableOptions.minDragX = false
    }
    if (isValid(viewerOptions.maxDragY)) {
      initialDisableOptions.maxDragY = false
    }
    if (isValid(viewerOptions.minDragY)) {
      initialDisableOptions.minDragY = false
    }
    if (isValid(viewerOptions.maxDragZ)) {
      initialDisableOptions.maxDragZ = false
    }
    if (isValid(viewerOptions.minDragZ)) {
      initialDisableOptions.minDragZ = false
    }

    if (
      viewerOptions.exposure !== defaultViewerOptions.exposure &&
      isValid(viewerOptions.exposure)
    ) {
      initialResetOptions.environment = true
    }
    if (viewerOptions.hdri !== defaultViewerOptions.hdri && isValid(viewerOptions.hdri)) {
      initialResetOptions.environment = true
    }
    if (
      viewerOptions.sceneBackground !== defaultViewerOptions.sceneBackground &&
      isValid(viewerOptions.sceneBackground)
    ) {
      initialResetOptions.background = true
    }
    if (
      viewerOptions.backgroundColor !== defaultViewerOptions.backgroundColor &&
      isValid(viewerOptions.backgroundColor)
    ) {
      initialResetOptions.background = true
    }
    if (
      viewerOptions.backgroundImage !== defaultViewerOptions.backgroundImage &&
      isValid(viewerOptions.backgroundImage)
    ) {
      initialResetOptions.background = true
    }
    if (
      viewerOptions.activeFog !== defaultViewerOptions.activeFog &&
      isValid(viewerOptions.activeFog)
    ) {
      initialResetOptions.fogEffect = true
    }
    if (
      viewerOptions.fogColor !== defaultViewerOptions.fogColor &&
      isValid(viewerOptions.fogColor)
    ) {
      initialResetOptions.fogEffect = true
    }
    if (viewerOptions.fogNear !== defaultViewerOptions.fogNear && isValid(viewerOptions.fogNear)) {
      initialResetOptions.fogEffect = true
    }
    if (viewerOptions.fogFar !== defaultViewerOptions.fogFar && isValid(viewerOptions.fogFar)) {
      initialResetOptions.fogEffect = true
    }
    if (
      viewerOptions.activeShadow !== defaultViewerOptions.activeShadow &&
      isValid(viewerOptions.activeShadow)
    ) {
      initialResetOptions.shadow = true
    }
    if (
      viewerOptions.shadowOpacity !== defaultViewerOptions.shadowOpacity &&
      isValid(viewerOptions.shadowOpacity)
    ) {
      initialResetOptions.shadow = true
    }
    if (
      viewerOptions.shadowOffsetX !== defaultViewerOptions.shadowOffsetX &&
      isValid(viewerOptions.shadowOffsetX)
    ) {
      initialResetOptions.shadow = true
    }
    if (
      viewerOptions.shadowOffsetY !== defaultViewerOptions.shadowOffsetY &&
      isValid(viewerOptions.shadowOffsetY)
    ) {
      initialResetOptions.shadow = true
    }
    if (
      viewerOptions.shadowOffsetZ !== defaultViewerOptions.shadowOffsetZ &&
      isValid(viewerOptions.shadowOffsetZ)
    ) {
      initialResetOptions.shadow = true
    }
    if (
      viewerOptions.shadowRadius !== defaultViewerOptions.shadowRadius &&
      isValid(viewerOptions.shadowRadius)
    ) {
      initialResetOptions.shadow = true
    }
    if (
      viewerOptions.cameraOrbitSensitivity !== defaultViewerOptions.cameraOrbitSensitivity &&
      isValid(viewerOptions.cameraOrbitSensitivity)
    ) {
      initialResetOptions.camera = true
    }
    if (
      viewerOptions.cameraNear !== defaultViewerOptions.cameraNear &&
      isValid(viewerOptions.cameraNear)
    ) {
      initialResetOptions.camera = true
    }
    if (
      viewerOptions.cameraFar !== defaultViewerOptions.cameraFar &&
      isValid(viewerOptions.cameraFar)
    ) {
      initialResetOptions.camera = true
    }
    if (viewerOptions.maxZoom !== defaultViewerOptions.maxZoom && isValid(viewerOptions.maxZoom)) {
      initialResetOptions.zoom = true
    }
    if (viewerOptions.minZoom !== defaultViewerOptions.minZoom && isValid(viewerOptions.minZoom)) {
      initialResetOptions.zoom = true
    }
    if (
      viewerOptions.initialZoom !== defaultViewerOptions.initialZoom &&
      isValid(viewerOptions.initialZoom)
    ) {
      initialResetOptions.zoom = true
    }
    if (
      viewerOptions.maxAzimuthalAngleRotation !== defaultViewerOptions.maxAzimuthalAngleRotation &&
      isValid(viewerOptions.maxAzimuthalAngleRotation)
    ) {
      initialResetOptions.orbit = true
    }
    if (
      viewerOptions.minAzimuthalAngleRotation !== defaultViewerOptions.minAzimuthalAngleRotation &&
      isValid(viewerOptions.minAzimuthalAngleRotation)
    ) {
      initialResetOptions.orbit = true
    }
    if (
      viewerOptions.maxPolarAngleRotation !== defaultViewerOptions.maxPolarAngleRotation &&
      isValid(viewerOptions.maxPolarAngleRotation)
    ) {
      initialResetOptions.orbit = true
    }
    if (
      viewerOptions.minPolarAngleRotation !== defaultViewerOptions.minPolarAngleRotation &&
      isValid(viewerOptions.minPolarAngleRotation)
    ) {
      initialResetOptions.orbit = true
    }
    if (
      viewerOptions.maxDragX !== defaultViewerOptions.maxDragX &&
      isValid(viewerOptions.maxDragX)
    ) {
      initialResetOptions.displacement = true
    }
    if (
      viewerOptions.minDragX !== defaultViewerOptions.minDragX &&
      isValid(viewerOptions.minDragX)
    ) {
      initialResetOptions.displacement = true
    }
    if (
      viewerOptions.maxDragY !== defaultViewerOptions.maxDragY &&
      isValid(viewerOptions.maxDragY)
    ) {
      initialResetOptions.displacement = true
    }
    if (
      viewerOptions.minDragY !== defaultViewerOptions.minDragY &&
      isValid(viewerOptions.minDragY)
    ) {
      initialResetOptions.displacement = true
    }
    if (
      viewerOptions.maxDragZ !== defaultViewerOptions.maxDragZ &&
      isValid(viewerOptions.maxDragZ)
    ) {
      initialResetOptions.displacement = true
    }
    if (
      viewerOptions.minDragZ !== defaultViewerOptions.minDragZ &&
      isValid(viewerOptions.minDragZ)
    ) {
      initialResetOptions.displacement = true
    }
    if (
      viewerOptions.initialAnimation !== defaultViewerOptions.initialAnimation &&
      isValid(viewerOptions.initialAnimation)
    ) {
      initialResetOptions.animation = true
    }
    if (
      viewerOptions.initialBloom !== defaultViewerOptions.initialBloom &&
      isValid(viewerOptions.initialBloom)
    ) {
      initialResetOptions.bloom = true
    }
    if (
      viewerOptions.bloomIntensity !== defaultViewerOptions.bloomIntensity &&
      isValid(viewerOptions.bloomIntensity)
    ) {
      initialResetOptions.bloom = true
    }
    if (
      viewerOptions.bloomRadius !== defaultViewerOptions.bloomRadius &&
      isValid(viewerOptions.bloomRadius)
    ) {
      initialResetOptions.bloom = true
    }
    if (
      viewerOptions.bloomThreshold !== defaultViewerOptions.bloomThreshold &&
      isValid(viewerOptions.bloomThreshold)
    ) {
      initialResetOptions.bloom = true
    }
    if (
      viewerOptions.enableBloomButton !== defaultViewerOptions.enableBloomButton &&
      isValid(viewerOptions.enableBloomButton)
    ) {
      initialResetOptions.bloom = true
    }

    setResetOptions(initialResetOptions)
    setDisableOptions(initialDisableOptions)
  }, [loading])

  useEffect(() => {
    if (!saving) setOldViewerOptions(viewerOptions)
  }, [saving])

  return (
    <Container>
      <MenuContainer>
        <span className='title' style={{ margin: 10 }}>
          {t('title')}
        </span>
        <CollapsibleContainer resetVisible={resetOptions.environment}>
          <Collapsible
            header={t('environmentTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, environment: false })
              setViewerOptionsPartial({
                exposure: defaultViewerOptions.exposure,
                hdri: defaultViewerOptions.hdri
              })
            }}
          >
            <ConfigurationBox>
              <span className='subTitle'>{t('exposureTitle')}</span>
              <InputRange
                min={0}
                max={2}
                step={0.01}
                value={getValidValue(viewerOptions.exposure, defaultViewerOptions.exposure)}
                onChange={(newValue) => {
                  setViewerOptionsPartial({ exposure: newValue })
                  if (newValue !== defaultViewerOptions.exposure) {
                    setResetOptions({ ...resetOptions, environment: true })
                  }
                }}
                afterChange={(newValue) =>
                  product && setViewerOptionsPartial({ exposure: newValue })
                }
              />
            </ConfigurationBox>
            <ConfigurationBox>
              <span className='subTitle'>{t('hdriTitle')}</span>
              <div className='hdris'>
                {hdris.map((hdri) => (
                  <div className='hdri'>
                    <button
                      type='button'
                      className={viewerOptions.hdri === hdri.url ? 'selected' : ''}
                      onClick={
                        product
                          ? () => {
                              setViewerOptionsPartial({ hdri: hdri.url })
                              if (hdri.url !== defaultViewerOptions.hdri) {
                                setResetOptions({ ...resetOptions, environment: true })
                              }
                            }
                          : undefined
                      }
                    >
                      <img src={hdri.thumbnail} alt={hdri.name} />
                    </button>
                    <span>{hdri.name}</span>
                  </div>
                ))}
              </div>
            </ConfigurationBox>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer resetVisible={resetOptions.background}>
          <Collapsible
            header={t('backgroundTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, background: false })
              setViewerOptionsPartial({
                sceneBackground: defaultViewerOptions.sceneBackground,
                backgroundColor: defaultViewerOptions.backgroundColor,
                backgroundImage: defaultViewerOptions.backgroundImage
              })
              setOutputBackgroundColor(defaultViewerOptions.backgroundColor)
              setDisableOptions({ ...disableOptions, backgroundColor: true })
            }}
          >
            <ConfigurationBox>
              <CheckboxContainer>
                <span className='subTitle'>{t('sceneBackgroundTitle')}</span>
                <label className='switch' htmlFor='scene-background'>
                  <input
                    id='scene-background'
                    type='checkbox'
                    checked={getValidValue(
                      viewerOptions.sceneBackground,
                      defaultViewerOptions.sceneBackground
                    )}
                    onChange={(e) => {
                      setViewerOptionsPartial({
                        sceneBackground: e.target.checked
                      })
                      if (e.target.checked !== defaultViewerOptions.sceneBackground) {
                        setResetOptions({ ...resetOptions, background: true })
                      }
                    }}
                  />
                  <span className='slider' />
                </label>
              </CheckboxContainer>
            </ConfigurationBox>
            <ConfigurationBox>
              <div
                style={
                  disableOptions.backgroundColor
                    ? { opacity: 0.5, pointerEvents: 'none' }
                    : undefined
                }
              >
                <span className='subTitle'>{t('backgroundColorTitle')}</span>
                <ColorContainer
                  value={getValidValue(
                    viewerOptions.backgroundColor,
                    defaultViewerOptions.backgroundColor
                  )}
                  v={outputBackgroundColor}
                  setV={setOutputBackgroundColor}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ backgroundColor: newValue })
                    if (newValue !== defaultViewerOptions.backgroundColor) {
                      setResetOptions({ ...resetOptions, background: true })
                    }
                  }}
                />
              </div>
              <div style={{ display: 'flex', marginTop: '8px' }}>
                <input
                  type='checkbox'
                  className='default-checkbox'
                  checked={disableOptions.backgroundColor}
                  onChange={(e) => {
                    setDisableOptions({
                      ...disableOptions,
                      backgroundColor: e.target.checked
                    })
                    if (e.target.checked !== true) {
                      setResetOptions({ ...resetOptions, background: true })
                    }

                    if (e.target.checked) setViewerOptionsPartial({ backgroundColor: '' })
                  }}
                />
                <span className='default-checkbox-span'>{t('transparentTitle')}</span>
              </div>
            </ConfigurationBox>
            <ConfigurationBox>
              <span className='subTitle'>{t('backgroundImageTitle')}</span>
              <InputContainer>
                <input
                  className='property-input'
                  type='string'
                  value={getValidValue(
                    viewerOptions.backgroundImage,
                    defaultViewerOptions.backgroundImage
                  )}
                  onChange={(e) => {
                    setViewerOptionsPartial({ backgroundImage: e.target.value })
                    if (e.target.value !== defaultViewerOptions.backgroundImage) {
                      setResetOptions({ ...resetOptions, background: true })
                    }
                  }}
                />
              </InputContainer>
            </ConfigurationBox>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer resetVisible={resetOptions.fogEffect}>
          <Collapsible
            header={t('fogTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, fogEffect: false })
              setViewerOptionsPartial({
                activeFog: defaultViewerOptions.activeFog,
                fogColor: defaultViewerOptions.fogColor,
                fogNear: defaultViewerOptions.fogNear,
                fogFar: defaultViewerOptions.fogFar
              })
              setOutputFogColor(defaultViewerOptions.fogColor)
            }}
          >
            <ConfigurationBox>
              <CheckboxContainer>
                <span className='subTitle'>{t('enableFogTitle')}</span>
                <label className='switch' htmlFor='enable-fog'>
                  <input
                    id='enable-fog'
                    type='checkbox'
                    checked={getValidValue(viewerOptions.activeFog, defaultViewerOptions.activeFog)}
                    onChange={(e) => {
                      setViewerOptionsPartial({
                        activeFog: e.target.checked
                      })
                      if (e.target.checked !== defaultViewerOptions.activeFog) {
                        setResetOptions({ ...resetOptions, fogEffect: true })
                      }
                    }}
                  />
                  <span className='slider' />
                </label>
              </CheckboxContainer>
            </ConfigurationBox>
            <div
              style={!viewerOptions.activeFog ? { opacity: 0.5, pointerEvents: 'none' } : undefined}
            >
              <ConfigurationBox>
                <span className='subTitle'>{t('fogColorTitle')}</span>
                <ColorContainer
                  value={getValidValue(viewerOptions.fogColor, defaultViewerOptions.fogColor)}
                  v={outputFogColor}
                  setV={setOutputFogColor}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ fogColor: newValue })
                    if (newValue !== defaultViewerOptions.fogColor) {
                      setResetOptions({ ...resetOptions, fogEffect: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('fogNearTitle')}</span>
                <InputRange
                  min={0.1}
                  max={20}
                  step={0.01}
                  value={
                    viewerOptions.fogNear ? viewerOptions.fogNear : defaultViewerOptions.fogNear
                  }
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ fogNear: newValue })
                    if (newValue !== defaultViewerOptions.fogNear) {
                      setResetOptions({ ...resetOptions, fogEffect: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('fogFarTitle')}</span>
                <InputRange
                  min={0.1}
                  max={20}
                  step={0.01}
                  value={viewerOptions.fogFar ? viewerOptions.fogFar : defaultViewerOptions.fogFar}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ fogFar: newValue })
                    if (newValue !== defaultViewerOptions.fogFar) {
                      setResetOptions({ ...resetOptions, fogEffect: true })
                    }
                  }}
                />
              </ConfigurationBox>
            </div>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer resetVisible={resetOptions.shadow}>
          <Collapsible
            header={t('shadowTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, shadow: false })
              setViewerOptionsPartial({
                activeShadow: defaultViewerOptions.activeShadow,
                shadowOpacity: defaultViewerOptions.shadowOpacity,
                shadowOffsetX: defaultViewerOptions.shadowOffsetX,
                shadowOffsetY: defaultViewerOptions.shadowOffsetY,
                shadowOffsetZ: defaultViewerOptions.shadowOffsetZ,
                shadowRadius: defaultViewerOptions.shadowRadius
              })
            }}
          >
            <ConfigurationBox>
              <CheckboxContainer>
                <span className='subTitle'>{t('enableShadowTitle')}</span>
                <label className='switch' htmlFor='enable-shadow'>
                  <input
                    id='enable-shadow'
                    type='checkbox'
                    checked={getValidValue(
                      viewerOptions.activeShadow,
                      defaultViewerOptions.activeShadow
                    )}
                    onChange={(e) => {
                      setViewerOptionsPartial({
                        activeShadow: e.target.checked
                      })
                      if (e.target.checked !== defaultViewerOptions.activeShadow) {
                        setResetOptions({ ...resetOptions, shadow: true })
                      }
                    }}
                  />
                  <span className='slider' />
                </label>
              </CheckboxContainer>
            </ConfigurationBox>
            <div
              style={
                viewerOptions.activeShadow !== undefined && !viewerOptions.activeShadow
                  ? { opacity: 0.5, pointerEvents: 'none' }
                  : undefined
              }
            >
              <ConfigurationBox>
                <span className='subTitle'>{t('shadowOpacityTitle')}</span>
                <InputRange
                  min={0}
                  max={1}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.shadowOpacity,
                    defaultViewerOptions.shadowOpacity
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ shadowOpacity: newValue })
                    if (newValue !== defaultViewerOptions.shadowOpacity) {
                      setResetOptions({ ...resetOptions, shadow: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('shadowOffsetXTitle')}</span>
                <InputRange
                  min={-50}
                  max={50}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.shadowOffsetX,
                    defaultViewerOptions.shadowOffsetX
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ shadowOffsetX: newValue })
                    if (newValue !== defaultViewerOptions.shadowOffsetX) {
                      setResetOptions({ ...resetOptions, shadow: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('shadowOffsetYTitle')}</span>
                <InputRange
                  min={-50}
                  max={50}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.shadowOffsetY,
                    defaultViewerOptions.shadowOffsetY
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ shadowOffsetY: newValue })
                    if (newValue !== defaultViewerOptions.shadowOffsetY) {
                      setResetOptions({ ...resetOptions, shadow: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('shadowOffsetZTitle')}</span>
                <InputRange
                  min={-50}
                  max={50}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.shadowOffsetZ,
                    defaultViewerOptions.shadowOffsetZ
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ shadowOffsetZ: newValue })
                    if (newValue !== defaultViewerOptions.shadowOffsetZ) {
                      setResetOptions({ ...resetOptions, shadow: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('shadowRadiusTitle')}</span>
                <InputRange
                  min={1}
                  max={50}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.shadowRadius,
                    defaultViewerOptions.shadowRadius
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ shadowRadius: newValue })
                    if (newValue !== defaultViewerOptions.shadowRadius) {
                      setResetOptions({ ...resetOptions, shadow: true })
                    }
                  }}
                />
              </ConfigurationBox>
            </div>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer resetVisible={resetOptions.camera}>
          <Collapsible
            header={t('cameraTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, camera: false })
              setViewerOptionsPartial({
                cameraOrbitSensitivity: defaultViewerOptions.cameraOrbitSensitivity,
                cameraNear: defaultViewerOptions.cameraNear,
                cameraFar: defaultViewerOptions.cameraFar
              })
            }}
          >
            <ConfigurationBox>
              <span className='subTitle'>{t('orbitSensitivityTitle')}</span>
              <InputRange
                min={1}
                max={10}
                step={0.01}
                value={getValidValue(
                  viewerOptions.cameraOrbitSensitivity,
                  defaultViewerOptions.cameraOrbitSensitivity
                )}
                onChange={(newValue) => {
                  setViewerOptionsPartial({ cameraOrbitSensitivity: newValue })
                  if (newValue !== defaultViewerOptions.cameraOrbitSensitivity) {
                    setResetOptions({ ...resetOptions, camera: true })
                  }
                }}
              />
            </ConfigurationBox>
            <ConfigurationBox>
              <span className='subTitle'>{t('cameraNearTitle')}</span>
              <InputRange
                min={0.1}
                max={15}
                step={0.01}
                value={getValidValue(viewerOptions.cameraNear, defaultViewerOptions.cameraNear)}
                onChange={(newValue) => {
                  setViewerOptionsPartial({ cameraNear: newValue })
                  if (newValue !== defaultViewerOptions.cameraNear) {
                    setResetOptions({ ...resetOptions, camera: true })
                  }
                }}
              />
            </ConfigurationBox>
            <ConfigurationBox>
              <span className='subTitle'>{t('cameraFarTitle')}</span>
              <InputRange
                min={0.1}
                max={100}
                step={0.01}
                value={getValidValue(viewerOptions.cameraFar, defaultViewerOptions.cameraFar)}
                onChange={(newValue) => {
                  setViewerOptionsPartial({ cameraFar: newValue })
                  if (newValue !== defaultViewerOptions.cameraFar) {
                    setResetOptions({ ...resetOptions, camera: true })
                  }
                }}
              />
            </ConfigurationBox>
            <CollapsibleContainer resetVisibleNested={resetOptions.zoom}>
              <Collapsible
                nested
                header={t('zoomTitle')}
                resetAction={() => {
                  setResetOptions({ ...resetOptions, zoom: false })
                  setViewerOptionsPartial({
                    maxZoom: defaultViewerOptions.maxZoom,
                    minZoom: defaultViewerOptions.minZoom,
                    initialZoom: defaultViewerOptions.initialZoom
                  })
                  setDisableOptions({
                    ...disableOptions,
                    maxZoom: true,
                    minZoom: true,
                    initialZoom: true
                  })
                }}
              >
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.minZoom ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('minZoomTitle')}</span>
                    <InputRange
                      min={0}
                      max={100}
                      step={0.01}
                      value={getZoomRange(
                        getValidValue(viewerOptions.minZoom, defaultViewerOptions.minZoom)
                      )}
                      maxValue={getZoomRange(
                        getValidValue(viewerOptions.maxZoom, defaultViewerOptions.maxZoom)
                      )}
                      onChange={(range) => {
                        const newValue = getZoomValue(range)

                        setViewerOptionsPartial({ minZoom: newValue })
                        if (newValue !== defaultViewerOptions.minZoom) {
                          setResetOptions({ ...resetOptions, zoom: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.minZoom}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          minZoom: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, zoom: true })
                        }
                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            minZoom: defaultViewerOptions.minZoom
                          })
                          updateViewer({
                            radius: getValidValue(
                              viewerOptions.initialZoom,
                              defaultViewerOptions.initialZoom
                            )
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('adaptModelTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.maxZoom ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('maxZoomTitle')}</span>
                    <InputRange
                      min={0}
                      max={99.9}
                      step={0.01}
                      value={getZoomRange(
                        getValidValue(viewerOptions.maxZoom, defaultViewerOptions.maxZoom)
                      )}
                      minValue={getZoomRange(
                        getValidValue(viewerOptions.minZoom, defaultViewerOptions.minZoom)
                      )}
                      onChange={(range) => {
                        const newValue = getZoomValue(range)

                        setViewerOptionsPartial({ maxZoom: newValue })
                        if (newValue !== defaultViewerOptions.maxZoom) {
                          setResetOptions({ ...resetOptions, zoom: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.maxZoom}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          maxZoom: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, zoom: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            maxZoom: defaultViewerOptions.maxZoom
                          })
                          updateViewer({
                            radius: getValidValue(
                              viewerOptions.initialZoom,
                              defaultViewerOptions.initialZoom
                            )
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('adaptModelTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.initialZoom
                        ? { opacity: 0.5, pointerEvents: 'none' }
                        : undefined
                    }
                  >
                    <span className='subTitle'>{t('initialZoomTitle')}</span>
                    <InputRange
                      min={0}
                      max={99.9}
                      step={0.01}
                      value={getZoomRange(
                        getValidValue(viewerOptions.initialZoom, defaultViewerOptions.initialZoom)
                      )}
                      minValue={getZoomRange(
                        getValidValue(viewerOptions.minZoom, defaultViewerOptions.minZoom)
                      )}
                      maxValue={getZoomRange(
                        getValidValue(viewerOptions.maxZoom, defaultViewerOptions.maxZoom)
                      )}
                      onChange={(range) => {
                        const newValue = getZoomValue(range)

                        setViewerOptionsPartial({ initialZoom: newValue })
                        if (newValue !== defaultViewerOptions.initialZoom) {
                          setResetOptions({ ...resetOptions, zoom: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.initialZoom}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          initialZoom: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, zoom: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            initialZoom: defaultViewerOptions.initialZoom
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('adaptModelTitle')}</span>
                  </div>
                </ConfigurationBox>
              </Collapsible>
            </CollapsibleContainer>
            <CollapsibleContainer resetVisibleNested={resetOptions.orbit}>
              <Collapsible
                nested
                header={t('orbitTitle')}
                resetAction={() => {
                  setResetOptions({ ...resetOptions, orbit: false })
                  setViewerOptionsPartial({
                    maxAzimuthalAngleRotation: defaultViewerOptions.maxAzimuthalAngleRotation,
                    minAzimuthalAngleRotation: defaultViewerOptions.minAzimuthalAngleRotation,
                    maxPolarAngleRotation: defaultViewerOptions.maxPolarAngleRotation,
                    minPolarAngleRotation: defaultViewerOptions.minPolarAngleRotation
                  })
                  setDisableOptions({
                    ...disableOptions,
                    maxAzimuth: true,
                    minAzimuth: true,
                    maxPolar: true,
                    minPolar: true
                  })
                }}
              >
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.minAzimuth
                        ? { opacity: 0.5, pointerEvents: 'none' }
                        : undefined
                    }
                  >
                    <span className='subTitle'>{t('minAzimuthalTitle')}</span>
                    <InputRange
                      min={-75}
                      max={75}
                      step={0.01}
                      maxValue={getValidValue(
                        viewerOptions.maxAzimuthalAngleRotation,
                        defaultViewerOptions.maxAzimuthalAngleRotation
                      )}
                      value={getValidValue(
                        viewerOptions.minAzimuthalAngleRotation,
                        defaultViewerOptions.minAzimuthalAngleRotation
                      )}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ minAzimuthalAngleRotation: newValue })
                        if (newValue !== defaultViewerOptions.minAzimuthalAngleRotation) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.minAzimuth}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          minAzimuth: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            minAzimuthalAngleRotation:
                              defaultViewerOptions.minAzimuthalAngleRotation
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.maxAzimuth
                        ? { opacity: 0.5, pointerEvents: 'none' }
                        : undefined
                    }
                  >
                    <span className='subTitle'>{t('maxAzimuthalTitle')}</span>
                    <InputRange
                      min={-75}
                      max={75}
                      step={0.01}
                      minValue={getValidValue(
                        viewerOptions.minAzimuthalAngleRotation,
                        defaultViewerOptions.minAzimuthalAngleRotation
                      )}
                      value={getValidValue(
                        viewerOptions.maxAzimuthalAngleRotation,
                        defaultViewerOptions.maxAzimuthalAngleRotation
                      )}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ maxAzimuthalAngleRotation: newValue })
                        if (newValue !== defaultViewerOptions.maxAzimuthalAngleRotation) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }
                      }}
                      afterChange={() => null}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.maxAzimuth}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          maxAzimuth: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            maxAzimuthalAngleRotation:
                              defaultViewerOptions.maxAzimuthalAngleRotation
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.minPolar ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('minPolarTitle')}</span>
                    <InputRange
                      min={-360}
                      max={360}
                      step={0.01}
                      maxValue={getValidValue(
                        viewerOptions.maxPolarAngleRotation,
                        defaultViewerOptions.maxPolarAngleRotation
                      )}
                      value={getValidValue(
                        viewerOptions.minPolarAngleRotation,
                        defaultViewerOptions.minPolarAngleRotation
                      )}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ minPolarAngleRotation: newValue })
                        if (newValue !== defaultViewerOptions.minPolarAngleRotation) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.minPolar}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          minPolar: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            minPolarAngleRotation: defaultViewerOptions.minPolarAngleRotation
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.maxPolar ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('maxPolarTitle')}</span>
                    <InputRange
                      min={-360}
                      max={360}
                      step={0.01}
                      minValue={getValidValue(
                        viewerOptions.minPolarAngleRotation,
                        defaultViewerOptions.minPolarAngleRotation
                      )}
                      value={getValidValue(
                        viewerOptions.maxPolarAngleRotation,
                        defaultViewerOptions.maxPolarAngleRotation
                      )}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ maxPolarAngleRotation: newValue })
                        if (newValue !== defaultViewerOptions.maxPolarAngleRotation) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.maxPolar}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          maxPolar: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, orbit: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({
                            maxPolarAngleRotation: defaultViewerOptions.maxPolarAngleRotation
                          })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
              </Collapsible>
            </CollapsibleContainer>
            <CollapsibleContainer resetVisibleNested={resetOptions.displacement}>
              <Collapsible
                nested
                header={t('dragTitle')}
                resetAction={() => {
                  setResetOptions({ ...resetOptions, displacement: false })
                  setViewerOptionsPartial({
                    maxDragX: defaultViewerOptions.maxDragX,
                    minDragX: defaultViewerOptions.minDragX,
                    maxDragY: defaultViewerOptions.maxDragY,
                    minDragY: defaultViewerOptions.minDragY,
                    maxDragZ: defaultViewerOptions.maxDragZ,
                    minDragZ: defaultViewerOptions.minDragZ
                  })
                  updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                  setDisableOptions({
                    ...disableOptions,
                    maxDragX: true,
                    minDragX: true,
                    maxDragY: true,
                    minDragY: true,
                    maxDragZ: true,
                    minDragZ: true
                  })
                }}
              >
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.minDragX ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('minDragXTitle')}</span>
                    <InputRange
                      min={-10}
                      max={10}
                      step={0.01}
                      maxValue={getValidValue(
                        viewerOptions.maxDragX,
                        defaultViewerOptions.maxDragX
                      )}
                      value={getValidValue(viewerOptions.minDragX, defaultViewerOptions.minDragX)}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ minDragX: newValue })
                        if (newValue !== defaultViewerOptions.minDragX) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }
                      }}
                      afterChange={() => {
                        updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.minDragX}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          minDragX: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({ minDragX: defaultViewerOptions.minDragX })
                          updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.maxDragX ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('maxDragXTitle')}</span>
                    <InputRange
                      min={-10}
                      max={10}
                      step={0.01}
                      minValue={getValidValue(
                        viewerOptions.minDragX,
                        defaultViewerOptions.minDragX
                      )}
                      value={getValidValue(viewerOptions.maxDragX, defaultViewerOptions.maxDragX)}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ maxDragX: newValue })
                        if (newValue !== defaultViewerOptions.maxDragX) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }
                      }}
                      afterChange={() => {
                        updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.maxDragX}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          maxDragX: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({ maxDragX: defaultViewerOptions.maxDragX })
                          updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.minDragY ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('minDragYTitle')}</span>
                    <InputRange
                      min={-10}
                      max={10}
                      step={0.01}
                      maxValue={getValidValue(
                        viewerOptions.maxDragY,
                        defaultViewerOptions.maxDragY
                      )}
                      value={getValidValue(viewerOptions.minDragY, defaultViewerOptions.minDragY)}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ minDragY: newValue })
                        if (newValue !== defaultViewerOptions.minDragY) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }
                      }}
                      afterChange={() => {
                        updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.minDragY}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          minDragY: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({ minDragY: defaultViewerOptions.minDragY })
                          updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.maxDragY ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('maxDragYTitle')}</span>
                    <InputRange
                      min={-10}
                      max={10}
                      step={0.01}
                      minValue={getValidValue(
                        viewerOptions.minDragY,
                        defaultViewerOptions.minDragY
                      )}
                      value={getValidValue(viewerOptions.maxDragY, defaultViewerOptions.maxDragY)}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ maxDragY: newValue })
                        if (newValue !== defaultViewerOptions.maxDragY) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }
                      }}
                      afterChange={() => {
                        updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.maxDragY}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          maxDragY: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({ maxDragY: defaultViewerOptions.maxDragY })
                          updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.minDragZ ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('minDragZTitle')}</span>
                    <InputRange
                      min={-10}
                      max={10}
                      step={0.01}
                      maxValue={getValidValue(
                        viewerOptions.maxDragZ,
                        defaultViewerOptions.maxDragZ
                      )}
                      value={getValidValue(viewerOptions.minDragZ, defaultViewerOptions.minDragZ)}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ minDragZ: newValue })
                        if (newValue !== defaultViewerOptions.minDragZ) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }
                      }}
                      afterChange={() => {
                        updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.minDragZ}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          minDragZ: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({ minDragZ: defaultViewerOptions.minDragZ })
                          updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
                <ConfigurationBox>
                  <div
                    style={
                      disableOptions.maxDragZ ? { opacity: 0.5, pointerEvents: 'none' } : undefined
                    }
                  >
                    <span className='subTitle'>{t('maxDragZTitle')}</span>
                    <InputRange
                      min={-10}
                      max={10}
                      step={0.01}
                      minValue={getValidValue(
                        viewerOptions.minDragZ,
                        defaultViewerOptions.minDragZ
                      )}
                      value={getValidValue(viewerOptions.maxDragZ, defaultViewerOptions.maxDragZ)}
                      onChange={(newValue) => {
                        setViewerOptionsPartial({ maxDragZ: newValue })
                        if (newValue !== defaultViewerOptions.maxDragZ) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }
                      }}
                      afterChange={() => {
                        updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input
                      type='checkbox'
                      className='default-checkbox'
                      checked={disableOptions.maxDragZ}
                      onChange={(e) => {
                        setDisableOptions({
                          ...disableOptions,
                          maxDragZ: e.target.checked
                        })
                        if (e.target.checked !== true) {
                          setResetOptions({ ...resetOptions, displacement: true })
                        }

                        if (e.target.checked) {
                          setViewerOptionsPartial({ maxDragZ: defaultViewerOptions.maxDragZ })
                          updateViewer({ dragX: 0, dragY: 0, dragZ: 0 })
                        }
                      }}
                    />
                    <span className='default-checkbox-span'>{t('noLimitsTitle')}</span>
                  </div>
                </ConfigurationBox>
              </Collapsible>
            </CollapsibleContainer>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer resetVisible={resetOptions.animation}>
          <Collapsible
            header={t('animationTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, animation: false })
              setViewerOptionsPartial({
                initialAnimation: defaultViewerOptions.initialAnimation
              })
            }}
          >
            <ConfigurationBox>
              <CheckboxContainer>
                <span className='subTitle'>{t('initialAnimationTitle')}</span>
                <label className='switch' htmlFor='initial-animation'>
                  <input
                    id='initial-animation'
                    type='checkbox'
                    checked={getValidValue(
                      viewerOptions.initialAnimation,
                      defaultViewerOptions.initialAnimation
                    )}
                    onChange={(e) => {
                      setViewerOptionsPartial({
                        initialAnimation: e.target.checked
                      })
                      if (e.target.checked !== defaultViewerOptions.initialAnimation) {
                        setResetOptions({ ...resetOptions, animation: true })
                      }
                    }}
                  />
                  <span className='slider' />
                </label>
              </CheckboxContainer>
            </ConfigurationBox>
          </Collapsible>
        </CollapsibleContainer>
        <CollapsibleContainer resetVisible={resetOptions.bloom}>
          <Collapsible
            header={t('bloomTitle')}
            resetAction={() => {
              setResetOptions({ ...resetOptions, bloom: false })
              setViewerOptionsPartial({ bloom: defaultViewerOptions.bloom })
              if (propsViewerOptions.bloom) {
                setViewerOptionsPartial({
                  initialBloom: defaultViewerOptions.initialBloom,
                  bloomIntensity: defaultViewerOptions.bloomIntensity,
                  bloomRadius: defaultViewerOptions.bloomRadius,
                  bloomThreshold: defaultViewerOptions.bloomThreshold,
                  enableBloomButton: defaultViewerOptions.enableBloomButton
                })
              }
            }}
          >
            <ConfigurationBox>
              <div style={{ height: '55px' }}>
                <CheckboxContainer>
                  <span className='subTitle'>{t('enableBloomTitle')}</span>
                  <label className='switch' htmlFor='enable-bloom'>
                    <input
                      id='enable-bloom'
                      type='checkbox'
                      checked={getValidValue(viewerOptions.bloom, defaultViewerOptions.bloom)}
                      onChange={(e) => {
                        setViewerOptionsPartial({
                          bloom: e.target.checked
                        })
                        if (e.target.checked !== defaultViewerOptions.bloom) {
                          setResetOptions({ ...resetOptions, bloom: true })
                        }
                      }}
                    />
                    <span className='slider' />
                  </label>
                </CheckboxContainer>
                <div className='disclaimer'>
                  <img alt='attention' src={Attention} className='attention-icon' />
                  <span>{t('bloomDisclaimerTitle')}</span>
                </div>
              </div>
            </ConfigurationBox>
            <div
              style={
                !propsViewerOptions.bloom ? { opacity: 0.5, pointerEvents: 'none' } : undefined
              }
            >
              <ConfigurationBox>
                <CheckboxContainer>
                  <span className='subTitle'>{t('initialBloomTitle')}</span>
                  <label className='switch' htmlFor='initial-bloom'>
                    <input
                      id='initial-bloom'
                      type='checkbox'
                      checked={getValidValue(
                        viewerOptions.initialBloom,
                        defaultViewerOptions.initialBloom
                      )}
                      onChange={(e) => {
                        setViewerOptionsPartial({
                          initialBloom: e.target.checked
                        })
                        if (e.target.checked !== defaultViewerOptions.initialBloom) {
                          setResetOptions({ ...resetOptions, bloom: true })
                        }
                      }}
                    />
                    <span className='slider' />
                  </label>
                </CheckboxContainer>
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('bloomIntensityTitle')}</span>
                <InputRange
                  min={0}
                  max={10}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.bloomIntensity,
                    defaultViewerOptions.bloomIntensity
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ bloomIntensity: newValue })
                    if (newValue !== defaultViewerOptions.bloomIntensity) {
                      setResetOptions({ ...resetOptions, bloom: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('bloomRadiusTitle')}</span>
                <InputRange
                  min={0}
                  max={10}
                  step={0.01}
                  value={getValidValue(viewerOptions.bloomRadius, defaultViewerOptions.bloomRadius)}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ bloomRadius: newValue })
                    if (newValue !== defaultViewerOptions.bloomRadius) {
                      setResetOptions({ ...resetOptions, bloom: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <span className='subTitle'>{t('bloomThresholdTitle')}</span>
                <InputRange
                  min={0}
                  max={1}
                  step={0.01}
                  value={getValidValue(
                    viewerOptions.bloomThreshold,
                    defaultViewerOptions.bloomThreshold
                  )}
                  onChange={(newValue) => {
                    setViewerOptionsPartial({ bloomThreshold: newValue })
                    if (newValue !== defaultViewerOptions.bloomThreshold) {
                      setResetOptions({ ...resetOptions, bloom: true })
                    }
                  }}
                />
              </ConfigurationBox>
              <ConfigurationBox>
                <CheckboxContainer>
                  <span className='subTitle'>{t('enableBloomButtonTitle')}</span>
                  <label className='switch' htmlFor='bloom-button'>
                    <input
                      id='bloom-button'
                      type='checkbox'
                      checked={getValidValue(
                        viewerOptions.enableBloomButton,
                        defaultViewerOptions.enableBloomButton
                      )}
                      onChange={(e) => {
                        setViewerOptionsPartial({
                          enableBloomButton: e.target.checked
                        })
                        if (e.target.checked !== defaultViewerOptions.enableBloomButton) {
                          setResetOptions({ ...resetOptions, bloom: true })
                        }
                      }}
                    />
                    <span className='slider' />
                  </label>
                </CheckboxContainer>
              </ConfigurationBox>
            </div>
          </Collapsible>
        </CollapsibleContainer>
        <span className='advertising'>{t('advertising')}</span>
      </MenuContainer>
      <SaveButtonContainer
        isViewerOptionsChanged={!isViewerOptionsEquals(viewerOptions, oldViewerOptions)}
      >
        <button
          className='save-button'
          type='button'
          onClick={() => {
            if (!saving && !isViewerOptionsEquals(viewerOptions, oldViewerOptions)) {
              setSaving(true)
              saveViewerOptions({}).then(() => {
                setSaving(false)
                openToast({
                  text: `${t('savedTitle')}`,
                  icon: DoneIcon,
                  color: 'darkBlue3',
                  filter:
                    'invert(43%) sepia(37%) saturate(5736%) hue-rotate(132deg) brightness(93%) contrast(101%)'
                })
                setTimeout(() => {
                  closeToast()
                }, 3000)
              })
            }
          }}
        >
          {saving ? (
            <Saving saving={saving} text={t('savingTitle')} loadingColor='#fff' />
          ) : (
            t('saveTitle')
          )}
        </button>
      </SaveButtonContainer>
    </Container>
  )
}

export default ConfigurationBar
