import React, { useContext, useState, useEffect } from 'react'

import { Line } from 'rc-progress'
import { useTranslation } from 'react-i18next'

import ArrowIcon from '@console/common/assets/arrowLeft.svg'
import theme from '@console/common/theme.json'

import { RegistrationSteps, ProcessContext } from '../../contexts/ProcessContext'
import { Header, HeaderButtons, ArrowRight } from './styles'

interface Props {
  steps: RegistrationSteps[]
}

const ProgressBar: React.FC<Props> = ({ steps }: Props) => {
  const { t } = useTranslation(['registration'])
  const [progress, setProgress] = useState<number>(0)
  const { currentStep, setCurrentStep } = useContext(ProcessContext)
  const [progressUnit] = useState(100 / steps?.length)

  const handleCurrentStep = (step: RegistrationSteps) => {
    const index = steps.findIndex((s) => s === step)
    setCurrentStep(step)
    setProgress((index + 1) * progressUnit)
  }

  useEffect(() => {
    handleCurrentStep(currentStep)
  }, [currentStep])

  return (
    <Header>
      <HeaderButtons>
        {steps?.map((rs, index) => (
          <React.Fragment key={rs}>
            <button
              type='button'
              onClick={() => setCurrentStep(rs)}
              disabled={progress < (index + 1) * progressUnit}
            >
              {t(`progressBar.${rs}`)}
            </button>
            {index + 1 < steps.length && <ArrowRight src={ArrowIcon} />}
          </React.Fragment>
        ))}
      </HeaderButtons>
      <Line percent={progress} strokeWidth={1} strokeColor={theme.blue} trailColor={theme.grey9} />
    </Header>
  )
}

export default ProgressBar
