import React, { createContext, useState, ReactNode } from 'react'

export enum BarOptions {
  COMMENTS = 'COMMENTS',
  CONFIGURATION = 'CONFIGURATION',
  CUSTOMIZATION = 'CUSTOMIZATION',
  INFO = 'INFO',
  AR = 'AR',
  REFERENCE = 'REFERENCE',
  MATERIALS = 'MATERIALS'
}

interface Props {
  children: ReactNode
}

interface rightBarData {
  rightBarOptions: BarOptions | null
  setRightBarOptions: (option: BarOptions | null) => void
}

export const RightBarOptionsContext = createContext<rightBarData>({} as rightBarData)

export const RightBarOptionsProvider: React.FC<Props> = ({ children }: Props) => {
  const [rightBarOptions, setRightBarOptions] = useState<BarOptions | null>(BarOptions.COMMENTS)

  return (
    <RightBarOptionsContext.Provider
      value={{
        rightBarOptions,
        setRightBarOptions
      }}
    >
      {children}
    </RightBarOptionsContext.Provider>
  )
}
