import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Header from '@console/common/components/Header'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import emptyStateImg from './assets/emptyState.svg'
import NotificationContainer from './components/NotificationContainer'
import Skeleton from './components/Skeleton'
import { getNotifications, Notification } from './services/api'
import { Container, EmptyStateContainer } from './styles'

const Review: React.FC = () => {
  const { t } = useTranslation(['notification'])
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [loading, setLoading] = useState(false)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    setLoading(true)
    getNotifications().then((e) => {
      setNotifications(e)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    analytics?.track(AnalyticsEvent.NOTIFICATIONS)
  }, [analytics])

  return (
    <Container>
      <Header title={t('title')} />
      {loading ? (
        Array(3)
          .fill(null)
          // eslint-disable-next-line react/no-array-index-key
          .map((_, i) => <Skeleton key={i} />)
      ) : notifications.length ? (
        <div>
          {notifications.map((e) => (
            <NotificationContainer key={e.id} notification={e} />
          ))}
          <small>{t('timeFilter')}</small>
        </div>
      ) : (
        <EmptyStateContainer>
          <img src={emptyStateImg} alt={t('empty')} />
          <h2>{t('empty')}</h2>
        </EmptyStateContainer>
      )}
    </Container>
  )
}

export default Review
