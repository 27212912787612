import React, { useState, useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { ModalContext } from '@console/common/contexts/ModalContext'
import { UserRole } from '@console/common/services/api'

import configurationsIcon from '../../assets/configurations.svg'
// import helpIcon from '../../assets/help.png'
import R2UMinimized from '../../assets/logo1.svg'
import R2UPlatform from '../../assets/logo3.svg'
import notificationIcon from '../../assets/notification.svg'
import performanceIcon from '../../assets/performance.svg'
import portfolioIcon from '../../assets/portfolio.svg'
import revisionIcon from '../../assets/revision.svg'
import signOutIcon from '../../assets/signOut.svg'
import Admin from './Admin'
import SideBarLink from './SideBarLink'
import {
  Customer,
  CustomerLogo,
  CustomerLogoPlaceholder,
  Container,
  Divider,
  Logo,
  Menu,
  Top
} from './styles'

interface Props {
  expanded: boolean
}

const SideBar: React.FC<Props> = ({ expanded }: Props) => {
  const { t } = useTranslation(['components/sidebar'])

  const { session } = useContext(AuthContext)
  const { closeModal } = useContext(ModalContext)

  const location = useLocation()

  useEffect(closeModal, [location])

  // TODO: get from context/session
  const [notificationCount] = useState<number | null>(null)

  return (
    <Container>
      <Top>
        <Link to='/'>
          <Logo src={expanded ? R2UPlatform : R2UMinimized} expanded={expanded} />
        </Link>
        <Divider expanded={expanded} />
        <Customer>
          {session?.role === UserRole.ADMIN ||
          session?.role === UserRole.R2USER ||
          session?.role === UserRole.ARTIST ? (
            <Admin expanded={expanded} />
          ) : session ? (
            <>
              {session.customer.logoUrl ? (
                <CustomerLogo expanded={expanded} src={session.customer.logoUrl} />
              ) : (
                <CustomerLogoPlaceholder expanded={expanded}>
                  {session.customer.name.charAt(0)}
                </CustomerLogoPlaceholder>
              )}
              {expanded ? <p>{session.name}</p> : null}
            </>
          ) : null}
        </Customer>
      </Top>
      <Menu>
        {session && session?.role !== UserRole.FREELANCER ? (
          <>
            {session.role !== UserRole.ARTIST ? (
              <SideBarLink
                to='/notifications'
                src={notificationIcon}
                text={t('notifications')}
                expanded={expanded}
                notifications={notificationCount}
              />
            ) : null}
            <SideBarLink
              to='/portfolio'
              src={portfolioIcon}
              text={t('portfolio')}
              expanded={expanded}
            />
            <SideBarLink
              to='/review'
              src={revisionIcon}
              text={t('revisions')}
              expanded={expanded}
              notifications={notificationCount}
            />
            <SideBarLink
              to='/performance'
              src={performanceIcon}
              text={t('performance')}
              expanded={expanded}
            />
            <Divider expanded={expanded} />
            {session.role !== UserRole.ARTIST ? (
              <SideBarLink
                to='/settings'
                src={configurationsIcon}
                text={t('settings')}
                expanded={expanded}
              />
            ) : null}
          </>
        ) : null}
        {/* <SideBarLink to='/help' src={helpIcon} text={t('help')} expanded={expanded} /> */}
        <SideBarLink to='/logout' src={signOutIcon} text={t('logout')} expanded={expanded} />
      </Menu>
    </Container>
  )
}

export default SideBar
