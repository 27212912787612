import React, { useContext, useEffect } from 'react'

import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'

import { CheckboxProvider } from './contexts/CheckboxContext'
import { ProductsProvider } from './contexts/ProductsContext'
import List from './pages/List'
import Reviewer from './pages/Reviewer'

const Review: React.FC = () => {
  const { path } = useRouteMatch()
  const { analytics } = useContext(AnalyticsContext)

  const { session } = useContext(AuthContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.REVIEW)
  }, [analytics])

  return (
    <Switch>
      <Route exact path={path}>
        {session?.role === UserRole.FREELANCER ? (
          <Redirect to='/forbidden' />
        ) : (
          <ProductsProvider>
            <CheckboxProvider>
              <List />
            </CheckboxProvider>
          </ProductsProvider>
        )}
      </Route>
      <Route path={`${path}/:productId`}>
        <Reviewer />
      </Route>
    </Switch>
  )
}

export default Review
