/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useContext, useEffect, useState } from 'react'

import Viewer from '@r2u/viewer'

import arCube2 from '@console/common/assets/arCube2.svg'
import close from '@console/common/assets/close.svg'
import embed from '@console/common/assets/embed.svg'
import help from '@console/common/assets/help.svg'
import lampOff from '@console/common/assets/light-OFF.svg'
import lampOn from '@console/common/assets/light-ON.svg'
import tune from '@console/common/assets/tune.svg'

import { ConfigurationContext } from '../../contexts/ConfigurationContext'
import EmbedPopup from './EmbedPopup'
import Help from './Help'
import QR from './QRCode'
import SceneConfiguration from './SceneConfiguration'
import { VerticalMenu, PopupContainer } from './styles'

interface Props {
  src: string
  customerId?: string | null
  sku?: string | null
  viewerUrl?: string | null
  viewerBloom?: boolean | null
}

const AssetViewer: React.FC<Props> = ({ src, customerId, sku, viewerUrl, viewerBloom }: Props) => {
  type MenuPopupModes = 'AR' | 'HDRI' | 'EMBED' | 'HELP'
  const [popupMode, setPopupMode] = useState<MenuPopupModes>('AR')
  const [open, setOpen] = useState<boolean>(false)
  const [popupAnimation, setPopupAnimation] = useState<'open' | 'close' | 'none'>('none')
  const [bloomIsOn, setBloomIsOn] = useState(false)

  const { viewerOptions, loading, viewerRef, updateViewer } = useContext(ConfigurationContext)
  interface PopupProps {
    children: ReactElement
  }

  const Popup: React.FC<PopupProps> = ({ children }: PopupProps) => (
    <PopupContainer id='popupContainer' popupAnimation={popupAnimation}>
      <img
        src={close}
        alt='close'
        className='close'
        onClick={() => {
          setPopupAnimation('close')
        }}
      />
      {children}
    </PopupContainer>
  )

  useEffect(() => {
    const popup = document.getElementById('popupContainer')
    popup?.addEventListener('animationend', () => {
      if (popupAnimation === 'close') setOpen(false)
      setPopupAnimation('none')
    })
  })

  useEffect(() => {
    if (viewerOptions?.initialBloom) setBloomIsOn(true)
  }, [loading])

  interface MenuItem {
    src: string
    alt: string
    popup: ReactElement
  }

  const menuItems: Record<MenuPopupModes, MenuItem> = {
    AR: {
      src: arCube2,
      alt: 'ar cube',
      popup: <QR src={viewerUrl ?? ''} />
    },
    HDRI: {
      src: tune,
      alt: 'tune',
      popup: <SceneConfiguration />
    },
    EMBED: {
      src: embed,
      alt: 'embed',
      popup: <EmbedPopup customerId={customerId} sku={sku} />
    },
    HELP: {
      src: help,
      alt: 'help',
      popup: <Help />
    }
  }

  const mode = menuItems[popupMode]?.popup

  if (loading) return <></>

  return (
    <Viewer
      glbSrc={src}
      bloom={!!viewerBloom}
      {...viewerOptions}
      ref={viewerRef}
      enableBloomButton={false}
    >
      {open ? <Popup>{mode}</Popup> : null}
      <VerticalMenu>
        {viewerBloom ? (
          <div>
            <img
              src={bloomIsOn ? lampOn : lampOff}
              alt={bloomIsOn ? 'lampOn' : 'lampOff'}
              onClick={() => {
                setBloomIsOn(!bloomIsOn)
                updateViewer({ bloomOn: !bloomIsOn })
              }}
              className={bloomIsOn ? 'activePopupMenu' : ''}
            />
          </div>
        ) : null}
        {Object.keys(menuItems).map((key) => (
          <div key={key}>
            <img
              src={menuItems[key as MenuPopupModes]?.src}
              alt={menuItems[key as MenuPopupModes]?.alt}
              className={
                open && popupAnimation !== 'close' && popupMode === key ? 'activePopupMenu' : ''
              }
              onClick={() => {
                if (open && popupMode === key) setPopupAnimation('close')
                else {
                  setPopupAnimation('open')
                  setOpen(true)
                  setPopupMode(key as MenuPopupModes)
                }
              }}
            />
          </div>
        ))}
      </VerticalMenu>
    </Viewer>
  )
}

export default AssetViewer
