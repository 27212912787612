import React, { ReactElement, useContext, useState } from 'react'

import arrow from '@console/common/assets/arrowLeftBlack.svg'

import { ConfigurationContext } from '../../contexts/ConfigurationContext'
import hdris from '../Hdris'
import { SceneConfigurationContainer, Arrow } from './styles'

const SceneConfiguration: React.FC = () => {
  const { viewerOptions, saveViewerOptions, updateViewer } = useContext(ConfigurationContext)
  const [activeSlide, setActiveSlide] = useState<number>(
    hdris.reduce(
      (accumulator, hdri, index) =>
        viewerOptions.hdri === hdri.url ? Math.floor(index / 3) : accumulator,
      0
    )
  )

  const slidesLimit = {
    min: 0,
    max: Math.ceil(hdris.length / 3 - 1)
  }

  const dots: ReactElement[] = Array(slidesLimit.max + 1)
    .fill(undefined)
    .map((_, index) => (
      <button
        type='button'
        className={`dot ${index === activeSlide ? 'active' : ''}`}
        onClick={() => setActiveSlide(index)}
        aria-label='select slide'
      />
    ))

  return (
    <SceneConfigurationContainer>
      <div className='carouselOuter'>
        <Arrow
          src={arrow}
          alt='left-arrow'
          onClick={() => setActiveSlide(Math.max(slidesLimit.min, activeSlide - 1))}
          active={activeSlide !== slidesLimit.min}
        />
        <div className='carousel'>
          <div
            className='carouselInner'
            style={{ transform: `translateX(-${activeSlide * 100}%)` }}
          >
            {hdris.map((hdri) => (
              <div className='carouselItem'>
                <button
                  type='button'
                  className={viewerOptions.hdri === hdri.url ? 'selected' : ''}
                  onClick={() => {
                    saveViewerOptions({ hdri: hdri.url })
                    updateViewer({ hdri: hdri.url })
                  }}
                >
                  <img src={hdri.thumbnail} alt={hdri.name} />
                </button>
                <span>{hdri.name}</span>
              </div>
            ))}
          </div>
        </div>
        <Arrow
          src={arrow}
          alt='right-arrow'
          right
          onClick={() => setActiveSlide(Math.min(activeSlide + 1, slidesLimit.max))}
          active={activeSlide !== slidesLimit.max}
        />
      </div>
      <div className='dots'>{dots}</div>
    </SceneConfigurationContainer>
  )
}

export default SceneConfiguration
