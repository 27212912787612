import React, { useContext } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import WarningIcon from '@console/common/assets/warning.svg'
import { AuthContext } from '@console/common/contexts/AuthContext'

import PerformanceHeader from '../../../components/Header'
import { Container } from './styles'

const Home: React.FC = () => {
  const { t } = useTranslation(['performance'])
  const { assumedCustomer, sessionCustomer } = useContext(AuthContext)

  const srcAnalytics =
    assumedCustomer?.metadata?.performance?.['ga4-analytics'] ??
    sessionCustomer?.metadata?.performance?.['ga4-analytics']

  return (
    <Container>
      <PerformanceHeader />
      {srcAnalytics ? (
        <div>
          <iframe
            title='looker analytics'
            width='100%'
            height='2550px'
            src={srcAnalytics}
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '1rem'
          }}
        >
          <img src={WarningIcon} alt='' />
          <div>
            <Trans t={t} i18nKey='pages.analytics.no-analytics.title' />
            <p>{t('pages.analytics.no-analytics.subtitle')}</p>
            <ul>
              <li>
                <Trans
                  t={t}
                  i18nKey='pages.analytics.no-analytics.steps.1'
                  components={{
                    lnk: <a href='https://docs.r2u.io/javascript/quickstart/index.html'> </a>
                  }}
                />
              </li>
              <li>
                <Trans
                  t={t}
                  i18nKey='pages.analytics.no-analytics.steps.2'
                  components={{
                    lnk: (
                      <a href='https://docs.r2u.io/javascript/analytics/index.html#analytics-setup'>
                        {' '}
                      </a>
                    )
                  }}
                />
              </li>
              <li>
                <Trans t={t} i18nKey='pages.analytics.no-analytics.steps.3' />
              </li>
              <li>
                <Trans
                  t={t}
                  i18nKey='pages.analytics.no-analytics.steps.4'
                  components={{
                    lnk: <a href='https://r2u.io/contato/'> </a>
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      )}
    </Container>
  )
}

export default Home
