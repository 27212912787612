/**
 * ! Disclaimer
 * This code presented is meant for temporary use while the set default customization is not yet
 * implemented on the viewer. When it is, please delete this file along with the usages marked with
 * * "TODO delete code segment".
 */

import type { ViewerRef } from '@r2u/viewer/dist/types'

type SlotsModels = ReturnType<ViewerRef['getAllSlotsModels']>

interface Props {
  slotsModels: SlotsModels
  setSlotsModels: (slotsModels: SlotsModels) => void
  viewerRef: ViewerRef
}

const setDefaultSlotsModels = ({ slotsModels, setSlotsModels, viewerRef }: Props): void => {
  slotsModels?.slots.forEach((sm) => {
    if (sm.active !== -1) {
      setDefaultSlotsModels({
        slotsModels: sm.models[sm.active],
        setSlotsModels,
        viewerRef
      })
    } else if (sm.models.length) {
      viewerRef.setModel({ modelId: slotsModels.id, slot: sm.name, modelName: sm.models[0].id })

      setSlotsModels(viewerRef.getAllSlotsModels())
    }
  })
}

export default setDefaultSlotsModels
