import styled from 'styled-components'

export const StyledCheckbox = styled.span`
  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.dark2};
  }
  & .MuiTypography-body1 {
    font-size: 1.125rem;
    font-family: Inter;
  }
`

export const SelectAllContainer = styled.span`
  justify-self: start;
  align-self: end;
  text-align: left;
  color: ${(props) => props.theme.dark};
  margin-top: 35px;
`
