const stage = process.env.REACT_APP_STAGE

const api = {
  baseURL:
    stage === 'production'
      ? 'https://api.3d.r2u.io'
      : stage === 'dev'
      ? 'https://dev.api.3d.r2u.io'
      : 'http://localhost:4000'
}

const auth =
  stage === 'production'
    ? {
        AUTH0_DOMAIN: 'real2u.auth0.com',
        AUTH0_CLIENT_ID: 'OFwy1JL7mqIckqRldNyHrUAfQSstKZSt',
        AUTH0_CALLBACK_URL: 'https://app.r2u.io',
        AUTH0_AUDIENCE: 'real2u.console.app',
        AUTH0_CONTAINER: 'auth0-login-container'
      }
    : stage === 'dev'
    ? {
        AUTH0_DOMAIN: 'r2u-dev.us.auth0.com',
        AUTH0_CLIENT_ID: 'ZLyCIPK9uCOkVkgd6rZRY2kFxB8dbZ13',
        AUTH0_CALLBACK_URL: 'https://dev.app.r2u.io',
        AUTH0_AUDIENCE: 'dev.console.api',
        AUTH0_CONTAINER: 'auth0-login-container'
      }
    : {
        AUTH0_DOMAIN: 'r2u-dev.us.auth0.com',
        AUTH0_CLIENT_ID: 'ZLyCIPK9uCOkVkgd6rZRY2kFxB8dbZ13',
        AUTH0_CALLBACK_URL: 'http://localhost:3000',
        AUTH0_AUDIENCE: 'dev.console.api',
        AUTH0_CONTAINER: 'auth0-login-container'
      }

const mixpanel = {
  debug: stage !== 'production',
  projectToken:
    stage === 'production' ? '83dee3a4e143f0b2dec7ab58c446ad37' : 'bc41d93dbeb1396fddd7a0e6fdf0a572'
}

export default {
  api,
  auth,
  mixpanel
}
