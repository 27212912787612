import React from 'react'

import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'

import 'react-image-gallery/styles/css/image-gallery.css'
import { GalleryContainer } from './styles'

interface Props {
  images: ReactImageGalleryItem[] | undefined
  isLoading?: boolean
}

const Carousel: React.FC<Props> = ({ images, isLoading }: Props) => (
  <GalleryContainer isLoading={isLoading ?? false}>
    {images ? (
      <ImageGallery items={images} showThumbnails={false} showPlayButton={false} showBullets />
    ) : null}
  </GalleryContainer>
)

export default Carousel
