import React, { useContext, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ReviewContext } from '../../contexts/ReviewContext'
import { NextButton, TimeCounter, TimerText } from './styles'

const CountdownButton: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { reviewStatus, confirmReviewUpdate } = useContext(ReviewContext)
  const [counter, setCounter] = useState(5)

  const intervalId = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (counter === 0) {
      if (intervalId.current) clearInterval(intervalId.current)
      confirmReviewUpdate()
    }
  }, [counter])

  useEffect(() => {
    if (reviewStatus) {
      intervalId.current = setInterval(() => {
        setCounter((c) => c - 1)
      }, 1000)
    } else {
      if (intervalId.current) clearInterval(intervalId.current)
      setCounter(5)
    }
  }, [reviewStatus])

  if (reviewStatus) {
    return (
      <NextButton onClick={confirmReviewUpdate}>
        <TimerText>{t('approval.next')}</TimerText>
        <TimeCounter> {counter} </TimeCounter>
      </NextButton>
    )
  }

  return null
}

export default CountdownButton
