import React, { useEffect, useState, useContext } from 'react'

import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'

// import Cascade, { SelectOptions } from '@console/common/components/Cascade'
import { Input } from '@console/common/components/TextInput/styles'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { ProductPlacement } from '@console/common/services/api'

import NextButton from '../../../components/NextButton'
import { RegistrationSteps } from '../../../contexts/ProcessContext'
import { ContentContainer, FieldsContainer, FieldNames } from './styles'

const ProductData: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [name, setName] = useState<string>()
  const [sku, setSku] = useState<string>()
  const [ean, setEan] = useState<string>()
  const [pdpUrl, setPdpUrl] = useState<string>()
  // const [placement, setPlacement] = useState<SelectOptions | null>(null)
  const [height, setHeight] = useState<string>()
  const [width, setWidth] = useState<string>()
  const [depth, setDepth] = useState<string>()
  const { analytics } = useContext(AnalyticsContext)

  // const PlacementOptions = [
  //   {
  //     value: ProductPlacement.FLOOR,
  //     label: t('productData.floor')
  //   },
  //   {
  //     value: ProductPlacement.WALL,
  //     label: t('productData.wall')
  //   }
  // ]

  useEffect(() => {
    const productDataItem = localStorage.getItem('productData')
    if (productDataItem) {
      const data = JSON.parse(productDataItem)
      setName(data.name)
      setSku(data.sku)
      setEan(data.ean)
      setPdpUrl(data.pdpUrl)
      setHeight(data.height)
      setWidth(data.width)
      setDepth(data.depth)
      // setPlacement(PlacementOptions.find((option) => option.value === data.placement) || null)
    }
  }, [])

  return (
    <div>
      <Formik
        initialValues={{ name, sku, ean, pdpUrl, height, width, depth }}
        enableReinitialize
        onSubmit={(values) => {
          analytics?.track(AnalyticsEvent.REGISTRATION_SUBMIT_DATA)
          localStorage.setItem(
            'productData',
            JSON.stringify({ ...values, placement: ProductPlacement.FLOOR })
          )
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <ContentContainer>
              <FieldsContainer>
                <FieldNames>{t('productData.productName')}</FieldNames>
                <Field
                  name='name'
                  as={Input}
                  width='330px'
                  placeholder={t('productData.placeholders.name')}
                />
                <FieldNames>{t('productData.sku')}</FieldNames>
                <Field
                  name='sku'
                  as={Input}
                  width='330px'
                  placeholder={t('productData.placeholders.sku')}
                />
                <FieldNames>{t('productData.ean')}</FieldNames>
                <Field
                  name='ean'
                  as={Input}
                  width='330px'
                  placeholder={t('productData.placeholders.ean')}
                />
                <FieldNames>{t('productData.pdp')}</FieldNames>
                <Field
                  name='pdpUrl'
                  as={Input}
                  width='330px'
                  placeholder={t('productData.placeholders.pdp')}
                />
              </FieldsContainer>
              <FieldsContainer>
                {/* <FieldNames>{t('productData.placement')}</FieldNames>
                <Cascade
                  options={PlacementOptions}
                  onChange={(option) => setPlacement(option)}
                  value={placement}
                  isSearchable={false}
                  placeholder={t('productData.placeholders.placement')}
                /> */}
                <FieldNames>{t('productData.height')}</FieldNames>
                <Field
                  name='height'
                  as={Input}
                  width='115px'
                  placeholder={t('productData.placeholders.height')}
                />
                <FieldNames>{t('productData.width')}</FieldNames>
                <Field
                  name='width'
                  as={Input}
                  width='115px'
                  placeholder={t('productData.placeholders.width')}
                />
                <FieldNames>{t('productData.depth')}</FieldNames>
                <Field
                  name='depth'
                  as={Input}
                  width='115px'
                  placeholder={t('productData.placeholders.depth')}
                />
              </FieldsContainer>
            </ContentContainer>
            <NextButton nextPage={RegistrationSteps.IMAGE_UPLOAD} onClick={() => submitForm()} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProductData
