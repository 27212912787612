import React from 'react'

import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import { QRCodeContainer } from './styles'

interface Props {
  src: string | null
}

const QR: React.FC<Props> = ({ src }: Props) => {
  const { t } = useTranslation(['portfolio'])
  return (
    <QRCodeContainer>
      <span>{t('detailed.viewAR')}</span>
      <QRCode value={src ?? ''} renderAs='svg' />
    </QRCodeContainer>
  )
}

export default QR
