import React from 'react'

import { useTranslation } from 'react-i18next'

import galleryEmptyState from '../../assets/galleryEmptyState.svg'
import modelEmptyState from '../../assets/modelEmptyState.svg'
import placeholder from '../../assets/placeholder.svg'
import { Container, Image, CardText, ImageText, ModelText } from './styles'

export enum PlaceholderTypes {
  CARD = 'CARD',
  REFERENCE = 'REFERENCE',
  MODEL = 'MODEL'
}

interface Props {
  type: string
}

const PlaceholderImage: React.FC<Props> = ({ type }: Props) => {
  const { t } = useTranslation(['components/placeholder-image'])
  if (type === PlaceholderTypes.CARD) {
    return (
      <Container>
        <Image src={placeholder} alt='' />
        <CardText className='firstLine'>{t('card.firstLine')}</CardText>
        <CardText className='secondLine'>{t('card.secondLine')}</CardText>
      </Container>
    )
  }
  if (type === PlaceholderTypes.REFERENCE) {
    return (
      <>
        <Container>
          <Image src={galleryEmptyState} alt='' />
          <ImageText className='firstLine'>{t('image.firstLine')}</ImageText>
          <ImageText className='secondLine'>{t('image.secondLine')}</ImageText>
        </Container>
      </>
    )
  }
  return (
    <>
      <Container>
        <Image src={modelEmptyState} alt='' />
        <ModelText className='firstLine'>{t('model.firstLine')}</ModelText>
        <ModelText className='secondLine'>{t('model.secondLine')}</ModelText>
      </Container>
    </>
  )
}

export default PlaceholderImage
