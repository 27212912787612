import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import MultiProductIcon from '@console/common/assets/illustrations/multiProduct.svg'
import SingleProductIcon from '@console/common/assets/illustrations/singleProduct.svg'
import Header from '@console/common/components/Header'

import {
  MainContainer,
  OptionsContainer,
  RegistrationOption,
  OptionTitle,
  OptionButton
} from './styles'

const SelectRegistration: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const { t: tPortfolio } = useTranslation(['portfolio'])

  return (
    <MainContainer>
      <Header title={tPortfolio('title')} />
      <OptionsContainer>
        <RegistrationOption>
          <OptionTitle>{t('registrationOptions.singleTitle')}</OptionTitle>
          <img src={SingleProductIcon} alt='' />
          <Link to='/registration/single' style={{ textDecoration: 'none' }}>
            <OptionButton isLoading={false}>{t('registrationOptions.singleButton')}</OptionButton>
          </Link>
        </RegistrationOption>
        <RegistrationOption>
          <OptionTitle>{t('registrationOptions.multipleTitle')}</OptionTitle>
          <img src={MultiProductIcon} alt='' />
          <Link to='/registration/multiple' style={{ textDecoration: 'none' }}>
            <OptionButton isLoading={false}>{t('registrationOptions.multipleButton')}</OptionButton>
          </Link>
        </RegistrationOption>
      </OptionsContainer>
    </MainContainer>
  )
}

export default SelectRegistration
