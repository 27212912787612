import React, { useContext } from 'react'

import ProgressBar from '../../components/ProgressBar'
import { MultipleContext } from '../../contexts/MultipleContext'
import { RegistrationSteps, ProcessContext } from '../../contexts/ProcessContext'
import Confirmation from './Confirmation'
import ModelImageUpload from './ModelImageUpload'
import UploadPopup from './ModelImageUpload/UploadPopup'
import ProductData from './ProductData'
import { MainContainer } from './styles'

const MultipleProduct: React.FC = () => {
  const { currentStep } = useContext(ProcessContext)
  const { modal } = useContext(MultipleContext)

  const steps = [
    RegistrationSteps.PRODUCT_DATA,
    RegistrationSteps.MODEL_IMAGE_UPLOAD,
    RegistrationSteps.CONFIRMATION
  ]

  const renderSwitch = (step: RegistrationSteps) => {
    switch (step) {
      case RegistrationSteps.PRODUCT_DATA:
        return <ProductData />
      case RegistrationSteps.MODEL_IMAGE_UPLOAD:
        return <ModelImageUpload />
      case RegistrationSteps.CONFIRMATION:
        return <Confirmation />
      default:
        return <div />
    }
  }

  return (
    <>
      {modal !== 'CLOSE' && <UploadPopup />}
      <MainContainer>
        <ProgressBar steps={steps} />
        <div>{renderSwitch(currentStep)}</div>
      </MainContainer>
    </>
  )
}

export default MultipleProduct
