import React, { ReactNode, useContext } from 'react'

import CustomerSelector from '@console/common/components/CustomerSelector'
import LgpdBanner from '@console/common/components/LgpdBanner'
import Toast from '@console/common/components/Toast'
import { AnalyticsContext } from '@console/common/contexts/AnalyticsContext'
import { ExpandBarContext } from '@console/common/contexts/ExpandBarContext'
import { ModalProvider } from '@console/common/contexts/ModalContext'
import { ToastContext } from '@console/common/contexts/ToastContext'

import nextIcon from '../../assets/nextIcon.svg'
import SideBar from '../SideBar'
import { Container, CloseSideBarIcon, Icon, Children } from './styles'

interface Props {
  children: ReactNode
}

const SideBarWrapper: React.FC<Props> = ({ children }: Props) => {
  const { barExpanded, toggleSideBar } = useContext(ExpandBarContext)
  const { banner, handleUserChoice } = useContext(AnalyticsContext)
  const { isOpen, toastProps, closeToast } = useContext(ToastContext)

  return (
    <Container expanded={barExpanded}>
      <ModalProvider>
        <SideBar expanded={barExpanded} />
        <CloseSideBarIcon expanded={barExpanded} onClick={toggleSideBar}>
          <Icon src={nextIcon} expanded={barExpanded} />
        </CloseSideBarIcon>
        <Children expanded={barExpanded}>
          <CustomerSelector />
          {children}
          {banner && <LgpdBanner handleUserChoice={handleUserChoice} />}
          {isOpen && (
            <Toast handleClose={closeToast} icon={toastProps?.icon ?? ''} {...toastProps} />
          )}
        </Children>
      </ModalProvider>
    </Container>
  )
}

export default SideBarWrapper
