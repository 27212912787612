import React, { useState, useContext, useEffect } from 'react'

import Checkbox from '@material-ui/core/Checkbox'

import Card from '@console/common/components/Card'
import { CardOptions } from '@console/common/components/Card/Options'
import Status from '@console/common/types/Status'

import { CheckboxContext } from '../../contexts/CheckboxContext'
import { Container, CheckboxContainer } from './styles'

interface Props {
  imgSrc?: string
  name?: string
  sku?: string
  status?: Status
  customer?: string
  showOptions?: boolean
  options?: CardOptions[] | null
  id?: string
}

const CheckboxCard: React.FC<Props> = ({
  imgSrc,
  name,
  sku,
  status,
  customer,
  showOptions,
  options,
  id
}: Props) => {
  const [isChecked, setIsChecked] = useState(false)

  const { selectAllStatus, isAnyChecked, handleChange } = useContext(CheckboxContext)

  const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    if (id) {
      handleChange(id)
    }
  }

  const [hover, setHover] = useState(false)

  useEffect(() => {
    setIsChecked(selectAllStatus)
  }, [selectAllStatus])

  return (
    <Container onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {isAnyChecked || hover ? (
        <CheckboxContainer>
          <Checkbox
            onClick={(event) => {
              event.stopPropagation()
            }}
            size='medium'
            color='default'
            checked={isChecked}
            onChange={toggleCheckbox}
          />
        </CheckboxContainer>
      ) : null}
      <Card
        imgSrc={imgSrc}
        name={name}
        sku={sku}
        status={status}
        customer={customer}
        showOptions={showOptions}
        options={options}
      />
    </Container>
  )
}

export default CheckboxCard
