import React, { useState, useCallback, useRef, useEffect, useContext } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import camera from '@console/common/assets/camera.svg'
import refresh from '@console/common/assets/refresh.svg'
import Button from '@console/common/components/Button'
import Cascade, { SelectOptions } from '@console/common/components/Cascade'
import TextInput, { InputTypes } from '@console/common/components/TextInput'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { Language } from '@console/common/services/api'
import { upload } from '@console/common/services/upload'

import Header from '../../../components/Header'
import { createCustomer } from '../../../services/api'
import {
  Container,
  DropzoneContainer,
  RefreshLogo,
  FieldNames,
  CascadeRow,
  ButtonContainer
} from './styles'

const Creation: React.FC = () => {
  const { t } = useTranslation(['settings'])
  const history = useHistory()
  const [loading] = useState<boolean>(false)
  const [logo, setLogo] = useState<string | null>(null)
  const [name, setName] = useState<string>('')
  const [slug, setSlug] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [language, setLanguage] = useState<SelectOptions | null>(null)
  const [waterMark, setWaterMark] = useState<SelectOptions | null>(null)
  const [status, setStatus] = useState<SelectOptions | null>(null)
  const [validity, setValidity] = useState<boolean>(false)
  const nameRef = useRef<HTMLDivElement>(null)
  const emailRef = useRef<HTMLDivElement>(null)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    setSlug(
      name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
    )
  }, [name])

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS_CUSTOMERS_CREATE)
  }, [analytics])

  useEffect(() => {
    const nameValidity = (nameRef.current?.children[0] as HTMLInputElement)?.checkValidity()
    const emailValidity = (emailRef.current?.children[0] as HTMLInputElement)?.checkValidity()
    setValidity(
      nameValidity && emailValidity && !!slug && language?.value && waterMark?.value && status
    )
  }, [name, slug, email, language, waterMark, status])

  const LanguageOptions = [
    {
      value: Language.EN,
      label: Language.EN
    },
    {
      value: Language.PT,
      label: Language.PT
    }
  ]

  const WatermarkOptions = [
    {
      value: 'bottomLeft',
      label: t('customers.creation.bottomLeft')
    },
    {
      value: 'bottomRight',
      label: t('customers.creation.bottomRight')
    },
    {
      value: 'topLeft',
      label: t('customers.creation.topLeft')
    },
    {
      value: 'topRight',
      label: t('customers.creation.topRight')
    }
  ]

  const StatusOptions = [
    {
      value: true,
      label: t('customers.active')
    },
    {
      value: false,
      label: t('customers.inactive')
    }
  ]

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      upload(file, 'logo', setLogo)
    })
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    maxFiles: 1
  })

  const triggerCreation = async () => {
    if (!validity) return
    await createCustomer(
      name,
      slug,
      email,
      language?.value,
      waterMark?.value,
      status?.value,
      logo || undefined
    )

    analytics?.track(AnalyticsEvent.SETTINGS_CUSTOMERS_CREATE_CREATED, { name })

    history.push('/settings/customers')
  }

  return (
    <Container>
      <Header returnPage='/settings/customers' />
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        {logo ? (
          <>
            <img src={logo} alt='logo' />
            <RefreshLogo
              src={refresh}
              onClick={open}
              onKeyPress={open}
              alt=''
              role='presentation'
            />
          </>
        ) : (
          <div role='button' onClick={open} onKeyPress={open} tabIndex={0}>
            <img src={camera} alt='upload' />
          </div>
        )}
      </DropzoneContainer>
      <FieldNames isLoading={loading}>{t('customers.creation.name')}</FieldNames>
      <div ref={nameRef}>
        <TextInput
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder={t('customers.creation.nameExample')}
          required
          width='487px'
        />
      </div>
      <FieldNames isLoading={loading}>{t('customers.creation.slug')}</FieldNames>
      <TextInput
        value={slug}
        onChange={(e) => setSlug(e.currentTarget.value)}
        required
        disabled
        width='487px'
      />
      <FieldNames isLoading={loading}>{t('customers.creation.email')}</FieldNames>
      <div ref={emailRef}>
        <TextInput
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder={t('customers.creation.emailExample')}
          required
          type={InputTypes.EMAIL}
          width='487px'
        />
      </div>
      <CascadeRow>
        <div>
          <FieldNames isLoading={loading}>{t('customers.creation.language')}</FieldNames>
          <Cascade options={LanguageOptions} onChange={(option) => setLanguage(option)} />
        </div>
        <div>
          <FieldNames isLoading={loading}>{t('customers.creation.watermarkPosition')}</FieldNames>
          <Cascade options={WatermarkOptions} onChange={(option) => setWaterMark(option)} />
        </div>
      </CascadeRow>
      <FieldNames isLoading={loading}>{t('customers.creation.status')}</FieldNames>
      <Cascade options={StatusOptions} onChange={(option) => setStatus(option)} />
      <ButtonContainer>
        <Button onClick={triggerCreation} disabled={!validity}>
          {t('customers.creation.create')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default Creation
