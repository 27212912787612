import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

export const Container = styled.div`
  overflow: auto;
  height: 100vh;
  width: 372px;
  background-color: ${(props) => props.theme.grey4};
`

export const LoadingContainer = styled.div`
  height: 100%;
  width: 372px;
  ${skeletonAnimation}
`
