import React, { useContext, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useLocation, useHistory } from 'react-router-dom'

import AddIcon from '@console/common/assets/addProduct.svg'
import FilterIcon from '@console/common/assets/filter.svg'
import ReportIcon from '@console/common/assets/icons/report.svg'
import Cascade, { SelectOptions } from '@console/common/components/Cascade'
import Chip from '@console/common/components/Chip'
import Header from '@console/common/components/Header'
import IconMenu from '@console/common/components/IconMenu'
import SearchBar from '@console/common/components/SearchBar'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { OrderOptions, UserRole } from '@console/common/services/api'
import Status from '@console/common/types/Status'

import CardsList from '../../components/CardsList'
import ExportCsvButton from '../../components/ExportCsvButton'
import ForwardProductButton from '../../components/ForwardProductButton'
import ProductCount from '../../components/ProductCount'
import SelectAllCheckbox from '../../components/SelectAllCheckbox'
import { CheckboxProvider } from '../../contexts/CheckboxContext'
import { ProductsProvider } from '../../contexts/ProductsContext'
import { SearchContext } from '../../contexts/SearchContext'
import {
  HeaderButtonsContainer,
  AddProductButton,
  MainContainer,
  FiltersContainer,
  FilterDivider,
  SearchContainer,
  FilterTopContainer,
  FilterMidContainer,
  Divider,
  FilterBottomContainer,
  FilterBottomContainerLeft,
  FilterBottomContainerRight
} from './styles'

type CASCADE_TYPE = 'status' | 'order'

interface Filter {
  label: string
  value: string
}

const List: React.FC = () => {
  const { t } = useTranslation(['portfolio'])
  const { t: tStatus } = useTranslation(['components/status-badge'])
  const { t: tFilter } = useTranslation(['components/filter-chip'])
  const [search, setSearch] = useState<string | null>(null)
  const [filters, setFilters] = useState<Array<Filter>>([])
  const [lastMonthActive, setLastMonthActive] = useState<string | null>(null)
  const [continueRegistration, setContinueRegistration] = useState(false)
  const {
    getSearchPreview,
    results,
    clearResults,
    loading: searchLoading
  } = useContext(SearchContext)
  const { session } = useContext(AuthContext)

  const history = useHistory()
  const location = useLocation()

  const orderOptions = [
    {
      label: t('selection.order.aToZ'),
      value: OrderOptions.A_TO_Z
    },
    {
      label: t('selection.order.zToA'),
      value: OrderOptions.Z_TO_A
    },
    {
      label: t('selection.order.sku'),
      value: OrderOptions.SKU
    },
    {
      label: t('selection.order.older'),
      value: OrderOptions.OLDER
    },
    {
      label: t('selection.order.newer'),
      value: OrderOptions.NEWER
    }
  ]

  const getDefaultValue = (option: string) => {
    const params = new URLSearchParams(location.search.toString())
    const value = params.get(option)?.toString()
    if (option === 'order') return orderOptions.find((op) => op.value === value) || orderOptions[4]
    return value
  }

  const [status, setStatus] = useState<Status | null>(getDefaultValue('status') as Status)
  const [order, setOrder] = useState<SelectOptions | null>(
    getDefaultValue('order') as SelectOptions
  )

  const updateQueryString = (option: string, value: string) => {
    const params = new URLSearchParams(location.search.toString())
    params.set(option, value)
    history.replace({ search: params.toString() })
  }

  const statusOptions = [
    {
      label: t('selection.status.generalStatus'),
      value: null
    },
    {
      label: tStatus('RUNNING'),
      value: Status.RUNNING
    },
    {
      label: tStatus('PAUSED'),
      value: Status.PAUSED
    },
    {
      label: tStatus('REJECTED'),
      value: Status.REJECTED
    },
    {
      label: tStatus('PENDING_REVIEW'),
      value: Status.PENDING_REVIEW
    },
    {
      label: tStatus('IN_PROGRESS'),
      value: Status.IN_PROGRESS
    }
  ]

  const filterOptions = [
    {
      label: tFilter('LAST_MONTH_ACTIVE'),
      value: 'lastMonthActive'
    }
  ]

  const updateCascade = (type: CASCADE_TYPE, option: SelectOptions | null) => {
    if (option) {
      const setType = type === 'order' ? setOrder : setStatus
      setType(type === 'order' ? option : option.value)
      updateQueryString(type, option.value)
    }
  }

  const handleIconMenuItem = (filter: Filter) => {
    if (filters.findIndex((f) => f.value === filter.value) === -1) {
      if (filter.value === 'lastMonthActive') setLastMonthActive(filter.value)
      setFilters([...filters, filter])
    }
  }

  const deleteFilter = (label: string) => {
    const filtered = filters.filter((f) => f.label !== label)
    if (label === tFilter('LAST_MONTH_ACTIVE')) setLastMonthActive(null)
    setFilters(filtered)
  }

  useEffect(() => {
    const multipleProductsData = localStorage.getItem('multipleProducts')
    if (multipleProductsData) setContinueRegistration(true)
  }, [])

  return (
    <ProductsProvider
      order={order?.value}
      status={status}
      search={search}
      lastMonthActive={lastMonthActive}
    >
      <CheckboxProvider>
        <MainContainer>
          <Header title={t('title')}>
            <HeaderButtonsContainer>
              {continueRegistration ? (
                <Link to='/registration/multiple' style={{ textDecoration: 'none' }}>
                  <AddProductButton>
                    <img src={ReportIcon} alt={t('continueRegistration')} />
                    <span>{t('continueRegistration')}</span>
                  </AddProductButton>
                </Link>
              ) : null}
              <Link to='/registration' style={{ textDecoration: 'none' }}>
                <AddProductButton>
                  <img src={AddIcon} alt={t('addProduct')} />
                  <span>{t('addProduct')}</span>
                </AddProductButton>
              </Link>
            </HeaderButtonsContainer>
          </Header>
          <SearchContainer>
            <FiltersContainer expanded={filters.length !== 0}>
              <FilterTopContainer>
                <SearchBar
                  placeholder={t('selection.searchPlaceholder')}
                  getSearchPreview={getSearchPreview}
                  results={results}
                  clearResults={clearResults}
                  searchCallback={(query: string) => setSearch(query || null)}
                  isLoading={searchLoading}
                />
                <FilterDivider />
                <Cascade
                  value={statusOptions.find((option) => option.value === status) as SelectOptions}
                  options={statusOptions}
                  onChange={(option) => updateCascade('status', option)}
                  placeholder={t('selection.status.productStatus')}
                  isSearchable={false}
                />
                <IconMenu
                  icon={FilterIcon}
                  items={filterOptions}
                  handleItem={handleIconMenuItem}
                  tooltip={t('selection.filterTooltip')}
                />
              </FilterTopContainer>
              {filters.length !== 0 && (
                <>
                  <Divider />
                  <FilterMidContainer>
                    {filters.map((f) => (
                      <Chip
                        key={f.value}
                        label={f.label}
                        variant='default'
                        handleDelete={deleteFilter}
                      />
                    ))}
                  </FilterMidContainer>
                </>
              )}
            </FiltersContainer>
          </SearchContainer>
          <FilterBottomContainer>
            <FilterBottomContainerLeft>
              <SelectAllCheckbox />
              <ExportCsvButton />
              {session?.role === UserRole.ADMIN ? <ForwardProductButton /> : null}
            </FilterBottomContainerLeft>
            <FilterBottomContainerRight>
              <ProductCount />
              <FilterDivider />
              <Cascade
                value={order}
                options={orderOptions}
                onChange={(option) => updateCascade('order', option)}
                placeholder={t('selection.order.orderBy')}
                isSearchable={false}
              />
            </FilterBottomContainerRight>
          </FilterBottomContainer>
          <CardsList />
        </MainContainer>
      </CheckboxProvider>
    </ProductsProvider>
  )
}

export default List
