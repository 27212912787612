import styled from 'styled-components'

export const Container = styled.div`
  margin-left: 44px;
  margin-right: 44px;
  color: ${(props) => props.theme.dark};

  small {
    display: block;
    font-weight: lighter;
    margin-top: 23px;
  }
`

export const FieldNames = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 17px;
  margin-bottom: 4px;
`

export const ButtonContainer = styled.div`
  margin-top: 37px;
`
