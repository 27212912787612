import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CheckboxIcon from '@console/common/assets/checkbox.svg'
import CheckboxOnIcon from '@console/common/assets/checkboxOn.svg'
import ViewerIcon from '@console/common/assets/viewer.svg'
import Button from '@console/common/components/Button'
import { Asset, getAssetSize } from '@console/common/services/api'

import {
  DownloadContainer,
  DownloadHeader,
  Checkbox,
  CheckAllContainer,
  FileTypeContainer,
  FileTextContainer,
  DownloadButtonContainer
} from './styles'

interface Props {
  glb?: Asset
  usdz?: Asset
  skp?: Asset
  handleDownload: (types: string[]) => void
  close: () => void
}

interface FileInfo {
  id: string
  type: string
  size: string
}

type CheckType = 'GLB' | 'USDZ' | 'SKP' | 'ALL'

const Download: React.FC<Props> = ({ glb, usdz, skp, handleDownload, close }: Props) => {
  const { t } = useTranslation(['portfolio'])
  const [usdzInfo, setUsdzInfo] = useState<FileInfo>()
  const [glbInfo, setGlbInfo] = useState<FileInfo>()
  const [skpInfo, setSkpInfo] = useState<FileInfo>()
  const [checked, setChecked] = useState<CheckType[]>([])

  useEffect(() => {
    if (glb) {
      getAssetSize(glb).then((size) => {
        setGlbInfo({ id: glb.id, size, type: glb.type })
      })
    }
    if (usdz) {
      getAssetSize(usdz).then((size) => {
        setUsdzInfo({ id: usdz.id, size, type: usdz.type })
      })
    }
    if (skp) {
      getAssetSize(skp).then((size) => {
        setSkpInfo({ id: skp.id, size, type: skp.type })
      })
    }
  }, [glb, usdz, skp])

  const handleCheckbox = (type: CheckType) => {
    if (isChecked(type)) {
      if (type === 'ALL') {
        return setChecked([])
      }
      return setChecked(checked.filter((c) => c !== type))
    }

    if (type === 'ALL') {
      return setChecked(['ALL', 'GLB', 'USDZ', 'SKP'])
    }

    return setChecked([...checked, type])
  }

  const isChecked = (type: CheckType) => checked.find((c) => c === type)

  const handleDownloadCallback = () => handleDownload(checked)

  return (
    <DownloadContainer>
      <div className='inside'>
        <DownloadHeader>{t('download.title')}</DownloadHeader>
        <CheckAllContainer>
          <span className='title'>{t('download.allButton')}</span>
          <Checkbox onClick={() => handleCheckbox('ALL')}>
            <img src={isChecked('ALL') ? CheckboxOnIcon : CheckboxIcon} alt='' />
          </Checkbox>
        </CheckAllContainer>
        {[glbInfo, usdzInfo, skpInfo].map((file, index) => (
          <React.Fragment key={`${index + 1}-${file?.id}`}>
            {file ? (
              <FileTypeContainer>
                <img className='icon' src={ViewerIcon} alt='' />
                <FileTextContainer>
                  {/* eslint-disable-next-line react/jsx-no-literals */}
                  <h3>{`${file?.type} (${file?.size})`}</h3>
                  <span>{t(`download.${file?.type}`)}</span>
                </FileTextContainer>
                <Checkbox
                  className='checkbox'
                  onClick={() => handleCheckbox(file.type as CheckType)}
                >
                  <img
                    src={isChecked(file.type as CheckType) ? CheckboxOnIcon : CheckboxIcon}
                    alt=''
                  />
                </Checkbox>
              </FileTypeContainer>
            ) : null}
          </React.Fragment>
        ))}
        <DownloadButtonContainer>
          <Button
            alternative
            style={{ width: '50%', height: '46px' }}
            onClick={() => close()}
            isLoading={false}
          >
            {t('detailed.popup.cancel')}
          </Button>
          <Button
            style={{ width: '50%', height: '46px' }}
            onClick={() => handleDownloadCallback()}
            isLoading={false}
          >
            {t('download.buttonName')}
          </Button>
        </DownloadButtonContainer>
      </div>
    </DownloadContainer>
  )
}

export default Download
