import React, { useContext, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import DeleteIcon from '@console/common/assets/delete.svg'
import EditIcon from '@console/common/assets/edit.svg'
import RefreshIcon from '@console/common/assets/refresh.svg'
import Table from '@console/common/components/Table'
import { ModalContext } from '@console/common/contexts/ModalContext'
import { User } from '@console/common/services/api'

import { inviteAgain } from '../../services/api'
import InviteStatus from '../../types/InviteStatus'
import { PopupOperation } from '../HomePopup'
import InviteStatusBadge from '../InviteStatusBadge'
import RoleBadge from '../RoleBadge'
import { InviteField, ActionsButtons } from './styles'

interface Props {
  users: User[]
  setPopupOperation: (userId: string, operation: PopupOperation) => void
  isLoading: boolean
}

const UsersTable: React.FC<Props> = ({ users, setPopupOperation, isLoading }: Props) => {
  const { t } = useTranslation(['settings'])
  const { isOpen, openModal } = useContext(ModalContext)

  const resendInvite = (userId: string) => {
    if (isOpen) return
    inviteAgain(userId).then((u) => {
      if (u) {
        setPopupOperation(userId, PopupOperation.INVITE)
        openModal()
      }
    })
  }

  const confirmDelete = (userId: string) => {
    if (isOpen) return
    setPopupOperation(userId, PopupOperation.DELETE)
    openModal()
  }

  const columns: { Header: string; accessor: string }[] = React.useMemo(
    () => [
      {
        Header: t('users.name'),
        accessor: 'name'
      },
      {
        Header: t('users.email'),
        accessor: 'email'
      },
      {
        Header: t('users.inviteStatus'),
        accessor: 'inviteStatus'
      },
      {
        Header: t('users.access'),
        accessor: 'access'
      },
      {
        Header: t('users.actions'),
        accessor: 'actions'
      }
    ],
    []
  )

  const data: { [x: string]: string | ReactElement }[] = React.useMemo(
    () =>
      users.map((user) => ({
        name: user.name,
        email: user.id,
        inviteStatus: (
          <InviteField>
            {user.auth0Id.length ? null : <div style={{ width: '36.58px' }} />}
            <InviteStatusBadge
              status={user.auth0Id.length ? InviteStatus.ACCEPTED : InviteStatus.NOT_ACCEPTED}
            />
            {user.auth0Id.length ? null : (
              <button type='button' onClick={() => resendInvite(user.id)}>
                <img src={RefreshIcon} alt='' />
              </button>
            )}
          </InviteField>
        ),
        access: <RoleBadge role={user.role} />,
        actions: (
          <ActionsButtons>
            <Link to={`/settings/users/edit/${user.id}`}>
              <img src={EditIcon} alt='' />
            </Link>
            <button type='button' onClick={() => confirmDelete(user.id)}>
              <img src={DeleteIcon} alt='' />
            </button>
          </ActionsButtons>
        )
      })),
    [users]
  )

  return <Table columns={columns} data={data} isLoading={isLoading} />
}

export default UsersTable
