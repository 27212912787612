import styled from 'styled-components'

export const Container = styled.div`
  margin-left: 44px;
  margin-right: 44px;
  color: ${(props) => props.theme.dark};

  small {
    display: block;
    font-weight: lighter;
    margin-top: 23px;
  }
`

export const EmptyStateContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.grey2};
`
