import React, { ReactElement, useContext } from 'react'

import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from '@console/common/contexts/AuthContext'

import { UserRole } from '../../services/api'

interface Props {
  children: ReactElement
  exact?: boolean
  path: string
}

const RouteWrapper: React.FC<Props> = ({ path, children, exact = false }: Props) => {
  const { session } = useContext(AuthContext)

  if (!session?.role) {
    return <div />
  }

  if (session.role !== UserRole.ADMIN && session.role !== UserRole.R2USER) {
    return <Redirect to='/forbidden' />
  }

  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  )
}

export default RouteWrapper
