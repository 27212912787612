import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import CheckMarkIcon from '@console/reviewer/assets/icons/checkMarkBlack.svg'
import LoadingIcon from '@console/reviewer/assets/icons/loading.svg'

import { CheckboxContext } from '../../contexts/CheckboxContext'
import { ApproveReviewButtonStyle } from './styles'

const ApproveReviewButton: React.FC = () => {
  const { t } = useTranslation(['review'])

  const { approveReviews, isAnyChecked, loading } = useContext(CheckboxContext)

  const onClick = async () => {
    await approveReviews()
  }

  return (
    <>
      {isAnyChecked ? (
        <ApproveReviewButtonStyle isLoading={loading} onClick={() => onClick()}>
          {loading ? (
            <img src={LoadingIcon} alt='loading' />
          ) : (
            <img src={CheckMarkIcon} alt={t('approveReview')} />
          )}
          {t('approveReview')}
        </ApproveReviewButtonStyle>
      ) : null}
    </>
  )
}

export default ApproveReviewButton
