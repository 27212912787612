import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
`

export const HeaderButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  button {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: ${(props) => props.theme.darkBlue4};

    :disabled {
      color: ${(props) => props.theme.grey10};
      cursor: default;
    }
  }
`

export const ArrowRight = styled.img`
  height: 17px;
  transform: rotate(180deg);

  // props.theme.grey10
  filter: invert(49%) sepia(0%) saturate(28%) hue-rotate(196deg) brightness(98%) contrast(85%);
`
