import styled from 'styled-components'

import StyledSkeleton from '@console/common/components/Skeleton'

export const CardDiv = styled.div`
  display: grid;
  width: 245px;
  height: 331px;
  border: ${(props) => `2px solid ${props.theme.grey9}`};
  border-radius: 8px;
  :hover {
    border: ${(props) => `2px solid ${props.theme.blue}`};
    box-shadow: ${(props) => `4px 4px 4px ${props.theme.grey8}`};
  }
  overflow: visible;
  grid-template-rows: 160px 38px 16px;
  cursor: pointer;
  background-color: ${(props) => props.theme.white};

  @media (max-width: 1440px) {
    width: 224px;
  }
`

export const Skeleton = styled(StyledSkeleton)`
  display: grid;
  width: 245px;
  height: 331px;
  border-radius: 8px;

  @media (max-width: 1440px) {
    width: 225px;
  }
`

export const ImageContainer = styled.div`
  max-height: 160px;
  width: 100%;
  justify-content: center;
  border-bottom: ${(props) => `2px solid ${props.theme.grey9}`};
  grid-area: 1 / 1;
  border-radius: 7px 7px 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
`

export const StatusContainer = styled.div`
  padding-top: 290px;
  justify-self: flex-end;
  padding-right: 16px;
  grid-area: 1 / 1;
`

export const Name = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Sku = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  margin-left: 16px;
  margin-right: 16px;
  color: ${(props) => props.theme.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Customer = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.grey8};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const OptionsContainer = styled.div`
  justify-self: start;
  margin-left: 10px;
  margin-bottom: 10px;
`

export const Ellipsis = styled.div`
  border-radius: 200px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: white;
  justify-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${(props) => props.theme.grey3};
  }
  img {
    width: 24px;
  }
`

export const HeaderText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.theme.dark};
  padding-left: 10px;
  padding-bottom: 10px;
`
