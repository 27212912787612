import React, { useEffect, useContext } from 'react'

import Auth0Lock from 'auth0-lock'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'

import config from '@console/common/config'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext, Session } from '@console/common/contexts/AuthContext'
import { ModalContext } from '@console/common/contexts/ModalContext'
import theme from '@console/common/theme.json'

import Logo from '../assets/logo1.svg'

interface Props {
  signUp: boolean
}

const Lock: React.FC<Props> = ({ signUp }: Props) => {
  const { t } = useTranslation(['login'])
  const { createSession } = useContext(AuthContext)
  const { openModal, isOpen } = useContext(ModalContext)
  const { analytics } = useContext(AnalyticsContext)
  const history = useHistory()
  const locationHash = useLocation().hash

  const clientID = config.auth.AUTH0_CLIENT_ID

  const options = {
    auth: {
      responseType: 'token',
      sso: false,
      params: {
        scope: 'openid email profile'
      },
      audience: config.auth.AUTH0_AUDIENCE
    },
    theme: {
      logo: Logo,
      primaryColor: theme.dark
    },
    language: t('language'),
    languageDictionary: {
      title: t('title'),
      loginSubmitLabel: t('enter'),
      databaseEnterpriseAlternativeLoginInstructions: t('or'),
      forgotPasswordAction: t('forgotPassword'),
      signUpTerms: t('termsAndConditions')
    },
    container: config.auth.AUTH0_CONTAINER,
    allowedConnections: ['Username-Password-Authentication', 'google-oauth2'],
    allowSignUp: signUp,
    allowLogin: !signUp,
    showTerms: true,
    mustAcceptTerms: true,
    hashCleanup: false
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lock = new Auth0Lock(clientID, config.auth.AUTH0_DOMAIN, options)
  const from = localStorage.getItem('from') || '/'

  const fixForm = () => {
    const form = document.querySelector('.horizontal-fade-exit')
    if (!form) return
    form.classList.remove('horizontal-fade-exit')
    form.classList.remove('horizontal-fade-exit-active')
  }

  useEffect(() => {
    // TODO: Check for UNREGISTERED error and display this error to the user
    if (!/access_token|id_token|error/.test(locationHash) && !isOpen) lock.show()
  }, [lock])

  lock.on('signin ready', () => {
    fixForm()
  })

  lock.on('signup ready', () => {
    fixForm()

    const termsAndConditions = document.getElementById('terms-and-conditions')
    if (!termsAndConditions) return

    analytics?.track(AnalyticsEvent.SIGN_UP)

    termsAndConditions.addEventListener(
      'click',
      (e) => {
        openModal()
        e.preventDefault()
      },
      true
    )
    termsAndConditions.addEventListener(
      'mousedown',
      (e) => {
        e.preventDefault()
      },
      true
    )
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lock.on('authenticated', async (authResult: { expiresIn?: any; accessToken: string }) => {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())

    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('expires_at', expiresAt)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lock.getProfile(authResult.accessToken, (err, profile: { sub: any }) => {
      const { accessToken } = authResult
      const { sub: auth0Id } = profile

      localStorage.setItem('auth0_id', auth0Id)
      createSession(accessToken, (session: Session | null) => {
        analytics?.track(AnalyticsEvent.LOGIN)
        analytics?.identify(session?.email)
        analytics?.people.set({
          $email: session?.email,
          $name: session?.name,
          role: session?.role,
          customer: session?.customer.name
        })

        localStorage.removeItem('from')
        history.replace(from)
      })
    })
  })

  return (
    <div>
      <div id={config.auth.AUTH0_CONTAINER} />
    </div>
  )
}
export default Lock
