// https://medium.com/octopus-wealth/skeleton-loading-pages-with-react-5a931f12677b
import styled, { css } from 'styled-components'

export const skeletonAnimation = css`
  background: ${(props) => props.theme.grey3};
  animation-name: pulse;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  @keyframes pulse {
    0% {
      opacity: 50%;
    }
    100% {
      opacity: 100%;
    }
  }
`

const StyledSkeleton = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;

  ${skeletonAnimation}
`

export default StyledSkeleton
