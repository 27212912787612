import { api, CustomerProduct, Product } from '@console/common/services/api'

export const createProduct = async (product: Partial<Product>): Promise<Product | null> => {
  try {
    const { data } = await api.post<Product>('/v1/products', product)
    return data
  } catch (e) {
    return null
  }
}

export const createProducts = async (
  products: Partial<Product>[]
): Promise<CustomerProduct[] | null> => {
  try {
    const { data } = await api.post<CustomerProduct[]>('/v1/products/multiple', products)
    return data
  } catch (e) {
    return null
  }
}
