import React, { useState } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import deleteIcon from '@console/common/assets/thrash.svg'
import { Comment } from '@console/common/services/api'

import editIcon from '../../assets/icons/editIcon.svg'
import InputComponent from './InputComponent'
import {
  CommentUser,
  CommentContainer,
  CommentText,
  Attatchment,
  AlterCommentButton,
  AlterButtonContainer,
  CommentDate,
  ShowCommentsContainer
} from './styles'

interface Props {
  comments: Comment[]
  deleteComment?: (id: string) => void
  editComment?: (
    id: string,
    content?: string,
    attachments?: string[]
  ) => (input?: string, attachments?: string[]) => void
}

const ShowComments: React.FC<Props> = ({ comments, deleteComment, editComment }: Props) => {
  const { t } = useTranslation(['components/comments-bar'])
  const [edit, setEdit] = useState(false)

  return (
    <>
      {comments
        .map((c) => (
          <ShowCommentsContainer key={c.id}>
            {!edit ? (
              <>
                {c.userId ? (
                  <CommentUser>
                    <span>
                      {c.userId} {t('COMMENTED')}
                    </span>
                  </CommentUser>
                ) : null}
                <CommentContainer key={c.id}>
                  <CommentText>{c.content}</CommentText>
                  <Attatchment>
                    {c.attachments.map((url) => (
                      <a href={url} target='_blank' rel='noopener noreferrer' key={url}>
                        <img src={url} alt={url} />
                      </a>
                    ))}
                  </Attatchment>
                  <div className='button-container' />
                  {deleteComment && editComment ? (
                    <AlterButtonContainer>
                      <AlterCommentButton onClick={() => setEdit(true)}>
                        <img src={editIcon} alt={t('COMMENTS_BUTTONS.EDIT')} />
                      </AlterCommentButton>
                      <AlterCommentButton onClick={() => deleteComment(c.id)}>
                        <img src={deleteIcon} alt={t('COMMENTS_BUTTONS.DELETE')} />
                      </AlterCommentButton>
                    </AlterButtonContainer>
                  ) : (
                    <AlterButtonContainer>
                      <CommentDate>{format(new Date(c.createdAt), 'dd/MM/yyyy')}</CommentDate>
                    </AlterButtonContainer>
                  )}
                </CommentContainer>
              </>
            ) : (
              <InputComponent
                initialInput={c.content}
                initialAttachments={c.attachments}
                cancelCallback={() => setEdit(false)}
                editCallback={editComment ? editComment(c.id) : undefined}
              />
            )}
          </ShowCommentsContainer>
        ))
        .reverse()}
    </>
  )
}

export default ShowComments
