import styled, { css } from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

interface Props {
  isLoading: boolean
}

export const MainContainer = styled.div`
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
`

export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 39px;
  color: ${(props) => props.theme.dark};
`

export const SectionContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`

export const SectionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
`

export const SectionTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 39px;
  color: ${(props) => props.theme.dark};
`

export const SectionDescription = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.grey6};
`

export const FieldNames = styled.h5<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 17px;
  margin-bottom: 4px;
  ${(props) => props.isLoading && skeletonAnimation}
  color: ${(props) => (props.isLoading ? 'transparent' : props.theme.dark)};
  width: ${(props) => (props.isLoading ? '200px' : null)};
  border-radius: 5px;
`

export const EanField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 330px;
`

export const PlusButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: ${(props) => props.theme.blue};
`

export const Separator = styled.hr`
  border: 1px solid ${(props) => props.theme.grey2};
  width: 100%;
`

export const Extensions = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.grey6};
  margin-top: -20px;
`
interface UploadContainerProps {
  disabled?: boolean
}

export const UploadContainer = styled.div<UploadContainerProps>`
  text-align: center;
  height: 250px;
  width: 100%;
  border: 1px dashed ${(props) => props.theme.dark};
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey4};
  ${({ disabled }) =>
    disabled
      ? css`
          filter: grayscale(100%);
        `
      : undefined}
`

export const UploadContainerEmpty = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    height: 75px;
    // props.theme.blue
    filter: invert(42%) sepia(95%) saturate(1461%) hue-rotate(167deg) brightness(91%) contrast(102%);
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: ${(props) => props.theme.blue};
  }
`

export const UploadButton = styled.div`
  display: flex;
  text-align: center;
  border: 2px solid ${(props) => props.theme.blue};
  box-sizing: border-box;
  border-radius: 8px;
  height: 58px;
  width: 30%;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.blue};
  }
`

export const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
`
