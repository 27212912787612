import styled from 'styled-components'

import StyledSkeleton from '@console/common/components/Skeleton'

interface Props {
  height?: string
  width?: string
}

export const CardDiv = styled.div<Props>`
  width: ${(props) => (props.width ? props.width : '200px')};
  height: ${(props) => (props.height ? props.height : '200px')};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.grey2};
  background-color: ${(props) => props.theme.white};
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Skeleton = styled(StyledSkeleton)<Props>`
  width: ${(props) => (props.width ? props.width : '200px')};
  height: ${(props) => (props.height ? props.height : '200px')};
  border-radius: 8px;
`

export const Image = styled.img<Props>`
  height: ${(props) => (props.height ? props.height : '200px')};
  max-width: ${(props) => (props.width ? props.width : '200px')};
  border-radius: 8px;
  object-fit: cover;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  height: 100%;
`

export const IconElement = styled.img`
  width: 68px;
  height: 66px;
`

export const ExtensionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
`

export const HoverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`

interface ButtonProps {
  direction: 'left' | 'right'
  deleteButton?: boolean
}

export const HoverButton = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 50px;
  cursor: pointer;
  background-color: ${(props) => props.theme.grey3};
  border: 2px solid ${(props) => props.theme.blue};
  box-sizing: border-box;
  ${(props) =>
    props.direction === 'left'
      ? `border-right: 1px solid ${props.theme.blue}`
      : `border-left: 1px solid ${props.theme.blue}`};
  border-radius: ${(props) => (props.direction === 'left' ? '8px 0px 0px 8px' : '0px 8px 8px 0px')};

  img {
    width: 26px;
    height: 26px;
    // props.theme.blue
    filter: invert(42%) sepia(95%) saturate(1461%) hue-rotate(167deg) brightness(91%) contrast(102%);
  }

  :hover {
    background-color: ${(props) => (props.deleteButton ? props.theme.red2 : props.theme.blue)};
    ${(props) => (props.deleteButton ? `border: 2px solid ${props.theme.red3}` : null)};

    img {
      filter: ${(props) =>
        props.deleteButton
          ? // props.theme.red3
            'invert(19%) sepia(74%) saturate(2014%) hue-rotate(340deg) brightness(87%) contrast(88%)'
          : // props.theme.white
            'invert(99%) sepia(100%) saturate(2%) hue-rotate(90deg) brightness(109%) contrast(100%)'};
    }
  }
`
