import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { SelectOptions } from '@console/common/components/Cascade'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'

import { Filters, SKUOrderOptions } from '../services/api'

const DEFAULT_LIMIT = 10

interface Props {
  children: ReactNode
}

interface ExtendedFilters extends Filters {
  dateOptions: SelectOptions[]
  orderByOptions: SelectOptions[]
  setFromOption: (date: SelectOptions) => void
  fromOption: SelectOptions | null
  setOrderByOption: (order: SelectOptions) => void
  orderByOption: SelectOptions | null
}

export const FiltersContext = createContext<ExtendedFilters>({} as ExtendedFilters)

export const FiltersProvider: React.FC<Props> = ({ children }: Props) => {
  const { t } = useTranslation(['performance'])
  const limit = DEFAULT_LIMIT

  const { session, assumedCustomer } = useContext(AuthContext)

  const customerId =
    session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER
      ? assumedCustomer?.id
      : undefined

  const dateOptions = [7, 14, 30, 60].map((number) => ({
    label: t('pages.general.dateSelect.last', { number }),
    value: new Date(Date.now() - number * 24 * 60 * 60 * 1000)
  }))

  const orderByOptions = [
    {
      label: t('pages.general.orderSelect.sku'),
      value: SKUOrderOptions.SKU
    },
    {
      label: t('pages.general.orderSelect.mostPageViews'),
      value: SKUOrderOptions.MostPageViews
    },
    {
      label: t('pages.general.orderSelect.leastPageViews'),
      value: SKUOrderOptions.LeastPageViews
    },
    {
      label: t('pages.general.orderSelect.mostInteractions'),
      value: SKUOrderOptions.MostInteractions
    },
    {
      label: t('pages.general.orderSelect.leastInteractions'),
      value: SKUOrderOptions.LeastInteractions
    },
    {
      label: t('pages.general.orderSelect.mostAddedToCart'),
      value: SKUOrderOptions.MostAddedToCart
    },
    {
      label: t('pages.general.orderSelect.leastAddedToCart'),
      value: SKUOrderOptions.LeastAddedToCart
    }
  ]

  const [to] = useState<Date>(new Date())
  const [from, setFrom] = useState<Date>(dateOptions[0].value)
  const [orderBy, setOrderBy] = useState<SKUOrderOptions>(orderByOptions[1].value)

  const [fromOption, setFromOption] = useState<SelectOptions | null>(dateOptions[0])
  const [orderByOption, setOrderByOption] = useState<SelectOptions | null>(orderByOptions[1])

  useEffect(() => {
    if (fromOption) setFrom(fromOption.value)
  }, [fromOption])

  useEffect(() => {
    if (orderByOption) setOrderBy(orderByOption.value)
  }, [orderByOption])

  return (
    <FiltersContext.Provider
      value={{
        dateOptions,
        orderByOptions,
        orderBy,
        orderByOption,
        setOrderByOption,
        from,
        fromOption,
        setFromOption,
        to,
        customerId,
        limit
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
