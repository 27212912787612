import styled from 'styled-components'

interface ButtonProps {
  approve?: 'approve' | 'reject' | 'review'
}

export const Button = styled.button<ButtonProps>`
  border-radius: 8px;
  width: 100%;
  height: 44px;
  border: 0px;
  cursor: pointer;

  span {
    font-size: 20px;
    text-align: center;
  }

  background-color: ${(props) =>
    props.approve === 'reject' ? props.theme.red5 : props.theme.green2};
`

export const ButtonText = styled.span`
  color: white;
  line-height: 1.29;
  text-align: center;
`
