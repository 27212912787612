import React from 'react'

import CloseIcon from '@console/common/assets/close.svg'

import { Container, InfoContainer, InfoText, Icon, CloseButton, VerticalBar } from './styles'

interface Props {
  text?: string
  color?: string
  handleClose: () => void
  filter?: string
  icon: string
}

const Toast: React.FC<Props> = ({ text, color, handleClose, filter, icon }: Props) => (
  <Container color={color}>
    <InfoContainer>
      <VerticalBar color={color} />
      <Icon src={icon} alt='done icon' color={color} filter={filter} />
      <InfoText>{text}</InfoText>
      <CloseButton onClick={() => handleClose()}>
        <img src={CloseIcon} alt='close btn' />
      </CloseButton>
    </InfoContainer>
  </Container>
)

export default Toast
