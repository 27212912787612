import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 0.5fr 80px;
  align-items: center;
  align-content: center;
  justify-content: center;
`

export const MaintText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  padding-top: 107px;
  width: 449px;
`

export const SubText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 449px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
