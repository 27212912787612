import React, { useContext, useEffect } from 'react'

import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { ModalProvider } from '@console/common/contexts/ModalContext'

import { SearchProvider } from './contexts/SearchContext'
import Detailed from './pages/Detailed'
import Edit from './pages/Edit'
import ForwardProduct from './pages/ForwardProduct'
import List from './pages/List'

const Portfolio: React.FC = () => {
  const { path } = useRouteMatch()
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    analytics?.track(AnalyticsEvent.PORTFOLIO)
  }, [analytics])

  return (
    <Switch>
      <Route exact path={path}>
        <SearchProvider>
          <List />
        </SearchProvider>
      </Route>
      <Route exact path={`${path}/forward`}>
        <ModalProvider>
          <ForwardProduct />
        </ModalProvider>
      </Route>
      <Route exact path={`${path}/:productId`}>
        <ModalProvider>
          <Detailed />
        </ModalProvider>
      </Route>
      <Route path={`${path}/:productId/edit`}>
        <ModalProvider>
          <Edit />
        </ModalProvider>
      </Route>
    </Switch>
  )
}

export default Portfolio
