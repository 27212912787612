import React, { useMemo } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import CameraIcon from '@console/common/assets/cameraLine.svg'
import ModelIcon from '@console/common/assets/cubeOutline.svg'
import theme from '@console/common/theme.json'

import { UploadContainer, UploadContainerEmpty, UploadButton } from './styles'

interface Props {
  loading: boolean
  type: 'IMAGE' | 'MODEL'
  onDrop: (acceptedFiles: File[]) => void
  disabled?: boolean
  children?: React.ReactNode
}

const UploadArea: React.FC<Props> = ({ loading, disabled, type, onDrop, children }: Props) => {
  const { t } = useTranslation(['portfolio'])

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: !disabled ? onDrop : () => undefined,
    accept: type === 'IMAGE' ? 'image/*' : undefined,
    noClick: !!children,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const baseStyle = {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.dark,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .15s ease-in-out'
  }

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <UploadContainer disabled={disabled} {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {children || (
        <UploadContainerEmpty>
          {type === 'IMAGE' ? (
            <img src={CameraIcon} alt='camera' />
          ) : (
            <img src={ModelIcon} alt='model' />
          )}
          <span>{t('edit.drop')}</span>
          <UploadButton>
            <span>{t('edit.click')}</span>
          </UploadButton>
        </UploadContainerEmpty>
      )}
    </UploadContainer>
  )
}

export default UploadArea
