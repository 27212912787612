import React, { useCallback, useEffect, useState } from 'react'

import { InputAdornment } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

import searchIcon from '../../assets/search.svg'
import { SearchBarWrapper, Icon, CustomPaper } from './styles'

interface Props {
  placeholder: string
  getSearchPreview: (query: string) => void
  results: string[]
  clearResults: () => void
  searchCallback: (query: string) => void
  isLoading: boolean
}

const SearchBar: React.FC<Props> = ({
  placeholder,
  getSearchPreview,
  results,
  clearResults,
  searchCallback,
  isLoading
}: Props) => {
  const { t } = useTranslation(['components/search-bar'])
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)

  const search = (query: string) => {
    clearResults()
    searchCallback(query)
  }

  const setSearchPreview = useCallback(
    debounce((text) => {
      getSearchPreview(text)
    }, 500),
    []
  )

  useEffect(() => {
    if (inputValue.length > 2) {
      setOpen(true)
      setSearchPreview(inputValue)
    }
  }, [inputValue, setSearchPreview])

  return (
    <SearchBarWrapper>
      <Autocomplete
        id='size-small-standard'
        className='auto-complete'
        size='small'
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        loading={isLoading}
        loadingText={t('loading')}
        noOptionsText={t('noOptions')}
        options={results}
        onChange={(event, input) => {
          if (input) {
            if (event.nativeEvent.type === 'click') {
              search(input)
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='standard'
            placeholder={placeholder}
            value={inputValue}
            className='text-input'
            onChange={(e) => {
              if (!e.target.value.length) {
                setOpen(false)
              }
              setInputValue(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                search(inputValue)
                setOpen(false)
              }
            }}
            InputProps={{
              ...params.InputProps,
              className: 'adornment',
              startAdornment: (
                <InputAdornment position='start'>
                  <button type='button' className='transparent'>
                    <Icon src={searchIcon} alt='' />
                  </button>
                </InputAdornment>
              ),
              endAdornment: <></>,
              disableUnderline: true
            }}
          />
        )}
        PaperComponent={({ children }) => <CustomPaper>{children}</CustomPaper>}
      />
    </SearchBarWrapper>
  )
}

export default SearchBar
