import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import RequestImage from '@console/common/assets/illustrations/personIdea.svg'
import UploadImage from '@console/common/assets/illustrations/personUsingPC.svg'
import Button from '@console/common/components/Button'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { createHanaAsset } from '@console/common/services/api'

import { RegistrationSteps, ProcessContext, ProductData } from '../../../contexts/ProcessContext'
import { MainContainer, ContentContainer } from './styles'

const ModelDecision: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const { setCurrentStep, setRegistering } = useContext(ProcessContext)
  const { analytics } = useContext(AnalyticsContext)

  const modelUpload = async () => {
    analytics?.track(AnalyticsEvent.REGISTRATION_DECISION_UPLOAD)

    const productDataItem = localStorage.getItem('productData')
    if (!productDataItem) return
    const productData = JSON.parse(productDataItem) as ProductData

    setRegistering(true)

    /**
     * Creates an asset in Hana3D so that every model uploaded in the next
     * step is a child of this asset in Hana3D DB
     */
    const hanaAssetId = await createHanaAsset(productData.name)
    if (!hanaAssetId) return
    localStorage.setItem('hanaAssetId', hanaAssetId)

    setCurrentStep(RegistrationSteps.MODEL_UPLOAD)
    setRegistering(false)
  }

  const finishRegistration = () => {
    analytics?.track(AnalyticsEvent.REGISTRATION_DECISION_FINISH)
    localStorage.setItem('registrationStep', RegistrationSteps.CONFIRMATION)
    setCurrentStep(RegistrationSteps.CONFIRMATION)
  }

  return (
    <div>
      <MainContainer>
        <ContentContainer>
          <span>{t('modelSpecifications.have3dModels')}</span>
          <img src={UploadImage} alt={t('modelSpecifications.upload')} />
          <Button style={{ width: '100%' }} onClick={modelUpload}>
            {t('modelSpecifications.upload')}
          </Button>
        </ContentContainer>
        <ContentContainer>
          <span>{t('modelSpecifications.request3d')}</span>
          <img src={RequestImage} alt={t('modelSpecifications.finish')} />
          <Button style={{ width: '100%' }} onClick={finishRegistration}>
            {t('modelSpecifications.finish')}
          </Button>
        </ContentContainer>
      </MainContainer>
    </div>
  )
}

export default ModelDecision
