import React, { createContext, ReactNode, useState, useCallback, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole, updateAsset, AssetStatus } from '@console/common/services/api'

import { ProductContext } from './ProductContext'
import { VersionContext } from './VersionContext'

interface Props {
  children: ReactNode
}

export enum ReviewStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

interface ReviewContextData {
  loading: boolean
  reviewStatus: ReviewStatus | null
  setReviewStatus: (reviewStatus: ReviewStatus | null) => void
  confirmReviewUpdate: () => void
}

export const ReviewContext = createContext<ReviewContextData>({} as ReviewContextData)

export const ReviewProvider: React.FC<Props> = ({ children }: Props) => {
  const { session } = useContext(AuthContext)
  const { product, neighbours } = useContext(ProductContext)
  const { versionIndex } = useContext(VersionContext)
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus | null>(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const redirect = () => history.push(neighbours?.next?.id ? neighbours.next.id : '/review')

  const [approval, rejection] =
    session?.role === UserRole.ARTIST || session?.role === UserRole.FREELANCER
      ? [AssetStatus.TEAM_APPROVED, AssetStatus.TEAM_REJECTED]
      : [AssetStatus.CUSTOMER_APPROVED, AssetStatus.CUSTOMER_REJECTED]

  const confirmReviewUpdate = useCallback(async () => {
    if (!product) return
    setLoading(true)
    await Promise.all(
      product.Versions[versionIndex]?.Assets?.map(async (asset) =>
        updateAsset(asset.id, {
          status:
            reviewStatus === ReviewStatus.APPROVED
              ? approval
              : reviewStatus === ReviewStatus.REJECTED
              ? rejection
              : AssetStatus.UNDER_REVIEW
        })
      )
    )
    setReviewStatus(null)
    setLoading(false)
    if (reviewStatus) {
      redirect()
    } else {
      window.location.reload()
    }
  }, [reviewStatus])

  return (
    <ReviewContext.Provider
      value={{
        loading,
        reviewStatus,
        setReviewStatus,
        confirmReviewUpdate
      }}
    >
      {children}
    </ReviewContext.Provider>
  )
}
