import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { ProductContext } from '../../contexts/ProductContext'
import { InfoBar, TextStyleBold, TextStyleLight } from './styles'

const NeighboursArrow: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { product, loading } = useContext(ProductContext)

  const name = product ? product.name : ''
  const sku = product ? product.sku : t('notFound.sku')

  const SKULiteral = 'SKU: '

  return (
    <>
      <InfoBar isLoading={loading}>
        {loading ? null : (
          <TextStyleLight>
            <TextStyleBold> {name} </TextStyleBold>
            {SKULiteral} {sku}
          </TextStyleLight>
        )}
      </InfoBar>
    </>
  )
}

export default NeighboursArrow
