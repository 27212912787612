import React, { createContext, ReactNode, useState } from 'react'

interface Props {
  children: ReactNode
}

interface CommentsContextData {
  hasInput: boolean
  setHasInput: (hasInput: boolean) => void
  hasCommented: boolean
  setHasCommented: (hasInput: boolean) => void
}

export const CommentsContext = createContext<CommentsContextData>({} as CommentsContextData)

export const CommentsProvider: React.FC<Props> = ({ children }: Props) => {
  const [hasInput, setHasInput] = useState(false)
  const [hasCommented, setHasCommented] = useState(false)

  return (
    <CommentsContext.Provider
      value={{
        hasInput,
        setHasInput,
        hasCommented,
        setHasCommented
      }}
    >
      {children}
    </CommentsContext.Provider>
  )
}
