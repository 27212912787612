import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    --vh: 0;
    background-color: #f9f9f9;
  }

  * {
    font-family: Inter;
  }
  a {
    text-decoration: none;
  }
`

export default GlobalStyle
