import React from 'react'

import Color from '@console/common/types/Color'

import { LoadingAnimation } from './styles'

interface LoadingProps {
  color?: Color
}

const Loading: React.FC<LoadingProps> = ({ color = 'rgb(0, 0, 0)' }: LoadingProps) => (
  <LoadingAnimation>
    <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient id='linear' x1='0%' x2='100%' y1='0%' y2='0%'>
          <stop offset='0%' stopColor={color} stopOpacity={0.2} />
          <stop offset='25%' stopColor={color} stopOpacity={0.2} />
          <stop offset='100%' stopColor={color} stopOpacity={0.4} />
        </linearGradient>
      </defs>
      <circle cx={50} cy={50} r={45} stroke='url(#linear)' strokeWidth={10} fill='transparent' />
    </svg>
  </LoadingAnimation>
)

export default Loading
