import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.grey5};
  box-shadow: 0px 0px 8px 4px rgba(104, 104, 104, 0.25);
  border-radius: 8px 8px 0px 0px;

  width: calc(100% - 240px);
  height: 20%;
  position: fixed;
  bottom: 0;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding-left: 64px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  padding-right: 64px;
`

export const Text = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.dark2};
  @media screen and (min-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 16px;
  }
`

export const Logo = styled.img`
  width: 100px;
  height: 56px;
`
