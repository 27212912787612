import React, { useContext, useEffect, useState, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '@console/common/components/Button'
import Header from '@console/common/components/Header'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'

import { createProducts } from '../../services/api'
import {
  MainContainer,
  SubTitle,
  ContentContainer,
  CustomerFromTo,
  ButtonsContainer
} from './styles'
import Table from './Table'

interface ProductData {
  id: string
  name: string
  sku: string
  pdpUrl: string
  ean: string
}

interface ForwardData {
  customer: {
    id: string
    name: string
  }
  products: ProductData[]
}

const ForwardProduct: React.FC = () => {
  const { t } = useTranslation(['portfolio'])
  const history = useHistory()
  const { analytics } = useContext(AnalyticsContext)
  const [loading, setLoading] = useState(true)
  const [customerId, setCustomerId] = useState<string>()
  const [customerName, setCustomerName] = useState<string>()
  const [products, setProducts] = useState<ProductData[]>([])

  const { session } = useContext(AuthContext)

  useEffect(() => {
    if (session?.role !== UserRole.ADMIN) return
    setLoading(true)
    const forwardDataItem = localStorage.getItem('forwardProduct')
    if (!forwardDataItem) return
    const forwardData = JSON.parse(forwardDataItem) as ForwardData
    setCustomerId(forwardData.customer.id)
    setCustomerName(forwardData.customer.name)
    setProducts(forwardData.products)
    setLoading(false)
  }, [session])

  const clearForwardData = () => {
    localStorage.removeItem('forwardProduct')
  }

  const cancel = () => {
    analytics?.track(AnalyticsEvent.PORTFOLIO_FORWARD_CANCEL)
    clearForwardData()
    history.push('/portfolio')
  }

  const duplicate = useCallback(async () => {
    analytics?.track(AnalyticsEvent.PORTFOLIO_FORWARD_PRODUCT)
    await createProducts(
      products.map((product) => ({
        id: product.id,
        name: product.name,
        sku: product.sku,
        pdpUrl: product.pdpUrl,
        customerId
      }))
    )
    clearForwardData()
    history.push('/portfolio')
  }, [products])

  return (
    <>
      <MainContainer>
        <Header title={t('forwardProduct.title')} returnPage='/portfolio' />
        <SubTitle>{t('forwardProduct.subtitle')}</SubTitle>
        <ContentContainer>
          <CustomerFromTo isLoading={loading}>
            <span>{t('forwardProduct.destination')}</span>
            <span>{customerName}</span>
          </CustomerFromTo>
          <Table products={products} setProducts={setProducts} loading={loading} />
          <ButtonsContainer>
            <Button isLoading={loading} onClick={cancel} alternative>
              {t('forwardProduct.cancel')}
            </Button>
            <Button isLoading={loading} onClick={duplicate}>
              {t('forwardProduct.duplicate')}
            </Button>
          </ButtonsContainer>
        </ContentContainer>
      </MainContainer>
    </>
  )
}

export default ForwardProduct
