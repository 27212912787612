import styled from 'styled-components'

interface Props {
  isActive: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 19px;
`

export const StyledStatusBadge = styled.span<Props>`
  width: 92px;
  height: 19px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-align: center;

  color: ${(props) => props.theme.white};
  border: 0;
  background-color: ${(props) => (props.isActive ? props.theme.green : props.theme.red)};
`
