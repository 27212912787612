import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const GifContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  gap: 49px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  width: 493px;
  height: 82px;
`

export const InfoTitle = styled.span`
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: ${(props) => props.theme.grey10};
  margin-left: 24px;
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 493px;
`

export const PercentageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 493px;
  height: 82px;
  background-color: ${(props) => props.theme.white};
  gap: 24px;
`

export const Percentage = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.blue};
  margin-left: 24px;
`

export const PercentageText = styled.span`
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.theme.black};
`

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 493px;
  height: 82px;
  gap: 15px;
`

export const LineText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.black};
`
