import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'

import { ProductContext } from '../../contexts/ProductContext'
import { ReviewContext, ReviewStatus } from '../../contexts/ReviewContext'
import { VersionContext } from '../../contexts/VersionContext'
import { Button, ButtonText } from './styles'

interface Props {
  mode: 'approve' | 'reject'
}

const ReviewButton: React.FC<Props> = ({ mode }: Props) => {
  const { t } = useTranslation(['review'])
  const { analytics } = useContext(AnalyticsContext)
  const { setReviewStatus } = useContext(ReviewContext)
  const { product } = useContext(ProductContext)
  const { versionIndex } = useContext(VersionContext)

  const reviewCallback = () => {
    const event =
      mode === 'approve'
        ? AnalyticsEvent.REVIEWER_APPROVE_CLICK
        : mode === 'reject'
        ? AnalyticsEvent.REVIEWER_REJECT_CLICK
        : AnalyticsEvent.REVIEWER_REVIEW_CLICK
    analytics?.track(event, { versionId: product?.Versions[versionIndex].id })

    setReviewStatus(
      mode === 'approve' ? ReviewStatus.APPROVED : mode === 'reject' ? ReviewStatus.REJECTED : null
    )
  }

  return (
    <Button approve={mode} type='button' className='approval-button' onClick={reviewCallback}>
      <ButtonText>{t(`approval.${mode}`)}</ButtonText>
    </Button>
  )
}

export default ReviewButton
