import type { ViewerRef } from '@r2u/viewer/dist/types'

const flatSlotsMaterials = (
  sm: ReturnType<ViewerRef['getAllSlotsMaterials']>
): { id: string; name: string; materials: string[] }[] =>
  [
    sm?.slots.map((slot) => ({ id: sm.id, name: slot.name, materials: slot.materials })) || [],
    sm?.models.map(flatSlotsMaterials).flat() || []
  ].flat()

export default flatSlotsMaterials
