import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const CheckboxContainer = styled.div`
  position: absolute;
  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.dark2};
  }
`
