import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 19px;
`

export const StyledStatusBadge = styled.span`
  width: 92px;
  height: 17px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;

  color: ${(props) => props.theme.blue};
  border: ${(props) => `1px solid ${props.theme.blue}`};
  background-color: ${(props) => props.theme.white};
`
