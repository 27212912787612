import React, { useContext } from 'react'

import LoadingIcon from '@console/common/components/LoadingIcon'

import ProgressBar from '../../components/SingleProductProgress'
import { RegistrationSteps, ProcessContext } from '../../contexts/ProcessContext'
import Confirmation from './Confirmation'
import ImageUpload from './ImageUpload'
import ModelConversions from './ModelConversions'
import ModelDecision from './ModelDecision'
import ModelUpload from './ModelUpload'
import ProductData from './ProductData'
import ProductView from './ProductView'
import { MainContainer, Loading } from './styles'

const SingleProduct: React.FC = () => {
  const { currentStep, registering } = useContext(ProcessContext)

  const renderSwitch = (step: RegistrationSteps) => {
    switch (step) {
      case RegistrationSteps.PRODUCT_DATA:
        return <ProductData />
      case RegistrationSteps.IMAGE_UPLOAD:
        return <ImageUpload />
      case RegistrationSteps.MODEL_DECISION:
        return <ModelDecision />
      case RegistrationSteps.MODEL_UPLOAD:
        return <ModelUpload />
      case RegistrationSteps.MODEL_CONVERSIONS:
        return <ModelConversions />
      case RegistrationSteps.PRODUCT_VIEW:
        return <ProductView />
      case RegistrationSteps.CONFIRMATION:
        return <Confirmation />
      default:
        return <ProductData />
    }
  }

  return (
    <>
      {registering ? (
        <Loading>
          <LoadingIcon />
        </Loading>
      ) : (
        <MainContainer>
          <ProgressBar />
          {renderSwitch(currentStep)}
        </MainContainer>
      )}
    </>
  )
}

export default SingleProduct
