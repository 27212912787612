import React, { ReactElement, useContext } from 'react'

import { Route, Redirect, useHistory } from 'react-router-dom'

import { AuthContext } from '@console/common/contexts/AuthContext'

import SideBarWrapper from '../components/SideBarWrapper'
import MobileWarning from '../pages/MobileWarning'
import NotFound from '../pages/NotFound'
import { isBetaUser } from './isBeta'

interface Props {
  children: ReactElement
  allowMobile?: boolean
  isPrivate?: boolean
  exact?: boolean
  path: string
  isBeta?: boolean
  hasSideBar?: boolean
  forbidAccess?: string[]
}

const isMobile = (): boolean =>
  /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const RouteWrapper: React.FC<Props> = ({
  path,
  children,
  exact = false,
  isPrivate = true,
  allowMobile = false,
  isBeta = false,
  hasSideBar = false,
  forbidAccess
}: Props) => {
  const { isSigned, session } = useContext(AuthContext)
  const history = useHistory()

  if (!allowMobile && isMobile()) return <MobileWarning />

  if (!isSigned && isPrivate) {
    // TODO: Ideally, 'from' should be a state parameter stored as a nonce, to avoid CSRF attacks
    // https://auth0.com/docs/protocols/state-parameters#csrf-attacks
    localStorage.setItem('from', history.location.pathname)
    return <Redirect to='/login' />
  }

  if (isBeta && !isBetaUser(session)) return <NotFound />

  if (forbidAccess && session && forbidAccess.includes(session.role)) return <NotFound />

  return (
    <Route path={path} exact={exact}>
      {hasSideBar ? <SideBarWrapper>{children}</SideBarWrapper> : <>{children}</>}
    </Route>
  )
}

export default RouteWrapper
