import styled from 'styled-components'

export const TooltipText = styled.span`
  width: 120px;
  font-size: 18px;
  text-align: center;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  width: max-content;
  margin-top: 70px;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.dark};
  display: none;
`

interface ArrowProps {
  type: 'prev' | 'next' | 'none'
}

export const Arrow = styled.div<ArrowProps>`
  align-self: ${(props) => (props.type === 'prev' ? 'left' : 'right')};
  cursor: ${(props) => (props.type === 'none' ? 'inherit' : 'cursor')};
  text-align: center;
  height: 32px;
  border: 1px solid
    ${(props) => (props.type === 'none' ? `${props.theme.white}` : `${props.theme.dark}`)};
  border-radius: 19px;
  width: 164px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    ${TooltipText} {
      display: block;
      visibility: visible;
    }
  }

  @media (max-width: 1440px) {
    max-width: 120px;
  }
`

export const ArrowSku = styled.p`
  color: black;
  font-size: 18px;
  text-align: center;
  margin-right: 9px;
  margin-left: 9px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
`

export const Icon = styled.img<ArrowProps>`
  ${(props) => (props.type === 'next' ? 'margin-left: 10px' : 'margin-right: 10px')};
  height: 14px;
  width: auto;
  ${(props) => (props.type === 'next' ? 'transform: rotate(180deg)' : '')};
`
