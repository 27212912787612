import styled from 'styled-components'

export const StyledChip = styled.span`
  & .MuiChip-root {
    font-family: Inter;
    background-color: ${(props) => props.theme.grey5};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
  }
`
