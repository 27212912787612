import React from 'react'

import { useTranslation } from 'react-i18next'

import LogoImg from '@console/common/assets/r2uLogo.png'
import Button from '@console/common/components/Button'

import { Container, InfoContainer, Text, Logo, ButtonsContainer } from './styles'

interface Props {
  handleUserChoice: (choice: boolean) => void
}

const LgpdBanner: React.FC<Props> = ({ handleUserChoice }: Props) => {
  const { t } = useTranslation(['components/banner'])

  const submitChoice = (choice: boolean) => handleUserChoice(choice)

  return (
    <Container>
      <InfoContainer>
        <Logo src={LogoImg} alt='' />
        <Text>{t('textLine1')}</Text>
        <Text>
          {t('textLine2')}
          <a
            href='https://r2u.io/politica-de-privacidade/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('link')}
          </a>
        </Text>
      </InfoContainer>
      <ButtonsContainer>
        <Button
          style={{ width: '191px', height: '41px' }}
          onClick={() => submitChoice(false)}
          isLoading={false}
        >
          {t('button.accept')}
        </Button>
        <Button
          alternative
          style={{ width: '191px', height: '41px' }}
          onClick={() => submitChoice(true)}
          isLoading={false}
        >
          {t('button.decline')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export default LgpdBanner
