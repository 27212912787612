import React, { useContext, useState, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'

import ArrowIcon from '@console/common/assets/icons/arrowForward.svg'
import Button from '@console/common/components/Button'
import CustomerSelector from '@console/common/components/CustomerSelector/Selector'
import { Customer } from '@console/common/services/api'

import { CheckboxContext } from '../../contexts/CheckboxContext'
import { ProductsContext } from '../../contexts/ProductsContext'
import {
  ForwardButton,
  SelectContainer,
  SelectHeader,
  SelectSubheader,
  SelectFromTo,
  SkusContainer,
  SelectSkus,
  Separator,
  SelectButtons
} from './styles'

const ForwardProductButton: React.FC = () => {
  const selectionLimit = 100

  const { t } = useTranslation(['portfolio'])
  const history = useHistory()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [productIds, setProductIds] = useState<string[]>([])
  const [productSkus, setProductSkus] = useState<string[]>([])

  const { isAnyChecked, checkedStatus } = useContext(CheckboxContext)
  const { products } = useContext(ProductsContext)

  const selectionOutOfBounds = () => {
    const count = checkedStatus.filter((item) => item.checked).length
    if (count > selectionLimit) {
      return true
    }
    return false
  }

  const warnLimit = () => {
    // eslint-disable-next-line no-alert
    alert('Escolha no máximo 100 produtos para encaminhar.')
  }

  const afterOpenModal = useCallback(() => {
    const checkedProductIds = checkedStatus
      .filter((checkedProduct) => checkedProduct.checked)
      .map((checkedProduct) => checkedProduct.id)
    setProductIds(checkedProductIds)
    setProductSkus(
      products
        .filter((product) => checkedProductIds.includes(product.id))
        .map((product) => product.sku)
    )
  }, [checkedStatus])

  const sendToEdit = useCallback(() => {
    const selectedProducts = products
      .filter((product) => productIds.includes(product.id))
      .map((product) => ({
        id: product.id || '',
        name: product.name || '',
        sku: product.sku || '',
        pdpUrl: product.pdpUrl || '',
        ean: product.ean?.toString() || ''
      }))
    localStorage.setItem(
      'forwardProduct',
      JSON.stringify({
        customer: {
          id: customer?.id,
          name: customer?.name
        },
        products: selectedProducts
      })
    )
    history.push('/portfolio/forward')
  }, [productIds, customer])

  const openModal = async () => {
    setShowModal(true)
  }

  const closeModal = async () => {
    setShowModal(false)
  }

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      zIndex: 999999
    },
    content: {
      width: '40%',
      height: '80%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0',
      marginRight: '-50%',
      borderRadius: '8px',
      boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)',
      transform: 'translate(-50%, -50%)',
      overflow: 'clip'
    }
  }

  Modal.setAppElement('#root')

  return (
    <>
      <Modal
        isOpen={showModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <SelectContainer>
          <SelectHeader>{t('forwardProduct.header')}</SelectHeader>
          <SelectSubheader>{t('forwardProduct.subHeader')}</SelectSubheader>
          <SelectFromTo>
            <span>{t('forwardProduct.destination')}</span>
            <span>{customer?.name}</span>
          </SelectFromTo>
          <SkusContainer>
            {productSkus.map((sku) => (
              // eslint-disable-next-line react/jsx-no-literals
              <SelectSkus>SKU: {sku}</SelectSkus>
            ))}
          </SkusContainer>
          <Separator />
          <CustomerSelector onSelect={(selectedCustomer) => setCustomer(selectedCustomer)} />
          <SelectButtons>
            <Button onClick={closeModal} alternative>
              {t('forwardProduct.cancel')}
            </Button>
            <Button onClick={sendToEdit} disabled={!customer}>
              {t('forwardProduct.duplicate')}
            </Button>
          </SelectButtons>
        </SelectContainer>
      </Modal>
      {isAnyChecked ? (
        <ForwardButton onClick={selectionOutOfBounds() ? warnLimit : openModal}>
          <img src={ArrowIcon} alt={t('forwardProduct.button')} />
          {t('forwardProduct.button')}
        </ForwardButton>
      ) : null}
    </>
  )
}

export default ForwardProductButton
