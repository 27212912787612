import React, { useState, useEffect, useCallback, useContext } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import attachIcon from '@console/common/assets/attachFile.svg'
import { upload } from '@console/common/services/upload'

import removeIcon from '../../assets/icons/close.svg'
import sendIcon from '../../assets/icons/send.svg'
import { CommentsContext } from '../../contexts/CommentsContext'
import {
  InputContainer,
  DragAndDropContainer,
  ImageButton,
  CommentButton,
  TextareaContainer,
  AttachButton,
  ImageContainer,
  ImageWrapper
} from './styles'

interface Props {
  initialInput?: string
  initialAttachments?: string[]
  cancelCallback?: () => void
  saveCallback?: (input: string, attachments: string[]) => void
  editCallback?: (input?: string, attachments?: string[]) => void
}

const Input: React.FC<Props> = ({
  initialInput,
  initialAttachments,
  cancelCallback,
  saveCallback,
  editCallback
}: Props) => {
  const { t } = useTranslation(['components/comments-bar'])
  const { t: tReview } = useTranslation(['review'])
  const { setHasInput } = useContext(CommentsContext)
  const [input, setInput] = useState(initialInput || '')
  const [attachments, setAttachments] = useState<string[]>(initialAttachments || [])
  const clearInput = () => {
    setInput(initialInput || '')
    setAttachments(initialAttachments || [])
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      upload(file, 'attachments', (e: string) => {
        setAttachments((a) => [...a, e])
      })
    })
  }, [])

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const updateTextAreaHeight = (textarea: HTMLTextAreaElement) => {
    // This is needed becausae the height is considered in the scrollHeight: without this line
    // the textarea continuously grow as the user type instead of only growing when needed
    // eslint-disable-next-line no-param-reassign
    textarea.style.height = 'auto'
    // eslint-disable-next-line no-param-reassign
    textarea.style.height = `${2 + textarea.scrollHeight}px`
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateTextAreaHeight(e.target)
    setInput(e.target.value)
    setHasInput(!!e.target.value)
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (e.clipboardData.files.length) {
      onDrop(Array.from(e.clipboardData.files))
      e.preventDefault()
    }
  }

  useEffect(() => {
    const textarea = document.querySelector('textarea')
    if (textarea) updateTextAreaHeight(textarea)
  }, [])

  const removeAttachment = (url: string) => {
    setAttachments(attachments.filter((e) => e !== url))
  }

  const handleSubmit = (inputSubmitted: string, attachmentsSubmitted: string[]) => {
    const submit = saveCallback ?? editCallback
    if (submit) submit(inputSubmitted, attachmentsSubmitted)
    clearInput()
    if (cancelCallback) cancelCallback()
  }

  return (
    <InputContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <TextareaContainer>
        {!isDragActive ? (
          <>
            <textarea
              placeholder={t('placeholder')}
              value={input}
              onChange={handleChange}
              onPaste={handlePaste}
            />
            <ImageContainer>
              {attachments.map((url) => (
                <ImageWrapper key={url}>
                  <ImageButton
                    className='icon'
                    onClick={() => removeAttachment(url)}
                    src={removeIcon}
                  />
                  <a href={url} target='_blank' rel='noopener noreferrer' key={url}>
                    <img src={url} alt={url} />
                  </a>
                </ImageWrapper>
              ))}
            </ImageContainer>
            <AttachButton onClick={open} onKeyPress={open}>
              <img src={attachIcon} alt='attach icon' />
              <span>{t('COMMENTS_BUTTONS.ATTACH')}</span>
            </AttachButton>
          </>
        ) : (
          <DragAndDropContainer>{tReview('comments.drop')}</DragAndDropContainer>
        )}
      </TextareaContainer>
      <CommentButton onClick={() => handleSubmit(input, attachments)}>
        <span>{t(`COMMENTS_BUTTONS.${saveCallback ? 'COMMENT' : 'EDIT'}`)}</span>
        <img src={sendIcon} alt='' />
      </CommentButton>
    </InputContainer>
  )
}

export default Input
