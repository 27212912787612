import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react'

import { ProductsContext } from './ProductsContext'

interface Props {
  children: ReactNode
}

interface CheckboxContextData {
  checkedStatus: { id: string; checked: boolean }[]
  handleChange: (id: string) => void
  isAnyChecked: boolean
  selectAllStatus: boolean
  toggleSelectAll: () => void
}

export const CheckboxContext = createContext<CheckboxContextData>({} as CheckboxContextData)

export const CheckboxProvider: React.FC<Props> = ({ children }: Props) => {
  const { products } = useContext(ProductsContext)

  const [checkedStatus, setCheckedStatus] = useState<{ id: string; checked: boolean }[]>([])
  const [selectAllStatus, setSelectAllStatus] = useState(false)

  useEffect(() => {
    if (selectAllStatus) {
      setCheckedStatus(products.map((product) => ({ id: product.id, checked: true })))
    } else if (checkedStatus.length > 0) {
      const prevCheckedTrue: string[] = checkedStatus
        .filter((product) => product.checked)
        .map((product) => product.id)

      const newCheckedStatus: { id: string; checked: boolean }[] = []

      products.forEach((product) => {
        prevCheckedTrue.forEach((id) => {
          if (product.id === id) {
            newCheckedStatus.push({ id: product.id, checked: true })
          } else {
            newCheckedStatus.push({ id: product.id, checked: false })
          }
        })
      })
      setCheckedStatus(newCheckedStatus)
    } else {
      setCheckedStatus(products.map((product) => ({ id: product.id, checked: false })))
    }
  }, [products])

  const handleChange = (productId: string) => {
    const updatedCheckedStatus = checkedStatus.map((cardCheckedStatus) =>
      cardCheckedStatus.id === productId
        ? { id: cardCheckedStatus.id, checked: !cardCheckedStatus.checked }
        : { id: cardCheckedStatus.id, checked: cardCheckedStatus.checked }
    )
    setCheckedStatus(updatedCheckedStatus)
  }

  const isAnyChecked = !!checkedStatus.find((cardCheckedStatus) => cardCheckedStatus.checked)

  const toggleSelectAll = () => {
    setSelectAllStatus(!selectAllStatus)
  }

  useEffect(() => {
    const updatedAllCheckedStatus = checkedStatus.map((cardCheckedStatus) => ({
      id: cardCheckedStatus.id,
      checked: selectAllStatus
    }))
    setCheckedStatus(updatedAllCheckedStatus)
  }, [selectAllStatus])

  return (
    <CheckboxContext.Provider
      value={{
        checkedStatus,
        handleChange,
        isAnyChecked,
        selectAllStatus,
        toggleSelectAll
      }}
    >
      {children}
    </CheckboxContext.Provider>
  )
}
