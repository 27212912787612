import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export interface Props {
  expanded: boolean
}

export const Container = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.dark};
  display: grid;
  grid-template-rows: 260px auto;
`

export const Top = styled.div`
  display: grid;
  grid-template-rows: 148px 0px auto;
`

export const Menu = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
`

export const Divider = styled.hr<Props>`
  transition: all 0.3s ease-in-out;

  border: 1px solid ${(props) => props.theme.darkBlue2};
  background-color: ${(props) => props.theme.darkBlue2};
  height: 0px;
  width: calc(0.8 * ${(props) => (props.expanded ? '244px' : '88px')});
`

export const Logo = styled.img<Props>`
  transition: all 0.3s ease-in-out;

  margin: ${(props) => (props.expanded ? '25% auto' : '40% auto auto auto')};
  width: ${(props) => (props.expanded ? ' 202px' : '50.22px')};
  height: ${(props) => (props.expanded ? '42px' : '66px')};
  display: flex;
`

export const Customer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    margin-top: 5px;
  }
`
export const CustomerLogo = styled.img<Props>`
  transition: all 0.3s ease-in-out;

  margin: ${(props) => (props.expanded ? '38px auto 0 auto' : 'unset')};
  border-radius: 50%;
  width: ${(props) => (props.expanded ? '48px' : '44px')};
  height: ${(props) => (props.expanded ? '48px' : '44px')};
  top: ${(props) => (props.expanded ? '' : '20px')};
`

export const AdminContainer = styled.div<Props>`
  transition: all 0.3s ease-in-out;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  p {
    color: white;
    margin-top: 40%;
    margin-bottom: 0;
  }

  small {
    display: block;
    color: white;
    font-weight: lighter;
  }

  img,
  h1 {
    position: ${(props) => (props.expanded ? 'absolute' : 'relative')};
  }

  .left {
    z-index: 1;
    ${(props) => (props.expanded ? 'left: 32.5%' : 'margin-top:0; top: 27%')};
  }

  .right {
    ${(props) => (props.expanded ? 'right: 32.5%' : '')};
  }

  .clickable {
    cursor: pointer;
    top: unset;
  }

  .close {
    transition: all 0.3s ease-in-out;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    margin-left: 55px;
    top: -30px;

    position: relative;

    ${(props) =>
      props.expanded &&
      css`
        margin-top: 55px;
        margin-left: 100px;
        top: -20px;
      `};
  }
`

export const CustomerLogoPlaceholder = styled.h1<Props>`
  transition: all 0.3s ease-in-out;

  display: flex;
  justify-content: center;
  flex-direction: column;

  color: ${(props) => props.theme.orange};
  width: ${(props) => (props.expanded ? '46px' : '44px')};
  height: ${(props) => (props.expanded ? '46px' : '44px')};
  margin: ${(props) => (props.expanded ? '38px auto 0 auto' : 'unset')};
  top: ${(props) => (props.expanded ? '' : '20px')};
  background-color: white;
  border-radius: 50%;
  text-align: center;
  font-weight: normal;
  line-height: ${(props) => (props.expanded ? '1.4' : '1.2')};
`

export const Icon = styled.img<Props>`
  transition: all 0.3s ease-in-out, filter 0s;
  margin-right: ${(props) => (props.expanded ? '5%' : 'unset')};
  margin-left: ${(props) => (props.expanded ? '10%' : 'unset')};
  max-width: 16px;
  filter: invert(52%) sepia(0%) saturate(506%) hue-rotate(242deg) brightness(97%) contrast(87%);
`

export const PlainButton = styled.button<Props>`
  transition: all 0.3s ease-in-out;

  background-color: inherit;
  color: ${(props) => props.theme.grey1};
  border: none;
  cursor: pointer;
  display: ${(props) => (props.expanded ? 'flex' : 'grid')};
  ${(props) => (props.expanded ? 'align-items: center' : 'align-content: center')};
  ${(props) => (props.expanded ? null : 'grid-template-columns: calc(50% + 8.5px) auto 5%')};
  justify-items: ${(props) => (props.expanded ? 'center' : 'self-end')};
  padding: ${(props) => (props.expanded ? '0 5% 0 5%' : '0')};
  height: 44px;
  width: 100%;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  text-align: left;

  :hover {
    background-color: ${(props) => props.theme.darkBlue3};
    color: white;
    ${Icon} {
      filter: invert(58%) sepia(82%) saturate(5300%) hue-rotate(171deg) brightness(97%)
        contrast(101%);
    }
  }

  span {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    height: 0;
    text-overflow: ellipsis;
    overflow: hidden;

    ${(props) =>
      props.expanded &&
      css`
        opacity: 1;
        height: auto;
      `};
  }
`

export const StyledNavLink = styled(NavLink)`
  &.${(props) => props.activeClassName} {
    ${PlainButton} {
      border-right: 5px solid ${(props) => props.theme.blue};
      background-color: ${(props) => props.theme.darkBlue3};
      color: white;
      ${Icon} {
        filter: invert(58%) sepia(82%) saturate(5300%) hue-rotate(171deg) brightness(97%)
          contrast(101%);
      }
    }
  }
`

export const NotificationBubble = styled.div<Props>`
  transition: all 0.3s ease-in-out;

  background-color: ${(props) => props.theme.red};
  color: ${(props) => props.theme.white} !important;
  font-weight: bold;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-left: auto;

  ${(props) =>
    !props.expanded &&
    css`
      position: fixed;
      margin-top: 14px;
      margin-bottom: 14px;
      margin-right: 10px;
    `};
`

export const TextBubble = styled.div<Props>`
  transition: all 0.3s ease-in-out;

  background-color: ${(props) => props.theme.red};
  display: flex;
  color: ${(props) => props.theme.white} !important;
  font-weight: bold;
  padding: 3px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-left: auto;

  width: 37px;
  height: 16px;
  border-radius: 22px;

  ${(props) =>
    !props.expanded &&
    css`
      position: fixed;
      margin-top: 14px;
      margin-bottom: 14px;
      margin-right: 10px;
      width: 16px;
      border-radius: 50%;
      padding: unset;

      text-indent: -99999px;
      line-height: 0;

      &:after {
        content: '!';
        text-indent: 0;
        line-height: initial;
      }
    `};
`
