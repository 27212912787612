import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import EditIcon from '@console/common/assets/edit.svg'
import Table from '@console/common/components/Table'
import { Customer } from '@console/common/services/api'

import ActiveStatusBadge from '../ActiveStatusBadge'
import { ActionsButtons } from './styles'

interface Props {
  customers: Customer[]
  isLoading: boolean
}

const CustomersTable: React.FC<Props> = ({ customers, isLoading }: Props) => {
  const { t } = useTranslation(['settings'])

  const columns: { Header: string; accessor: string }[] = React.useMemo(
    () => [
      {
        Header: t('customers.customer'),
        accessor: 'customer'
      },
      {
        Header: t('customers.email'),
        accessor: 'email'
      },
      {
        Header: t('customers.status'),
        accessor: 'status'
      },
      {
        Header: t('customers.actions'),
        accessor: 'actions'
      }
    ],
    []
  )

  const data: { [x: string]: string | ReactElement }[] = React.useMemo(
    () =>
      customers.map((customer) => ({
        customer: customer.name,
        email: customer.metadata?.email || '',
        status: <ActiveStatusBadge isActive={customer.active} />,
        actions: (
          <ActionsButtons>
            <Link to={`/settings/customers/edit/${customer.id}`}>
              <img src={EditIcon} alt='' />
            </Link>
          </ActionsButtons>
        )
      })),
    [customers]
  )

  return <Table columns={columns} data={data} isLoading={isLoading} />
}

export default CustomersTable
