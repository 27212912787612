import React, { createContext, ReactNode, useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  ReferenceType,
  ProductPlacement,
  createReference,
  updateHanaAsset
} from '@console/common/services/api'

import { createProduct } from '../services/api'

export enum RegistrationSteps {
  PRODUCT_DATA = 'PRODUCT_DATA',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
  MODEL_DECISION = 'MODEL_DECISION',
  MODEL_UPLOAD = 'MODEL_UPLOAD',
  MODEL_CONVERSIONS = 'MODEL_CONVERSIONS',
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  MODEL_IMAGE_UPLOAD = 'MODEL_IMAGE_UPLOAD',
  CONFIRMATION = 'CONFIRMATION'
}

export interface ProductData {
  name: string
  sku: string
  ean: string
  pdpUrl: string
  placement: ProductPlacement
  height: string
  width: string
  depth: string
}

export interface Image {
  id: string
  url: string
}

export interface Model {
  id: string
  url: string
  type: string
}

interface Props {
  children: ReactNode
}

interface ProcessContextData {
  currentStep: RegistrationSteps
  setCurrentStep: React.Dispatch<RegistrationSteps>
  confirmRegistration: () => void
  setRegistering: React.Dispatch<boolean>
  registering: boolean
}

export const ProcessContext = createContext<ProcessContextData>({} as ProcessContextData)

export const ProcessProvider: React.FC<Props> = ({ children }: Props) => {
  const [registering, setRegistering] = useState(false)
  const [currentStep, setCurrentStep] = useState<RegistrationSteps>(RegistrationSteps.PRODUCT_DATA)
  const { session, assumedCustomer } = useContext(AuthContext)
  const history = useHistory()
  const { analytics } = useContext(AnalyticsContext)

  const confirmRegistration = async () => {
    setRegistering(true)
    analytics?.track(AnalyticsEvent.REGISTRATION_COMPLETE)
    const productDataItem = localStorage.getItem('productData')
    if (!productDataItem) return
    const productData = JSON.parse(productDataItem) as ProductData
    const product = await createProduct({
      name: productData.name,
      sku: productData.sku,
      ean: productData.ean ? [productData.ean] : undefined,
      pdpUrl: productData.pdpUrl,
      metadata: { height: productData.height, width: productData.width, depth: productData.depth },
      customerId: assumedCustomer?.id ?? session?.customer.id
    })
    if (!product) return

    const imageUploadItem = localStorage.getItem('imageUpload')
    if (imageUploadItem) {
      const images = JSON.parse(imageUploadItem) as Image[]
      await Promise.all(
        images.map((image) =>
          createReference(product?.id, {
            url: image.url,
            type: ReferenceType.IMAGE,
            description: 'Uploaded using the platform',
            name: image.id
          })
        )
      )
    }

    /**
     * Updates asset in Hana3D DB with the Platform product id
     * then Hana3D sends the data of all the models to the platform
     * so the Platform assets related to those models are created
     */
    const hanaAssetId = localStorage.getItem('hanaAssetId')
    if (hanaAssetId) {
      updateHanaAsset(hanaAssetId, product.id)

      localStorage.removeItem('hanaAssetId')
    }

    localStorage.removeItem('productData')
    localStorage.removeItem('imageUpload')
    localStorage.removeItem('modelUpload')

    setRegistering(false)
    history.push(`/portfolio/${product.id}`)
  }

  return (
    <ProcessContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        confirmRegistration,
        setRegistering,
        registering
      }}
    >
      {children}
    </ProcessContext.Provider>
  )
}
