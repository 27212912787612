import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react'

import csv from 'csvtojson'
import { nanoid } from 'nanoid/non-secure'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import theme from '@console/common/theme.json'

import { TempProduct, MultipleContext } from '../../../contexts/MultipleContext'
import {
  CSVUploadContainer,
  CSVUploadContent,
  ImportButton,
  ButtonAndSubtitleContainer,
  ButtonSubtitle,
  UploadHelperLabel,
  CSVDownload,
  ContainerHelperLabel
} from './styles'
import Table from './Table'

const ProductData: React.FC = () => {
  const { t, i18n } = useTranslation(['registration'])
  const [parsedCsv, setParsedCsv] = useState<TempProduct[]>([])
  const { setProducts } = useContext(MultipleContext)

  useEffect(() => {
    if (parsedCsv.length > 0) setProducts(parsedCsv)
  }, [parsedCsv])

  const csvHeaders = [
    t('multipleRegistration.csvHeaders.productName'),
    t('multipleRegistration.csvHeaders.sku'),
    t('multipleRegistration.csvHeaders.ean'),
    t('multipleRegistration.csvHeaders.vendor'),
    t('multipleRegistration.csvHeaders.pdp'),
    t('multipleRegistration.csvHeaders.height'),
    t('multipleRegistration.csvHeaders.width'),
    t('multipleRegistration.csvHeaders.length'),
    t('multipleRegistration.csvHeaders.urlModel3d'),
    t('multipleRegistration.csvHeaders.imagesUrls')
  ]

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const reader = new FileReader()
      const lineProducts: TempProduct[] = []
      reader.onload = () => {
        csv({
          noheader: false,
          headers: csvHeaders,
          delimiter: ';'
        })
          .fromString(reader.result as string)
          .then((json) => {
            // eslint-disable-next-line array-callback-return
            json.map((j) => {
              lineProducts.push({
                id: nanoid(),
                name: j[t('multipleRegistration.csvHeaders.productName')],
                sku: j[t('multipleRegistration.csvHeaders.sku')],
                ean: j[t('multipleRegistration.csvHeaders.ean')],
                vendor: j[t('multipleRegistration.csvHeaders.vendor')],
                pdp: j[t('multipleRegistration.csvHeaders.pdp')],
                urlModel3d: j[t('multipleRegistration.csvHeaders.urlModel3d')],
                imagesUrls: j[t('multipleRegistration.csvHeaders.imagesUrls')],
                dimensions: `${j[t('multipleRegistration.csvHeaders.height')]} x ${
                  j[t('multipleRegistration.csvHeaders.width')]
                } x ${j[t('multipleRegistration.csvHeaders.length')]}`,
                width: j[t('multipleRegistration.csvHeaders.width')],
                height: j[t('multipleRegistration.csvHeaders.height')],
                depth: j[t('multipleRegistration.csvHeaders.length')]
              } as TempProduct)
            })
            setParsedCsv(lineProducts)
          })
      }
      acceptedFiles.map((file: File) => reader.readAsBinaryString(file))
    },
    [parsedCsv]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: '.csv',
    noClick: !!parsedCsv.length,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const downloadCsv = () => {
    window.open(
      `https://real2u-public-assets.s3.amazonaws.com/csv/${
        i18n.language === 'pt' ? 'R2U+-+NOVOS+PRODUTOS.csv' : 'R2U+-+NEW+PRODUCTS.csv'
      }`
    )
  }

  return (
    <div>
      {parsedCsv.length ? (
        <div />
      ) : (
        <>
          <CSVUploadContainer>
            <CSVUploadContent {...getRootProps({ style })}>
              <ButtonAndSubtitleContainer>
                <ImportButton>
                  <input {...getInputProps()} />
                  {t('multipleRegistration.productData.importButton')}
                </ImportButton>
                <ButtonSubtitle>
                  {t('multipleRegistration.productData.buttonSubtitle')}
                </ButtonSubtitle>
              </ButtonAndSubtitleContainer>
              <UploadHelperLabel>
                {t('multipleRegistration.productData.uploadHelperLabel')}
              </UploadHelperLabel>
            </CSVUploadContent>
            <CSVDownload onClick={() => downloadCsv()}>
              {t('multipleRegistration.productData.downloadCsv')}
            </CSVDownload>
          </CSVUploadContainer>
          <ContainerHelperLabel>
            {t('multipleRegistration.productData.containerHelperLabel')}
          </ContainerHelperLabel>
        </>
      )}
      <Table />
    </div>
  )
}

export default ProductData
