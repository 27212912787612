import styled from 'styled-components'

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const LargeContainer = styled.div`
  position: relative;
  width: 90vw;
  height: 95vh;
  background-color: white;
  border-radius: 10px;
`

export const Container = styled.div`
  position: relative;
  width: 547px;
  height: 433px;

  display: grid;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
  position: relative;
  width: 120px;
  height: 120px;
  z-index: 10;
  justify-self: center;
`

export const Content = styled.div`
  position: relative;
  top: -60px;
  width: 547px;
  height: 373px;
  background-color: white;
  border-radius: 10px;
  justify-self: center;
`
