import styled from 'styled-components'

export const Background = styled.div`
  background: #00000080;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999999999;

  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Container = styled.div`
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 60%;
  width: 30%;
  margin: auto auto auto 26%;
  border-radius: 10px;

  position: relative;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  img {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  small {
    text-align: center;
    width: 80%;
    margin: 10px auto 30px auto;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 25px;

  input {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 40px;
    border: 1px solid ${(props) => props.theme.dark};
    border-radius: 10px;

    ::placeholder {
      color: ${(props) => props.theme.grey2};
      font-weight: lighter;
      opacity: 1;
    }
  }

  img {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 60%;
  }
`

export const CustomerList = styled.div`
  height: 50%;
  width: 100%;
  overflow: auto;

  span {
    display: block;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 10px 0 10px 10px;
    color: ${(props) => props.theme.dark};

    :hover {
      color: ${(props) => props.theme.dark};
      background-color: ${(props) => props.theme.grey5};
    }
  }
`
