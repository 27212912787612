import { api, Product, Asset, Reference } from '@console/common/services/api'

export const getProductInfo = async (productId: string): Promise<Product | null> => {
  try {
    const { data } = await api.get<Product>(`/v1/products/${productId}`)
    return data
  } catch (e) {
    return null
  }
}

export const changeActiveStatus = async (
  customerId: string,
  productId: string,
  activeStatus: boolean
): Promise<Product | null> => {
  try {
    const data = { active: activeStatus }
    const { data: product } = await api.patch<Product>(
      `/v1/customers/${customerId}/products/${productId}`,
      data
    )
    return product
  } catch (e) {
    return null
  }
}

export const deleteProduct = async (
  customerId: string,
  productId: string
): Promise<Product | null> => {
  try {
    const { data } = await api.delete<Product>(`/v1/customers/${customerId}/products/${productId}`)
    return data
  } catch (e) {
    return null
  }
}

interface PatchCustomerProductSchema {
  sku?: string
  name?: string
  active?: boolean
  pdpUrl?: string
  ean?: string[]
  placement?: string
  height?: string
  width?: string
  depth?: string
}

export const patchCustomerProduct = async (
  customerId: string,
  productId: string,
  productData: PatchCustomerProductSchema
): Promise<Product | null> => {
  try {
    const { data } = await api.patch<Product>(
      `/v1/customers/${customerId}/products/${productId}`,
      productData
    )
    return data
  } catch (e) {
    return null
  }
}

export const getVersionAssets = async (versionId: string): Promise<Asset[] | null> => {
  try {
    const { data } = await api.get<Asset[]>(`/v1/versions/${versionId}/assets`)
    return data
  } catch (e) {
    return null
  }
}

export const deleteReference = async (referenceId: string): Promise<Reference | null> => {
  try {
    const { data } = await api.delete<Reference>(`/v1/references/${referenceId}`)
    return data
  } catch (e) {
    return null
  }
}

export const deleteAsset = async (assetId: string): Promise<Asset | null> => {
  try {
    const { data } = await api.delete<Asset>(`/v1/assets/${assetId}`, { data: {} })
    return data
  } catch (e) {
    return null
  }
}

export const createProducts = async (products: Partial<Product>[]): Promise<Product[] | null> => {
  try {
    const { data } = await api.post<Product[]>('/v1/products/multiple', products)
    return data
  } catch (e) {
    return null
  }
}
