import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import StatusBadge from '@console/common/components/StatusBadge'

import Status from '../../types/Status'
import PlaceholderImage, { PlaceholderTypes } from '../PlaceholderImage'
import Options, { CardOptions } from './Options'
import {
  CardDiv,
  Skeleton,
  ImageContainer,
  Image,
  Name,
  Sku,
  Customer,
  StatusContainer,
  OptionsContainer
} from './styles'

interface Props {
  imgSrc?: string
  name?: string
  sku?: string
  customer?: string
  status?: Status
  isLoading?: boolean
  showOptions?: boolean
  options?: CardOptions[] | null
}

const Card: React.FC<Props> = ({
  imgSrc,
  name,
  sku,
  customer,
  status,
  isLoading,
  showOptions,
  options
}: Props) => {
  const { t } = useTranslation(['components/card'])
  const [hasImage, setHasImage] = useState(false)

  useEffect(() => {
    setHasImage(!!imgSrc)
  }, [imgSrc])

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <CardDiv>
          {hasImage ? (
            <ImageContainer>
              <Image
                src={imgSrc}
                onError={(e) => {
                  setHasImage(false)
                }}
              />
            </ImageContainer>
          ) : (
            <ImageContainer>
              <PlaceholderImage type={PlaceholderTypes.CARD} />
            </ImageContainer>
          )}
          <Customer>{customer}</Customer>
          <Sku>
            {t('sku')} {sku}
          </Sku>
          <Name>{name}</Name>
          {status ? (
            <StatusContainer>
              <StatusBadge status={status} />
            </StatusContainer>
          ) : null}
          {showOptions && options ? (
            <OptionsContainer>
              <Options options={options} headerText={t('changeStatus')} />
            </OptionsContainer>
          ) : null}
        </CardDiv>
      )}
    </>
  )
}

export default Card
