import React, { useContext, useState, useEffect } from 'react'

import { Line } from 'rc-progress'
import { useTranslation } from 'react-i18next'

import ArrowIcon from '@console/common/assets/arrowLeft.svg'
import theme from '@console/common/theme.json'

import { RegistrationSteps, ProcessContext } from '../../contexts/ProcessContext'
import { Header, HeaderButtons, ArrowRight } from './styles'

const ProgressBar: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [progress, setProgress] = useState<number>(0)
  const { currentStep, setCurrentStep } = useContext(ProcessContext)

  useEffect(() => {
    switch (currentStep) {
      case RegistrationSteps.PRODUCT_DATA:
        setProgress(25)
        return
      case RegistrationSteps.IMAGE_UPLOAD:
        setProgress(50)
        return
      case RegistrationSteps.MODEL_DECISION:
        setProgress(75)
        return
      case RegistrationSteps.MODEL_UPLOAD:
        setProgress(75)
        return
      case RegistrationSteps.MODEL_CONVERSIONS:
        setProgress(75)
        return
      // case RegistrationSteps.PRODUCT_VIEW:
      //   setProgress(80)
      //   return
      case RegistrationSteps.CONFIRMATION:
        setProgress(100)
        return
      default:
        setProgress(0)
    }
  }, [currentStep])

  return (
    <Header>
      <HeaderButtons>
        <button
          type='button'
          onClick={() => setCurrentStep(RegistrationSteps.PRODUCT_DATA)}
          disabled={progress < 25}
        >
          {t('progressBar.PRODUCT_DATA')}
        </button>
        <ArrowRight src={ArrowIcon} />
        <button
          type='button'
          onClick={() => setCurrentStep(RegistrationSteps.IMAGE_UPLOAD)}
          disabled={progress < 50}
        >
          {t('progressBar.IMAGE_UPLOAD')}
        </button>
        <ArrowRight src={ArrowIcon} />
        <button
          type='button'
          onClick={() => setCurrentStep(RegistrationSteps.MODEL_DECISION)}
          disabled={progress < 75}
        >
          {t('progressBar.MODEL_DECISION')}
        </button>
        {/* <ArrowRight src={ArrowIcon} />
        <button
          type='button'
          onClick={() => setCurrentStep(RegistrationSteps.PRODUCT_VIEW)}
          disabled={progress < 80}
        >
          {t('progressBar.PRODUCT_VIEW')}
        </button> */}
        <ArrowRight src={ArrowIcon} />
        <button
          type='button'
          onClick={() => setCurrentStep(RegistrationSteps.CONFIRMATION)}
          disabled={progress < 100}
        >
          {t('progressBar.CONFIRMATION')}
        </button>
      </HeaderButtons>
      <Line percent={progress} strokeWidth={1} strokeColor={theme.blue} trailColor={theme.grey9} />
    </Header>
  )
}

export default ProgressBar
