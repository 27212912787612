import React, { createContext, ReactNode, useEffect, useState, useContext } from 'react'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole, AssetStatus, Product } from '@console/common/services/api'

import { ProductNeighbours, fetchProduct, fetchProductNeighbours } from '../services/api'

interface Props {
  children: ReactNode
  id: string
}

interface ProductContextData {
  neighbours: ProductNeighbours | null
  product: Product | null
  pendingReview: boolean
  loading: boolean
}

export const ProductContext = createContext<ProductContextData>({} as ProductContextData)

export const ProductProvider: React.FC<Props> = ({ children, id }: Props) => {
  const [product, setProduct] = useState<Product | null>(null)
  const [neighbours, setProductNeighbours] = useState<ProductNeighbours | null>(null)
  const [pendingReview, setPendingReview] = useState(false)
  const [loading, setLoading] = useState(false)

  const { session, assumedCustomer } = useContext(AuthContext)

  const role =
    session?.role === UserRole.ARTIST ||
    session?.role === UserRole.FREELANCER ||
    ((session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) && !assumedCustomer)
      ? UserRole.ARTIST
      : UserRole.CUSTOMER

  const status = role === UserRole.ARTIST ? AssetStatus.UNDER_REVIEW : AssetStatus.TEAM_APPROVED

  useEffect(() => {
    setLoading(true)
    if (!session) return
    fetchProduct(id, role).then((p) => {
      setProduct(p)
      setPendingReview(p?.status === status)
      setLoading(false)
    })
    fetchProductNeighbours(id, assumedCustomer?.id, status).then((p) => {
      setProductNeighbours(p)
    })
  }, [id, session, assumedCustomer])

  return (
    <ProductContext.Provider
      value={{
        neighbours,
        product,
        pendingReview,
        loading
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
