import React from 'react'

import { useTranslation } from 'react-i18next'

import logo from '../../assets/logo.png'
import mobile from '../../assets/mobile.svg'
import { Container } from './style'

const MobileWarning: React.FC = () => {
  const { t } = useTranslation(['mobile'])

  return (
    <Container>
      <header>
        <img src={logo} alt='logo' />
      </header>
      <img className='icon' alt='' src={mobile} />
      <h2>{t('text')}</h2>
      <p>{t('subText')}</p>
    </Container>
  )
}

export default MobileWarning
