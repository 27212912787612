import React, { useState } from 'react'

import { HexColorPicker } from 'react-colorful'

import { InputContainer } from './styles'

interface Props {
  value: string
  v: string
  setV(newValue: string): void
  onChange(newValue: string): void
}

const ColorContainer: React.FC<Props> = ({ value, v, setV, onChange }: Props) => {
  const [oldV, setOldV] = useState('')
  const saveValue = () => {
    let boxValue: string
    const hex = /^#([0-9a-f]{3}){1,2}$/i
    if (hex.test(v)) {
      boxValue = v
      setOldV(v)
    } else boxValue = oldV

    onChange(boxValue)
    setV(boxValue)
  }
  return (
    <>
      <HexColorPicker
        color={value}
        onChange={(e) => {
          onChange(e)
          setV(e)
          setOldV(e)
        }}
      />
      <InputContainer>
        <input
          className='color-input'
          type='string'
          value={v}
          onChange={(e) => {
            setV(e.target.value)
          }}
          onBlur={saveValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              saveValue()
            }
          }}
        />
      </InputContainer>
    </>
  )
}

export default ColorContainer
