import React from 'react'

import Collapsible from './Collapsible'
import SlotMaterialMenu from './SlotMaterialsMenu'
import { Menu } from './styles'
import type { SlotsModels, SlotsMaterials, ViewerRef, FlatSlotsMaterials } from './types'

interface SlotsModelsProps {
  slotsModels: SlotsModels
  slotsMaterials: FlatSlotsMaterials
  viewerRef: ViewerRef
  onChange: (slotsModel: SlotsModels, slotsMaterials: SlotsMaterials) => void
}

const SlotsModelsMenu: React.FC<SlotsModelsProps> = ({
  slotsModels,
  slotsMaterials,
  viewerRef,
  onChange
}: SlotsModelsProps) => (
  <>
    <SlotMaterialMenu
      slotsMaterials={slotsMaterials}
      slotsModels={slotsModels}
      viewerRef={viewerRef}
    />
    {slotsModels?.slots.map((sm) => (
      <Collapsible key={sm.name} title={sm.name}>
        <Menu>
          {sm.models.map((m, index) => (
            <React.Fragment key={sm.name.concat(m.id)}>
              <input
                id={sm.name.concat(m.id)}
                type='radio'
                name={sm.name}
                value={m.id}
                checked={index === sm.active}
                onChange={(event) => {
                  viewerRef.current?.setModel({
                    modelId: slotsModels.id,
                    slot: sm.name,
                    modelName: event.target.value
                  })

                  if (viewerRef.current) {
                    onChange(
                      viewerRef.current.getAllSlotsModels(),
                      viewerRef.current.getAllSlotsMaterials()
                    )
                  }
                }}
              />
              <label htmlFor={sm.name.concat(m.id)} title={m.id}>
                {m.id.length > 8 ? m.id.slice(0, 7).concat('...') : m.id}
              </label>
            </React.Fragment>
          ))}
        </Menu>

        {sm.active !== -1 ? (
          <SlotsModelsMenu
            slotsModels={sm.models[sm.active]}
            slotsMaterials={slotsMaterials}
            viewerRef={viewerRef}
            onChange={onChange}
          />
        ) : null}
      </Collapsible>
    ))}
  </>
)

export default SlotsModelsMenu
