import React from 'react'

import Select, { StylesConfig } from 'react-select'

import theme from '../../theme.json'

export interface SelectOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  label: string
}

interface Props {
  options: SelectOptions[]
  onChange: (option: SelectOptions | null) => void
  value?: SelectOptions | null
  placeholder?: string
  isSearchable?: boolean
  isLoading?: boolean
  isDisabled?: boolean
}

type IsMulti = false

const customStyles: StylesConfig<SelectOptions, IsMulti> = {
  control: (provided) => ({
    ...provided,
    width: '245px',
    height: '39px',
    border: 0,
    '&:hover': {
      border: 0,
      borderBottom: 0
    },
    boxShadow: 'none'
  }),

  menu: (provided) => ({
    ...provided,
    width: '245px',
    boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.25);',
    paddingBottom: '7px',
    paddingTop: '7px',
    marginTop: '-=15px'
  }),

  option: (provided, state) => ({
    ...provided,
    color: theme.dark,
    backgroundColor: state.isFocused ? theme.grey5 : 'rgba(0,0,0,0)',
    padding: 10,
    fontStyle: 'normal',
    fontWeight: state.isSelected ? 600 : 500,
    fontSize: '16px',
    cursor: 'pointer'
  }),

  singleValue: (provided) => ({
    ...provided,
    color: theme.black,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px'
  }),

  placeholder: (provided) => ({
    ...provided,
    color: theme.dark,
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 500
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: theme.dark,
    transform: state.isFocused ? 'rotate(180deg)' : ''
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(0,0,0,0)'
  })
}

const Cascade: React.FC<Props> = ({
  options,
  onChange,
  value,
  placeholder,
  isSearchable,
  isLoading,
  isDisabled
}: Props) => (
  <Select
    styles={customStyles}
    options={options}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    isSearchable={isSearchable}
    isLoading={isLoading}
    isDisabled={isDisabled}
  />
)

export default Cascade
