import styled from 'styled-components'

export const MainContainer = styled.div`
  margin-left: 44px;
  margin-right: 44px;

  h3 {
    margin: 0;
  }
`

export const SubheadingContainer = styled.div`
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => props.theme.black};
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  gap: 40px;
`

export const EmptyStateContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 347.65px;
    height: 310.21px;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: ${(props) => props.theme.grey2};
  }

  @media (max-width: 1440px) {
    img {
      width: 225px;
    }
  }
`
