import React, { createContext, ReactNode, useState, useEffect, useContext, useRef } from 'react'

import { Asset } from '@console/common/services/api'

import { ProductContext } from './ProductContext'

interface Props {
  children: ReactNode
}

interface VersionContextData {
  versionIndex: number
  setVersionIndex: (n: number) => void
  assetIndex: number
  commentId: string | undefined
  setCommentId: (id: string) => void
  command: string | undefined
  setCommand: (command: string) => void
  editing: { current: boolean }
  assets: Asset[]
  glb: string | undefined
  setGlb: (glb: string) => void
  usdz: string | undefined
  viewerUrl: string | null
  showUpload: boolean
  setShowUpload: (showUpload: boolean) => void
}

export const VersionContext = createContext<VersionContextData>({} as VersionContextData)

export const VersionProvider: React.FC<Props> = ({ children }: Props) => {
  const { product } = useContext(ProductContext)
  const [versionIndex, setVersionIndex] = useState(0)
  const [assetIndex, setAssetIndex] = useState(0)
  const [commentId, setCommentId] = useState<string>()
  const [command, setCommand] = useState<string>()
  const [glb, setGlb] = useState<string>()
  const [usdz, setUsdz] = useState<string>()
  const [viewerUrl, setViewerUrl] = useState<string | null>(null)
  const [showUpload, setShowUpload] = useState(false)
  const editing = useRef(false)

  const assets =
    product?.Versions[versionIndex]?.Assets?.filter((e: Asset) => e.type !== 'USDZ') || []

  useEffect(() => {
    setAssetIndex(0)
    setShowUpload(false)

    const versionGlb = product?.Versions[versionIndex]?.Assets?.find(
      (e: Asset) => e.type === 'GLB'
    )?.url
    setGlb(versionGlb)
    const glbURIEncoded = versionGlb ? window.encodeURIComponent(versionGlb) : null

    const versionUsdz = product?.Versions[versionIndex]?.Assets?.find(
      (e: Asset) => e.type === 'USDZ'
    )?.url
    setUsdz(versionUsdz)
    const usdzURIEncoded = versionUsdz ? window.encodeURIComponent(versionUsdz) : null

    const url =
      glbURIEncoded && usdzURIEncoded
        ? `https://viewer.r2u.io/?glb=${glbURIEncoded}&usdz=${usdzURIEncoded}`
        : glbURIEncoded
        ? `https://viewer.r2u.io/?glb=${glbURIEncoded}`
        : usdzURIEncoded
        ? `https://viewer.r2u.io/?usdz=${usdzURIEncoded}`
        : null

    setViewerUrl(url)
  }, [versionIndex, product])

  return (
    <VersionContext.Provider
      value={{
        versionIndex,
        setVersionIndex,
        assetIndex,
        commentId,
        setCommentId,
        command,
        setCommand,
        editing,
        assets,
        glb,
        setGlb,
        usdz,
        viewerUrl,
        showUpload,
        setShowUpload
      }}
    >
      {children}
    </VersionContext.Provider>
  )
}
