import React from 'react'

import { useTranslation } from 'react-i18next'

import logo from '../../assets/logo3.svg'
import maintenance from '../../assets/maintenance.svg'
import { Body, Container, Header } from './styles'

const NotFound: React.FC = () => {
  const { t } = useTranslation(['maintenance'])

  return (
    <Container>
      <Header>
        <img src={logo} width='20%' alt='logo' />
      </Header>
      <Body>
        <img src={maintenance} alt={t('title')} height='50%' />
        <div className='text'>
          <h2>{t('mainText')}</h2>
          <p>{t('subText')}</p>
        </div>
      </Body>
    </Container>
  )
}
export default NotFound
