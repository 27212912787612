import styled from 'styled-components'

import Button from '@console/common/components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 62px;
  padding-left: 26px;
  padding-right: 26px;
  overflow-x: hidden;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 8px;
  row-gap: 8px;
  background-color: ${(props) => props.theme.grey4};
`

export const Image = styled.img`
  height: 102px;
  max-width: 102px;
  object-fit: cover;
  justify-self: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.white};
`

export const AddImage = styled.img`
  height: 23px;
  width: 23px;
`

export const AddImageContainer = styled.div`
  height: 102px;
  width: 102px;
  cursor: pointer;
  background-color: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.blue};
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LargeButton = styled(Button)`
  width: 320px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PopupContainer = styled.div`
  margin: 28px 33px 28px 33px;
  display: flex;
  flex-direction: column;

  @media (max-height: 700px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const CloseButton = styled.div`
  width: 27px;
  height: 25px;
  margin-bottom: 41px;
  cursor: pointer;
  align-self: flex-end;

  @media (max-height: 700px) {
    width: 18px;
    height: 18px;
    margin-bottom: 25px;
  }
`

export const ComparisonContainer = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;

  div {
    width: 100%;
  }
`

export const ComparisonTitle = styled.div`
  width: 100%;
  height: 64px;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.dark};
  border: 1px solid ${(props) => props.theme.dark};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
  }

  @media (max-height: 700px) {
    height: 42px;
  }
`

export const ComparisonImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 60vh;
  border: 1px solid ${(props) => props.theme.grey2};
  justify-content: center;

  img {
    height: 100%;
    width: auto;
    max-height: 60vh;
    max-width: 100%;
    object-fit: contain;
  }
`

export const ModelViewerContainer = styled.div`
  width: 100%;
  height: 1fr;
  border: 1px solid ${(props) => props.theme.grey2};
`

export const ThumbnailsContainer = styled.div`
  margin-top: 17px;
  display: flex;
  height: 100px;
  width: 100%;
  max-width: 100%;
  gap: 7px;
  overflow: auto;

  @media (max-height: 700px) {
    height: 80px;
  }
`

export const Thumbnail = styled.img`
  height: 100%;
  width: auto;
  cursor: pointer;
  background-color: ${(props) => props.theme.white};
`
