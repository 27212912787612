import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

interface Props {
  isLoading: boolean
}

export const Container = styled.div`
  margin-left: 44px;
  margin-right: 44px;
  color: ${(props) => props.theme.dark};

  small {
    display: block;
    font-weight: lighter;
    margin-top: 23px;
  }
`

export const FieldNames = styled.h3<Props>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 17px;
  margin-bottom: 4px;
  ${(props) => props.isLoading && skeletonAnimation}
  color: ${(props) => (props.isLoading ? 'transparent' : props.theme.dark)};
  width: ${(props) => (props.isLoading ? '200px' : null)};
  border-radius: 5px;
`

export const ButtonContainer = styled.div`
  margin-top: 37px;
`
