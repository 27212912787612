import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import closeIcon from '../../assets/close.svg'
import { AuthContext } from '../../contexts/AuthContext'
import { ModalContext } from '../../contexts/ModalContext'
import Selector from './Selector'
import { Background, Container } from './styles'

const CustomerSelector: React.FC = () => {
  const { t } = useTranslation(['components/customer-selector'])
  const { assumeCustomer } = useContext(AuthContext)
  const { isOpen, closeModal } = useContext(ModalContext)

  return isOpen ? (
    <Background>
      <Container>
        <div role='button' onClick={closeModal} onKeyPress={closeModal} tabIndex={0}>
          <img src={closeIcon} alt='close' />
        </div>
        <h2>{t('title')}</h2>
        <small>{t('text')}</small>
        <Selector
          onSelect={(customer) => {
            assumeCustomer(customer)
            closeModal()
          }}
        />
      </Container>
    </Background>
  ) : null
}

export default CustomerSelector
