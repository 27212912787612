import React, { ReactElement } from 'react'

import { useTable } from 'react-table'

import { TableSkeleton, TableContainer } from './styles'

interface Props {
  columns: { Header: string; accessor: string }[]
  data: { [x: string]: string | ReactElement }[]
  isLoading: boolean
}

const Table: React.FC<Props> = ({ columns, data, isLoading }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <TableContainer>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')} </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, j) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TableContainer>
      )}
    </>
  )
}

export default Table
