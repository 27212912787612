import styled from 'styled-components'

export const ExportCsvButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.grey7};
  border-radius: 8px;
  border: 0;
  padding: 4px;
  cursor: pointer;

  /* text style */
  color: ${(props) => props.theme.dark2};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  img {
    height: 18px;
    margin-right: 8px;
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`
