import React, { createContext, ReactNode, useEffect, useState } from 'react'

import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'

import config from '../config'

interface Props {
  children: ReactNode
}

export enum AnalyticsEvent {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SIGN_UP = 'SIGN_UP',

  PORTFOLIO = 'PORTFOLIO',
  PORTFOLIO_CARD_CLICK = 'PORTFOLIO_CARD_CLICK',
  PORTFOLIO_DETAIL = 'PORTFOLIO_DETAIL',
  PORTFOLIO_DETAIL_CLICK_PDP_URL = 'PORTFOLIO_DETAIL_CLICK_PDP_URL',
  PORTFOLIO_DETAIL_CLICK_VIEWER_URL = 'PORTFOLIO_DETAIL_CLICK_VIEWER_URL',
  PORTFOLIO_DETAIL_CLICK_DELETE_PRODUCT = 'PORTFOLIO_DETAIL_CLICK_DELETE_PRODUCT',
  PORTFOLIO_DETAIL_DOWNLOAD_ASSETS = 'PORTFOLIO_DETAIL_DOWNLOAD_ASSETS',
  PORTFOLIO_DETAIL_UPLOAD_IMAGE = 'PORTFOLIO_DETAIL_UPLOAD_IMAGE',
  PORTFOLIO_DETAIL_UPLOAD_MODEL = 'PORTFOLIO_DETAIL_UPLOAD_MODEL',
  PORTFOLIO_EDIT_UPLOAD_IMAGE = 'PORTFOLIO_EDIT_UPLOAD_IMAGE',
  PORTFOLIO_EDIT_UPLOAD_MODEL = 'PORTFOLIO_EDIT_UPLOAD_MODEL',
  PORTFOLIO_EDIT_DELETE_IMAGE = 'PORTFOLIO_EDIT_DELETE_IMAGE',
  PORTFOLIO_EDIT_DELETE_MODEL = 'PORTFOLIO_EDIT_DELETE_MODEL',
  PORTFOLIO_EDIT_SAVE = 'PORTFOLIO_EDIT_SAVE',
  PORTFOLIO_FORWARD_PRODUCT = 'PORTFOLIO_FORWARD_PRODUCT',
  PORTFOLIO_FORWARD_CANCEL = 'PORTFOLIO_FORWARD_CANCEL',

  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATIONS_CLICK = 'NOTIFICATIONS_CLICK',

  REVIEW = 'REVIEW',
  REVIEW_DETAIL = 'REVIEW_DETAIL',
  REVIEW_CARD_CLICK = 'REVIEW_CARD_CLICK',
  REVIEWER = 'REVIEWER',
  REVIEWER_APPROVE_CLICK = 'REVIEWER_APPROVE_CLICK',
  REVIEWER_REJECT_CLICK = 'REVIEWER_REJECT_CLICK',
  REVIEWER_REVIEW_CLICK = 'REVIEWER_REVIEW_CLICK',
  REVIEWER_COMMENT_CREATE = 'REVIEWER_COMMENT_CREATE',
  REVIEWER_COMMENT_CANCEL = 'REVIEWER_COMMENT_CANCEL',
  REVIEWER_COMMENT_UPDATE = 'REVIEWER_COMMENT_UPDATE',
  REVIEWER_COMMENT_DELETE = 'REVIEWER_COMMENT_DELETE',
  REVIEWER_COMPARE_REFERENCE = 'REVIEWER_COMPARE_REFERENCE',
  REVIEWER_UPLOAD_REFERENCE = 'REVIEWER_UPLOAD_REFERENCE',

  PERFORMANCE = 'PERFORMANCE',
  PERFORMANCE_GENERAL = 'PERFORMANCE_GENERAL',
  PERFORMANCE_GENERAL_CHANGE_DATE = 'PERFORMANCE_GENERAL_CHANGE_DATE',
  PERFORMANCE_GENERAL_CLICK_TABLE_ROW = 'PERFORMANCE_GENERAL_CLICK_TABLE_ROW',
  PERFORMANCE_FEATURES = 'PERFORMANCE_FEATURES',
  PERFORMANCE_FEATURES_CHANGE_DATE = 'PERFORMANCE_FEATURES_CHANGE_DATE',
  PERFORMANCE_FEATURES_NO_CUSTOMIZER_CLICK = 'PERFORMANCE_FEATURES_NO_CUSTOMIZER_CLICK',

  SETTINGS = 'SETTINGS',
  SETTINGS_USERS = 'SETTINGS_USERS',
  SETTINGS_USERS_HOME = 'SETTINGS_USERS_HOME',
  SETTINGS_USERS_INVITE = 'SETTINGS_USERS_INVITE',
  SETTINGS_USERS_INVITE_SEND = 'SETTINGS_USERS_INVITE_SEND',

  SETTINGS_CUSTOMERS = 'SETTINGS_CUSTOMERS',
  SETTINGS_CUSTOMERS_HOME = 'SETTINGS_CUSTOMERS_HOME',
  SETTINGS_CUSTOMERS_CREATE = 'SETTINGS_CUSTOMERS_CREATE',
  SETTINGS_CUSTOMERS_CREATE_CREATED = 'SETTINGS_CUSTOMERS_CREATE_CREATED',

  REGISTRATION = 'REGISTRATION',
  REGISTRATION_SUBMIT_DATA = 'REGISTRATION_SUBMIT_DATA',
  REGISTRATION_UPLOAD_IMAGE = 'REGISTRATION_UPLOAD_IMAGE',
  REGISTRATION_DECISION_UPLOAD = 'REGISTRATION_DECISION_UPLOAD',
  REGISTRATION_DECISION_FINISH = 'REGISTRATION_DECISION_FINISH',
  REGISTRATION_DELETE_IMAGE = 'REGISTRATION_DELETE_IMAGE',
  REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE',
  REGISTRATION_MULTI_SUBMIT_DATA = 'REGISTRATION_MULTI_SUBMIT_DATA',
  REGISTRATION_MULTI_COMPLETE = 'REGISTRATION_MULTI_COMPLETE'
}

interface AnalyticsContextData {
  analytics: OverridedMixpanel | null
  optOut: boolean | null
  banner: boolean
  handleUserChoice: (choice: boolean) => void
}

export const AnalyticsContext = createContext<AnalyticsContextData>({} as AnalyticsContextData)

export const AnalyticsProvider: React.FC<Props> = ({ children }: Props) => {
  const [analytics, setAnalytics] = useState<OverridedMixpanel | null>(null)
  const [optOut, setOptOut] = useState<boolean | null>(null)
  const [banner, setBanner] = useState(false)

  const init = () => {
    mixpanel.init(config.mixpanel.projectToken, { debug: config.mixpanel.debug })
    setAnalytics(mixpanel)
    const storageOptOut = localStorage.getItem('optOut')
    if (storageOptOut === null) return setBanner(true)
    return setOptOut(storageOptOut === 'true')
  }

  const handleUserChoice = (choice: boolean) => {
    localStorage.setItem('optOut', String(choice))
    setOptOut(choice)
    setBanner(false)
  }

  useEffect(() => {
    if (optOut) mixpanel.opt_out_tracking()
    else if (optOut === false) mixpanel.opt_in_tracking()
  }, [optOut])

  useEffect(() => {
    init()
  }, [])

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        optOut,
        handleUserChoice,
        banner
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
