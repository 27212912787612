import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import { ButtonsContainer, Button } from './styles'

export interface SubPage {
  to: string
  title: string
}

interface Props {
  subPages: SubPage[]
}

const SubPageMenu: React.FC<Props> = ({ subPages }: Props) => {
  const location = useLocation()

  return (
    <div>
      <ButtonsContainer>
        {subPages.map(({ to, title }) => (
          <Link key={to} to={to}>
            <Button selected={to === location.pathname}>{title}</Button>
          </Link>
        ))}
      </ButtonsContainer>
    </div>
  )
}

export default SubPageMenu
