import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'

import Header from '@console/common/components/Header'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'

const PerformanceHeader: React.FC = () => {
  const { t } = useTranslation(['performance'])
  const { session } = useContext(AuthContext)

  const { path } = useRouteMatch()

  const [subPages, setSubPages] = useState([
    { to: '/performance/general', title: t('pages.bigquery.title') },
    { to: '/performance/general/purchase', title: t('pages.analytics.title') }
  ])

  useEffect(() => {
    if (session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) {
      setSubPages([
        { to: '/performance/general', title: t('pages.bigquery.title') },
        { to: '/performance/general/purchase', title: t('pages.analytics.title') }
      ])
    }
  }, [session])

  return (
    <Header
      title={t('title')}
      subPages={subPages}
      returnPage={path === '/performance' ? undefined : '/performance'}
    />
  )
}

export default PerformanceHeader
