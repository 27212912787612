import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react'

import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  AssetStatus,
  Product,
  fetchProducts,
  OrderOptions,
  UserRole
} from '@console/common/services/api'
import Status from '@console/common/types/Status'

interface ProductWithStatus extends Product {
  publicStatus?: Status
}

interface Props {
  children: ReactNode
}

interface ProductsContextData {
  products: ProductWithStatus[]
  loading: boolean
  total: number | null
  nextPage: number | null
  fetchAndSetProducts: (reset?: boolean) => void
}

export const ProductsContext = createContext<ProductsContextData>({} as ProductsContextData)

export const ProductsProvider: React.FC<Props> = ({ children }: Props) => {
  const [products, setProducts] = useState<ProductWithStatus[]>([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<number | null>(null)
  const [nextPage, setNextPage] = useState<number | null>(0)
  const [pageSize] = useState<number>(30)

  const { session, assumedCustomer } = useContext(AuthContext)

  const role =
    session?.role === UserRole.ARTIST ||
    session?.role === UserRole.FREELANCER ||
    ((session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) && !assumedCustomer)
      ? UserRole.ARTIST
      : UserRole.CUSTOMER

  const status = role === UserRole.ARTIST ? AssetStatus.UNDER_REVIEW : AssetStatus.TEAM_APPROVED

  const addStatusToProducts = (productList: Product[]): ProductWithStatus[] =>
    // eslint-disable-next-line implicit-arrow-linebreak
    productList.map((p) => ({
      ...p,
      publicStatus: Status.PENDING_REVIEW
    }))

  const fetchAndSetProducts = useCallback(
    (reset = false) => {
      if (loading) return
      setLoading(true)
      const order = OrderOptions.SKU
      const page = reset ? 0 : nextPage ?? 0
      fetchProducts({
        order,
        page,
        pageSize,
        status: [status],
        customerId: assumedCustomer?.id
      }).then((p) => {
        if (p) {
          const newList = reset
            ? addStatusToProducts(p.results)
            : [...products, ...addStatusToProducts(p.results)]
          setProducts(
            session?.role === UserRole.CUSTOMER
              ? newList.filter((product) => product.owner === session.customer.id)
              : newList
          )
          setTotal(p.total)
          setNextPage(
            p.total > p.start + p.results.length
              ? Math.round((p.start + p.results.length) / pageSize)
              : null
          )
        }
        setLoading(false)
      })
    },
    [loading, products, nextPage, pageSize, assumedCustomer, session]
  )

  useEffect(() => {
    if (!session) return
    setProducts([])
    fetchAndSetProducts(true)
  }, [session, assumedCustomer])

  return (
    <ProductsContext.Provider
      value={{
        products,
        loading,
        total,
        nextPage,
        fetchAndSetProducts
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
