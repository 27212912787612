import React from 'react'

import { Chip as ChipMUI } from '@material-ui/core'

import { StyledChip } from './styles'

type VARIANT = 'outlined' | 'default' | undefined

interface Props {
  label: string
  handleDelete: (label: string) => void
  variant: VARIANT
}

const Chip: React.FC<Props> = ({ label, handleDelete, variant }: Props) => (
  <StyledChip>
    <ChipMUI label={label} variant={variant} onDelete={() => handleDelete(label)} />
  </StyledChip>
)

export default Chip
