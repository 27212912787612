import React from 'react'

import { SpinnerComponent, Animation } from './styles'

interface Props {
  width: string
  height: string
  text: string
}

const Spinner: React.FC<Props> = ({ width, height, text }: Props) => (
  <SpinnerComponent>
    <Animation width={width} height={height} />
    <p>{text}</p>
  </SpinnerComponent>
)

export default Spinner
