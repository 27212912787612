import React, { createContext, ReactNode, useState, useEffect, useContext } from 'react'

import { AuthContext } from '@console/common/contexts/AuthContext'
import { fetchProducts, OrderOptions, UserRole } from '@console/common/services/api'

interface Props {
  children: ReactNode
}

export const SearchContext = createContext<SearchPreview>({} as SearchPreview)

interface SearchPreview {
  getSearchPreview: (query: string) => void
  results: string[]
  clearResults: () => void
  loading: boolean
}

export const SearchProvider: React.FC<Props> = ({ children }: Props) => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<string[]>([])
  const [query, setQuery] = useState('')
  const { session, assumedCustomer } = useContext(AuthContext)

  useEffect(() => {
    if (loading || !query) return
    setLoading(true)
    fetchProducts({
      order: OrderOptions.SKU,
      page: 0,
      pageSize: 50,
      search: query,
      customerId:
        session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER
          ? assumedCustomer?.id
          : null
    }).then((p) => {
      if (p) {
        setResults(p.results.reduce((acc: string[], cur) => acc.concat([cur.name, cur.sku]), []))
        setLoading(false)
      }
    })
  }, [query])

  const clearResults = () => {
    setQuery('')
    setResults([])
  }

  useEffect(() => {
    clearResults()
  }, [assumedCustomer])

  return (
    <SearchContext.Provider value={{ getSearchPreview: setQuery, results, clearResults, loading }}>
      {children}
    </SearchContext.Provider>
  )
}
