import React from 'react'

import { Redirect, Switch } from 'react-router-dom'

import { UserRole } from '@console/common/services/api'
import Notifications from '@console/notifications'
import Performance from '@console/performance'
import Portfolio from '@console/portfolio'
import Registration from '@console/registration'
import Review from '@console/reviewer'
import Settings from '@console/settings'

import Login from '../pages/Login'
import Logout from '../pages/Logout'
import Maintenance from '../pages/Maintenance'
import NotFound from '../pages/NotFound'
import Route from './Route'

const Routes: React.FC = () => (
  <Switch>
    <Route exact path='/' hasSideBar>
      <Redirect to='/portfolio' />
    </Route>
    <Route path='/portfolio' hasSideBar>
      <Portfolio />
    </Route>
    <Route path='/review' hasSideBar allowMobile>
      <Review />
    </Route>
    <Route
      path='/performance'
      hasSideBar
      forbidAccess={[UserRole.ARTIST, UserRole.FREELANCER, UserRole.R2USER]}
    >
      <Performance />
    </Route>
    <Route path='/notifications' hasSideBar forbidAccess={[UserRole.ARTIST, UserRole.FREELANCER]}>
      <Notifications />
    </Route>
    <Route path='/settings' hasSideBar forbidAccess={[UserRole.ARTIST, UserRole.FREELANCER]}>
      <Settings />
    </Route>
    <Route path='/registration' hasSideBar>
      <Registration />
    </Route>
    <Route path='/login' isPrivate={false} allowMobile>
      <Login />
    </Route>
    <Route path='/signup' isPrivate={false} allowMobile>
      <Login signUp />
    </Route>
    <Route path='/logout' isPrivate={false} allowMobile>
      <Logout />
    </Route>
    <Route path='/maintenance' isPrivate={false}>
      <Maintenance />
    </Route>
    <Route path='*'>
      <NotFound />
    </Route>
  </Switch>
)

export default Routes
