import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import searchIcon from '../../assets/search.svg'
import { getCustomers, Customer } from '../../services/api'
import TextInput from '../TextInput'
import { InputWrapper, CustomerList } from './styles'

interface Props {
  onSelect: (customer: Customer) => void
}

const Selector: React.FC<Props> = ({ onSelect }: Props) => {
  const { t } = useTranslation(['components/customer-selector'])
  const [customers, setCustomers] = useState<Customer[]>([])
  const [filter, setFilter] = useState(new RegExp('', 'i'))

  useEffect(() => {
    if (!customers.length) getCustomers().then(setCustomers)
    setFilter(new RegExp('', 'i'))
    const searchBar = document.getElementById('search-customer')
    if (searchBar) searchBar.focus()
  }, [])

  return (
    <>
      <InputWrapper>
        <img src={searchIcon} alt='' />
        <TextInput
          id='search-customer'
          placeholder={t('placeholder')}
          onChange={(e) => setFilter(new RegExp(e.currentTarget.value, 'i'))}
        />
      </InputWrapper>
      <CustomerList>
        {customers
          .filter((c) => filter.test(c.slug) || filter.test(c.name))
          .map((c) => (
            <span
              role='button'
              tabIndex={0}
              key={c.id}
              onKeyPress={() => onSelect(c)}
              onClick={() => onSelect(c)}
            >
              {c.name}
            </span>
          ))}
      </CustomerList>
    </>
  )
}

export default Selector
