import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from '@console/common/components/Button'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { User } from '@console/common/services/api'

import Header from '../../../components/Header'
import HomePopup, { PopupOperation } from '../../../components/HomePopup'
import UsersTable from '../../../components/UsersTable'
import { getUsers } from '../../../services/api'
import { Container } from './styles'

const Home: React.FC = () => {
  const { t } = useTranslation(['settings'])
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [popupOperation, setPopupOperation] = useState<PopupOperation | null>(null)
  const [userId, setUserId] = useState<string>('')
  const { analytics } = useContext(AnalyticsContext)

  const loadUsers = () => {
    setLoading(true)
    getUsers().then((u) => {
      if (u) setUsers(u)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadUsers()
  }, [])

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS_USERS_HOME)
  }, [analytics])

  return (
    <>
      <HomePopup operation={popupOperation} userId={userId} loadUsers={loadUsers} />
      <Container>
        <Header />
        <Link to='/settings/users/invite'>
          <Button>{t('users.inviteUser')}</Button>
        </Link>
        <UsersTable
          users={users}
          setPopupOperation={(u, o) => {
            setUserId(u)
            setPopupOperation(o)
          }}
          isLoading={loading}
        />
      </Container>
    </>
  )
}

export default Home
