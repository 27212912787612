import React, { useContext, useRef, useCallback, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import Card from '@console/common/components/Card'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { AssetStatus, getThumbIfExists, updateAsset } from '@console/common/services/api'
import Status from '@console/common/types/Status'

import { ProductsContext, ProductWithStatus } from '../../contexts/ProductsContext'
// import { changeActiveStatus } from '../../services/api'
import { getVersionAssets } from '../../services/api'
import CheckboxCard from '../CheckboxCard'
import { CardsContainer } from './styles'

const CardsList: React.FC = () => {
  const { t } = useTranslation(['components/status-badge'])
  const { loading, products, nextPage, fetchAndSetProducts, setProducts } =
    useContext(ProductsContext)
  const { session } = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)
  const location = useLocation()

  const observer = useRef<IntersectionObserver>()
  const lastCardElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          fetchAndSetProducts()
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, nextPage]
  )

  const getOptions = (product: ProductWithStatus) => {
    if (!product.Versions[0]) return null

    const updateAssets = async (status: AssetStatus, active: boolean | null) => {
      const assets = await getVersionAssets(product.Versions[0].id)
      if (!assets) return
      await Promise.all(assets.map((asset) => updateAsset(asset.id, { status })))
      // if (active !== null) await changeActiveStatus(product.id, active)
    }

    const updateStatusBadge = (status: Status) => {
      setProducts(
        products.map((aProduct) => {
          if (aProduct === product) {
            return { ...aProduct, publicStatus: status }
          }
          return aProduct
        })
      )
    }

    return [
      {
        label: t('RUNNING'),
        action: () => {
          updateAssets(AssetStatus.CUSTOMER_APPROVED, true).then(() =>
            updateStatusBadge(Status.RUNNING)
          )
        }
      },
      {
        label: t('PAUSED'),
        action: () => {
          updateAssets(AssetStatus.CUSTOMER_APPROVED, false).then(() =>
            updateStatusBadge(Status.PAUSED)
          )
        }
      },
      {
        label: t('REJECTED'),
        action: () => {
          updateAssets(AssetStatus.CUSTOMER_REJECTED, null).then(() =>
            updateStatusBadge(Status.REJECTED)
          )
        }
      },
      {
        label: t('PENDING_REVIEW'),
        action: () => {
          updateAssets(AssetStatus.TEAM_APPROVED, null).then(() =>
            updateStatusBadge(Status.PENDING_REVIEW)
          )
        }
      },
      {
        label: t('IN_PROGRESS'),
        action: () => {
          updateAssets(AssetStatus.WORK_IN_PROGRESS, null).then(() =>
            updateStatusBadge(Status.IN_PROGRESS)
          )
        }
      }
    ]
  }

  const [thumbs, setThumbs] = useState<{ [productId: string]: string }>({})

  const loadThumbnails = async () => {
    if (products) {
      const newThumbs: { [productId: string]: string } = {}
      const promises: Promise<void>[] = []

      const loadThumbnail = async (product: ProductWithStatus) => {
        const thumb = await getThumbIfExists(product.References[0]?.url)
        newThumbs[product.id] = thumb
      }

      products.forEach((product) => {
        const key = product.id
        // If thumbnail was already loaded, don't load again
        if (thumbs[key]) {
          newThumbs[key] = thumbs[key]
        } else if (product.References) {
          promises.push(loadThumbnail(product))
        }
      })

      await Promise.all(promises)
      setThumbs(newThumbs)
    }
  }

  useEffect(() => {
    loadThumbnails()
  }, [products])

  const cards = products?.map((product, index) => (
    <Link
      ref={products.length === index + 1 ? lastCardElementRef : null}
      key={product.id}
      onClick={() =>
        analytics?.track(AnalyticsEvent.PORTFOLIO_CARD_CLICK, { productId: product.id })
      }
      to={`/portfolio/${product.id}${location.search?.toString()}`}
      style={{ textDecoration: 'none' }}
    >
      <CheckboxCard
        imgSrc={thumbs && thumbs[product.id]}
        name={product.Customers?.[0].CustomerProduct?.name || product.name}
        sku={product.Customers?.[0].CustomerProduct?.sku || ''}
        customer={product.Customers?.find((fCustomer) => fCustomer.id === product.owner)?.name}
        status={product.publicStatus}
        showOptions={session?.role === 'ADMIN'}
        options={getOptions(product)}
        id={product.id}
      />
    </Link>
  ))

  return (
    <CardsContainer>
      {cards}
      {loading
        ? Array(5)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, i) => <Card isLoading key={i} />)
        : null}
    </CardsContainer>
  )
}

export default CardsList
