import React from 'react'

import { useTranslation } from 'react-i18next'

import { Container, StyledStatusBadge } from './styles'

interface Props {
  isActive: boolean
}

const ActiveStatusBadge: React.FC<Props> = ({ isActive }: Props) => {
  const { t } = useTranslation(['settings'])

  return (
    <Container>
      <StyledStatusBadge isActive={isActive}>
        {isActive ? t('customers.active') : t('customers.inactive')}
      </StyledStatusBadge>
    </Container>
  )
}

export default ActiveStatusBadge
