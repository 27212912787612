import styled from 'styled-components'

import Button from '@console/common/components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 62px;
  padding-left: 26px;
  padding-right: 26px;
  overflow-x: hidden;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
`

export const VersionContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const SimpleButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddButton = styled(SimpleButton)`
  width: 36px;
  height: 36px;
  border: 1px solid ${(props) => props.theme.white};

  img {
    width: 14px;
    height: 14px;
  }
`

export const VersionButton = styled(SimpleButton)`
  width: 36px;
  height: 36px;
`

export const Separator = styled.hr`
  border: 1px solid ${(props) => props.theme.grey3};
  width: 100%;
`

export const ApproveButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
`
