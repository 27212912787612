import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['pt', 'en'],
    preload: ['pt', 'en'],
    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        if (format === 'number') return new Intl.NumberFormat(lng).format(value)
        return value
      }
    },
    load: 'all',
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          allowMultiloading: true
        }
      ]
    }
  })

export default i18next
