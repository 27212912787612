import React from 'react'

import { useTranslation } from 'react-i18next'

import Status from '@console/common/types/Status'

import { Container, StyledStatusBadge } from './styles'

interface Props {
  status: Status
}

const StatusBadge: React.FC<Props> = ({ status }: Props) => {
  const { t } = useTranslation(['components/status-badge'])

  return (
    <Container>
      <StyledStatusBadge status={status}>{t(status as unknown as string)}</StyledStatusBadge>
    </Container>
  )
}

export default StatusBadge
