import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'

import { Formik, Form, Field, FieldArray, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import Button from '@console/common/components/Button'
import CarouselCard from '@console/common/components/CarouselCard'
import Cascade, { SelectOptions } from '@console/common/components/Cascade'
import Header from '@console/common/components/Header'
import MultiCarousel from '@console/common/components/MultiCarousel'
import Saving from '@console/common/components/Saving'
import TextInput, { InputTypes } from '@console/common/components/TextInput'
import { Input } from '@console/common/components/TextInput/styles'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import {
  Product,
  Customer,
  UserRole,
  ProductPlacement,
  ReferenceType,
  createReference,
  getModelPresignedUrl,
  confirmModelUpload
} from '@console/common/services/api'
import { upload, uploadToUrl } from '@console/common/services/upload'

import {
  getProductInfo,
  patchCustomerProduct,
  deleteAsset,
  deleteReference
} from '../../services/api'
import {
  MainContainer,
  SubTitle,
  SectionContainer,
  SectionContent,
  SectionTitle,
  SectionDescription,
  FieldNames,
  EanField,
  PlusButton,
  Separator,
  Extensions,
  ButtonContainer
} from './styles'
import UploadArea from './UploadArea'

interface ParamTypes {
  productId: string
}

const Edit: React.FC = () => {
  const { t } = useTranslation(['portfolio'])
  const history = useHistory()
  const { productId } = useParams<ParamTypes>()
  const { analytics } = useContext(AnalyticsContext)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<Product | null>(null)
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [name, setName] = useState<string>()
  const [sku, setSku] = useState<string>()
  const [ean, setEan] = useState<string[]>([])
  const [pdpUrl, setPdpUrl] = useState<string>()
  const [placement, setPlacement] = useState<SelectOptions | null>(null)
  const [height, setHeight] = useState<string>()
  const [width, setWidth] = useState<string>()
  const [depth, setDepth] = useState<string>()
  const [images, setImages] = useState<{ id: string; url: string }[]>([])
  const [models, setModels] = useState<{ id: string; url: string; type: string }[]>([])
  const [uploadingImage, setUploadingImage] = useState(false)
  const [uploadingModel, setUploadingModel] = useState(false)
  const [imageSlides, setImageSlides] = useState<React.ReactNode[]>([])
  const [modelSlides, setModelSlides] = useState<React.ReactNode[]>([])

  const { session, assumedCustomer } = useContext(AuthContext)

  const formikRef = useRef<FormikProps<{ ean: string[] }>>(null)

  const PlacementOptions = [
    {
      value: ProductPlacement.FLOOR,
      label: t('edit.dimensions.floor')
    },
    {
      value: ProductPlacement.WALL,
      label: t('edit.dimensions.wall')
    }
  ]

  useEffect(() => {
    if (!ean.length) {
      setEan(ean.concat(['']))
    }
  }, [ean])

  useEffect(() => {
    if (product) {
      setImages(
        product?.References?.filter((reference) => reference.type === ReferenceType.IMAGE)?.map(
          (reference) => ({ id: reference.id, url: reference.url })
        )
      )
      setModels(product?.Versions[0]?.Assets || [])
    }
  }, [product])

  useEffect(() => {
    if (session && product) {
      setCustomer(
        session.role === UserRole.CUSTOMER
          ? product.Customers?.find((fCustomer) => fCustomer.id === session.customer.id) || null
          : (session?.role === UserRole.ADMIN || session?.role === UserRole.R2USER) &&
            assumedCustomer
          ? product.Customers?.find((fCustomer) => fCustomer.id === assumedCustomer.id) || null
          : product.Customers?.find((fCustomer) => fCustomer.id === product.owner) || null
      )
    }
  }, [product, session, assumedCustomer])

  useEffect(() => {
    if (customer?.CustomerProduct) {
      setName(customer.CustomerProduct.name)
      setSku(customer.CustomerProduct.sku)
      setPdpUrl(customer.CustomerProduct.pdpUrl)
    }
  }, [customer])

  useEffect(() => {
    setLoading(true)
    getProductInfo(productId).then((p) => {
      setProduct(p)
      if (p) {
        setEan(p.ean || [])
        setPlacement(PlacementOptions.find((option) => option.value === p.placement) || null)
        setHeight(product?.metadata?.height)
        setWidth(product?.metadata?.width)
        setDepth(product?.metadata?.depth)
      }
      setLoading(false)
    })
  }, [productId])

  const [saving, setSaving] = useState(false)
  const saveCallback = async () => {
    setSaving(true)
    analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_SAVE, { productId: product?.id })
    if (!customer) return
    const sEan = formikRef.current?.values.ean.filter((fEan) => fEan !== '')
    await patchCustomerProduct(customer.id, productId, {
      name,
      sku,
      ean: sEan?.length ? sEan : undefined,
      pdpUrl: pdpUrl || undefined,
      placement: placement?.value,
      height,
      width,
      depth
    })
    history.push(`/portfolio/${productId}`)
  }

  const deleteImage = useCallback(
    async (id: string) => {
      analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_DELETE_IMAGE, { productId: product?.id })
      await deleteReference(id)
      setImages(images.filter((image) => image.id !== id))
    },
    [images]
  )

  const deleteModel = useCallback(
    async (id: string) => {
      analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_DELETE_MODEL, { productId: product?.id })
      await deleteAsset(id)
      setModels(models.filter((model) => model.id !== id))
    },
    [models]
  )

  useEffect(() => {
    const slides = images.map((image) => (
      <React.Fragment key={image.id}>
        <CarouselCard
          type='IMAGE'
          imageUrl={image.url}
          deleteCallback={() => deleteImage(image.id)}
        />
      </React.Fragment>
    ))
    if (uploadingImage) slides.push(<CarouselCard type='UPLOADING' />)
    setImageSlides(slides)
  }, [images, uploadingImage])

  useEffect(() => {
    const slides = models.map((model) => (
      <React.Fragment key={model.id}>
        <CarouselCard
          type='MODEL'
          extension={model.type}
          modelUrl={model.url}
          deleteCallback={() => deleteModel(model.id)}
        />
      </React.Fragment>
    ))
    if (uploadingModel) slides.push(<CarouselCard type='UPLOADING' />)
    setModelSlides(slides)
  }, [models, uploadingModel])

  const onDropImage = useCallback(
    async (acceptedFiles) => {
      if (!product) return
      setUploadingImage(true)
      analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_UPLOAD_IMAGE, { productId: product.id })
      const newImages = [...images]

      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          await upload(
            file,
            `references/${product.id}`,
            async (e: string) => {
              createReference(product.id, {
                name: file.name,
                type: ReferenceType.IMAGE,
                url: e,
                description: 'Uploaded using the platform'
              }).then((reference) => {
                if (reference) {
                  product.References.push(reference)
                  setImages(newImages.concat([{ id: reference.id, url: reference.url }]))
                  newImages.push({ id: reference.id, url: reference.url })
                }
              })
            },
            undefined,
            true
          )
        })
      )
      setUploadingImage(false)
    },
    [images, product]
  )

  const onDropModel = useCallback(
    async (acceptedFiles) => {
      if (!product) return
      setUploadingModel(true)
      analytics?.track(AnalyticsEvent.PORTFOLIO_EDIT_UPLOAD_MODEL, { productId: product.id })
      const newModels = [...models]

      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
          const uploadRequest = await getModelPresignedUrl(product.id, extension)
          if (!uploadRequest) return
          await uploadToUrl(file, uploadRequest.uploadUrl, async () => {
            await confirmModelUpload(uploadRequest.assetId, uploadRequest.viewId)
            setModels(
              newModels.concat([
                { id: uploadRequest.viewId, url: uploadRequest.outputUrl, type: extension }
              ])
            )
            newModels.push({
              id: uploadRequest.viewId,
              url: uploadRequest.outputUrl,
              type: extension
            })
          })
        })
      )
      setUploadingModel(false)
    },
    [models, product]
  )

  return (
    <>
      <MainContainer>
        <Header title={t('edit.title')} returnPage={`/portfolio/${productId}`} />
        <SubTitle>{t('edit.subTitle')}</SubTitle>
        <SectionContainer>
          <Separator />
          <SectionContent>
            <div>
              <SectionTitle>{t('edit.main.title')}</SectionTitle>
              <SectionDescription>{t('edit.main.description')}</SectionDescription>
            </div>
            <div>
              <FieldNames isLoading={loading}>{t('edit.main.name')}</FieldNames>
              <TextInput
                disabled={saving}
                value={name || ''}
                onChange={(e) => setName(e.currentTarget.value)}
                width='330px'
                type={InputTypes.TEXT}
                isLoading={loading}
              />
              <FieldNames isLoading={loading}>{t('edit.main.sku')}</FieldNames>
              <TextInput
                disabled={saving}
                value={sku || ''}
                onChange={(e) => setSku(e.currentTarget.value)}
                width='330px'
                type={InputTypes.TEXT}
                isLoading={loading}
              />
              <Formik
                innerRef={formikRef}
                initialValues={{ ean }}
                enableReinitialize
                onSubmit={(values) => {
                  setEan(values.ean)
                }}
              >
                {({ values }) => (
                  <Form>
                    <FieldArray
                      name='ean'
                      render={(arrayHelpers) => (
                        <div>
                          {values.ean && values.ean.length
                            ? values.ean.map((friend, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={index}>
                                  <EanField>
                                    <FieldNames isLoading={loading}>
                                      {t('edit.main.ean')}
                                    </FieldNames>
                                    {index === 0 && !loading ? (
                                      <PlusButton
                                        disabled={saving}
                                        type='button'
                                        onClick={() => arrayHelpers.push('')}
                                        // eslint-disable-next-line react/jsx-no-literals
                                      >
                                        +
                                      </PlusButton>
                                    ) : null}
                                  </EanField>
                                  <Field
                                    name={`ean.${index}`}
                                    as={Input}
                                    isLoading={loading}
                                    width='330px'
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    render={(form: any) => (
                                      <Input
                                        width='330px'
                                        {...form.field}
                                        disabled={saving}
                                        type='text'
                                      />
                                    )}
                                  />
                                </div>
                              ))
                            : null}
                        </div>
                      )}
                    />
                  </Form>
                )}
              </Formik>
              <FieldNames isLoading={loading}>{t('edit.main.pdp')}</FieldNames>
              <TextInput
                disabled={saving}
                value={pdpUrl || ''}
                onChange={(e) => setPdpUrl(e.currentTarget.value)}
                width='330px'
                type={InputTypes.URL}
                isLoading={loading}
              />
            </div>
          </SectionContent>
        </SectionContainer>
        <SectionContainer>
          <Separator />
          <SectionContent>
            <div>
              <SectionTitle>{t('edit.dimensions.title')}</SectionTitle>
              <SectionDescription>{t('edit.dimensions.description')}</SectionDescription>
            </div>
            <div>
              <FieldNames isLoading={loading}>{t('edit.dimensions.placement')}</FieldNames>
              <Cascade
                isDisabled={saving}
                options={PlacementOptions}
                onChange={(option) => setPlacement(option)}
                value={placement}
                isLoading={loading}
                isSearchable={false}
              />
              <FieldNames isLoading={loading}>{t('edit.dimensions.height')}</FieldNames>
              <TextInput
                disabled={saving}
                value={height || ''}
                onChange={(e) => setHeight(e.currentTarget.value)}
                width='96px'
                type={InputTypes.TEXT}
                isLoading={loading}
              />
              <FieldNames isLoading={loading}>{t('edit.dimensions.width')}</FieldNames>
              <TextInput
                disabled={saving}
                value={width || ''}
                onChange={(e) => setWidth(e.currentTarget.value)}
                width='96px'
                type={InputTypes.TEXT}
                isLoading={loading}
              />
              <FieldNames isLoading={loading}>{t('edit.dimensions.depth')}</FieldNames>
              <TextInput
                disabled={saving}
                value={depth || ''}
                onChange={(e) => setDepth(e.currentTarget.value)}
                width='96px'
                type={InputTypes.TEXT}
                isLoading={loading}
              />
            </div>
          </SectionContent>
        </SectionContainer>
        <SectionTitle>{t('edit.images.title')}</SectionTitle>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Extensions>PNG, JPG</Extensions>
        <UploadArea disabled={saving} type='IMAGE' loading={loading} onDrop={onDropImage}>
          {images?.length ? <MultiCarousel slides={imageSlides} isLoading={loading} /> : null}
        </UploadArea>
        <SectionTitle>{t('edit.models.title')}</SectionTitle>
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Extensions>GLB, USDZ, STP, FBX, SKP, OBJ+MTL, BLEND, DWG</Extensions>
        <UploadArea disabled={saving} type='MODEL' loading={loading} onDrop={onDropModel}>
          {models?.length ? <MultiCarousel slides={modelSlides} isLoading={loading} /> : null}
        </UploadArea>
        <ButtonContainer>
          <Button disabled={saving} onClick={saveCallback}>
            <Saving saving={saving} text={t('edit.save')} />
          </Button>
        </ButtonContainer>
      </MainContainer>
    </>
  )
}

export default Edit
