import comfy from '@console/common/assets/comfy.png'
import comfydes from '@console/common/assets/comfydes.png'
import neutral from '@console/common/assets/neutral.png'
import river from '@console/common/assets/river.png'
import studio8 from '@console/common/assets/studio 8.png'
import studio9 from '@console/common/assets/studio 9.png'

interface HDRI {
  name: string
  url: string
  thumbnail: string
}

const hdris: HDRI[] = [
  {
    name: 'Neutral',
    url: 'neutral',
    thumbnail: neutral
  },
  {
    name: 'Studio 8',
    url: 'https://sdk.r2u.io/hdri/studio_small_08_2k.hdr',
    thumbnail: studio8
  },
  {
    name: 'Studio 9',
    url: 'https://sdk.r2u.io/hdri/studio_small_09_2k.hdr',
    thumbnail: studio9
  },
  {
    name: 'Comfy Café',
    url: 'https://sdk.r2u.io/hdri/comfy_cafe_2k.hdr',
    thumbnail: comfy
  },
  {
    name: 'Comfy White',
    url: 'https://sdk.r2u.io/hdri/comfy_cafe_white_2k.hdr',
    thumbnail: comfydes
  },
  {
    name: 'River Walk',
    url: 'https://sdk.r2u.io/hdri/river_walk_1_2k.hdr',
    thumbnail: river
  }
]

export default hdris
