import styled from 'styled-components'

export const ForwardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.grey7};
  border-radius: 8px;
  border: 0;
  padding: 4px;
  cursor: pointer;

  /* text style */
  color: ${(props) => props.theme.dark2};
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  img {
    height: 18px;
    margin-right: 8px;
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  margin-left: 93px;
  margin-right: 93px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 88.8%;
`

export const SelectHeader = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`

export const SelectSubheader = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-top: 8px;
`

export const SelectFromTo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 56px;
  background: ${(props) => props.theme.grey11};
  border-radius: 8px;
  margin-top: 48px;
  margin-bottom: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  span:first-child {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
`

export const SkusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
`

export const SelectSkus = styled.span`
  width: 33%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`

export const Separator = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.grey9};
  background-color: ${(props) => props.theme.grey9};
  margin-top: 32px;
  margin-bottom: 32px;
`

export const SelectButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
`
