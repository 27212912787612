import React, { useContext, useEffect, useState } from 'react'

import applyMaterialsIcon from '../../assets/icons/applyMaterialIcon.svg'
import ARIcon from '../../assets/icons/ARIcon.svg'
import commentsIcon from '../../assets/icons/commentsIcon.svg'
import configurationIcon from '../../assets/icons/ConfigurationIcon.svg'
import customizationIcon from '../../assets/icons/customizationIcon.svg'
import infoIcon from '../../assets/icons/infoIcon.svg'
import referenceImagesIcon from '../../assets/icons/referenceImagesIcon.svg'
import { ProductContext } from '../../contexts/ProductContext'
import { BarOptions, RightBarOptionsContext } from '../../contexts/RightBarOptionsContext'
import { Button, Icon } from './styles'

interface Props {
  type: BarOptions | null
}

const ViewerButton: React.FC<Props> = ({ type }: Props) => {
  const { rightBarOptions, setRightBarOptions } = useContext(RightBarOptionsContext)
  const { loading } = useContext(ProductContext)
  const [active, setActive] = useState(true)

  useEffect(() => {
    setActive(type === rightBarOptions)
  }, [rightBarOptions])

  const toggleActiveButton = () => setActive(!active)

  const handleClick = () => {
    toggleActiveButton()
    // eslint-disable-next-line no-unused-expressions
    rightBarOptions === type ? setRightBarOptions(null) : setRightBarOptions(type)
  }

  const icon = () => {
    switch (type) {
      case BarOptions.COMMENTS:
        return commentsIcon
      case BarOptions.CONFIGURATION:
        return configurationIcon
      case BarOptions.CUSTOMIZATION:
        return customizationIcon
      case BarOptions.INFO:
        return infoIcon
      case BarOptions.AR:
        return ARIcon
      case BarOptions.REFERENCE:
        return referenceImagesIcon
      case BarOptions.MATERIALS:
        return applyMaterialsIcon
      default:
        return undefined
    }
  }

  return (
    <Button isLoading={loading} active={active} onClick={handleClick}>
      {loading ? null : <Icon active={active} src={icon()} />}
    </Button>
  )
}

export default ViewerButton
