import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  header {
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: ${(props) => props.theme.dark};
  }

  .icon {
    width: 80%;
    margin: 15% auto 5% auto;
  }
`
