import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import EditIcon from '@console/common/assets/editSquare.svg'
import ThrashIcon from '@console/common/assets/thrash.svg'
import StatusBadge from '@console/common/components/StatusBadge'
import Toggle from '@console/common/components/Toggle'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { UserRole } from '@console/common/services/api'
import Status from '@console/common/types/Status'

import {
  HeaderStatusAndUpdate,
  HeaderBar,
  HeaderActionButtons,
  Activate,
  ActionButton,
  DeleteButton
} from './styles'

interface Props {
  loading: boolean
  status?: Status | null
  date?: Date | null
  activateCallback: () => void
  active: boolean
  openDeletePopup: () => void
  editPath: string
}

const formatDate = (d: Date): string => d.toLocaleString([], { dateStyle: 'short' })

const formatTime = (d: Date): string => d.toLocaleString([], { timeStyle: 'short' })

const UpdateAndStatus: React.FC<Props> = ({
  loading,
  status,
  date,
  activateCallback,
  active,
  openDeletePopup,
  editPath
}: Props) => {
  const { t } = useTranslation(['portfolio'])

  const { session } = useContext(AuthContext)

  return (
    <HeaderBar isLoading={loading}>
      {loading ? null : (
        <HeaderStatusAndUpdate>
          {status ? <StatusBadge status={status} /> : null}
          {date && !Number.isNaN(date.getTime()) ? (
            <h3>
              {t('detailed.lastUpdated')} {formatDate(date)}
              {t('detailed.timeSeparator')}
              {formatTime(date)}
            </h3>
          ) : null}
        </HeaderStatusAndUpdate>
      )}
      {loading ? null : (
        <HeaderActionButtons>
          {session && session.role !== UserRole.ARTIST ? (
            <Activate isLoading={loading}>
              <Toggle value={active} onChange={activateCallback} />
              <h3>{t('detailed.activateAR')}</h3>
            </Activate>
          ) : null}
          <Link to={editPath}>
            <ActionButton isLoading={loading}>
              <img src={EditIcon} alt={t('detailed.edit')} />
              <h3>{t('detailed.edit')}</h3>
            </ActionButton>
          </Link>
          {session && session.role !== UserRole.ARTIST ? (
            <DeleteButton onClick={openDeletePopup}>
              <img src={ThrashIcon} alt={t('detailed.edit')} />
              <span>{t('detailed.delete')}</span>
            </DeleteButton>
          ) : null}
        </HeaderActionButtons>
      )}
    </HeaderBar>
  )
}

export default UpdateAndStatus
