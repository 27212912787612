import styled from 'styled-components'

import { skeletonAnimation } from '@console/common/components/Skeleton'

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface Props {
  isLoading?: boolean
}

export const ViewerContainer = styled.div<Props>`
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  background-color: ${(props) => props.theme.white};
  margin-bottom: 40px;

  model-viewer {
    width: 100%;
    height: 40vh;
  }

  ${(props) => (props.isLoading ? '' : `border: 1px solid ${props.theme.grey2};`)};
  ${(props) => props.isLoading && skeletonAnimation}
`

interface RequirementsProps {
  pass?: boolean
}

export const Approval = styled.div<RequirementsProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
  margin-bottom: 24px;
  padding: 12px;
  box-sizing: border-box;

  border: 2px solid ${(props) => (props.pass ? props.theme.orange2 : props.theme.green2)};

  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
`

export const RequirementsContainer = styled.div<RequirementsProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
  padding: 24px;
  box-sizing: border-box;
`

export const Requirement = styled.div<RequirementsProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
`

export const RequirementTitle = styled.div<RequirementsProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.white};
  gap: 16px;

  img {
    width: 30px;
    height: 30px;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
  }
`

export const RequirementValues = styled.div<RequirementsProps>`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.white};

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.grey10};
  }
`

export const Separator = styled.hr`
  border: 1px solid ${(props) => props.theme.grey9};
  background-color: ${(props) => props.theme.grey9};
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const InstructionsContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 40px;
`

export const Instruction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
`

export const QRContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
  padding: 16px;
  gap: 24px;
  box-sizing: border-box;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`
