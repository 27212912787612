import styled from 'styled-components'

import Button from '@console/common/components/Button'

export const MainContainer = styled.div`
  margin-left: 44px;
  margin-right: 44px;
  margin-bottom: 62px;
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

export const RegistrationOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.grey5};
  border: 1px solid ${(props) => props.theme.lightBlue2};
  border-radius: 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 24px;

  @media screen and (min-width: 1024px) {
    width: 540px;
    height: 464px;
    gap: 21px;
    img {
      width: 176px;
      height: 275px;
    }
  }
  @media screen and (min-width: 1441px) {
    width: 740px;
    height: 664px;
    gap: 31px;
    img {
      width: 276px;
      height: 375px;
    }
  }
`

export const OptionTitle = styled.span`
  line-height: 39px;
  text-align: center;
  color: ${(props) => props.theme.black};
  @media screen and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 32px;
  }
`

export const OptionButton = styled(Button)`
  height: 44px;
  @media screen and (min-width: 1024px) {
    width: 362px;
  }
  @media screen and (min-width: 1441px) {
    width: 462px;
  }
`
