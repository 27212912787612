import styled from 'styled-components'

import Button from '@console/common/components/Button'
import { Input } from '@console/common/components/TextInput/styles'

export const CSVUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1024px) {
    height: 208px;
  }
  @media screen and (min-width: 1441px) {
    height: 258px;
  }
  border: 2px dashed ${(props) => props.theme.darkBlue4};
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey5};
`

export const CSVUploadContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 60px;
`

export const UploadHelperLabel = styled.span`
  font-weight: normal;
  line-height: 40px;
  color: ${(props) => props.theme.darkBlue4};
  @media screen and (min-width: 1024px) {
    font-size: 20px;
    width: 663px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 24px;
    width: 773px;
  }
`

export const ButtonAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

export const ImportButton = styled(Button)`
  height: 52px;
  @media screen and (min-width: 1024px) {
    width: 200px;
  }
  @media screen and (min-width: 1441px) {
    width: 290px;
  }
`

export const ButtonSubtitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue4};
  @media screen and (min-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 16px;
  }
`

export const CSVDownload = styled.span`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${(props) => props.theme.darkBlue4};
  margin-bottom: 0.5%;

  @media screen and (min-width: 1024px) {
    font-weight: 500;
    font-size: 12px;
    margin-left: 68%;
  }
  @media screen and (min-width: 1441px) {
    font-weight: 500;
    font-size: 16px;
    margin-left: 72%;
  }
`

export const ContainerHelperLabel = styled.span`
  margin-top: 10px;
  font-weight: normal;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.black};
  @media screen and (min-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 18px;
  }
`

// Table

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5%;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 100px;
`

export const TableHeader = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: justify;
  width: 20%;
  font-weight: bold;
  line-height: 29px;
  color: ${(props) => props.theme.dark2};
  @media screen and (min-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 24px;
  }
`

export const TableHeaderSubtitle = styled.span`
  font-weight: 500;
  line-height: 20px;
  color: ${(props) => props.theme.grey10};
  @media screen and (min-width: 1024px) {
    font-size: 13px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 16px;
  }
`

export const TableDivider = styled.hr`
  border: 2px solid ${(props) => props.theme.grey8};
  width: 100%;
`

export const TableForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 100px;
  :hover {
    background-color: ${(props) => props.theme.grey9};
  }
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  gap: 30px;
`

interface InputProps {
  selected?: boolean
  error?: boolean
}

export const FullInput = styled(Input)<InputProps>`
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.error ? props.theme.red5 : props.theme.red4} !important`
      : '0 !important'};
`

export const SmallInput = styled(Input)<InputProps>`
  width: 70%;
  border-radius: 0;
  background-color: transparent;
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.error ? props.theme.red5 : props.theme.red4} !important`
      : '0 !important'};
`

export const RemoveIcon = styled.img`
  width: 21px;
  height: 27px;
`

export const NewLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2%;
`

export const NewLineBtn = styled.button`
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  gap: 10px;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.darkBlue4};
  }

  img {
    width: 20px;
    height: 20px;
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const BottomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
`

interface ValidationProps {
  error?: boolean
}

export const ValidationBanner = styled.div<ValidationProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: ${(props) => props.theme.white};
  border: 4px solid ${(props) => (props.error ? props.theme.red5 : props.theme.red4)};
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 2%;
  gap: 19px;
  position: relative;
`

export const ValidationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`

export const BannerErrorType = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.dark2};
`

export const BannerTextHelper = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.grey10};
`

export const ErrorDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 34px;
`

export const ArrowButton = styled.button<ValidationProps>`
  width: 20px;
  height: 41px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  img {
    width: 5px;
    height: 12px;
    filter: ${(props) =>
      props.error
        ? // theme.red5
          'invert(13%) sepia(89%) saturate(3206%) hue-rotate(356deg) brightness(95%) contrast(88%)'
        : // theme.red4
          'invert(71%) sepia(54%) saturate(3154%) hue-rotate(323deg) brightness(99%) contrast(96%)'};
  }
  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`

export const SkipButton = styled.button`
  width: 352px;
  height: 36px;
  cursor: pointer;
  border: 0;
  background-color: ${(props) => props.theme.grey9};
  border-radius: 8px;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.dark2};
  }

  :hover {
    background-color: ${(props) => props.theme.grey5};
  }
`
