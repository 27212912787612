import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import notFound from '../../assets/404.png'
import logo from '../../assets/logo.png'
import { Body, Container, Header } from './styles'

const NotFound: React.FC = () => {
  const { t } = useTranslation(['not-found'])

  return (
    <Container>
      <Header>
        <img src={logo} alt='logo' />
      </Header>
      <Body>
        <img src={notFound} alt={t('title')} />
        <div className='text'>
          <h2>{t('title')}</h2>
          <p>{t('message')}</p>
          <Link to='/help'>
            <p>{t('support')}</p>
          </Link>
        </div>
      </Body>
    </Container>
  )
}
export default NotFound
