import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@console/common/components/Button'
import PopupBase, { PopupMode } from '@console/common/components/Popup'
import { ModalContext } from '@console/common/contexts/ModalContext'

import { deleteUser } from '../../services/api'
import { Container, MaintText, SubText, ButtonsContainer } from './styles'

export enum PopupOperation {
  INVITE = 'invite',
  DELETE = 'delete'
}

interface Props {
  operation: PopupOperation | null
  userId: string
  loadUsers: () => void
}

const HomePopup: React.FC<Props> = ({ operation, userId, loadUsers }: Props) => {
  const { t } = useTranslation(['settings'])
  const { isOpen, closeModal } = useContext(ModalContext)

  const closeCallback = () => {
    closeModal()
  }

  const confirmDelete = () => {
    deleteUser(userId)
    closeCallback()
    loadUsers()
  }

  if (operation === PopupOperation.INVITE) {
    return (
      <PopupBase mode={PopupMode.CONFIRM} trigger={isOpen}>
        <Container>
          <MaintText>{t('users.invite.popup.mainText')}</MaintText>
          <div />
          <ButtonsContainer>
            <Button onClick={closeCallback}>{t('users.invite.popup.ok')}</Button>
          </ButtonsContainer>
        </Container>
      </PopupBase>
    )
  }
  return operation ? (
    <PopupBase mode={PopupMode.ALERT} trigger={isOpen}>
      <Container>
        <MaintText>{t('users.delete.mainText')}</MaintText>
        <SubText>{t('users.delete.subText')}</SubText>
        <ButtonsContainer>
          <Button alternative onClick={closeCallback}>
            {t('users.delete.cancel')}
          </Button>
          <Button onClick={confirmDelete}>{t('users.delete.confirm')}</Button>
        </ButtonsContainer>
      </Container>
    </PopupBase>
  ) : null
}

export default HomePopup
