import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 10px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
`

export const TableHeader = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.67%;
  font-weight: bold;
  color: ${(props) => props.theme.dark2};
  @media screen and (min-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 24px;
  }
`

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  background-color: ${(props) => props.theme.white};
  height: 145px;
  border-radius: 8px;
  margin-top: 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
`

interface TableRowProps {
  wrapText?: boolean
}

export const TableRow = styled.div<TableRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 16.67%;
  @media screen and (min-width: 1024px) {
    max-width: 164.67px;
  }
  @media screen and (min-width: 1441px) {
    max-width: 257px;
  }
  span {
    width: text-align: ${(props) => (props.wrapText ? '100%' : undefined)};;
    text-align: ${(props) => (props.wrapText ? 'center' : undefined)};
    color: ${(props) => (props.wrapText ? props.theme.dark2 : undefined)};
    font-size: ${(props) => (props.wrapText ? '24px' : undefined)};
    overflow: ${(props) => (props.wrapText ? 'hidden' : undefined)};
    text-overflow: ${(props) => (props.wrapText ? 'ellipsis' : undefined)};
    white-space: ${(props) => (props.wrapText ? 'nowrap' : undefined)};
  }
`

export const HeaderIcon = styled.img`
  width: 33px;
  height: 31px;
`

export const RowIcon = styled.img`
  width: 25px;
  height: 25px;
  color: grey;
  cursor: pointer;
`

export const UploadAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 97px;
  border: 2px dashed ${(props) => props.theme.darkBlue4};
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey5};
  padding: 4px;
`

export const UploadText = styled.span`
  text-align: center;
  font-weight: 500;
  line-height: 19px;
  color: ${(props) => props.theme.darkBlue4};
  width: 100%;
  height: 38px;
  padding: 10px;

  @media screen and (min-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1441px) {
    font-size: 16px;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 97px;
  gap: 16px;
`

export const PercentageSecondsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  span {
    color: ${(props) => props.theme.darkBlue4};
  }
  @media screen and (min-width: 1024px) {
    span {
      font-size: 11px;
    }
  }
  @media screen and (min-width: 1441px) {
    span {
      font-size: 16px;
    }
  }
`

export const ImageThumbnail = styled.img`
  width: 130px;
  height: 115px;
  cursor: pointer;
  border-radius: 8px;
`

export const ModelThumbnail = styled.div`
  width: 130px;
  height: 115px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey5};

  img {
    width: 38px;
    height: 39px;
    // theme.lightBlue2
    filter: invert(87%) sepia(8%) saturate(500%) hue-rotate(167deg) brightness(88%) contrast(95%);
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.lightBlue2};
  }
`

// Modal

export const UploadModal = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.32);
`

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  height: 60%;
  background-color: ${(props) => props.theme.grey7};
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: ${(props) => props.theme.grey4};
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  padding: 32px;
  @media screen and (min-width: 1024px) {
    gap: 8px;
  }
  @media screen and (min-width: 1441px) {
    gap: 24px;
  }
`

export const UploadModalHeader = styled.span`
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.dark2};
`

export const UploadModalSubtitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.dark};
`

export const ModalUploadArea = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px dashed ${(props) => props.theme.darkBlue4};
  border-radius: 8px;
  background-color: ${(props) => props.theme.grey5};
  @media screen and (min-width: 1024px) {
    height: 308px;
  }
  @media screen and (min-width: 1441px) {
    height: 361px;
  }
`

export const ModalUploadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1024px) {
    gap: 10px;
  }
  @media screen and (min-width: 1441px) {
    gap: 16px;
    margin-top: 80px;
  }
`

export const ModalUploadIcon = styled.img`
  @media screen and (min-width: 1024px) {
    width: 70px;
    height: 72px;
  }
  @media screen and (min-width: 1441px) {
    width: 76px;
    height: 78px;
  }
  // theme.darkBlue4
  filter: invert(15%) sepia(56%) saturate(2495%) hue-rotate(174deg) brightness(95%) contrast(101%);
`

export const ModalUploadTextHelper = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: ${(props) => props.theme.darkBlue4};
`

export const ModalUploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.darkBlue4};
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.darkBlue4};
  }
  @media screen and (min-width: 1024px) {
    width: 326px;
    height: 48px;
  }
  @media screen and (min-width: 1441px) {
    width: 426px;
    height: 58px;
  }
`

export const BottomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
`

export const CarouselContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    height: 258px;
  }
  @media screen and (min-width: 1441px) {
    height: 361px;
  }
`
