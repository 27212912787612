import axios from 'axios'
import Resizer from 'react-image-file-resizer'

import config from '../config'

export const api = axios.create({ baseURL: config.api.baseURL })

export enum AssetType {
  GLB = 'GLB',
  USDZ = 'USDZ',
  RENDER = 'RENDER'
}

export enum AssetStatus {
  WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
  UNDER_REVIEW = 'UNDER_REVIEW',
  TEAM_APPROVED = 'TEAM_APPROVED',
  CUSTOMER_APPROVED = 'CUSTOMER_APPROVED',
  TEAM_REJECTED = 'TEAM_REJECTED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED'
}

export enum ReferenceType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export enum Language {
  PT = 'pt',
  EN = 'en'
}

export enum ProductPlacement {
  FLOOR = 'FLOOR',
  WALL = 'WALL'
}

export interface Asset {
  id: string
  type: AssetType
  url: string
  status: AssetStatus
  size?: string
  Comments: Comment[]
}

export interface Comment {
  id: string
  content: string
  attachments: string[]
  createdAt: string
  userId?: string // represents the user email
}

export interface Version {
  id: string
  name: string
  createdAt: string
  Assets: Asset[]
}

export interface Reference {
  id: string
  name: string
  type: ReferenceType
  url: string
  description: string
  createdAt: string
}

export interface ViewerOptions {
  id?: string
  bloom?: boolean
  initialBloom?: boolean
  enableBloomButton?: boolean
  bloomIntensity?: number
  bloomRadius?: number
  bloomThreshold?: number
  initialAnimation?: boolean
  exposure?: number
  hdri?: string
  initialZoom?: number
  minZoom?: number
  maxZoom?: number
  sceneBackground?: boolean
  backgroundImage?: string
  backgroundColor?: string
  activeShadow?: boolean
  activeBakedShadow?: boolean
  bakedShadowUrl?: string
  shadowOffsetX?: number
  shadowOffsetY?: number
  shadowOffsetZ?: number
  shadowOpacity?: number
  shadowRadius?: number
  minAzimuthalAngleRotation?: number
  maxAzimuthalAngleRotation?: number
  minPolarAngleRotation?: number
  maxPolarAngleRotation?: number
  maxDragX?: number
  minDragX?: number
  maxDragY?: number
  minDragY?: number
  maxDragZ?: number
  minDragZ?: number
  cameraNear?: number
  cameraFar?: number
  cameraOrbitSensitivity?: number
  activeFog?: boolean
  fogColor?: string
  fogNear?: number
  fogFar?: number
  customerId?: string
  productId?: string
  customerProductId?: string
  active?: boolean
  deleted?: boolean
  createdAt?: Date
  updatedAt?: Date
}

export interface Product {
  id: string
  name: string
  owner: string
  ean: string[]
  customerId: string
  sku: string
  hdri?: string
  exposure?: number
  bloom?: boolean
  ViewerOption?: ViewerOptions
  internal: boolean
  active: boolean
  status: AssetStatus
  Versions: Version[]
  References: Reference[]
  Customers?: Customer[]
  pdpUrl: string
  placement?: ProductPlacement
  metadata?: {
    depth?: string
    width?: string
    height?: string
  }
  createdAt?: Date
  updatedAt?: Date
}

export interface CustomerProduct {
  id?: string
  productId?: string
  customerId?: string
  sku: string
  active: boolean
  name?: string
  pdpUrl?: string
  ViewerOption?: ViewerOptions
}

export interface Customer {
  id: string
  name: string
  slug: string
  language: Language
  active: boolean
  waterMarkPosition: string
  logoUrl: string
  CustomerProduct?: CustomerProduct
  ViewerOption?: ViewerOptions
  metadata?: {
    jira?: {
      epicKey?: string
    }
    email?: string
    hana3d?: {
      libraryId?: string
    }
    performance?: {
      'ga4-analytics'?: string
    }
  }
}

export enum UserRole {
  ARTIST = 'ARTIST',
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  R2USER = 'R2USER',
  FREELANCER = 'FREELANCER'
}

export interface User {
  id: string
  auth0Id: string
  name: string
  customerId: string
  role: UserRole
}

interface Session {
  id: string
  role: UserRole
  name: string
  customer: Customer
}

export enum OrderOptions {
  A_TO_Z = 'name',
  Z_TO_A = 'name desc',
  SKU = 'sku',
  OLDER = 'date',
  NEWER = 'date desc'
}

interface FetchAllProductsParams {
  order: OrderOptions
  status?: AssetStatus[] | null
  active?: boolean | null
  customerId?: string | null
  search?: string | null
  lastMonthActive?: string | null
}

interface FetchProductsParams {
  order: OrderOptions
  page: number
  pageSize?: number
  status?: AssetStatus[] | null
  active?: boolean | null
  customerId?: string | null
  search?: string | null
  lastMonthActive?: string | null
}

export const fetchProducts = async ({
  order,
  page,
  pageSize = 30,
  status = null,
  active = null,
  customerId = null,
  search = null,
  lastMonthActive = null
}: FetchProductsParams): Promise<{ results: Product[]; total: number; start: number } | null> => {
  try {
    const params = {
      customerId,
      orderBy: order,
      page,
      pageSize,
      status: status?.join(','),
      active,
      search,
      lastMonthActive
    }
    const { data: products } = await api.get<{ results: Product[]; total: number; start: number }>(
      '/v1/products',
      { params }
    )
    return products
  } catch (e) {
    return null
  }
}

export const createViewerOptions = async (
  data: ViewerOptions,
  productId?: string,
  customerId?: string
): Promise<{ status: number; data: ViewerOptions } | null> => {
  try {
    const {
      id,
      productId: pId,
      customerId: cId,
      customerProductId: cpId,
      deleted,
      createdAt,
      updatedAt,
      ...payload
    } = data

    if (productId || customerId) {
      let url = ''
      if (productId && customerId) {
        url = `/v1/customers/${customerId}/products/${productId}/viewer-options`
      } else if (productId) {
        url = `/v1/products/${productId}/viewer-options`
      } else {
        url = `/v1/customers/${customerId}/viewer-options`
      }
      const { data: viewerOptions, status } = await api.post<ViewerOptions>(url, { ...payload })
      return {
        status,
        data: viewerOptions
      }
    }
    return null
  } catch (e) {
    return null
  }
}

interface UpdateViewerOptionsProps {
  data: ViewerOptions
  viewerOptionsId?: string
  productId?: string
  customerId?: string
}

export const updateViewerOptions = async ({
  data,
  viewerOptionsId,
  productId,
  customerId
}: UpdateViewerOptionsProps): Promise<{ status: number; data: ViewerOptions } | null> => {
  try {
    const {
      id,
      productId: pId,
      customerId: cId,
      customerProductId: cpId,
      deleted,
      createdAt,
      updatedAt,
      ...payload
    } = data

    if (viewerOptionsId) {
      const viewerOptionsIdResponse = await api.patch<ViewerOptions>(
        `/v1/viewer-options/${viewerOptionsId}`,
        { ...payload }
      )
      return {
        status: viewerOptionsIdResponse.status,
        data: viewerOptionsIdResponse.data
      }
    }

    let url = ''
    if (productId && customerId) {
      url = `/v1/customers/${customerId}/products/${productId}/viewer-options`
    } else if (productId) {
      url = `/v1/products/${productId}/viewer-options`
    } else if (customerId) {
      url = `/v1/customers/${customerId}/viewer-options`
    }
    if (url) {
      const { data: viewerOptions, status } = await api.patch<ViewerOptions>(url, { ...payload })
      return {
        status,
        data: viewerOptions
      }
    }
    return null
  } catch (e) {
    return null
  }
}

export const changeProductHdri = async (
  productId: string,
  hdriUrl: string
): Promise<Product | null> => {
  try {
    const data = { hdri: hdriUrl }
    const { data: product } = await api.patch<Product>(`/v1/products/${productId}`, data)
    return product
  } catch (e) {
    return null
  }
}

export const changeProductExposure = async (
  productId: string,
  exposure: number
): Promise<Product | null> => {
  try {
    const data = { exposure }
    const { data: product } = await api.patch<Product>(`/v1/products/${productId}`, data)
    return product
  } catch (e) {
    return null
  }
}

export const getSession = async (): Promise<Session | null> => {
  try {
    const { data: session } = await api.get<Session>('/v1/session')
    return session
  } catch (e) {
    return null
  }
}

export const getCustomer = async (customerId: string): Promise<Customer | null> => {
  try {
    const { data } = await api.get<Customer>(`/v1/customers/${customerId}`)
    return data
  } catch (e) {
    return null
  }
}

export const getCustomers = async (): Promise<Customer[]> => {
  try {
    const { data: customers } = await api.get<Customer[]>('/v1/customers')
    return customers
  } catch (e) {
    return []
  }
}

export const getUploadUrl = async (
  extension?: string,
  path?: string,
  fileName?: string
): Promise<string | null> => {
  try {
    const params = {} as { extension: string; path: string; fileName: string }
    if (extension) params.extension = extension
    if (path) params.path = path
    if (fileName) params.fileName = fileName

    const { data: uploadUrl } = await api.get<string>('/v1/upload', { params })
    return uploadUrl
  } catch (e) {
    return null
  }
}

export const updateAsset = async (id: string, data: Partial<Asset>): Promise<Asset | null> => {
  try {
    const { data: asset } = await api.patch<Asset>(`/v1/assets/${id}`, data)
    return asset
  } catch (e) {
    return null
  }
}

export const getAssetSize = async (asset: Asset): Promise<string> => {
  const {
    headers: { 'content-length': contentLength }
  } = await axios.head(asset.url)
  const bytes = Number(contentLength)
  return `${(bytes / 1024 / 1024).toFixed(2)} MB`
}

export const createReference = async (
  productId: string,
  data: Partial<Reference>
): Promise<Reference | null> => {
  try {
    const { data: reference } = await api.post<Reference>(
      `/v1/products/${productId}/references`,
      data
    )
    return reference
  } catch (e) {
    return null
  }
}

export const fetchAllProducts = async ({
  order,
  status = null,
  active = null,
  customerId = null,
  search = null,
  lastMonthActive = null
}: FetchAllProductsParams): Promise<Blob | null> => {
  try {
    const params = {
      customerId,
      orderBy: order,
      status: status?.join(','),
      active,
      search,
      lastMonthActive
    }
    const { data: products } = await api.get<string>('/v1/products/export', {
      params
    })
    const base64 = await fetch(`data:application/zip;base64,${products}`)
    return base64.blob()
  } catch (e) {
    return null
  }
}

interface UploadModelResponse {
  assetId: string
  viewId: string
  uploadUrl: string
  outputUrl: string
}

export const getModelPresignedUrl = async (
  productId: string,
  extension: string
): Promise<UploadModelResponse | null> => {
  try {
    const { data } = await api.get<UploadModelResponse>(`/v1/upload/model/${productId}`, {
      params: { extension }
    })
    return data
  } catch (e) {
    return null
  }
}

export const createHanaAsset = async (name: string): Promise<string | null> => {
  try {
    const { data } = await api.post<string>('/v1/upload/hana/asset', { name })
    return data
  } catch (e) {
    return null
  }
}

export const getModelPresignedWithoutId = async (
  assetId: string,
  customerId: string,
  sku: string,
  extension: string
): Promise<UploadModelResponse | null> => {
  try {
    const { data } = await api.get<UploadModelResponse>('/v1/upload/model', {
      params: { assetId, customerId, sku, extension }
    })
    return data
  } catch (e) {
    return null
  }
}

export const confirmModelUpload = async (assetId: string, viewId: string): Promise<void | null> => {
  try {
    const { data } = await api.patch<void>('/v1/upload/model', { assetId, viewId })
    return data
  } catch (e) {
    return null
  }
}

export const updateHanaAsset = async (assetId: string, productId: string): Promise<void | null> => {
  try {
    const { data } = await api.patch<void>('/v1/upload/hana/asset', { assetId, productId })
    return data
  } catch (e) {
    return null
  }
}

export const patchProductStatus = async (
  productIds: string[],
  status: string
): Promise<boolean> => {
  try {
    const { data: success } = await api.patch<boolean>('/v1/reviews/approve', {
      productIds,
      status
    })
    return success
  } catch (e) {
    return false
  }
}

const getThumbUrl = (url: string): string => {
  const urlSplit = url.split('/')
  const filename = urlSplit.pop()
  if (urlSplit.length) return `${urlSplit.join('/')}/thumbs/${filename}`
  return `thumbs/${filename}`
}

export const getResizedImage = async (file: File, size = 150): Promise<File> =>
  new Promise<File>((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      `${file.name.split('.')?.pop()}`,
      100,
      0,
      (result) => {
        resolve(result as File)
      },
      'file',
      size,
      size
    )
  })

const uploadToUrl = async (
  file: File,
  uploadUrl: string,
  onEnd?: (uploadUrl: string) => void,
  handlePercentage?: (value: number) => void
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    let extension = file.name.split('.').pop()
    if (extension) extension = `.${extension}`

    const xhr = new XMLHttpRequest()

    if (handlePercentage) {
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          handlePercentage((e.loaded / e.total) * 100)
        }
      }
    }

    xhr.onload = () => {
      if (onEnd) {
        onEnd(uploadUrl.split('?')[0])
      }
      resolve()
    }

    xhr.open('PUT', uploadUrl, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.send(file)
  })

const getApiPath = (path: string) => {
  const urlSplit = path.split('/')
  urlSplit.pop()
  let result = ''
  let currentFolder = urlSplit.pop()
  while (currentFolder?.indexOf('reviewer') === -1) {
    result = `${currentFolder}/${result}`
    currentFolder = urlSplit.pop()
  }
  return result
}

const uploadThumbnailFor = async (path: string): Promise<void> => {
  if (!path) {
    return
  }

  const urlSplit = path.split('/')
  const filename = urlSplit.pop()
  const pathname = getApiPath(path)

  const request = await axios.get(path, { responseType: 'blob' })
  const file = new File([request.data], filename as string, {
    type: request.headers['content-type']
  })
  if (file.size > 2_000_000) {
    const thumbnail = await getResizedImage(file, 150)
    const uploadUrl = await getUploadUrl(
      `.${filename?.split('.')[1]}`,
      `${pathname}thumbs`,
      filename?.split('.')[0]
    )
    await uploadToUrl(thumbnail, uploadUrl as string)
  }
}

export const getThumbIfExists = async (path: string): Promise<string> => {
  if (!path) {
    return ''
  }

  let thumbPath = getThumbUrl(path)
  await axios.head(thumbPath).catch(() => {
    uploadThumbnailFor(path)
    thumbPath = path
  })
  return thumbPath
}
