import styled, { css } from 'styled-components'

export interface Props {
  expanded: boolean
}

export const Container = styled.div<Props>`
  width: 88px;
  transition: width 0.3s ease-in-out;
  height: 100%;
  overflow: hidden;

  ${(props) =>
    props.expanded &&
    css`
      width: 240px;
    `};
`

export const Children = styled.div<Props>`
  position: absolute;
  top: 0px;
  left: 88px;
  width: calc(100% - 88px);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;

  ${(props) =>
    props.expanded &&
    css`
      width: calc(100% - 240px);
      left: 240px;
    `};
`

export const CloseSideBarIcon = styled.button<Props>`
  cursor: pointer;
  border: none;
  z-index: 6;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 40px;

  transition: left 0.3s ease-in-out;
  left: calc(-10px + ${(props) => (props.expanded ? '240px' : '88px')});

  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
`

export const Icon = styled.img<Props>`
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;

  margin-right: 15%;
  color: #fff;
  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(180deg);
    `};
`
