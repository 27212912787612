import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react'

import { nanoid } from 'nanoid/non-secure'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import CameraIcon from '@console/common/assets/cameraLine.svg'
import CarouselCard from '@console/common/components/CarouselCard'
import MultiCarousel from '@console/common/components/MultiCarousel'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { upload } from '@console/common/services/upload'
import theme from '@console/common/theme.json'

import NextButton from '../../../components/NextButton'
import { RegistrationSteps, Image } from '../../../contexts/ProcessContext'
import { UploadContainer, CarouselContainer, UploadContainerEmpty, UploadButton } from './styles'

const ImageUpload: React.FC = () => {
  const { t } = useTranslation(['registration'])
  const [uploading, setUploading] = useState(false)
  const [images, setImages] = useState<Image[]>([])
  const [imageSlides, setImageSlides] = useState<React.ReactNode[]>([])

  const { analytics } = useContext(AnalyticsContext)

  const carouselResponsive = {
    three: {
      breakpoint: { max: 3000, min: 1700 },
      items: 3,
      slidesToSlide: 1
    },
    two: {
      breakpoint: { max: 1700, min: 500 },
      items: 2,
      slidesToSlide: 1
    }
  }

  useEffect(() => {
    const imageUploadItem = localStorage.getItem('imageUpload')
    if (imageUploadItem) {
      setImages(JSON.parse(imageUploadItem))
    }
  }, [])

  const deleteImage = useCallback(
    async (id: string) => {
      analytics?.track(AnalyticsEvent.REGISTRATION_DELETE_IMAGE)
      setImages(images.filter((image) => image.id !== id))
    },
    [images]
  )

  useEffect(() => {
    const slides = images.map((image) => (
      <React.Fragment key={image.id}>
        <CarouselCard
          type='IMAGE'
          imageUrl={image.url}
          deleteCallback={() => deleteImage(image.id)}
          height='334px'
          width='400px'
        />
      </React.Fragment>
    ))
    if (uploading) {
      slides.push(<CarouselCard key={nanoid()} type='UPLOADING' height='334px' width='400px' />)
    }
    setImageSlides(slides)
  }, [images, uploading])

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true)
      analytics?.track(AnalyticsEvent.REGISTRATION_UPLOAD_IMAGE)

      const newImages = [...images]

      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          await upload(
            file,
            'registration',
            async (uploadUrl: string) => {
              const id = nanoid()
              setImages(newImages.concat([{ id, url: uploadUrl }]))
              newImages.push({ id, url: uploadUrl })
            },
            undefined,
            true
          )
          return 'OK'
        })
      )
      setUploading(false)
    },
    [images]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/*',
    noClick: !!images.length,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const baseStyle = {
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.dark,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .15s ease-in-out'
  }

  const activeStyle = {
    borderColor: theme.blue
  }

  const acceptStyle = {
    borderColor: theme.green
  }

  const rejectStyle = {
    borderColor: theme.red
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const saveInfo = () => {
    localStorage.setItem('imageUpload', JSON.stringify(images))
  }

  return (
    <div>
      <UploadContainer {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {imageSlides.length ? (
          <CarouselContainer>
            <MultiCarousel slides={imageSlides} responsive={carouselResponsive} />
          </CarouselContainer>
        ) : (
          <UploadContainerEmpty>
            <img src={CameraIcon} alt='camera' />
            <span>{t('imageUpload.dragNDrop')}</span>
            <UploadButton>
              <span>{t('imageUpload.select')}</span>
            </UploadButton>
          </UploadContainerEmpty>
        )}
      </UploadContainer>
      <NextButton nextPage={RegistrationSteps.MODEL_DECISION} onClick={() => saveInfo()} />
    </div>
  )
}

export default ImageUpload
