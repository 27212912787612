import React, { createContext, ReactNode, Ref, useEffect, useRef, useState } from 'react'

import { ViewerRef } from '@r2u/viewer/dist/types'

import {
  createViewerOptions,
  updateViewerOptions,
  Customer,
  Product,
  ViewerOptions
} from '../services/api'
import setDefaultSlotsModels from '../utils/setDefaultSlotsModels'
import viewerLoaded from '../utils/viewerLoaded'

type SlotsModel = ReturnType<ViewerRef['getAllSlotsModels']>

interface Props {
  children: ReactNode
  product: Product
  customer?: Customer | null
}

interface ConfigurationContextData {
  setViewerOptions: (viewerOptions: ViewerOptions) => void
  viewerOptions: ViewerOptions
  saveViewerOptions: (viewerOptions: ViewerOptions) => Promise<void>
  updateViewer: (data: UpdateViewerProps) => void
  viewerRef: Ref<ViewerRef>
  loading: boolean
}

interface UpdateViewerProps extends ViewerOptions {
  radius?: number
  dragX?: number
  dragY?: number
  dragZ?: number
  bloomOn?: boolean
}

const isExclusive = (a: unknown, b: unknown): boolean =>
  (a !== undefined && b === undefined) || (a === undefined && b !== undefined)

export const ConfigurationContext: React.Context<ConfigurationContextData> =
  createContext<ConfigurationContextData>({} as ConfigurationContextData)

export const ConfigurationProvider: React.FC<Props> = ({ children, product, customer }: Props) => {
  const [loading, setLoading] = useState(true)
  const [viewerOptions, setViewerOptions] = useState<ViewerOptions>({} as ViewerOptions)

  const viewerRef = useRef<ViewerRef>(null)

  /**
   * ! Disclaimer
   * @function setDefaultSlotsModels sets an initial configuration for the customizable
   * model and is only meant to be used while the native default glb configuration is not
   * yet implemented. When such a feature is implemented on the viewer, the following
   * segment of the code should be deleted.
   */

  // TODO Delete code segment start
  const [slotsModels, setSlotsModels] = useState<SlotsModel>(null)

  useEffect(() => {
    viewerLoaded(viewerRef).then(() => {
      if (!viewerRef.current) return

      setSlotsModels(viewerRef.current.getAllSlotsModels())
    })
  }, [])

  if (viewerRef.current) {
    setDefaultSlotsModels({
      slotsModels,
      setSlotsModels,
      viewerRef: viewerRef.current
    })
  }
  // TODO Delete code segment end

  useEffect(() => {
    const customerOwner = product.Customers?.find((pCustomer) => pCustomer.id === product.owner)

    if (customerOwner?.ViewerOption) setViewerOptions(customerOwner.ViewerOption)
    if (product?.ViewerOption) setViewerOptions(product.ViewerOption)
    if (customer?.CustomerProduct?.ViewerOption) {
      setViewerOptions(customer.CustomerProduct.ViewerOption)
    }

    setLoading(false)
  }, [])

  const saveViewerOptions = async (data: ViewerOptions) => {
    if (customer) {
      let newViewerOptions: { status: number; data: ViewerOptions } | null = null

      const payload = {
        ...viewerOptions,
        ...data
      }

      setViewerOptions(payload)

      if (product.owner === customer.id) {
        newViewerOptions = await updateViewerOptions({ data: payload, productId: product.id })
        if (!newViewerOptions) newViewerOptions = await createViewerOptions(payload, product.id)
      }

      newViewerOptions = await updateViewerOptions({
        data: payload,
        productId: product.id,
        customerId: customer.id
      })

      if (!newViewerOptions) {
        newViewerOptions = await createViewerOptions(payload, product.id, customer.id)
      }
    }
  }

  const updateViewer = (data: UpdateViewerProps) => {
    if (!viewerRef) return

    const {
      exposure,
      hdri,
      sceneBackground,
      initialAnimation,
      activeFog,
      fogColor,
      fogNear,
      fogFar,
      activeShadow,
      shadowOpacity,
      shadowOffsetX,
      shadowOffsetY,
      shadowOffsetZ,
      shadowRadius,
      cameraOrbitSensitivity,
      cameraNear,
      cameraFar,
      radius,
      maxZoom,
      minZoom,
      initialZoom,
      maxAzimuthalAngleRotation,
      minAzimuthalAngleRotation,
      maxPolarAngleRotation,
      minPolarAngleRotation,
      maxDragX,
      minDragX,
      maxDragY,
      minDragY,
      maxDragZ,
      minDragZ,
      dragX,
      dragY,
      dragZ,
      initialBloom,
      bloomIntensity,
      bloomRadius,
      bloomThreshold,
      bloomOn
    } = data

    viewerRef.current?.updateEnvironment({ exposure, hdri, sceneBackground, initialAnimation })
    viewerRef.current?.updateFog({
      enable: activeFog,
      color: fogColor,
      near: fogNear,
      far: fogFar
    })
    viewerRef.current?.updateShadow({
      activeShadow,
      opacity: shadowOpacity,
      XOffset: shadowOffsetX,
      YOffset: shadowOffsetY,
      ZOffset: shadowOffsetZ,
      radius: shadowRadius
    })
    viewerRef.current?.setCamera({
      orbitSensitivity: cameraOrbitSensitivity,
      near: cameraNear,
      far: cameraFar,
      maxRadius: minZoom,
      minRadius: maxZoom,
      initialRadius: initialZoom,
      radius: initialZoom !== undefined ? initialZoom : radius,
      maxAzimuthalAngle: maxAzimuthalAngleRotation,
      minAzimuthalAngle: minAzimuthalAngleRotation,
      maxPolarAngle: maxPolarAngleRotation,
      minPolarAngle: minPolarAngleRotation,
      maxDragX,
      minDragX,
      maxDragY,
      minDragY,
      maxDragZ,
      minDragZ,
      dragX,
      dragY,
      dragZ
    })
    if (initialBloom !== undefined) viewerRef.current?.updateBloom(initialBloom)
    if (bloomOn !== undefined) viewerRef.current?.updateBloom(bloomOn)
    viewerRef.current?.updateBloomProps({
      intensity: bloomIntensity,
      radius: bloomRadius,
      threshold: bloomThreshold
    })

    if (isExclusive(minZoom, maxZoom)) {
      if (minZoom !== undefined) viewerRef.current?.setCamera({ radius: minZoom })
      else viewerRef.current?.setCamera({ radius: maxZoom })
    }

    if (isExclusive(minAzimuthalAngleRotation, maxAzimuthalAngleRotation)) {
      if (minAzimuthalAngleRotation !== undefined) {
        viewerRef.current?.setCamera({ azimuthalAngle: minAzimuthalAngleRotation })
      } else {
        viewerRef.current?.setCamera({ azimuthalAngle: maxAzimuthalAngleRotation })
      }
    }

    if (isExclusive(minPolarAngleRotation, maxPolarAngleRotation)) {
      if (minPolarAngleRotation !== undefined) {
        viewerRef.current?.setCamera({ polarAngle: minPolarAngleRotation })
      } else {
        viewerRef.current?.setCamera({ polarAngle: maxPolarAngleRotation })
      }
    }

    if (isExclusive(minDragX, maxDragX)) {
      if (minDragX !== undefined) {
        viewerRef.current?.setCamera({ dragX: minDragX })
      } else {
        viewerRef.current?.setCamera({ dragX: maxDragX })
      }
    }

    if (isExclusive(minDragY, maxDragY)) {
      if (minDragY !== undefined) {
        viewerRef.current?.setCamera({ dragX: minDragY })
      } else {
        viewerRef.current?.setCamera({ dragX: maxDragY })
      }
    }

    if (isExclusive(minDragZ, maxDragZ)) {
      if (minDragZ !== undefined) {
        viewerRef.current?.setCamera({ dragX: minDragZ })
      } else {
        viewerRef.current?.setCamera({ dragX: maxDragZ })
      }
    }
  }

  return (
    <ConfigurationContext.Provider
      value={{
        viewerOptions,
        setViewerOptions,
        saveViewerOptions,
        updateViewer,
        viewerRef,
        loading
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  )
}
